<template>
    <div>
        <personal-competitive-purchase v-if="purchase.product.id === 'competitive'" :purchase="purchase" @refresh="refresh" />
        <personal-direct-purchase v-if="purchase.product.id === 'direct'" :purchase="purchase" @refresh="refresh" />
        <personal-simple-purchase v-if="purchase.product.id === 'simple'" :purchase="purchase" @refresh="refresh" />
    </div>
</template>

<script>
import PersonalCompetitivePurchase from '@/components/cabinet/components/personal-competitive-purchase.vue';
import PersonalSimplePurchase from '@/components/cabinet/components/personal-simple-purchase.vue';
import PersonalDirectPurchase from '@/components/cabinet/components/personal-direct-purchase.vue';

export default {
    name: 'personal-purchase',
    components: { PersonalDirectPurchase, PersonalSimplePurchase, PersonalCompetitivePurchase },
    props: ['purchase'],
    methods: {
        refresh() {
            this.$emit('refresh');
        },
    },
};
</script>

<style scoped>
.purchase_part {
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
}

.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}

@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div {
        position: relative;
    }

    .purchase_part-body_col > div::before {
        content: '';
        width: 100% !important;
        height: 1px !important;
        top: -12px;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        /*margin-left: 5px !important;*/
    }

    /*.purchase_status {*/
    /*    margin-left: 5px !important;*/
    /*}*/
}

@media screen and (max-width: 768px) {
    .purchase_part-body_col::before {
        content: '';
        display: none;
    }
}

@media screen and (max-width: 420px) {
    .purchase_part-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 65px;
    }
}
</style>
<style>
.draft.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--est-blue-2);
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.draft .purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.draft .purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}
</style>
<style scoped>
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #959ba4;
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.purchase_part-header_number {
    font-size: 14px !important;
    color: #fff;
    margin: 0;
    font-weight: 600;
}
.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}
.status_color_2,
.status_color_3,
.status_color_4 {
    background-color: var(--est-blue-2);
}
.status_color_7,
.status_color_8,
.status_color_5,
.status_color_6 {
    background-color: #f6b33a;
}
</style>
