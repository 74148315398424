var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-4", attrs: { fluid: "" } },
        [
          _c(
            "b-form",
            {
              ref: "filter",
              staticClass: "w-100",
              attrs: { autocomplete: "off" },
              on: {
                focusin: function ($event) {
                  _vm.alert = false
                },
              },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "fs-52 fw-600 white-color mt-2 mb-4 less_font-size_mobile",
                                  },
                                  [_vm._v("Реестр организаций")]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "filter" },
                            [
                              _c("filter-input-text", {
                                attrs: {
                                  label: "Название",
                                  md: "6",
                                  lg: "6",
                                  placeholder: "Укажите название организации",
                                },
                                model: {
                                  value: _vm.filter.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "name", $$v)
                                  },
                                  expression: "filter.name",
                                },
                              }),
                              _c("filter-input-text", {
                                attrs: {
                                  label: "ИНН",
                                  placeholder: "Укажите ИНН организации",
                                },
                                model: {
                                  value: _vm.filter.inn,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "inn", $$v)
                                  },
                                  expression: "filter.inn",
                                },
                              }),
                              _c("filter-input-text", {
                                attrs: {
                                  label: "ОКПО",
                                  placeholder: "Укажите ОКПО организации",
                                },
                                model: {
                                  value: _vm.filter.okpo,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "okpo", $$v)
                                  },
                                  expression: "filter.okpo",
                                },
                              }),
                              _c("filter-input-text", {
                                attrs: {
                                  label: "Рег.номер",
                                  placeholder:
                                    "Укажите регистрационный номер организации",
                                },
                                model: {
                                  value: _vm.filter.reg_number,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "reg_number", $$v)
                                  },
                                  expression: "filter.reg_number",
                                },
                              }),
                              _c("filter-input-text", {
                                attrs: {
                                  label: "Юридический адрес",
                                  md: "6",
                                  lg: "6",
                                  placeholder:
                                    "Укажите юридический адрес организации",
                                },
                                model: {
                                  value: _vm.filter.address,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "address", $$v)
                                  },
                                  expression: "filter.address",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-4" },
                            [
                              _c(
                                "b-col",
                                { staticClass: "flex_col-mobile" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: { variant: "custom-white" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getOrganizations(false)
                                        },
                                      },
                                    },
                                    [_vm._v("Найти ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.clearFilter },
                                    },
                                    [_vm._v("Сбросить")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.loading ? _c("system-preloader") : _vm._e(),
      _c(
        "b-container",
        { staticClass: "greyBg pb-4", attrs: { fluid: "" } },
        [
          !_vm.loading && !_vm.organizations.length
            ? _c("b-container", [_c("empty-request")], 1)
            : _vm._e(),
          _c(
            "b-container",
            [
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c("b-pagination", {
                    staticStyle: { "padding-bottom": "0" },
                    attrs: {
                      align: "center",
                      "total-rows": _vm.meta.total,
                      "per-page": _vm.meta.per_page,
                    },
                    on: { change: _vm.applyFilters },
                    model: {
                      value: _vm.meta.current_page,
                      callback: function ($$v) {
                        _vm.$set(_vm.meta, "current_page", $$v)
                      },
                      expression: "meta.current_page",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "pt-4" },
                _vm._l(_vm.organizations, function (organization) {
                  return _c("organization-card", {
                    key: organization.id,
                    attrs: { organization: organization },
                  })
                }),
                1
              ),
              _c(
                "div",
                { staticStyle: { width: "100%" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "center",
                      "total-rows": _vm.meta.total,
                      "per-page": _vm.meta.per_page,
                    },
                    on: { change: _vm.applyFilters },
                    model: {
                      value: _vm.meta.current_page,
                      callback: function ($$v) {
                        _vm.$set(_vm.meta, "current_page", $$v)
                      },
                      expression: "meta.current_page",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }