var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !!_vm.organization
        ? _c(
            "b-container",
            { staticClass: "main_layer py-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { staticClass: "my-1" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-between column-768",
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass: "fs-40 fw-600 white-color main_text",
                            },
                            [_vm._v(_vm._s(_vm.organization.name))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.organization.country_name
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("div", [
                              _c(
                                "p",
                                { staticClass: "fs-20 white-color my-1" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.organization.country_name) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-container",
        [
          !!_vm.organization
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pb-5" },
                    [
                      _c("text-header", [_vm._v("Реквизиты организации")]),
                      _c("text-row", {
                        attrs: { label: "ИНН" },
                        model: {
                          value: _vm.organization.inn,
                          callback: function ($$v) {
                            _vm.$set(_vm.organization, "inn", $$v)
                          },
                          expression: "organization.inn",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "ОКПО" },
                        model: {
                          value: _vm.organization.okpo,
                          callback: function ($$v) {
                            _vm.$set(_vm.organization, "okpo", $$v)
                          },
                          expression: "organization.okpo",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "Рег.номер" },
                        model: {
                          value: _vm.organization.registration_number,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.organization,
                              "registration_number",
                              $$v
                            )
                          },
                          expression: "organization.registration_number",
                        },
                      }),
                      _c("text-row-date", {
                        attrs: { label: "Дата регистрации" },
                        model: {
                          value: _vm.organization.registration_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.organization, "registration_date", $$v)
                          },
                          expression: "organization.registration_date",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "Руководитель" },
                        model: {
                          value: _vm.organization.head,
                          callback: function ($$v) {
                            _vm.$set(_vm.organization, "head", $$v)
                          },
                          expression: "organization.head",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "Юридический адрес" },
                        model: {
                          value: _vm.organization.legal_address,
                          callback: function ($$v) {
                            _vm.$set(_vm.organization, "legal_address", $$v)
                          },
                          expression: "organization.legal_address",
                        },
                      }),
                      _c("text-header", [_vm._v("Контактная информация")]),
                      _c("text-row", {
                        attrs: { label: "Почтовый адрес" },
                        model: {
                          value: _vm.organization.postal_address,
                          callback: function ($$v) {
                            _vm.$set(_vm.organization, "postal_address", $$v)
                          },
                          expression: "organization.postal_address",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "Email" },
                        model: {
                          value: _vm.organization.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.organization, "email", $$v)
                          },
                          expression: "organization.email",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "Сайт" },
                        model: {
                          value: _vm.organization.website,
                          callback: function ($$v) {
                            _vm.$set(_vm.organization, "website", $$v)
                          },
                          expression: "organization.website",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "Телефон" },
                        model: {
                          value: _vm.organization.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.organization, "phone", $$v)
                          },
                          expression: "organization.phone",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "ФИО контактного лица" },
                        model: {
                          value: _vm.organization.contact_person_name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.organization,
                              "contact_person_name",
                              $$v
                            )
                          },
                          expression: "organization.contact_person_name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }