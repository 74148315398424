var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-2" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "purchase_part" }, [
              _c(
                "div",
                { staticClass: "purchase_part-header status_color_2" },
                [
                  _c("b-col", [
                    _c(
                      "p",
                      { staticClass: "purchase_part-header_number" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "white-system_link-style fw-600",
                            attrs: {
                              to: {
                                name: "CompetitivePurchaseShow",
                                params: { id: _vm.purchase.id },
                              },
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.purchase.product.title) +
                                " - № " +
                                _vm._s(_vm.purchase.registration_number) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "p",
                      { staticClass: "purchase_part-header_label fw-600" },
                      [_vm._v(" " + _vm._s(_vm.purchase.status.title) + " ")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "purchase_part-body row" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c(
                        "div",
                        [
                          _vm.purchase.status.id === "accepting"
                            ? _c("div", { staticClass: "my-2" }, [
                                _c(
                                  "p",
                                  { staticClass: "fs-12 m-0 grey-color" },
                                  [_vm._v("До окончания приема заявок:")]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "fs-18 m-0 grey-color fw-600 time_to_end",
                                  },
                                  [
                                    !!_vm.purchase.purchase_end_datetime
                                      ? _c("timer-new", {
                                          attrs: {
                                            data: _vm.purchase
                                              .purchase_end_datetime,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm.$store.getters.isLoggedIn &&
                          (_vm.purchase.actions.supplier.proposal.create ||
                            _vm.purchase.actions.supplier.proposal.edit)
                            ? [
                                _vm.purchase.actions.supplier.has_money.status
                                  ? [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            to: {
                                              name: "CompetitivePurchaseNewProposal",
                                              params: { id: _vm.purchase.id },
                                            },
                                            target: "_blank",
                                            variant: "custom-blue",
                                          },
                                        },
                                        [
                                          _vm.purchase.actions.supplier.proposal
                                            .create
                                            ? [_vm._v(" Подать заявку")]
                                            : _vm._e(),
                                          _vm.purchase.actions.supplier.proposal
                                            .edit
                                            ? [_vm._v(" Изменить заявку")]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            id:
                                              "popover-" +
                                              _vm.purchase.product.id +
                                              "-" +
                                              _vm.purchase.id,
                                            target: "_blank",
                                            variant: "custom-blue",
                                          },
                                        },
                                        [
                                          _vm.purchase.actions.supplier.proposal
                                            .create
                                            ? [_vm._v(" Подать заявку")]
                                            : _vm._e(),
                                          _vm.purchase.actions.supplier.proposal
                                            .edit
                                            ? [_vm._v(" Изменить заявку")]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "b-popover",
                                        {
                                          attrs: {
                                            target:
                                              "popover-" +
                                              _vm.purchase.product.id +
                                              "-" +
                                              _vm.purchase.id,
                                            triggers: "hover",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " На лицевом счете недостаточно средств. Для подачи предложения не хватает "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-danger text-nowrap",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$formatPrice(
                                                    _vm.purchase.actions
                                                      .supplier.has_money
                                                      .not_enough
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" сом."),
                                          _c("br"),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "CabinetAccountReplenishment",
                                                },
                                              },
                                            },
                                            [_vm._v("Пополнить лицевой счет")]
                                          ),
                                          _vm._v(". "),
                                        ],
                                        1
                                      ),
                                    ],
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", [
                        _vm.purchase.name
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                                _vm._v("Наименование конкурса:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fs-12 green-color fw-600" },
                                [_vm._v(_vm._s(_vm.purchase.name))]
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                            _vm._v("Предмет закупки:"),
                          ]),
                          _c("p", { staticClass: "fs-12 green-color fw-600" }, [
                            _vm._v(
                              _vm._s(_vm.purchase.deliverable_group_title)
                            ),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "my-2 d-flex flex-column" },
                          [
                            _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                              _vm._v("Заказчик:"),
                            ]),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "system_link-style fw-600 text-uppercase fs-12",
                                attrs: {
                                  to: {
                                    name: "OrganizationShow",
                                    params: { id: _vm.purchase.customer.id },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.purchase.customer.name) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 my-2 grey-color" }, [
                            _vm._v(" Сумма закупки: "),
                            _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatPrice(_vm.purchase.starting_price)
                                )
                              ),
                            ]),
                          ]),
                          _c("p", { staticClass: "fs-12 my-2 grey-color" }, [
                            _vm._v(" Валюта: "),
                            _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                              _vm._v(_vm._s(_vm.purchase.currency_title)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", [
                        _c("div", { staticClass: "my-2" }, [
                          _vm.purchase.envelopes_opening_protocol_attachment
                            ? _c("p", { staticClass: "fs-12" }, [
                                _c("span", { staticClass: "grey-color" }, [
                                  _vm._v("Протокол вскрытия конвертов: "),
                                ]),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.$getAttachmentDownloadLink(
                                        _vm.purchase
                                          .envelopes_opening_protocol_attachment
                                          .id
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.purchase
                                          .envelopes_opening_protocol_attachment
                                          .name
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.purchase.final_protocol_attachment
                            ? _c("p", { staticClass: "fs-12" }, [
                                _c("span", { staticClass: "grey-color" }, [
                                  _vm._v("Протокол процесса закупки: "),
                                ]),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: _vm.$getAttachmentDownloadLink(
                                        _vm.purchase.final_protocol_attachment
                                          .id
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.purchase.final_protocol_attachment
                                          .name
                                      )
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }