<template>
    <div>
        <b-container v-if="!$store.getters.isLoggedIn || ($store.getters.isLoggedIn && $store.getters.getOrganization && $store.getters.getOrganization.id !== purchase.customer.id)" class="my-2" fluid>
            <b-container v-if="purchase.proposals && purchase.proposals.length > 0">
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Конкурсные заявки</h2>
                    </b-col>
                </b-row>
                <public-proposal-card v-for="(proposal, index) in purchase.proposals" :key="'proposal_' + index" :index="index" :proposal="proposal" />
            </b-container>
            <b-container v-if="purchase.proposals && purchase.proposals.length === 0">
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Конкурсные заявки отсутствуют</h2>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>
<script>
import PublicProposalCard from '@/components/purchases/direct/public-proposal-card.vue';

export default {
    name: 'public-proposals-block',
    components: { PublicProposalCard },
    props: {
        purchase: {},
    },
};
</script>
