var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("text-header", { attrs: { "margin-class": "mb-3" } }, [
        _vm._v("Запрос счета на оплату"),
      ]),
      _c("form-row-single-select", {
        attrs: {
          options: _vm.currencies,
          v: _vm.$v.form.currency,
          label: "Валюта",
          "label-field": "title",
          placeholder: "Выберите валюту закупки",
        },
        model: {
          value: _vm.form.currency,
          callback: function ($$v) {
            _vm.$set(_vm.form, "currency", $$v)
          },
          expression: "form.currency",
        },
      }),
      _c("form-row-edit-price", {
        attrs: { v: _vm.$v.form.amount, label: "Сумма пополнения" },
        model: {
          value: _vm.form.amount,
          callback: function ($$v) {
            _vm.$set(_vm.form, "amount", $$v)
          },
          expression: "form.amount",
        },
      }),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "text-uppercase mx-2 fs-14",
              attrs: { variant: "custom-outline-secondary" },
              on: { click: _vm.onCancel },
            },
            [_vm._v("Отмена")]
          ),
          _c(
            "b-button",
            {
              staticClass: "text-uppercase mx-2 fs-14",
              attrs: {
                disabled: _vm.$v.form.$invalid,
                variant: "custom-green",
              },
              on: { click: _vm.sendRequest },
            },
            [_vm._v("Скачать счет")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }