var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.purchase.product.id === "competitive"
        ? _c("personal-competitive-purchase", {
            attrs: { purchase: _vm.purchase },
            on: { refresh: _vm.refresh },
          })
        : _vm._e(),
      _vm.purchase.product.id === "direct"
        ? _c("personal-direct-purchase", {
            attrs: { purchase: _vm.purchase },
            on: { refresh: _vm.refresh },
          })
        : _vm._e(),
      _vm.purchase.product.id === "simple"
        ? _c("personal-simple-purchase", {
            attrs: { purchase: _vm.purchase },
            on: { refresh: _vm.refresh },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }