<template>
    <div>
        <b-modal id="email-input-modal" v-model="showEmailInputModal" centered no-close-on-backdrop no-close-on-esc hide-header-close title="Введите Email">
            <form-row-edit-email label="Email" :v="$v.form.email" :value="$v.form.email.$model" @value="$v.form.email.$model = $event" />
            <template slot="modal-footer">
                <b-overlay :show="isEmailChecking" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancel">Отмена </b-button>
                </b-overlay>
                <b-overlay :show="isEmailChecking" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="!$v.form.email.$model || $v.form.email.$invalid" class="text-uppercase" variant="custom-blue" @click="ok">Добавить</b-button>
                </b-overlay>
            </template>
        </b-modal>

        <div v-if="isEmailChecked && !showEmailInputModal">
            <text-header>Добавление пользователя</text-header>
            <form-row-edit-text label="ФИО" v-model="$v.form.name.$model" :v="$v.form.name" />
            <form-row-edit-email label="Email" disabled :v="$v.form.email" :value="$v.form.email.$model" @value="$v.form.email.$model = $event" />
            <form-row-edit-text v-model="$v.form.password.$model" :v="$v.form.password" label="Пароль" type="password" />
            <form-row-edit-text label="Телефон" v-model="$v.form.phone.$model" :v="$v.form.phone" />
            <form-row-single-select v-model="$v.form.role.$model" :options="dictionaryRoles" :v="$v.form.role" label="Роль" label-field="title" placeholder="Выберите роль" />
            <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                <b-button class="mx-2 text-uppercase" variant="custom-outline-secondary" @click="cancel">Отмена</b-button>
            </b-overlay>
            <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                <b-button :disabled="$v.$invalid" class="mx-2 text-uppercase" variant="custom-blue" @click="store">Добавить</b-button>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators';

export default {
    name: 'user-create',
    data() {
        return {
            isLoading: true,
            showEmailInputModal: false,
            form: {
                name: '',
                email: '',
                password: '',
                phone: '',
                role: '',
            },
            isDataSending: false,
            isEmailChecking: false,
            isEmailChecked: false,
            dictionaries: {
                posts: [
                    { name: 'Руководитель организации' },
                    { name: 'Администратор организации' },
                    { name: 'Лицо, уполномоченное на размещение информации и документов' },
                    { name: 'Лицо, имеющее право подписи документов от имени организации' },
                ],
            },
        };
    },
    validations() {
        return {
            form: {
                name: {
                    required,
                },
                email: {
                    required,
                    email,
                },
                password: {
                    required,
                    minLength: minLength(8),
                },
                phone: {
                    required,
                },
                role: {
                    required,
                },
            },
        };
    },
    mounted() {
        this.showEmailInputModal = true;
    },
    methods: {
        cancel() {
            this.$router.push({ name: 'UsersList' });
        },
        async ok() {
            this.isEmailChecking = true;
            if (await this.$api.personal.users.checkEmail(this.form.email)) {
                this.isEmailChecked = true;
                this.isEmailChecking = false;
                this.showEmailInputModal = false;
            } else {
                this.isEmailChecked = false;
                this.isEmailChecking = false;
            }
        },
        async store() {
            this.isDataSending = true;
            if (await this.$api.personal.users.create(this.form)) {
                this.cancel();
            }
            this.isDataSending = false;
        },
    },
    computed: {
        dictionaryRoles() {
            return this.$globalDictionaries.userRoles
                .filter((role) => !role.disabled)
                .map((role) => {
                    let isCustomer =
                        this.$store.state.organization.roles.includes(this.$orgRoles.CUSTOMER_COMPETITIVE) ||
                        this.$store.state.organization.roles.includes(this.$orgRoles.CUSTOMER_SIMPLE) ||
                        this.$store.state.organization.roles.includes(this.$orgRoles.CUSTOMER_DIRECT);
                    return {
                        id: role.id,
                        title: role.title,
                        $isDisabled: (!isCustomer && role.id === this.$userRoles.BUYER) || role.disabled,
                    };
                });
        },
    },
};
</script>
