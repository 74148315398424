<template>
    <div class="m-2 toast_parent" :style="'order:' + toast.id">
        <div>
            <div class="toast_head" :class="'toast-' + toast.variant">
                {{ toast.title }}
                <i @click="remove" class="close_btn">
                    <span></span>
                    <span></span>
                </i>
            </div>
            <div class="toast_body">
                <p class="fw-600 boldText m-0" v-if="toast.boldText">
                    {{ toast.boldText }}
                </p>
                <span class="text" v-if="toast.text">
                    {{ toast.text }}
                </span>
                <span class="text mt-2" style="display: block" v-if="toast.linkText" v-html="toast.linkText"> </span>
                <router-link v-if="toast.link" :to="toast.link" class="fs-12 green-color">подробнее...</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: ['toast'],
    data() {
        return {
            timeout: null,
        };
    },
    mounted() {
        if (this.toast.timer) {
            this.timeout = setTimeout(this.remove, this.toast.timer);
        }
    },
    methods: {
        remove() {
            this.removeToast(this.toast.id);
            clearTimeout(this.timeout);
        },
        ...mapActions({
            removeToast: 'removeToast',
        }),
    },
};
</script>

<style scoped>
.toast-orange {
    background-color: #f6b33a !important;
}
.toast-red,
.toast-danger {
    background-color: #ff7888 !important;
}
.toast-green {
    background-color: var(--est-blue-2) !important;
}
.toast_head {
    min-height: 32px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    color: #ffffff;
    padding: 7px 15px;
    padding-right: 30px;
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    position: relative;
    background-color: var(--grey-color);
}
.toast_body {
    padding: 12px 15px;
}
.boldText {
    color: var(--est-blue-2);
    font-size: 10px;
    text-transform: uppercase;
}
.toast_parent {
    max-width: 320px;
    background-color: #ffffff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 15px 7px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 15px 7px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 15px 7px rgba(0, 0, 0, 0.1);
}
.text {
    font-size: 10px;
    color: var(--grey-color);
}
.close_btn {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
.close_btn span {
    display: block;
    width: 13px;
    height: 2px;
    background: #ffffff8a;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.close_btn span {
    -webkit-transform: rotate(-45deg) translateY(1.5px);
    -moz-transform: rotate(-45deg) translateY(1.5px);
    -ms-transform: rotate(-45deg) translateY(1.5px);
    -o-transform: rotate(-45deg) translateY(1.5px);
    transform: rotate(-45deg) translateY(1.5px);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}
.close_btn span:nth-child(2) {
    -webkit-transform: rotate(45deg) translateY(-1.5px);
    -moz-transform: rotate(45deg) translateY(-1.5px);
    -ms-transform: rotate(45deg) translateY(-1.5px);
    -o-transform: rotate(45deg) translateY(-1.5px);
    transform: rotate(45deg) translateY(-1.5px);
    -webkit-transform-origin: center;
    -moz-transform-origin: center;
    -ms-transform-origin: center;
    -o-transform-origin: center;
    transform-origin: center;
}
</style>
