<template>
    <div v-if="!isLoading && form">
        <text-header>Идентификационные данные пользователя</text-header>
        <form-row-edit-text v-model="$v.form.name.$model" disabled label="ФИО" />
        <form-row-edit-email :v="$v.form.email" :value="$v.form.email.$model" label="Email" @value="$v.form.email.$model = $event" />
        <form-row-edit-text v-model="$v.form.password.$model" :v="$v.form.password" label="Пароль" type="password" />
        <form-row-edit-text v-model="$v.form.phone.$model" :v="$v.form.phone" label="Телефон" />
        <form-row-single-select v-model="$v.form.role.$model" :options="dictionaryRoles" :v="$v.form.role" label="Роль" label-field="title" placeholder="Выберите роль" />
        <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
            <b-button class="mx-2 text-uppercase" variant="custom-outline-secondary" @click="cancel">Отмена</b-button>
        </b-overlay>
        <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
            <b-button :disabled="$v.$invalid || !$v.$anyDirty" class="mx-2 text-uppercase" variant="custom-blue" @click="save">Сохранить</b-button>
        </b-overlay>
    </div>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators';

export default {
    name: 'user-edit',
    data() {
        return {
            isLoading: true,
            form: null,
            isDataSending: false,
        };
    },
    validations() {
        return {
            form: {
                name: {
                    required,
                },
                email: {
                    required,
                    email,
                },
                password: {
                    required,
                    minLength: minLength(8),
                },
                phone: {
                    required,
                },
                role: {
                    required,
                },
            },
        };
    },
    mounted() {
        this.fillData();
    },
    methods: {
        async fillData() {
            this.isLoading = true;
            this.form = await this.$api.personal.users.get(this.$route.params.id);
            this.isLoading = false;
        },
        cancel() {
            this.$router.push({ name: 'UsersList' });
        },
        async save() {
            this.isDataSending = true;
            if (await this.$api.personal.users.update(this.$route.params.id, this.form)) {
                this.cancel();
            }
            this.isDataSending = false;
        },
    },
    computed: {
        dictionaryRoles() {
            return this.$globalDictionaries.userRoles.map((role) => {
                let isCustomer =
                    this.$store.state.organization.roles.includes(this.$orgRoles.CUSTOMER_COMPETITIVE) ||
                    this.$store.state.organization.roles.includes(this.$orgRoles.CUSTOMER_SIMPLE) ||
                    this.$store.state.organization.roles.includes(this.$orgRoles.CUSTOMER_DIRECT);
                return {
                    id: role.id,
                    title: role.title,
                    $isDisabled: (!isCustomer && role.id === this.$userRoles.BUYER) || role.disabled,
                };
            });
        },
    },
};
</script>
