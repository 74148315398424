var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-btn",
        {
          staticClass: "btn-card-action btn-card-action-light my-1",
          on: { click: _vm.showModal },
        },
        [_vm._v("Копировать")]
      ),
      _c(
        "b-modal",
        {
          ref: "cancel-modal",
          attrs: {
            title: _vm.title,
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            "hide-header-close": "",
            centered: "",
            size: "lg",
          },
          model: {
            value: _vm.isModalVisible,
            callback: function ($$v) {
              _vm.isModalVisible = $$v
            },
            expression: "isModalVisible",
          },
        },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("p", { staticClass: "fs-16 font-weight-bold grey-color" }, [
                  _vm._v(
                    'Будет создана копия объявления о проводимом конкурсе, за исключением данных в поле "Дата и время окончания приема заявок и вскрытия конвертов"'
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100 text-right",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.cancelModal },
                    },
                    [_vm._v("Отмена")]
                  ),
                ],
                1
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    rounded: "",
                    opacity: "0.6",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: { variant: "custom-danger" },
                      on: { click: _vm.copyPurchase },
                    },
                    [_vm._v("Копировать")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }