var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "fs-14 my-2" },
    [
      _c("proposal-card-title", {
        staticClass: "fs-16",
        attrs: { index: _vm.index, proposal: _vm.proposal },
      }),
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "my-1", attrs: { md: "6" } }, [
            _c("p", [
              _vm._v(" Время подачи заявки: "),
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.getDateTimeFormatSeconds(_vm.proposal.created_at))
                ),
              ]),
            ]),
            _vm.proposal.proposal_validity_days
              ? _c("p", [
                  _vm._v(
                    " Срок действия предложения от даты окончания приема заявок, дней: "
                  ),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.proposal.proposal_validity_days)),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { md: "6" } },
            [
              _c("b", [_vm._v(_vm._s(_vm.proposal.status.title))]),
              _c("br"),
              _vm.proposal.status.id === "rejected"
                ? [
                    _c("b", [_vm._v("Причина отклонения:")]),
                    _vm._v(" " + _vm._s(_vm.proposal.rejection_reason) + " "),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }