<template>
    <div>
        <b-container v-if="$store.getters.isLoggedIn && purchase.actions.customer.open_envelopes && purchase.private_data && purchase.private_data.envelopes_opening && purchase.status.id === 'summarizing'" class="my-2" fluid>
            <b-container>
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Процедура вскрытия конвертов</h2>
                    </b-col>
                    <b-col class="text-right my-auto" md="2">
                        <a :href="$links.manual.competitive_purchase_envelopes_opening" class="btn btn-custom-danger" target="_blank">Инструкция</a>
                    </b-col>
                </b-row>
                <text-row label="Состав комиссии" value=" " />
                <b-row class="my-2" no-gutters>
                    <b-col class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>ФИО</th>
                                    <th>Должность</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in purchase.private_data.envelopes_opening.commission" :key="index" v-bind="item">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.position }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
                <b-form-group label="Шаблон протокола вскрытия конвертов:" label-class="fs-14 grey-color fw-700 d-flex align-middle" label-cols-lg="3" label-cols-sm="4">
                    <b-button :href="purchase.private_data.envelopes_opening.protocol_template_link" variant="custom-blue">Скачать</b-button>
                </b-form-group>
                <form-row-upload-single-document v-model="form.attachment" :v="$v.form.attachment" header-class="fs-14 fw-700" title="Подписанный членами комиссии протокол вскрытия конвертов" />
                <div class="w-100 text-right">
                    <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="$v.$invalid" class="text-uppercase" variant="custom-danger" @click="showConfirmModal = true">Отправить подписанный членами комиссии протокол вскрытия конвертов</b-button>
                    </b-overlay>
                </div>
            </b-container>
            <b-modal ref="publish-modal" v-model="showConfirmModal" centered hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Отправка подписанного протокола вскрытия конвертов">
                <div class="d-block text-left">Вы действительно хотите отправить подписанный членами комиссии протокол вскрытия конвертов?</div>
                <div class="d-block text-left">Данное действие является необратимым, отменить его будет невозможно!</div>
                <div slot="modal-footer" class="w-100 text-right">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="showConfirmModal = false">Отмена</b-button>
                    <b-button class="text-uppercase" variant="custom-danger" @click="confirmEnvelopesOpening">Отправить подписанный членами комиссии протокол вскрытия конвертов</b-button>
                </div>
            </b-modal>
        </b-container>

        <b-container
            v-if="$store.getters.isLoggedIn && purchase.private_data && purchase.private_data.envelopes_opening && !purchase.private_data.winner_selection && purchase.private_data.proposals && purchase.status.id === 'summarizing'"
            class="my-2"
            fluid>
            <b-container v-if="purchase.private_data.proposals && purchase.private_data.proposals.length > 0">
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Конкурсные заявки</h2>
                    </b-col>
                </b-row>
                <envelopes-opening-proposal-card
                    v-for="(proposal, index) in purchase.private_data.proposals"
                    :key="'proposal_' + index"
                    :index="index"
                    :proposal="proposal"
                    :purchase="purchase"
                    :purchase-id="purchase.id"
                    :supplier-requirements="purchase.supplier_requirements" />
            </b-container>
            <b-container v-if="purchase.private_data.proposals && purchase.private_data.proposals.length === 0">
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Конкурсные заявки отсутствуют</h2>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import FormRowUploadSingleDocument from '@/components/common/form-rows/form-row-upload-single-document.vue';
import EnvelopesOpeningProposalCard from '@/components/purchases/competitive/envelopes-opening-proposal-card.vue';

export default {
    name: 'private-envelopes-opening-block',
    components: { EnvelopesOpeningProposalCard, FormRowUploadSingleDocument },
    props: {
        purchase: {},
    },
    validations: {
        form: {
            attachment: { required },
        },
    },
    data() {
        return {
            showConfirmModal: false,
            form: {
                attachment: null,
            },
            isDataSending: false,
        };
    },
    methods: {
        async confirmEnvelopesOpening() {
            this.isDataSending = true;
            if (await this.$api.purchases.competitive.confirmEnvelopesOpening(this.purchase.id, this.form)) {
                this.showConfirmModal = false;
                this.$emit('confirm');
            }
            this.isDataSending = false;
        },
    },
};
</script>
