<template>
    <div class="cms-content">
        <div class="col-xs-12 col-md-12 pr40 mb30 ml30">
            <ul class="fs14 lh24 end_list">
                <li class="mb10">Гарантируем корректность и актуальность прилагаемой информации.</li>
                <li class="mb10">Понимаем, что представление недостоверной информации повлечет за собой отказ в прохождении аккредитации либо прекращение аккредитации.</li>
                <li class="mb10">Согласны на использование информации, предоставляемой при прохождении процедуры регистрации / аккредитации, в базах данных оператора ИС ЕСТ, в том числе на публикацию в открытой части ИС.</li>
                <li class="mb10">
                    Обязуемся в течение 5 (пяти) рабочих дней с момента внесения в сведения, указанные в настоящем заявлении об аккредитации и в представленные документы (в случае их наличия в заявлении), а также о замене и прекращении
                    действия указанных документов (в том числе замене или прекращении действия электронной подписи), извещать оператора ИС ЕСТ о наличии таких изменений.
                </li>
                <li class="mb10">Согласны соответствовать требованиям к участникам закупок, установленным законодательством Республики Кыргызстан, нормативными правовыми актами и Регламентом функционирования ИС ЕСТ.</li>
                <li class="mb10">Согласны обеспечить при работе в ИС ЕСТ соблюдение законодательства Республики Кыргызстан, нормативных правовых актов и Регламента функционирования ИС ЕСТ.</li>
                <li class="mb10">Гарантируем, что субъект персональных данных был уведомлен, и дает согласие на обработку персональных данных.</li>
                <li class="mb10">Гарантируем корректность действий Уполномоченного лица, совершаемые при заполнении формы заявления, а также за иные действия Уполномоченного лица, связанные с обеспечением участия заявителя в ИС ЕСТ.</li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'registration-confirmation-text-block',
};
</script>

<style>
.end_list {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 40px;
}

.end_list li {
    margin: 15px 0;
    font-size: 14px;
    color: var(--grey-color);
    position: relative;
}

.end_list li::before {
    content: '';
    position: absolute;
    top: 8px;
    left: -15px;
    width: 5px;
    height: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: var(--est-blue-2);
}
</style>
