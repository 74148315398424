'use strict';

export default class SVTAExceptionRutoken extends Error {
    constructor(message, code = 90099) {
        super(message);

        if (!code) {
            throw Error('SVTA Exception: The code argument must be provided');
        }

        this.name = 'SVTAException';
        this.code = code;
    }
}
