<template>
    <div></div>
</template>

<script>
export default {
    created() {
        this.$store.dispatch('logout').then(() => {
            window.location.assign('/');
        });
    },
};
</script>
