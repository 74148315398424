<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-between align-items-center">
                <text-header>Документы</text-header>
                <file-uploader @set_file="addFile" :with-favourite="documents.length > 0" :files-list="documents" modal-title="Выберите документы для прикрепления к заявке" btn-text="Добавить документ"> Добавить документ </file-uploader>
            </b-col>
        </b-row>
        <b-row class="pb-4">
            <b-col>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 50px"></th>
                            <th>Наименование</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody v-if="attachments.length">
                        <tr v-for="(document, index) in attachments" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>
                                <a :href="$getAttachmentDownloadLink(document.id)">{{ document.name }}</a>
                            </td>
                            <td width="1">
                                <b-button @click="removeFile(index)" variant="light" size="sm" style="background: transparent">
                                    <img src="/images/trash_icon.svg" alt="trash_icon" />
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="3" align="center">Документы не добавлены.</td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import FileUploader from '@/components/common/FileUploader';

export default {
    name: 'proposal-documents',
    components: {
        FileUploader,
    },
    props: {
        documents: {
            type: Array,
            required: true,
        },
        v: {
            type: Object,
            required: true,
        },
    },
    methods: {
        addFile(file) {
            Array.isArray(file) ? (this.attachments = Array.from(new Set(this.attachments.concat(file)))) : this.attachments.push(file);
        },
        removeFile(index) {
            this.attachments.splice(index, 1);
        },
    },
    computed: {
        attachments: {
            get() {
                return this.v.attachments;
            },
            set(value) {
                this.v.attachments = value;
            },
        },
    },
};
</script>
