<template>
    <div v-if="!isLoading && profile">
        <text-header margin-class="mb-3">Идентификационные данные пользователя</text-header>
        <text-row v-model="profile.user.name" :compact="true" label="ФИО" />
        <text-row v-model="profile.user.email" :compact="true" label="Email" />
        <text-row v-model="profile.user.phone" :compact="true" label="Телефон" />
        <text-row v-model="profile.user.role" :compact="true" label="Роль" />
    </div>
</template>

<script>
export default {
    name: 'user-profile',
    data() {
        return {
            isLoading: true,
            profile: null,
        };
    },
    mounted() {
        this.fillData();
    },
    methods: {
        async fillData() {
            this.isLoading = true;
            this.profile = await this.$api.personal.profile.getInformation();
            this.isLoading = false;
        },
    },
};
</script>
