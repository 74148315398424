<template>
    <div>
        <text-header>Информация о заказчике</text-header>
        <text-row-link label="Наименование" v-model="customer.name" :router-link="{ name: 'OrganizationShow', params: { id: customer.id } }" />
        <text-row label="ИНН" v-model="customer.inn" />
        <text-row label="ОКПО" v-model="customer.okpo" />
        <text-row label="Рег.номер" v-model="customer.reg_number" />
        <text-row v-model="customer.address" label="Юридический адрес" />
        <text-row :label="additionalLabel" v-model="additionalInfo" v-if="additionalInfo" />
    </div>
</template>

<script>
export default {
    name: 'customer-info',
    props: {
        customer: {
            type: Object,
            required: true,
        },
        additionalInfo: {
            type: String,
            default: '',
        },
        additionalLabel: {
            type: String,
            default: '',
        },
    },
};
</script>
