<template>
    <div>
        <b-modal ref="certificate-modal" v-model="showModal" hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Выбор сертификата" @show="showWindow">
            <b-overlay :show="is_certificates_loading" opacity="0.6" rounded spinner-variant="primary">
                <b-container v-if="rutokenError" class="text-danger fs-18 fw-600 text-center my-5">
                    <p>{{ rutokenError.message }}</p>
                    <template v-if="rutokenError.code === 90097 || rutokenError.code === 90098">
                        <p>
                            Инструкция по установке Рутокен Плагин на сайте разработчика -
                            <a href="https://dev.rutoken.ru/pages/viewpage.action?pageId=72451796" target="_blank">ссылка</a>
                        </p>
                    </template>
                </b-container>
                <b-form-group v-else class="text-center">
                    <multiselect
                        v-model="selectCertificate"
                        :allow-clear="false"
                        :allow-empty="false"
                        :close-on-select="true"
                        :disabled="is_certificates_loading"
                        :multiple="false"
                        :options="certificates"
                        :preserve-search="false"
                        :show-labels="false"
                        label="text"
                        placeholder="Выберите сертификат"
                        track-by="id">
                        <span slot="noOptions">Нет данных</span>
                        <span slot="noResult">По вашему запросу сертификаты не найдены</span>
                        <template slot="singleLabel" slot-scope="props">
                            <b-row>
                                <b-col v-if="props.option.subject.inn" class="py-1" md="6">
                                    <strong>ИНН</strong><br />
                                    {{ props.option.subject.inn }}
                                </b-col>
                                <b-col v-if="props.option.subject.organization_name" class="py-1" md="6">
                                    <strong>Организация</strong><br />
                                    {{ props.option.subject.organization_name }}
                                </b-col>
                                <b-col v-if="props.option.subject.full_name" class="py-1" md="6">
                                    <strong>ФИО</strong><br />
                                    {{ props.option.subject.full_name }}
                                </b-col>
                                <b-col v-if="props.option.subject.position" class="py-1" md="6">
                                    <strong>Должность</strong><br />
                                    {{ props.option.subject.position }}
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="py-1" md="6">
                                    <strong>SHA1 отпечаток</strong><br />
                                    {{ props.option.prepared_thumbprint }}
                                </b-col>
                                <b-col class="py-1" md="6">
                                    <strong>Срок действия</strong><br />
                                    С {{ getDateFormat(props.option.valid.from) }} по {{ getDateFormat(props.option.valid.to) }}
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="py-1">
                                    <strong>Серийный номер</strong><br />
                                    {{ props.option.prepared_serial }}
                                </b-col>
                            </b-row>
                        </template>
                        <template slot="option" slot-scope="props">
                            <b-row>
                                <b-col v-if="props.option.subject.inn" class="py-1" md="6">
                                    <strong>ИНН</strong><br />
                                    {{ props.option.subject.inn }}
                                </b-col>
                                <b-col v-if="props.option.subject.organization_name" class="py-1" md="6">
                                    <strong>Организация</strong><br />
                                    {{ props.option.subject.organization_name }}
                                </b-col>
                                <b-col v-if="props.option.subject.full_name" class="py-1" md="6">
                                    <strong>ФИО</strong><br />
                                    {{ props.option.subject.full_name }}
                                </b-col>
                                <b-col v-if="props.option.subject.position" class="py-1" md="6">
                                    <strong>Должность</strong><br />
                                    {{ props.option.subject.position }}
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="py-1" md="6">
                                    <strong>SHA1 отпечаток</strong><br />
                                    {{ props.option.prepared_thumbprint }}
                                </b-col>
                                <b-col class="py-1" md="6">
                                    <strong>Срок действия</strong><br />
                                    С {{ getDateFormat(props.option.valid.from) }} по {{ getDateFormat(props.option.valid.to) }}
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col class="py-1">
                                    <strong>Серийный номер</strong><br />
                                    {{ props.option.prepared_serial }}
                                </b-col>
                            </b-row>
                        </template>
                    </multiselect>
                    <template v-if="!state">
                        <b-form-invalid-feedback class="text-left" force-show>{{ errorMessage }}</b-form-invalid-feedback>
                    </template>
                </b-form-group>
            </b-overlay>
            <template #modal-footer>
                <b-overlay :show="is_certificates_loading" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="mx-1 text-uppercase" variant="custom-outline-secondary" @click="cancel">Отмена</b-button>
                    <b-button class="mx-1 text-uppercase" variant="custom-outline-secondary" @click="refreshCertificates">Обновить список сертификатов</b-button>
                    <b-button :disabled="$v.$invalid" class="mx-1 text-uppercase" variant="custom-blue" @click="save">Продолжить</b-button>
                </b-overlay>
            </template>
        </b-modal>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'select-certificate-modal',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        rutoken: {
            required: true,
        },
    },
    data() {
        return {
            isPageLoading: true,
            certificates: [], // сертификаты для селекта
            is_certificates_loading: false,
            selectCertificate: null,
            rutokenError: null,
            trackBy: 'certificateID',
        };
    },
    validations() {
        return {
            selectCertificate: {
                required,
            },
        };
    },
    methods: {
        async showWindow() {
            try {
                await this.refreshCertificates();
            } catch (error) {
                this.rutokenError = error;
            }
        },
        async refreshCertificates() {
            this.rutokenError = null;
            this.is_certificates_loading = true;
            try {
                this.certificates = await this.rutoken.getCertificatesList();
            } catch (error) {
                this.rutokenError = error;
            }
            this.selectCertificate = null;
            this.is_certificates_loading = false;
        },
        async getActualCertData() {
            try {
                return await this.rutoken.getCertificateModel(this.selectCertificate);
            } catch (e) {
                this.showRutokenError(e);
            }
        },
        cancel() {
            this.$refs['certificate-modal'].hide();
        },
        save() {
            this.$refs['certificate-modal'].hide();
            this.$emit('save', this.selectCertificate);
        },
    },
    computed: {
        showModal: {
            get() {
                return this.show;
            },
            set(value) {
                this.$emit('hideModal', value);
            },
        },
        state() {
            return this.selectCertificate !== null ? !this.selectCertificate.$invalid : null;
        },
        errorMessage() {
            if (!this.state) return 'Сертификат не выбран';
            return '';
        },
    },
};
</script>
