var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading && _vm.form
    ? _c(
        "div",
        [
          _c("text-header", [_vm._v("Идентификационные данные пользователя")]),
          _c("form-row-edit-text", {
            attrs: { disabled: "", label: "ФИО" },
            model: {
              value: _vm.$v.form.name.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form.name, "$model", $$v)
              },
              expression: "$v.form.name.$model",
            },
          }),
          _c("form-row-edit-email", {
            attrs: {
              v: _vm.$v.form.email,
              value: _vm.$v.form.email.$model,
              label: "Email",
            },
            on: {
              value: function ($event) {
                _vm.$v.form.email.$model = $event
              },
            },
          }),
          _c("form-row-edit-text", {
            attrs: {
              v: _vm.$v.form.password,
              label: "Пароль",
              type: "password",
            },
            model: {
              value: _vm.$v.form.password.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form.password, "$model", $$v)
              },
              expression: "$v.form.password.$model",
            },
          }),
          _c("form-row-edit-text", {
            attrs: { v: _vm.$v.form.phone, label: "Телефон" },
            model: {
              value: _vm.$v.form.phone.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form.phone, "$model", $$v)
              },
              expression: "$v.form.phone.$model",
            },
          }),
          _c("form-row-single-select", {
            attrs: {
              options: _vm.dictionaryRoles,
              v: _vm.$v.form.role,
              label: "Роль",
              "label-field": "title",
              placeholder: "Выберите роль",
            },
            model: {
              value: _vm.$v.form.role.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.form.role, "$model", $$v)
              },
              expression: "$v.form.role.$model",
            },
          }),
          _c(
            "b-overlay",
            {
              staticClass: "d-inline-block",
              attrs: {
                show: _vm.isDataSending,
                opacity: "0.6",
                rounded: "",
                "spinner-small": "",
                "spinner-variant": "primary",
              },
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mx-2 text-uppercase",
                  attrs: { variant: "custom-outline-secondary" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("Отмена")]
              ),
            ],
            1
          ),
          _c(
            "b-overlay",
            {
              staticClass: "d-inline-block",
              attrs: {
                show: _vm.isDataSending,
                opacity: "0.6",
                rounded: "",
                "spinner-small": "",
                "spinner-variant": "primary",
              },
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mx-2 text-uppercase",
                  attrs: {
                    disabled: _vm.$v.$invalid || !_vm.$v.$anyDirty,
                    variant: "custom-blue",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("Сохранить")]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }