var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("text-header", [_vm._v("Информация о заказчике")]),
      _c("text-row-link", {
        attrs: {
          label: "Наименование",
          "router-link": {
            name: "OrganizationShow",
            params: { id: _vm.customer.id },
          },
        },
        model: {
          value: _vm.customer.name,
          callback: function ($$v) {
            _vm.$set(_vm.customer, "name", $$v)
          },
          expression: "customer.name",
        },
      }),
      _c("text-row", {
        attrs: { label: "ИНН" },
        model: {
          value: _vm.customer.inn,
          callback: function ($$v) {
            _vm.$set(_vm.customer, "inn", $$v)
          },
          expression: "customer.inn",
        },
      }),
      _c("text-row", {
        attrs: { label: "ОКПО" },
        model: {
          value: _vm.customer.okpo,
          callback: function ($$v) {
            _vm.$set(_vm.customer, "okpo", $$v)
          },
          expression: "customer.okpo",
        },
      }),
      _c("text-row", {
        attrs: { label: "Рег.номер" },
        model: {
          value: _vm.customer.reg_number,
          callback: function ($$v) {
            _vm.$set(_vm.customer, "reg_number", $$v)
          },
          expression: "customer.reg_number",
        },
      }),
      _c("text-row", {
        attrs: { label: "Юридический адрес" },
        model: {
          value: _vm.customer.address,
          callback: function ($$v) {
            _vm.$set(_vm.customer, "address", $$v)
          },
          expression: "customer.address",
        },
      }),
      _vm.additionalInfo
        ? _c("text-row", {
            attrs: { label: _vm.additionalLabel },
            model: {
              value: _vm.additionalInfo,
              callback: function ($$v) {
                _vm.additionalInfo = $$v
              },
              expression: "additionalInfo",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }