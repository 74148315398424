<template>
    <div>
        <b-container fluid class="main_layer py-4">
            <b-form v-on:focusin="alert = false" autocomplete="off" ref="filter" class="w-100">
                <b-container>
                    <b-row>
                        <b-col>
                            <b-row>
                                <b-col>
                                    <p class="fs-52 fw-600 white-color mt-2 mb-4 less_font-size_mobile">Реестр организаций</p>
                                </b-col>
                            </b-row>
                            <b-row class="filter">
                                <filter-input-text label="Название" md="6" lg="6" v-model="filter.name" placeholder="Укажите название организации" />
                                <filter-input-text label="ИНН" v-model="filter.inn" placeholder="Укажите ИНН организации" />
                                <filter-input-text label="ОКПО" v-model="filter.okpo" placeholder="Укажите ОКПО организации" />
                                <filter-input-text label="Рег.номер" v-model="filter.reg_number" placeholder="Укажите регистрационный номер организации" />
                                <filter-input-text label="Юридический адрес" md="6" lg="6" v-model="filter.address" placeholder="Укажите юридический адрес организации" />
                            </b-row>
                            <b-row class="mt-4">
                                <b-col class="flex_col-mobile">
                                    <b-button class="mr-2 text-uppercase" variant="custom-white" v-on:click="getOrganizations(false)">Найти </b-button>
                                    <b-button class="mr-2 text-uppercase" variant="custom-outline-light" v-on:click="clearFilter">Сбросить</b-button>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-container>
            </b-form>
        </b-container>
        <system-preloader v-if="loading"></system-preloader>
        <b-container fluid class="greyBg pb-4">
            <b-container v-if="!loading && !organizations.length">
                <empty-request></empty-request>
            </b-container>
            <b-container>
                <div style="width: 100%">
                    <b-pagination align="center" @change="applyFilters" v-model="meta.current_page" :total-rows="meta.total" :per-page="meta.per_page" style="padding-bottom: 0" />
                </div>
                <b-row class="pt-4">
                    <organization-card v-for="organization in organizations" :key="organization.id" :organization="organization" />
                </b-row>
                <div style="width: 100%">
                    <b-pagination align="center" @change="applyFilters" v-model="meta.current_page" :total-rows="meta.total" :per-page="meta.per_page" />
                </div>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import OrganizationCard from '@/components/organizations/elements/organization-card.vue';

export default {
    components: {
        OrganizationCard,
    },
    metaInfo: {
        title: 'Реестр организаций.',
    },
    name: 'organizations-list',
    data() {
        return {
            loading: true,
            organizations: [],
            filter: {
                name: '',
                inn: '',
                okpo: '',
                reg_number: '',
                address: '',
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1,
            },
        };
    },
    async created() {
        await this.getOrganizations();
    },
    methods: {
        applyFilters(value = 1) {
            this.loading = true;
            this.meta.current_page = value;
            this.getOrganizations();
        },
        clearFilter() {
            this.$refs.filter.reset();
            this.filter.name = '';
            this.filter.inn = '';
            this.filter.okpo = '';
            this.filter.reg_number = '';
            this.filter.address = '';
            this.getOrganizations(false);
        },
        getFilters(page = true) {
            const filterValues = [];
            // eslint-disable-next-line no-unused-vars
            for (let obj of Object.entries(this.filter)) {
                if (obj[1]) {
                    filterValues[obj[0]] = obj[1];
                }
            }
            return {
                ...filterValues,
                page: page ? this.meta.current_page : 1,
            };
        },
        async getOrganizations(page = true, loading = true) {
            this.loading = loading;
            const organizationsData = await this.$api.organizations.list(this.getFilters(page));
            this.organizations = organizationsData.data;
            this.meta = organizationsData.meta;
            this.$scrollToTop();
            this.loading = false;
        },
    },
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}
</style>
