'use strict';

import rutoken from '@aktivco/rutoken-plugin';

import RutokenCertificateVO from './RutokenCertificate.valueobject';
import SVTAExceptionRutoken from './SVTAExceptionRutoken';

export default class RutokenService {
    #plugin;
    #errorDescription;
    #errorCodes;

    // NB: The instantiation must be conducted after window load.
    constructor() {
        this.#plugin = undefined;
        this.#errorCodes = undefined;
        this.#errorDescription = [];
    }

    #throwIfNotInitialized() {
        if (!this.#plugin) {
            throw new Error('SVTAException: Initialize RutokenService before calling it.');
        }
    }

    async initialize() {
        try {
            const isRutokenReady = await rutoken.ready;
            const isExtensionInstalled = await rutoken.isExtensionInstalled();
            if (!isExtensionInstalled) {
                throw new SVTAExceptionRutoken('Расширение "Адаптер Рутокен Плагин" не установлено', 90098);
            }
            const isPluginInstalled = await rutoken.isPluginInstalled();
            if (!isPluginInstalled) {
                throw new SVTAExceptionRutoken('Рутокен Плагин не установлен', 90097);
            }

            const plugin = await rutoken.loadPlugin();

            if (!isRutokenReady || !plugin) {
                throw new SVTAExceptionRutoken('Невозможно инициализировать Рутокен плагин.');
            }

            this.#plugin = plugin;
            this.#errorCodes = this.#plugin.errorCodes;

            this.#errorDescription[plugin.errorCodes.UNKNOWN_ERROR] = 'Неизвестная ошибка';
            this.#errorDescription[plugin.errorCodes.BAD_PARAMS] = 'Неправильные параметры';
            this.#errorDescription[plugin.errorCodes.NOT_ENOUGH_MEMORY] = 'Недостаточно памяти';

            this.#errorDescription[plugin.errorCodes.DEVICE_NOT_FOUND] = 'Устройство не найдено';
            this.#errorDescription[plugin.errorCodes.DEVICE_ERROR] = 'Ошибка устройства';
            this.#errorDescription[plugin.errorCodes.TOKEN_INVALID] = 'Ошибка чтения/записи устройства. Возможно, устройство было извлечено. Попробуйте выполнить enumerate';

            this.#errorDescription[plugin.errorCodes.CERTIFICATE_CATEGORY_BAD] = 'Недопустимый тип сертификата';
            this.#errorDescription[plugin.errorCodes.CERTIFICATE_EXISTS] = 'Сертификат уже существует на устройстве';
            this.#errorDescription[plugin.errorCodes.CERTIFICATE_NOT_FOUND] = 'Сертификат не найден';
            this.#errorDescription[plugin.errorCodes.CERTIFICATE_HASH_NOT_UNIQUE] = 'Хэш сертификата не уникален';
            this.#errorDescription[plugin.errorCodes.CA_CERTIFICATES_NOT_FOUND] = 'Корневые сертификаты не найдены';
            this.#errorDescription[plugin.errorCodes.CERTIFICATE_VERIFICATION_ERROR] = 'Ошибка проверки сертификата';

            this.#errorDescription[plugin.errorCodes.PKCS11_LOAD_FAILED] = 'Не удалось загрузить PKCS#11 библиотеку';

            this.#errorDescription[plugin.errorCodes.PIN_LENGTH_INVALID] = 'Некорректная длина PIN-кода';
            this.#errorDescription[plugin.errorCodes.PIN_INCORRECT] = 'Некорректный PIN-код';
            this.#errorDescription[plugin.errorCodes.PIN_LOCKED] = 'PIN-код заблокирован';
            this.#errorDescription[plugin.errorCodes.PIN_CHANGED] = 'PIN-код был изменен';
            this.#errorDescription[plugin.errorCodes.PIN_INVALID] = 'PIN-код содержит недопустимые символы';
            this.#errorDescription[plugin.errorCodes.USER_PIN_NOT_INITIALIZED] = 'PIN-код пользователя не инициализирован';
            this.#errorDescription[plugin.errorCodes.PIN_EXPIRED] = 'Действие PIN-кода истекло';
            this.#errorDescription[plugin.errorCodes.INAPPROPRIATE_PIN] = 'Устанавливаемый PIN-код не удовлетворяет политикам смены PIN-кодов';
            this.#errorDescription[plugin.errorCodes.PIN_IN_HISTORY] = 'Устанавливаемый PIN-код содержится в истории PIN-кодов';

            this.#errorDescription[plugin.errorCodes.SESSION_INVALID] = 'Состояние токена изменилось';
            this.#errorDescription[plugin.errorCodes.USER_NOT_LOGGED_IN] = 'Выполните вход на устройство';
            this.#errorDescription[plugin.errorCodes.ALREADY_LOGGED_IN] = 'Вход на устройство уже был выполнен';

            this.#errorDescription[plugin.errorCodes.ATTRIBUTE_READ_ONLY] = 'Свойство не может быть изменено';
            this.#errorDescription[plugin.errorCodes.KEY_NOT_FOUND] = 'Соответствующая сертификату ключевая пара не найдена';
            this.#errorDescription[plugin.errorCodes.KEY_ID_NOT_UNIQUE] = 'Идентификатор ключевой пары не уникален';
            this.#errorDescription[plugin.errorCodes.CEK_NOT_AUTHENTIC] = 'Выбран неправильный ключ';
            this.#errorDescription[plugin.errorCodes.KEY_LABEL_NOT_UNIQUE] = 'Метка ключевой пары не уникальна';
            this.#errorDescription[plugin.errorCodes.WRONG_KEY_TYPE] = 'Неправильный тип ключа';
            this.#errorDescription[plugin.errorCodes.LICENCE_READ_ONLY] = 'Лицензия доступна только для чтения';

            this.#errorDescription[plugin.errorCodes.DATA_INVALID] = 'Неверные данные';
            this.#errorDescription[plugin.errorCodes.DATA_LEN_RANGE] = 'Некорректный размер данных';
            this.#errorDescription[plugin.errorCodes.UNSUPPORTED_BY_TOKEN] = 'Операция не поддерживается токеном';
            this.#errorDescription[plugin.errorCodes.KEY_FUNCTION_NOT_PERMITTED] = 'Операция запрещена для данного типа ключа';

            this.#errorDescription[plugin.errorCodes.BASE64_DECODE_FAILED] = 'Ошибка декодирования даных из BASE64';
            this.#errorDescription[plugin.errorCodes.PEM_ERROR] = 'Ошибка разбора PEM';
            this.#errorDescription[plugin.errorCodes.ASN1_ERROR] = 'Ошибка декодирования ASN1 структуры';

            this.#errorDescription[plugin.errorCodes.FUNCTION_REJECTED] = 'Операция отклонена пользователем';
            this.#errorDescription[plugin.errorCodes.FUNCTION_FAILED] = 'Невозможно выполнить операцию';
            this.#errorDescription[plugin.errorCodes.MECHANISM_INVALID] = 'Указан неправильный механизм';
            this.#errorDescription[plugin.errorCodes.ATTRIBUTE_VALUE_INVALID] = 'Передан неверный атрибут';

            this.#errorDescription[plugin.errorCodes.X509_UNABLE_TO_GET_ISSUER_CERT] = 'Невозможно получить сертификат эмитента';
            this.#errorDescription[plugin.errorCodes.X509_UNABLE_TO_GET_CRL] = 'Невозможно получить CRL';
            this.#errorDescription[plugin.errorCodes.X509_UNABLE_TO_DECRYPT_CERT_SIGNATURE] = 'Невозможно расшифровать подпись сертификата';
            this.#errorDescription[plugin.errorCodes.X509_UNABLE_TO_DECRYPT_CRL_SIGNATURE] = 'Невозможно расшифровать подпись CRL';
            this.#errorDescription[plugin.errorCodes.X509_UNABLE_TO_DECODE_ISSUER_PUBLIC_KEY] = 'Невозможно раскодировать открытый ключ эмитента';
            this.#errorDescription[plugin.errorCodes.X509_CERT_SIGNATURE_FAILURE] = 'Неверная подпись сертификата';
            this.#errorDescription[plugin.errorCodes.X509_CRL_SIGNATURE_FAILURE] = 'Неверная подпись CRL';
            this.#errorDescription[plugin.errorCodes.X509_CERT_NOT_YET_VALID] = 'Срок действия сертификата еще не начался';
            this.#errorDescription[plugin.errorCodes.X509_CRL_NOT_YET_VALID] = 'Срок действия CRL еще не начался';
            this.#errorDescription[plugin.errorCodes.X509_CERT_HAS_EXPIRED] = 'Срок действия сертификата истек';
            this.#errorDescription[plugin.errorCodes.X509_CRL_HAS_EXPIRED] = 'Срок действия CRL истек';
            this.#errorDescription[plugin.errorCodes.X509_ERROR_IN_CERT_NOT_BEFORE_FIELD] = 'Некорректные данные в поле "notBefore" у сертификата';
            this.#errorDescription[plugin.errorCodes.X509_ERROR_IN_CERT_NOT_AFTER_FIELD] = 'Некорректные данные в поле "notAfter" у сертификата';
            this.#errorDescription[plugin.errorCodes.X509_ERROR_IN_CRL_LAST_UPDATE_FIELD] = 'Некорректные данные в поле "lastUpdate" у CRL';
            this.#errorDescription[plugin.errorCodes.X509_ERROR_IN_CRL_NEXT_UPDATE_FIELD] = 'Некорректные данные в поле "nextUpdate" у CRL';
            this.#errorDescription[plugin.errorCodes.X509_OUT_OF_MEM] = 'Не хватает памяти';
            this.#errorDescription[plugin.errorCodes.X509_DEPTH_ZERO_SELF_SIGNED_CERT] = 'Недоверенный самоподписанный сертификат';
            this.#errorDescription[plugin.errorCodes.X509_SELF_SIGNED_CERT_IN_CHAIN] = 'В цепочке обнаружен недоверенный самоподписанный сертификат';
            this.#errorDescription[plugin.errorCodes.X509_UNABLE_TO_GET_ISSUER_CERT_LOCALLY] = 'Невозможно получить локальный сертификат эмитента';
            this.#errorDescription[plugin.errorCodes.X509_UNABLE_TO_VERIFY_LEAF_SIGNATURE] = 'Невозможно проверить первый сертификат';
            this.#errorDescription[plugin.errorCodes.X509_CERT_CHAIN_TOO_LONG] = 'Слишком длинная цепочка сертификатов';
            this.#errorDescription[plugin.errorCodes.X509_CERT_REVOKED] = 'Сертификат отозван';
            this.#errorDescription[plugin.errorCodes.X509_INVALID_CA] = 'Неверный корневой сертификат';
            this.#errorDescription[plugin.errorCodes.X509_INVALID_NON_CA] = 'Неверный некорневой сертфикат, помеченный как корневой';
            this.#errorDescription[plugin.errorCodes.X509_PATH_LENGTH_EXCEEDED] = 'Превышена длина пути';
            this.#errorDescription[plugin.errorCodes.X509_PROXY_PATH_LENGTH_EXCEEDED] = 'Превышина длина пути прокси';
            this.#errorDescription[plugin.errorCodes.X509_PROXY_CERTIFICATES_NOT_ALLOWED] = 'Проксирующие сертификаты недопустимы';
            this.#errorDescription[plugin.errorCodes.X509_INVALID_PURPOSE] = 'Неподдерживаемое назначение сертификата';
            this.#errorDescription[plugin.errorCodes.X509_CERT_UNTRUSTED] = 'Недоверенный сертификат';
            this.#errorDescription[plugin.errorCodes.X509_CERT_REJECTED] = 'Сертифкат отклонен';
            this.#errorDescription[plugin.errorCodes.X509_APPLICATION_VERIFICATION] = 'Ошибка проверки приложения';
            this.#errorDescription[plugin.errorCodes.X509_SUBJECT_ISSUER_MISMATCH] = 'Несовпадения субьекта и эмитента';
            this.#errorDescription[plugin.errorCodes.X509_AKID_SKID_MISMATCH] = 'Несовпадение идентификатора ключа у субьекта и доверенного центра';
            this.#errorDescription[plugin.errorCodes.X509_AKID_ISSUER_SERIAL_MISMATCH] = 'Несовпадение серийного номера субьекта и доверенного центра';
            this.#errorDescription[plugin.errorCodes.X509_KEYUSAGE_NO_CERTSIGN] = 'Ключ не может быть использован для подписи сертификатов';
            this.#errorDescription[plugin.errorCodes.X509_UNABLE_TO_GET_CRL_ISSUER] = 'Невозможно получить CRL подписанта';
            this.#errorDescription[plugin.errorCodes.X509_UNHANDLED_CRITICAL_EXTENSION] = 'Неподдерживаемое расширение';
            this.#errorDescription[plugin.errorCodes.X509_KEYUSAGE_NO_CRL_SIGN] = 'Ключ не может быть использован для подписи CRL';
            this.#errorDescription[plugin.errorCodes.X509_KEYUSAGE_NO_DIGITAL_SIGNATURE] = 'Ключ не может быть использован для цифровой подписи';
            this.#errorDescription[plugin.errorCodes.X509_UNHANDLED_CRITICAL_CRL_EXTENSION] = 'Неподдерживаемое расширение CRL';
            this.#errorDescription[plugin.errorCodes.X509_INVALID_EXTENSION] = 'Неверное или некорректное расширение сертификата';
            this.#errorDescription[plugin.errorCodes.X509_INVALID_POLICY_EXTENSION] = 'Неверное или некорректное расширение политик сертификата';
            this.#errorDescription[plugin.errorCodes.X509V3_INVALID_OBJECT_IDENTIFIER] = 'Неверный или некорректный идентификатор объекта';
            this.#errorDescription[plugin.errorCodes.X509_NO_EXPLICIT_POLICY] = 'Явные политики отсутствуют';
            this.#errorDescription[plugin.errorCodes.X509_DIFFERENT_CRL_SCOPE] = 'Другая область CRL';
            this.#errorDescription[plugin.errorCodes.X509_UNSUPPORTED_EXTENSION_FEATURE] = 'Неподдерживаемое расширение возможностей';
            this.#errorDescription[plugin.errorCodes.X509_UNNESTED_RESOURCE] = 'RFC 3779 неправильное наследование ресурсов';
            this.#errorDescription[plugin.errorCodes.X509_PERMITTED_VIOLATION] = 'Неправильная структура сертифката';
            this.#errorDescription[plugin.errorCodes.X509_EXCLUDED_VIOLATION] = 'Неправильная структура сертфиката';
            this.#errorDescription[plugin.errorCodes.X509_SUBTREE_MINMAX] = 'Неправильная структура сертифката';
            this.#errorDescription[plugin.errorCodes.X509_UNSUPPORTED_CONSTRAINT_TYPE] = 'Неправильная структура сертфиката';
            this.#errorDescription[plugin.errorCodes.X509_UNSUPPORTED_CONSTRAINT_SYNTAX] = 'Неправильная структура сертифката';
            this.#errorDescription[plugin.errorCodes.X509_UNSUPPORTED_NAME_SYNTAX] = 'Неправильная структура сертфиката';
            this.#errorDescription[plugin.errorCodes.X509_CRL_PATH_VALIDATION_ERROR] = 'Неправильный путь CRL';
            this.#errorDescription[plugin.errorCodes.CMS_CERTIFICATE_ALREADY_PRESENT] = 'Сертификат уже используется';
            this.#errorDescription[plugin.errorCodes.CANT_HARDWARE_VERIFY_CMS] = 'Проверка множественной подписи с вычислением хеша на устройстве не поддерживается';
            this.#errorDescription[plugin.errorCodes.DECRYPT_UNSUCCESSFUL] = 'Расшифрование не удалось';

            this.#errorDescription[plugin.errorCodes.TS_TOKEN_MISSED] = 'Ответ службы меток доверенного времени не содержит саму метку';
            this.#errorDescription[plugin.errorCodes.TS_WRONG_CONTENT_TYPE] = 'Метка доверенного времени имеет неверный тип содержимого';
            this.#errorDescription[plugin.errorCodes.TS_MUST_BE_ONE_SIGNER] = 'Метка доверенного времени должна иметь одного подписанта';
            this.#errorDescription[plugin.errorCodes.TS_NO_CONTENT] = 'Метка доверенного времени не содержит данные';
            this.#errorDescription[plugin.errorCodes.TS_ESS_SIGNING_CERT_ERROR] = 'Метка доверенного времени не содержит ESSCertID сертификата TSA';
            this.#errorDescription[plugin.errorCodes.TS_UNSUPPORTED_VERSION] = 'Версия метки доверенного времени не поддерживается';
            this.#errorDescription[plugin.errorCodes.TS_POLICY_MISMATCH] = 'Политика в метке доверенного времени отличается от запрошенной';
            this.#errorDescription[plugin.errorCodes.TS_NONCE_NOT_RETURNED] = 'Метка доверенного времени не содержит nonce, хотя он был запрошен';
            this.#errorDescription[plugin.errorCodes.TS_TSA_UNTRUSTED] = 'Метка доверенного времени создана недоверенным TSA';

            this.#errorDescription[plugin.errorCodes.HOST_NOT_FOUND] = 'Не удалось найти сервер';
            this.#errorDescription[plugin.errorCodes.HTTP_ERROR] = 'HTTP ответ с ошибкой';
            this.#errorDescription[plugin.errorCodes.TST_VERIFICATION_ERROR] = 'Ошибка проверки timestamp токена';
            this.#errorDescription[plugin.errorCodes.UNKNOWN_OBJECT_NAME] = 'Неизвестное имя объекта';

            return this;
        } catch (error) {
            throw new SVTAExceptionRutoken(error.message, error.code);
            // throw new SVTAExceptionRutoken('Невозможно инициализировать Рутокен плагин.');
        }
    }

    async getCertificatesList() {
        this.#throwIfNotInitialized();

        try {
            const devices = await this.#plugin.enumerateDevices();
            if (devices.length === 0) throw new SVTAExceptionRutoken(this.#errorDescription[this.#plugin.errorCodes.DEVICE_NOT_FOUND], 90003);
            const parsedCertificates = [];

            for (const deviceID of devices) {
                let certificateIDs;
                certificateIDs = await this.#plugin.enumerateCertificates(deviceID, this.#plugin.CERT_CATEGORY_USER);

                for (const certificateID of certificateIDs) {
                    const parsed = await this.#plugin.parseCertificate(deviceID, certificateID);
                    parsedCertificates.push(RutokenCertificateVO.fromParsedCertificate(deviceID, certificateID, parsed));
                }

                certificateIDs = await this.#plugin.enumerateCertificates(deviceID, this.#plugin.CERT_CATEGORY_OTHER);

                for (const certificateID of certificateIDs) {
                    const parsed = await this.#plugin.parseCertificate(deviceID, certificateID);
                    parsedCertificates.push(RutokenCertificateVO.fromParsedCertificate(deviceID, certificateID, parsed));
                }

                certificateIDs = await this.#plugin.enumerateCertificates(deviceID, this.#plugin.CERT_CATEGORY_UNSPEC);

                for (const certificateID of certificateIDs) {
                    const parsed = await this.#plugin.parseCertificate(deviceID, certificateID);
                    parsedCertificates.push(RutokenCertificateVO.fromParsedCertificate(deviceID, certificateID, parsed));
                }
            }

            return parsedCertificates;
        } catch (error) {
            throw new SVTAExceptionRutoken('Невозможно получить список сертификатов: ' + error.message, error.code);
        }
    }

    async checkPin(certificate, pin) {
        this.#throwIfNotInitialized();

        try {
            const currentCertificate = await this.refreshSelectedCertificateDeviceID(certificate);
            await this.#plugin.login(currentCertificate.device_id, pin);

            return true;
        } catch (error) {
            if (error.code > 90000) {
                throw error;
            } else {
                throw new SVTAExceptionRutoken(this.#errorDescription[error.message]);
            }
        }
    }

    async getDetachedSignature(certificate, pin, dataPlainString) {
        this.#throwIfNotInitialized();

        try {
            if (!this.#plugin.TOKEN_INFO_IS_LOGGED_IN) {
                await this.#plugin.login(certificate.device_id, pin);
            }

            const options = { detached: true, addEssCert: true, addSignTime: true };

            const signature = await this.#plugin.sign(certificate.device_id, certificate.id, dataPlainString, this.#plugin.DATA_FORMAT_PLAIN, options);

            await this.#plugin.logout(certificate.device_id);

            return signature;
        } catch (error) {
            throw new SVTAExceptionRutoken(this.#errorDescription[error.message]);
        }
    }

    async verifyDetachedSignature(deviceID, signature, dataPlainString) {
        try {
            return await this.#plugin.verify(deviceID, signature, { data: dataPlainString, verifyCertificate: false });
        } catch (error) {
            throw new SVTAExceptionRutoken(this.#errorDescription[error.message]);
        }
    }

    async refreshSelectedCertificateDeviceID(selectedCertificate) {
        const certificates = await this.getCertificatesList();

        const found = certificates.find((certificate) => {
            return certificate.id === selectedCertificate.id;
        });

        if (!found) {
            throw new SVTAExceptionRutoken('Невозможно найти выбранный сертификат среди подключенных Рутокенов.', 90096);
        }

        return new RutokenCertificateVO(found);
    }

    async getCertificateModel(selectedCertificate) {
        const currentCertificate = await this.refreshSelectedCertificateDeviceID(selectedCertificate);

        return {
            thumbprint: currentCertificate.prepared_thumbprint,
            serial: currentCertificate.prepared_serial,
            subject_full: JSON.stringify(currentCertificate.subject_full),
            issuer_full: JSON.stringify(currentCertificate.issuer_full),
            valid_from: currentCertificate.valid.from,
            valid_to: currentCertificate.valid.to,
        };
    }
}
