<template>
    <div>
        <text-header margin-class="mb-3">Закупки</text-header>
        <!--        <b-row class="mb-4">-->
        <!--            <b-col cols="12" sm="12" md="6" xl="4" class="mb-3">-->
        <!--                <multiselect-->
        <!--                    :options="statusList"-->
        <!--                    v-model="filter.status"-->
        <!--                    :multiple="true"-->
        <!--                    :clear-on-select="false"-->
        <!--                    :preserve-search="false"-->
        <!--                    placeholder="Выберите статус"-->
        <!--                    deselect-label="Отменить"-->
        <!--                    select-label="Выбрать"-->
        <!--                    selected-label="Выбрано"-->
        <!--                    label="title"-->
        <!--                    track-by="title">-->
        <!--                    <span slot="noOptions">Нет данных.</span>-->
        <!--                    <span slot="noResult">Записей, соответствующих вашему запросу, не найдено.</span>-->
        <!--                </multiselect>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12" sm="12" md="6" xl="4" class="mb-3">-->
        <!--                <b-input placeholder="Введите номер закупки" v-model="filter.number"></b-input>-->
        <!--            </b-col>-->
        <!--            <b-col cols="12" sm="12" md="6" xl="4" class="mb-3">-->
        <!--                <b-input placeholder="Введите предмет закупки" v-model="filter.name"></b-input>-->
        <!--            </b-col>-->
        <!--            &lt;!&ndash;<b-col cols="12" sm="12" md="6" xl="4" class="mb-3">&ndash;&gt;-->
        <!--            &lt;!&ndash;<b-input placeholder="Выберите категорию" v-model="filter.category"></b-input>&ndash;&gt;-->
        <!--            &lt;!&ndash;</b-col>&ndash;&gt;-->
        <!--            <b-col cols="12" sm="12" md="6" xl="8" class="mb-3">-->
        <!--                <b-input placeholder="Введите наименование поставщика" v-model="filter.provider"></b-input>-->
        <!--            </b-col>-->

        <!--            <b-col cols="12" sm="12" md="12" xl="4" class="mb-3">-->
        <!--                <div class="d-flex justify-content-between">-->
        <!--                    <b-btn @click.prevent="getPurchases(true)" variant="custom-blue" class="text-uppercase">НАЙТИ</b-btn>-->
        <!--                    <b-btn @click.prevent="cleanFilters" variant="custom-outline-secondary" class="text-uppercase">СБРОС</b-btn>-->
        <!--                </div>-->
        <!--            </b-col>-->
        <!--        </b-row>-->
        <!--      is_external-->
        <div v-for="purchase in purchases" :key="purchase.product.id + '_' + purchase.id">
            <personal-purchase :purchase="purchase" @refresh="refresh" />
        </div>
        <empty-request v-if="!purchases.length && !loading"></empty-request>

        <b-pagination v-if="purchases" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="mb-3" @input="getPurchases()"></b-pagination>
    </div>
</template>

<script>
import PersonalPurchase from '@/components/cabinet/components/personal-purchase.vue';

export default {
    name: 'personal-purchases',
    components: { PersonalPurchase },
    data() {
        return {
            statusList: [
                { title: 'Идет прием заявок', id: 1 },
                { title: 'Подведены итоги', id: 2 },
                { title: 'Закупка отменена', id: 7 },
                { title: 'Закупка не состоялась', id: 8 },
                { title: 'Черновик', id: 11 },
                { title: 'Заблокирован', id: 12 },
                { title: 'Сохранен и подписан', id: 13 },
            ],
            purchases: [],
            loading: false,
            showCancelModal: false,
            filter: {
                status: null,
                number: null,
                name: null,
                category: null,
                provider: null,
            },
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1,
            },
            organizations: [],
            emails: [],
        };
    },
    async mounted() {
        await this.getPurchases();
    },
    watch: {
        $route: 'applyFilters',
    },
    methods: {
        getFilters(page = true) {
            return {
                filter: { ...this.filter },
                page: page ? this.meta.current_page : 1,
            };
        },
        async getPurchases(page = true, loading = true) {
            this.loading = loading;
            const response = await this.$api.personal.purchases.getList(this.getFilters(page));
            this.purchases = response.data;
            this.meta = response.meta;
            this.$scrollToTop();
            this.loading = false;
        },
        refresh() {
            this.getPurchases();
        },
        applyFilters(loading = true) {
            this.loading = loading;
            this.getPurchases();
        },
        cleanFilters() {
            this.filter.status = null;
            this.filter.number = null;
            this.filter.name = null;
            this.filter.category = null;
            this.filter.provider = null;
            this.getPurchases(false);
        },
    },
};
</script>
<style scoped>
input::-webkit-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input {
    border-color: rgba(149, 155, 164, 0.2);
}
</style>
