<template>
    <div>
        <text-header>Требования к поставщикам</text-header>
        <div v-if="isEdit">
            <form-row-edit-number v-model="form.proposal_validity_days" :v="v.form.proposal_validity_days" label="Минимальное количество дней действия предложения участника" />
            <form-row-edit-checkbox v-model="form.supplier_requirements.has_no_arrears" label="У участника закупочной сессии должны отсутствовать задолженности по налогам и сборам" />
            <form-row-edit-checkbox v-model="form.supplier_requirements.is_reliable_supplier" label="Участник закупки не должен присутствовать в реестре недобросовестных поставщиков" />
            <form-row-edit-checkbox v-model="form.supplier_requirements.has_additional_requirements" label="Дополнительные требования к участникам закупки" @input="hasAdditionalRequirementsCheckboxEvent" />

            <div v-if="form.supplier_requirements.has_additional_requirements">
                <form-row-edit-textarea
                    v-model="form.supplier_requirements.additional_requirements_description"
                    :v="v.form.supplier_requirements.additional_requirements_description"
                    label="Укажите, каким требованиям должен соответствовать участник закупки" />

                <b-row class="form-row mb-3">
                    <b-col sm="9">
                        <p class="fs-14 grey-color font-weight-bold">Укажите, какими документами участник закупки должен подтвердить соответствие дополнительным требованиям:</p>
                    </b-col>
                    <b-col class="text-right" sm="3">
                        <b-btn class="fs-14 text-uppercase" variant="new-green" @click="addAdditionalRequirement">
                            <img alt="plus" class="mr-2" src="/images/plus-white.svg" />
                            Добавить документ
                        </b-btn>
                    </b-col>
                </b-row>

                <b-form-group
                    v-for="(document, index) in form.supplier_requirements.additional_requirements"
                    :key="index"
                    :label="'Документ ' + (index + 1) + ':'"
                    :state="!v.form.supplier_requirements.additional_requirements.$each.$iter[index].$invalid"
                    invalid-feedback="Поле обязательно для заполнения"
                    label-class="fs-14 grey-color fw-700 d-flex align-items-baseline"
                    label-cols-lg="3"
                    label-cols-sm="4">
                    <b-input-group>
                        <b-form-input
                            v-model="form.supplier_requirements.additional_requirements[index]"
                            class="fs-14"
                            placeholder="Введите название документа, которым участник закупки должен подтвердить соответствие указанным требованиям"></b-form-input>
                        <b-input-group-append>
                            <b-btn :disabled="form.supplier_requirements.additional_requirements.length === 1" class="fs-14" variant="danger" @click="removeAdditionalRequirement(index)">
                                <img alt="plus" src="/images/trash-white.svg" />
                            </b-btn>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group
                    label="Участник закупки должен обладать опытом аналогичных поставок на сумму не менее (Не требуется если пусто или 0)"
                    label-class="fs-14 grey-color fw-700 d-flex align-items-baseline"
                    label-cols-lg="8"
                    label-cols-sm="8">
                    <currency-input
                        v-model="form.supplier_requirements.similar_experience_amounts"
                        v-stop-number-mousewheel
                        :class="v.form.supplier_requirements.similar_experience_amounts ? 'is-valid' : 'is-invalid'"
                        :currency="null"
                        class="form-control fs-14"
                        locale="ru-KG"
                        value-as-integer />
                </b-form-group>
            </div>
        </div>
        <div v-else>
            <ul>
                <li v-if="purchase.proposal_validity_days" class="fs-14 grey-color my-auto col-form-label font-weight-bold">Минимальное количество дней действия предложения участника - {{ purchase.proposal_validity_days }}</li>
                <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">
                    У участника закупочной сессии должны отсутствовать задолженности по налогам и сборам -
                    {{ supplierRequirements.has_no_arrears }}
                </li>
                <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">
                    Участник закупки не должен присутствовать в реестре недобросовестных поставщиков -
                    {{ supplierRequirements.is_reliable_supplier }}
                </li>
                <template v-if="purchase.supplier_requirements.has_additional_requirements && purchase.supplier_requirements.additional_requirements && purchase.supplier_requirements.additional_requirements.length > 0">
                    <li class="fs-14 grey-color my-auto col-form-label">
                        <strong>Участник закупки должен соответствовать следующим требованиям</strong> -
                        {{ purchase.supplier_requirements.additional_requirements_description }}
                    </li>
                    <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">Участник закупки должен подтвердить соответствие указанным требованиям, прикрепив следующие документы при подаче заявки:</li>
                    <li style="list-style-type: none">
                        <ul>
                            <li v-for="requiredDocument in purchase.supplier_requirements.additional_requirements" :key="requiredDocument.id" class="fs-14 grey-color my-auto col-form-label">
                                {{ requiredDocument.title }}
                            </li>
                        </ul>
                    </li>
                </template>

                <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">
                    Участник закупки должен обладать опытом аналогичных поставок на сумму не менее -
                    {{ supplierRequirements.similar_experience_amounts }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'supplier-requirements',
    data() {
        return this.value;
    },
    props: {
        isEdit: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: null,
        },
        v: {
            type: Object,
            default: null,
        },
    },
    /*validations() {
        return this.v;
    },*/
    methods: {
        hasAdditionalRequirementsCheckboxEvent(e) {
            this.form.supplier_requirements.additional_requirements.splice(0);
            if (e) {
                this.addAdditionalRequirement();
            }
        },
        addAdditionalRequirement() {
            this.form.supplier_requirements.additional_requirements.push('');
        },
        removeAdditionalRequirement(index) {
            this.form.supplier_requirements.additional_requirements.splice(index, 1);
        },
    },
    computed: {
        supplierRequirements: {
            get() {
                return {
                    has_no_arrears: this.purchase.supplier_requirements.has_no_arrears ? 'Да' : 'Нет',
                    is_reliable_supplier: this.purchase.supplier_requirements.is_reliable_supplier ? 'Да' : 'Нет',
                    similar_experience_amounts: this.purchase.supplier_requirements.similar_experience_amounts ? this.$formatPrice(this.purchase.supplier_requirements.similar_experience_amounts) : 'Не требуется',
                };
            },
        },
    },
};
</script>
