<template>
    <div>
        <b-row class="mb-4">
            <b-col class="d-flex align-items-center justify-content-between">
                <h2 class="grey-color font-weight-bold mb-3">Возврат средств</h2>
                <b-btn :to="{ name: 'CabinetAccountRefundRequestCreate' }" class="fs-14" variant="new-green">
                    <img alt="plus" class="mr-2" src="/images/plus-white.svg" />
                    Запрос на возврат средств
                </b-btn>
            </b-col>
        </b-row>

        <div class="py-3">
            <b-table :busy="loading" :fields="fields" :items="items" :per-page="pagination.per_page" empty-text="Данные отсутствуют" responsive show-empty>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle mr-1"></b-spinner>
                        <strong>Загрузка данных...</strong>
                    </div>
                </template>
                <template #cell(created_at)="row">
                    {{ getDateTimeFormat(row.item.created_at) }}
                </template>
                <template #cell(operation)>
                    <div class="text-wrap">aklsjdhflkajshdflkhasdlkfhklashjdflkajshdlfkhalkjsdhflkashdflkajhsdfljkhasdlkjfhalksdjhflaksdhjf</div>
                </template>
                <!--            <template #cell(operation_type_id)="row">-->
                <!--                <strong>{{ row.item.operation_type_name }}&nbsp;</strong>-->
                <!--                <template v-if="row.item.accountable_type === 'purchase'">-->
                <!--                    (Извещение №-->
                <!--                    <router-link :to="{ name: 'purchases.grouped.item.view', params: { group: 'fl44', id: row.item.accountable.id } }" target="_blank">-->
                <!--                        {{ row.item.accountable.reg_number }}-->
                <!--                    </router-link>-->
                <!--                    )-->
                <!--                </template>-->
                <!--                <template v-if="row.item.accountable_type === 'refundRequest'"> (Запрос на возврат средств № {{ row.item.accountable.reg_number }})</template>-->
                <!--                <template v-if="row.item.accountable_type === 'organizationReport'">№ {{ row.item.accountable.reg_number }}, ИНН {{ row.item.accountable.inn }}</template>-->
                <!--            </template>-->
                <template #cell(amount)="row">
                    {{ $formatPrice(row.item.amount) }}
                </template>
            </b-table>
            <b-pagination v-if="pagination.total" v-model="pagination.current_page" :per-page="pagination.per_page" :total-rows="pagination.total" align="center" class="mt-4 mb-0 mx-auto" @input="applyFilters" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'cabinet-provider-accounting-refund-request-create',
    data() {
        return {
            loading: true,
            pagination: {},
            items: [],
            fields: [
                { key: 'reg_number', label: 'Рег.номер запроса' },
                { key: 'created_at', label: 'Дата' },
                { key: 'amount', label: 'Сумма, сом', class: 'text-right text-nowrap' },
                { key: 'status', label: 'Статус' },
            ],
        };
    },
    async created() {
        await this.fillData();
    },
    methods: {
        applyFilters() {
            this.fillData();
        },
        async fillData() {
            this.loading = true;
            const response = await this.$api.personal.account.getRefundRequests({ page: this.pagination.current_page || 1 });
            this.items = response.data;
            this.pagination = response.meta;
            this.loading = false;
            this.$scrollToTop();
        },
    },
};
</script>
