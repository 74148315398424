var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cms-content" }, [
      _c("div", { staticClass: "col-xs-12 col-md-12 pr40 mb30 ml30" }, [
        _c("ul", { staticClass: "fs14 lh24 end_list" }, [
          _c("li", { staticClass: "mb10" }, [
            _vm._v(
              "Гарантируем корректность и актуальность прилагаемой информации."
            ),
          ]),
          _c("li", { staticClass: "mb10" }, [
            _vm._v(
              "Понимаем, что представление недостоверной информации повлечет за собой отказ в прохождении аккредитации либо прекращение аккредитации."
            ),
          ]),
          _c("li", { staticClass: "mb10" }, [
            _vm._v(
              "Согласны на использование информации, предоставляемой при прохождении процедуры регистрации / аккредитации, в базах данных оператора ИС ЕСТ, в том числе на публикацию в открытой части ИС."
            ),
          ]),
          _c("li", { staticClass: "mb10" }, [
            _vm._v(
              " Обязуемся в течение 5 (пяти) рабочих дней с момента внесения в сведения, указанные в настоящем заявлении об аккредитации и в представленные документы (в случае их наличия в заявлении), а также о замене и прекращении действия указанных документов (в том числе замене или прекращении действия электронной подписи), извещать оператора ИС ЕСТ о наличии таких изменений. "
            ),
          ]),
          _c("li", { staticClass: "mb10" }, [
            _vm._v(
              "Согласны соответствовать требованиям к участникам закупок, установленным законодательством Республики Кыргызстан, нормативными правовыми актами и Регламентом функционирования ИС ЕСТ."
            ),
          ]),
          _c("li", { staticClass: "mb10" }, [
            _vm._v(
              "Согласны обеспечить при работе в ИС ЕСТ соблюдение законодательства Республики Кыргызстан, нормативных правовых актов и Регламента функционирования ИС ЕСТ."
            ),
          ]),
          _c("li", { staticClass: "mb10" }, [
            _vm._v(
              "Гарантируем, что субъект персональных данных был уведомлен, и дает согласие на обработку персональных данных."
            ),
          ]),
          _c("li", { staticClass: "mb10" }, [
            _vm._v(
              "Гарантируем корректность действий Уполномоченного лица, совершаемые при заполнении формы заявления, а также за иные действия Уполномоченного лица, связанные с обеспечением участия заявителя в ИС ЕСТ."
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }