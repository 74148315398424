<template>
    <div v-if="purchase">
        <b-container fluid class="main_layer py-4">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-40 fw-600 white-color mt-3 mb-3">{{ purchase.product.title }} - № {{ purchase.registration_number }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="white-color fs-14"><b>Статус</b>: {{ purchase.status.title }}</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row-link label="Наименование" v-model="purchase.customer.name" :router-link="{ name: 'OrganizationShow', params: { id: purchase.customer.id } }" />
                <text-row label="ИНН" v-model="purchase.customer.inn" />
                <text-row label="Государственный регистрационный номер" v-model="purchase.customer.reg_number" />
                <text-row label="ОКПО" v-model="purchase.customer.okpo" />
                <text-row label="Юридический адрес" v-model="purchase.customer.address" />
                <text-row label="Дополнительная контактная информация" v-model="purchase.additional_contact_info" v-if="purchase.additional_contact_info" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Условия закупки</text-header>
                <text-row label="Наименование конкурса" v-model="purchase.name" v-if="purchase.name" />
                <text-row label="Предмет закупки" v-model="purchase.deliverable_group_title" />
                <text-row-datetime label="Дата и время размещения закупки" v-model="purchase.purchase_start_datetime" />
                <text-row-datetime label="Дата и время окончания приема предложений" v-model="purchase.purchase_end_datetime" />
                <text-row label="Формат заключения контракта" v-model="purchase.edms.contract_format_title" />
                <text-row label="Оператор ЭДО" v-model="purchase.edms.operator_name" v-if="purchase.edms.operator_name" />
                <text-row-price label="Сумма закупки" v-model="purchase.starting_price" />
                <text-row label="Валюта" v-model="purchase.currency_title" />
                <text-row-documents label="Проект контракта" v-model="contractDraftAttachment" v-if="purchase.contract_draft_attachment" />
                <text-row-documents label="Дополнительные документы" v-model="purchase.notice_supplementary_attachments" v-if="purchase.notice_supplementary_attachments && purchase.notice_supplementary_attachments.length > 0" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Требования к поставщикам</text-header>
                <ul>
                    <li class="fs-14 grey-color my-auto col-form-label font-weight-bold" v-if="purchase.proposal_validity_days">Минимальное количество дней действия предложения участника - {{ purchase.proposal_validity_days }}</li>
                    <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">
                        У участника закупочной сессии должны отсутствовать задолженности по налогам и сборам -
                        {{ purchase.supplier_requirements.has_no_arrears ? 'Да' : 'Нет' }}
                    </li>
                    <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">
                        Участник закупки не должен присутствовать в реестре недобросовестных поставщиков -
                        {{ purchase.supplier_requirements.is_reliable_supplier ? 'Да' : 'Нет' }}
                    </li>
                    <template v-if="purchase.supplier_requirements.has_additional_requirements && purchase.supplier_requirements.additional_requirements && purchase.supplier_requirements.additional_requirements.length > 0">
                        <li class="fs-14 grey-color my-auto col-form-label">
                            <strong>Участник закупки должен соответствовать следующим требованиям</strong> -
                            {{ purchase.supplier_requirements.additional_requirements_description }}
                        </li>
                        <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">Участник закупки должен подтвердить соответствие указанным требованиям, прикрепив следующие документы при подаче предложения:</li>
                        <li style="list-style-type: none">
                            <ul>
                                <li class="fs-14 grey-color my-auto col-form-label" v-for="requiredDocument in purchase.supplier_requirements.additional_requirements" :key="requiredDocument.id">
                                    {{ requiredDocument.title }}
                                </li>
                            </ul>
                        </li>
                    </template>

                    <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">
                        Участник закупки должен обладать опытом аналогичных поставок на сумму не менее -
                        {{ purchase.supplier_requirements.similar_experience_amounts ? $formatPrice(purchase.supplier_requirements.similar_experience_amounts) : 'Не требуется' }}
                    </li>
                </ul>
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Спецификация</text-header>
                <b-row class="my-2" no-gutters>
                    <b-col class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="tcw-50">№</th>
                                    <th>Наименование товара (работ, услуг)</th>
                                    <th>Характеристики</th>
                                    <th class="tcw-100">Ед.изм</th>
                                    <th class="tcw-100">Количество</th>
                                    <th class="tcw-100">Цена за ед.</th>
                                    <th class="tcw-100">Валюта</th>
                                    <th class="tcw-100">Стоимость</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in purchase.deliverables" :key="item.id" v-bind="item">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.characteristics }}</td>
                                    <td class="text-right">{{ item.okei.local_symbol }}</td>
                                    <td class="text-right text-nowrap">{{ item.quantity }}</td>
                                    <td class="text-right text-nowrap">{{ $formatPrice(item.price_per_unit) }}</td>
                                    <td>{{ purchase.currency_title }}</td>
                                    <td class="text-right text-nowrap">{{ $formatPrice(item.quantity * item.price_per_unit) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="my-2" v-if="purchase.status.id === 'summarizing' && purchase.proposals && purchase.proposals.length > 0">
            <b-container>
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Ценовые предложения (подведение итогов)</h2>
                    </b-col>
                    <b-col class="text-right my-auto" md="2">
                        <a :href="$links.manual.simple_purchase_protocol_forming" target="_blank" class="btn btn-custom-danger">Инструкция</a>
                    </b-col>
                </b-row>
                <proposal-card
                    v-for="(proposal, index) in purchase.proposals"
                    :key="'proposal_' + index"
                    :proposal="proposal"
                    :purchase="purchase"
                    :purchase-id="purchase.id"
                    :supplier-requirements="purchase.supplier_requirements"
                    :index="index"
                    @refresh="fillData" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2" v-if="purchase.protocol">
            <b-container>
                <text-header>Итоговый протокол</text-header>
                <protocol-card :protocol="purchase.protocol" :purchase="purchase" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container class="mb-5">
                <b-row>
                    <b-col>
                        <template v-if="$store.getters.isLoggedIn && (purchase.actions.supplier.proposal.create || purchase.actions.supplier.proposal.edit)">
                            <template v-if="purchase.actions.supplier.has_money.status">
                                <b-button :to="{ name: 'SimplePurchaseNewProposal', params: { id: purchase.id } }" class="text-uppercase mx-2" target="_blank" variant="custom-blue">
                                    <template v-if="purchase.actions.supplier.proposal.create"> Подать заявку</template>
                                    <template v-if="purchase.actions.supplier.proposal.edit"> Изменить заявку</template>
                                </b-button>
                            </template>
                            <template v-else>
                                <b-button :id="'popover-' + purchase.product.id + '-' + purchase.id" class="text-uppercase mx-2" target="_blank" variant="custom-blue">
                                    <template v-if="purchase.actions.supplier.proposal.create"> Подать заявку</template>
                                    <template v-if="purchase.actions.supplier.proposal.edit"> Изменить заявку</template>
                                </b-button>
                                <b-popover :target="'popover-' + purchase.product.id + '-' + purchase.id" triggers="hover">
                                    На лицевом счете недостаточно средств. Для подачи предложения не хватает <span class="text-danger text-nowrap">{{ $formatPrice(purchase.actions.supplier.has_money.not_enough) }}</span> сом.<br />
                                    <router-link :to="{ name: 'CabinetAccountReplenishment' }">Пополнить лицевой счет</router-link>.
                                </b-popover>
                            </template>
                        </template>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import TextRowLink from '@/components/common/form-rows/text-row-link';
import ProposalCard from '@/components/purchases/simple/proposal-card.vue';
import ProtocolCard from '@/components/purchases/simple/protocol-card.vue';

export default {
    name: 'simple-purchase-show',
    components: {
        ProtocolCard,
        ProposalCard,
        TextRowLink,
    },
    data() {
        return {
            id: this.$route.params.id,
            purchase: null,
            visible: [],
        };
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        async fillData() {
            this.purchase = await this.$api.purchases.simple.show(this.id);
        },
    },
    computed: {
        contractDraftAttachment() {
            return [this.purchase.contract_draft_attachment];
        },
    },
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}
.link {
    color: var(--est-blue-2) !important;
}
.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}
.table {
    border-collapse: collapse;
}
.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}
.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
