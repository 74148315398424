<template>
    <div>
        <b-container v-if="showForm" class="register_page_header_block" fluid>
            <b-container>
                <b-row>
                    <b-col md="10">
                        <div class="fs-52 fw-600 white-color pt-3">Регистрация организации</div>
                    </b-col>
                    <b-col class="text-right my-auto" md="2">
                        <a :href="$links.manual.registration" class="btn btn-custom-danger" target="_blank">Инструкция</a>
                    </b-col>
                </b-row>
                <b-tabs v-model="tabIndex" class="w-100 register_customer py-3" content-class="invisible">
                    <b-tab>
                        <registration-tab-header slot="title" :has-errors="$v.form.organization.$invalid" number="01"> Регистрационные и контактные данные организации</registration-tab-header>
                    </b-tab>
                    <b-tab>
                        <registration-tab-header slot="title" :has-errors="$v.form.user.$invalid" number="02"> Идентификационные данные пользователя</registration-tab-header>
                    </b-tab>
                    <b-tab>
                        <registration-tab-header slot="title" :has-errors="$v.form.checkRules.$invalid" number="03"> Завершение создания заявления</registration-tab-header>
                    </b-tab>
                </b-tabs>
            </b-container>
        </b-container>
        <b-container v-if="showForm" fluid>
            <b-container>
                <b-tabs v-model="tabIndex" class="w-100" nav-class="invisible">
                    <b-tab>
                        <text-header>Данные организации</text-header>
                        <form-row-single-select
                            v-model="form.organization.country_id"
                            :disabled="true"
                            :options="$globalDictionaries.countries"
                            :v="$v.form.organization.country_id"
                            label="Страна резидентом которой является Ваша компания"
                            label-field="title" />
                        <form-row-edit-text v-model="form.organization.name" :v="$v.form.organization.name" label="Наименование" />
                        <form-row-edit-date-2 v-model="form.organization.registration_date" :v="$v.form.organization.registration_date" label="Дата государственной регистрации" />
                        <form-row-edit-text v-model="form.organization.registration_number" :v="$v.form.organization.registration_number" label="Государственный регистрационный номер" />
                        <form-row-edit-text v-model="form.organization.inn" :disabled="true" :v="$v.form.organization.inn" label="ИНН" />
                        <form-row-edit-text v-model="form.organization.okpo" :v="$v.form.organization.okpo" label="ОКПО" />
                        <form-row-edit-text v-model="form.organization.head" :v="$v.form.organization.head" label="ФИО руководителя" />

                        <text-header>Контактная информация</text-header>
                        <form-row-edit-text v-model="form.organization.contacts.legal_address" :v="$v.form.organization.contacts.legal_address" label="Адрес юридический" />
                        <form-row-edit-text v-model="form.organization.contacts.postal_address" :v="$v.form.organization.contacts.postal_address" label="Адрес почтовый" />
                        <form-row-edit-email
                            :email-confirmed="form.organization.contacts.email_confirmed"
                            :v="$v.form.organization.contacts.email"
                            :value="form.organization.contacts.email"
                            :with-validation="true"
                            label="Адрес электронной почты"
                            @value="form.organization.contacts.email = $event"
                            @email-confirmed="form.organization.contacts.email_confirmed = $event" />
                        <form-row-edit-text v-model="form.organization.contacts.website" :v="$v.form.organization.contacts.website" label="Адрес сайта" />
                        <form-row-edit-text v-model="form.organization.contacts.phone" :v="$v.form.organization.contacts.phone" label="Телефон" @value="form.organization.contacts.phone = $event" />
                        <form-row-edit-text v-model="form.organization.contacts.contact_person_name" :v="$v.form.organization.contacts.contact_person_name" label="ФИО контактного лица при осуществлении закупок" />
                    </b-tab>
                    <b-tab>
                        <text-header>Идентификационные данные пользователя</text-header>
                        <form-row-edit-text v-model="form.user.name" :v="$v.form.user.name" label="ФИО" />
                        <form-row-edit-email
                            :disabled="true"
                            :email-confirmed="form.user.email_confirmed"
                            :v="$v.form.user.email"
                            :value="form.user.email"
                            :with-validation="true"
                            description="Данный адрес будет использоваться как логин при входе в ИС"
                            label="Адрес электронной почты"
                            @value="form.user.email = $event"
                            @email-confirmed="form.user.email_confirmed = $event" />
                        <form-row-edit-text v-model="form.user.password" :v="$v.form.user.password" label="Пароль" type="password" />
                        <form-row-edit-text v-model="form.user.phone" :v="$v.form.user.phone" label="Телефон" />
                    </b-tab>
                    <b-tab>
                        <text-header>Завершение создания заявления</text-header>
                        <registration-confirmation-text-block />
                        <b-form-group :state="!$v.form.checkRules">
                            <b-form-checkbox v-model="form.checkRules" :state="!$v.form.checkRules">
                                <span class="grey-color fw-600">Подтверждаю перечисленные выше условия</span>
                            </b-form-checkbox>
                        </b-form-group>
                    </b-tab>
                </b-tabs>
                <div class="my-5">
                    <b-overlay :show="isGettingXML" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button v-if="tabIndex > 0" class="mx-2 text-uppercase" variant="custom-outline-secondary" @click="previousTab">Назад</b-button>
                    </b-overlay>
                    <b-button v-if="tabIndex < 2" class="mx-2 text-uppercase" variant="custom-blue" @click="nextTab">Далее</b-button>
                    <b-overlay :show="isGettingXML" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button v-if="tabIndex === 2" :disabled="$v.form.$invalid" class="mx-2 text-uppercase" variant="custom-blue" @click="register">Готово</b-button>
                    </b-overlay>
                    <span v-if="tabIndex === 2 && $v.form.$invalid" class="text-danger mx-2">Заполните все обязательные поля на каждой вкладке.</span>
                </div>
            </b-container>
        </b-container>
        <b-modal ref="enter-org-modal" v-model="showModal" hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Регистрация" @show="getCountryId">
            <div class="d-block">
                <b-overlay :show="checkingEnterOrg" opacity="0.6" rounded spinner-normal spinner-variant="primary">
                    <b-overlay :show="isGettingCountryId" opacity="0.6" rounded spinner-normal spinner-variant="primary">
                        <form-row-single-select
                            v-model="form.organization.country_id"
                            :options="$globalDictionaries.countries"
                            :v="$v.form.organization.country_id"
                            label="Выберите страну резидентом которой является Ваша компания"
                            label-field="title" />
                    </b-overlay>
                    <form-row-edit-text v-model="form.organization.inn" :errorText="innError" :v="$v.form.organization.inn" label="ИНН" @input="innChange" />
                    <form-row-edit-email
                        :errorText="emailError"
                        :v="$v.form.user.email"
                        :value="form.user.email"
                        description="Данный адрес будет использоваться как логин при входе в ИС"
                        label="Адрес электронной почты пользователя"
                        @change="emailChange"
                        @value="form.user.email = $event" />
                </b-overlay>
            </div>
            <div slot="modal-footer" class="w-100">
                <b-button :disabled="checkingEnterOrg" class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelEnterOrg">Отмена</b-button>
                <b-overlay :show="checkingEnterOrg" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="$v.form.organization.inn.$invalid || $v.form.user.email.$invalid" class="text-uppercase" variant="custom-blue" @click="checkEnterOrg"> Продолжить</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from 'axios';

import TextHeader from '@/components/common/form-rows/text-header';
import RegistrationTabHeader from '@/components/auth/parts/registration-tab-header';
import { email, minLength, required, helpers } from 'vuelidate/lib/validators';
import FormRowEditEmail from '@/components/common/form-rows/form-row-edit-email';
import RegistrationConfirmationTextBlock from '@/components/auth/parts/registration-confirmation-text-block';
import FormRowEditDate2 from '@/components/common/form-rows/form-row-edit-date-2.vue';

const isExist = (param) => helpers.withParams({ type: 'isExist', value: param }, (value) => !helpers.req(value) || param === false);

export default {
    name: 'RegisterCustomer',
    components: {
        FormRowEditDate2,
        RegistrationConfirmationTextBlock,
        FormRowEditEmail,
        RegistrationTabHeader,
        TextHeader,
    },
    data() {
        return {
            tabIndex: 0,
            showModal: true,
            checkingEnterOrg: false,
            isGettingCountryId: false,
            showForm: false,
            isGettingXML: false,
            countryCode: '',
            emailExist: false,
            emailError: '',
            innExist: false,
            innError: '',
            signedFormXml: '',
            form: {
                organization: {
                    country_id: null,
                    name: '',
                    registration_date: null,
                    registration_number: '',
                    inn: '',
                    okpo: '',
                    head: '',
                    contacts: {
                        legal_address: '',
                        postal_address: '',
                        email: '',
                        email_confirmed: false,
                        website: '',
                        phone: '',
                        contact_person_name: '',
                    },
                },
                user: {
                    name: '',
                    email: '',
                    email_confirmed: false,
                    password: '',
                    phone: '',
                },
                checkRules: false,
            },
        };
    },
    validations() {
        return {
            form: {
                organization: {
                    name: { required },
                    registration_date: { required },
                    registration_number: { required },
                    inn: { required, innExist: isExist(this.innExist) },
                    country_id: { required },
                    okpo: { required },
                    head: { required },
                    contacts: {
                        legal_address: { required },
                        postal_address: { required },
                        email: { required, email },
                        email_confirmed: { checked: (value) => value === true },
                        website: {},
                        phone: { required },
                        fax: {},
                        contact_person_name: { required },
                    },
                },
                user: {
                    name: { required, minLength: minLength(2) },
                    email: { required, email, emailExist: isExist(this.emailExist) },
                    email_confirmed: { checked: (value) => value === true },
                    password: { required, minLength: minLength(8) },
                    phone: { required },
                },
                checkRules: { checked: (value) => value === true },
            },
        };
    },
    methods: {
        nextTab() {
            this.tabIndex++;
            this.$scrollToTop();
        },
        previousTab() {
            this.tabIndex--;
            this.$scrollToTop();
        },
        innChange() {
            this.innExist = false;
        },
        emailChange() {
            this.emailExist = false;
        },
        async getCountryId() {
            this.isGettingCountryId = true;
            const ipInfoResponse = await axios.get('https://ipinfo.io/?token=d87f91031e8e3b');
            this.countryCode = ipInfoResponse?.data?.country ?? 'KG';

            const countryIdResponse = await axios.post('/auth/get_country_id', { country_code: this.countryCode });
            this.form.organization.country_id = countryIdResponse.data.id;
            // .then((response) => {
            //     this.form.organization.country_id = response.data.id;
            // });
            this.isGettingCountryId = false;
        },
        // если нажата "Отмена" при вводе ИНН и email
        cancelEnterOrg() {
            this.$refs['enter-org-modal'].hide();
            this.showForm = false;
            this.showOrgModal = false;
            this.emailExist = false;
            this.innExist = false;
            this.$router.back();
        },
        //проверяем ИНН и email
        async checkEnterOrg() {
            this.checkingEnterOrg = true;
            axios
                .post('/auth/check_inn_email', {
                    country_id: this.form.organization.country_id,
                    inn: this.form.organization.inn,
                    email: this.form.user.email,
                })
                .then(() => {
                    this.$refs['enter-org-modal'].hide();
                    this.showOrgModal = false;
                    this.showForm = true;
                })
                .catch((err) => {
                    if (err.response.status === 403) {
                        let messages = JSON.parse(err.response.data.message);
                        if (messages.inn) {
                            this.innError = messages.inn;
                            this.innExist = true;
                        }
                        if (messages.email) {
                            this.emailError = messages.email;
                            this.emailExist = true;
                        }
                    }
                    this.checkingEnterOrg = false;
                });
        },
        // Регистрация поставщика
        async register() {
            // регистрируем пользователя, создаем организацию и сохраняем в БД сертификат пользователя
            axios
                .post('/auth/register', {
                    user: this.form.user,
                    organization: this.form.organization,
                })
                .then((response) => {
                    // логиним пользователя и редиректим на главную
                    this.showSignModal = false;
                    this.$store
                        .dispatch('login', {
                            token: response.data.token,
                            user: response.data.user,
                            organization: response.data.organization,
                        })
                        .then(() => {
                            window.location.assign('/cabinet');
                        });
                })
                .catch((error) => {
                    this.showError(error);
                    console.log(error);
                    this.isDataSending = false;
                });
        },
    },
};
</script>

<style scoped>
.register_page_header_block {
    background-image: url('/images/providers_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.head_register-list {
    color: var(--halfWhite-color);
}
</style>

<style>
.register_customer ul.nav-tabs {
    width: 100%;
    background: transparent;
}

.register_customer ul.nav-tabs li {
    width: 33.33%;
}

.register_customer ul.nav-tabs li a {
    border: none;
}

.register_customer .nav-link.active {
    border-bottom: 2px solid #fff;
    background: transparent;
}

.register_customer .nav-link.active .head_register-list {
    color: #fff;
}

.invisible {
    display: none;
}
</style>
