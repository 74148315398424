<template>
    <b-card class="fs-14 my-2">
        <proposal-card-title :index="index" :proposal="proposal" class="fs-16" />
        <b-row>
            <b-col class="my-1" md="9">
                <p>
                    Время подачи заявки: <strong>{{ getDateTimeFormatSeconds(proposal.created_at) }}</strong>
                </p>
                <p v-if="proposal.pdf_signed_attachment_id">
                    Заявка участника - <a :href="$getAttachmentDownloadLink(proposal.pdf_signed_attachment_id)"><strong>Скачать</strong></a>
                </p>
                <p v-if="proposal.proposal_validity_days">
                    Срок действия предложения от даты окончания приема заявок, дней: <strong>{{ proposal.proposal_validity_days }}</strong>
                </p>
            </b-col>
            <b-col v-if="!confirmed" class="my-1" md="3">
                <form-row-edit-text-compact v-if="withRating && (proposal.status.id === 'actual' || proposal.status.id === 'winner')" v-model="rating" class="my-1" label="Рейтинг" type="number" />
                <b-button v-if="proposal.status.id === 'actual'" class="fs-14 mx-2 my-1" size="sm" variant="custom-green" @click="confirmWinner"> Выбрать победителем</b-button>
                <reject-direct-proposal-button :proposal="proposal" :purchase-id="purchaseId" @refresh="refresh" />
            </b-col>
            <b-col v-else class="my-1 text-right" md="3">
                <p class="fs-14">Рейтинг: {{ proposal.rating ? proposal.rating : 'Не установлен' }}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="my-1" md="6">
                <b>{{ proposal.status.title }}. </b>
                <template v-if="proposal.status.id === 'rejected'"><b>Причина отклонения:</b> {{ proposal.rejection_reason }}</template>
            </b-col>
            <b-col class="my-1 text-right" md="6">
                <b-button :aria-expanded="detailsVisible ? 'true' : 'false'" :class="detailsVisible ? null : 'collapsed'" aria-controls="collapse-proposal-details" class="fs-14 mx-2" size="sm" @click="detailsVisible = !detailsVisible">
                    {{ detailsVisible ? 'Скрыть' : 'Показать' }} подробности
                </b-button>
            </b-col>
        </b-row>
        <b-collapse id="collapse-proposal-details" v-model="detailsVisible">
            <b-row class="my-1">
                <b-col md="12">
                    Карточка предприятия -
                    <template v-if="proposal.organization_card && proposal.organization_card.id">
                        <a :href="$getAttachmentDownloadLink(proposal.organization_card.id)">{{ proposal.organization_card.name }}</a>
                    </template>
                    <template v-else> Отсутствует</template>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col v-if="supplierRequirements.has_no_arrears || supplierRequirements.is_reliable_supplier || supplierRequirements.has_additional_requirements" md="12">
                    Требования к поставщикам:<br />
                    <ul>
                        <template v-if="supplierRequirements.has_no_arrears">
                            <li>
                                <template v-if="proposal.supplier_requirements_confirmed.no_arrears.id">
                                    <shield-green />
                                    Поставщик подтвердил отсутствие задолженности по налогам и сборам -
                                    <a :href="$getAttachmentDownloadLink(proposal.supplier_requirements_confirmed.no_arrears.id)">{{ proposal.supplier_requirements_confirmed.no_arrears.name }}</a>
                                </template>
                                <template v-else>
                                    <shield-red />
                                    Поставщик <strong>НЕ</strong> подтвердил отсутствие задолженности по налогам и сборам
                                </template>
                            </li>
                        </template>
                        <template v-if="supplierRequirements.is_reliable_supplier">
                            <li>
                                <template v-if="proposal.supplier_requirements_confirmed.is_reliable_supplier">
                                    <shield-green />
                                    Поставщик подтвердил, что <strong>НЕ</strong> присутствует в реестре недобросовестных поставщиков
                                </template>
                                <template v-else>
                                    <shield-red />
                                    Поставщик <strong>НЕ</strong> подтвердил, что не присутствует в реестре недобросовестных поставщиков
                                </template>
                            </li>
                        </template>
                        <template v-if="supplierRequirements.has_additional_requirements">
                            <li>
                                <template v-if="proposal.supplier_requirements_confirmed.additional_requirements && proposal.supplier_requirements_confirmed.additional_requirements.length > 0">
                                    <shield-green />
                                    Поставщик подтвердил что соответствует дополнительным требованиям -
                                    {{ supplierRequirements.additional_requirements_description }}
                                </template>
                                <template v-else>
                                    <shield-red />
                                    Поставщик <strong>НЕ</strong> подтвердил что соответствует дополнительным требованиям -
                                    {{ supplierRequirements.additional_requirements_description }}
                                </template>
                            </li>
                            <span>Документы, подтверждающие соответствие требованиям:</span>
                            <ul>
                                <li v-for="additionalRequirement in proposal.supplier_requirements_confirmed.additional_requirements" :key="additionalRequirement.id">
                                    {{ additionalRequirement.title }} -
                                    <a :href="$getAttachmentDownloadLink(additionalRequirement.confirmation_document.id)" class="fs-14">{{ additionalRequirement.confirmation_document.name }}</a>
                                </li>
                            </ul>
                        </template>
                        <template v-if="supplierRequirements.similar_experience_amounts && supplierRequirements.similar_experience_amounts > 0">
                            <li>
                                <template v-if="proposal.supplier_requirements_confirmed.similar_experience_amounts.id">
                                    <shield-green />
                                    Поставщик подтвердил опыт аналогичных поставок на сумму не менее {{ $formatPrice(supplierRequirements.similar_experience_amounts) }} сом -
                                    <a :href="$getAttachmentDownloadLink(proposal.supplier_requirements_confirmed.similar_experience_amounts.id)">{{ proposal.supplier_requirements_confirmed.similar_experience_amounts.name }}</a>
                                </template>
                                <template v-else>
                                    <shield-red />
                                    Поставщик <strong>НЕ</strong> подтвердил опыт аналогичных поставок на сумму не менее {{ $formatPrice(supplierRequirements.similar_experience_amounts) }} сом
                                </template>
                            </li>
                        </template>
                    </ul>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col md="12">
                    <strong>Дополнительные документы, приложенные поставщиком к заявке:</strong><br />
                    <ul v-if="proposal.attachments.length > 0">
                        <li v-for="(document, docIndex) of proposal.attachments" :key="'provider_' + index + '_document_' + docIndex">
                            <a :href="$getAttachmentDownloadLink(document.id)">{{ document.name }}</a>
                        </li>
                    </ul>
                    <span v-else>Отсутствуют</span>
                </b-col>
            </b-row>
            <b-row class="my-1">
                <b-col md="12">
                    <strong>Спецификация:</strong><br />
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="tcw-50">№</th>
                                <th>Наименование товара (работ, услуг)</th>
                                <th>Характеристики</th>
                                <th class="tcw-100">Количество</th>
                                <th class="tcw-100">Цена за ед.</th>
                                <th class="tcw-100">Валюта</th>
                                <th class="tcw-100">Стоимость</th>
                                <th class="tcw-100">Ед.изм</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in proposal.deliverables" :key="item.id" v-bind="item">
                                <td>{{ index + 1 }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.characteristics }}</td>
                                <td class="text-right">{{ item.quantity }}</td>
                                <td class="text-right">{{ $formatPrice(item.price_per_unit) }}</td>
                                <td>{{ purchase.currency_title }}</td>
                                <td class="text-right">{{ $formatPrice(item.quantity * item.price_per_unit) }}</td>
                                <td class="text-right">{{ item.okei.local_symbol }}</td>
                            </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </b-collapse>
    </b-card>
</template>

<script>
import ProposalCardTitle from '@/components/purchases/direct/proposal-card-title.vue';
import ShieldGreen from '@/components/common/ShieldGreen.vue';
import ShieldRed from '@/components/common/ShieldRed.vue';
import FormRowEditTextCompact from '@/components/common/form-rows/form-row-edit-text-compact.vue';
import RejectDirectProposalButton from '@/components/purchases/direct/reject-direct-proposal-button.vue';

export default {
    name: 'private-winner-selection-proposal-card',
    props: ['supplierRequirements', 'proposal', 'index', 'purchaseId', 'value', 'v', 'withRating', 'confirmed', 'purchase'],
    components: { RejectDirectProposalButton, FormRowEditTextCompact, ShieldRed, ShieldGreen, ProposalCardTitle },
    data() {
        return {
            detailsVisible: false,
        };
    },
    methods: {
        refresh() {
            this.$emit('refresh');
        },
        confirmWinner() {
            const message = 'Вы действительно хотите выбрать заявку ' + this.proposal.supplier.name + ' на сумму ' + this.$formatPrice(this.proposal.price) + ' сом победителем?';
            const h = this.$createElement;
            const messageVNode = h('div', [h('p', [message]), h('p', { class: ['text-danger fw-600'] }, ['ВНИМАНИЕ! Данная операция необратима.'])]);
            this.$bvModal
                .msgBoxConfirm([messageVNode], {
                    title: 'Выбор победителя',
                    okTitle: 'Выбрать победителем',
                    okVariant: 'custom-danger',
                    cancelTitle: 'Отмена',
                    cancelVariant: 'custom-outline-secondary',
                    centered: true,
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                })
                .then(async (value) => {
                    if (value) {
                        await this.$api.purchases.direct.setWinnerProposal(this.purchaseId, this.proposal.id);
                        this.refresh();
                    }
                });
        },
        confirmReject() {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите отклонить заявку ' + this.proposal.supplier.name + ' на сумму ' + this.$formatPrice(this.proposal.price) + ' сом?', {
                    title: 'Отклонение заявку',
                    okTitle: 'Отклонить',
                    okVariant: 'custom-danger',
                    cancelTitle: 'Отмена',
                    cancelVariant: 'custom-outline-secondary',
                    centered: true,
                    noCloseOnBackdrop: true,
                    noCloseOnEsc: true,
                })
                .then(async (value) => {
                    if (value) {
                        await this.$api.purchases.direct.rejectProposal(this.purchaseId, this.proposal.id);
                        this.refresh();
                    }
                });
        },
    },
    computed: {
        rating: {
            get() {
                return this.value;
            },
            set(value) {
                if (this.v) {
                    this.v.$touch();
                }
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style scoped>
a {
    color: var(--est-blue-2);
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
