var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "rutoken-error-modal",
          attrs: {
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
            title: "Ошибка Рутокен",
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "mx-1 text-uppercase",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("Закрыть")]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _vm.rutokenError
            ? _c(
                "b-container",
                { staticClass: "text-danger fs-18 fw-600 text-center my-5" },
                [
                  _c("p", [_vm._v(_vm._s(_vm.rutokenError.message))]),
                  _vm.rutokenError.code === 90097 ||
                  _vm.rutokenError.code === 90098
                    ? [
                        _c("p", [
                          _vm._v(
                            " Инструкция по установке Рутокен Плагин на сайте разработчика - "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://dev.rutoken.ru/pages/viewpage.action?pageId=72451796",
                                target: "_blank",
                              },
                            },
                            [_vm._v("ссылка")]
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }