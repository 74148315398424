<template>
    <div>
        <text-header margin-class="mb-3">Запрос счета на оплату</text-header>
        <form-row-single-select v-model="form.currency" :options="currencies" :v="$v.form.currency" label="Валюта" label-field="title" placeholder="Выберите валюту закупки" />
        <form-row-edit-price v-model="form.amount" :v="$v.form.amount" label="Сумма пополнения" />
        <div class="text-right">
            <b-button class="text-uppercase mx-2 fs-14" variant="custom-outline-secondary" @click="onCancel">Отмена</b-button>
            <b-button :disabled="$v.form.$invalid" class="text-uppercase mx-2 fs-14" variant="custom-green" @click="sendRequest">Скачать счет</b-button>
        </div>
    </div>
</template>

<script>
import { required, minValue } from 'vuelidate/lib/validators';

export default {
    name: 'cabinet-account-replenishment',
    data() {
        return {
            isLoading: true,
            form: {
                currency: 'KGS',
                amount: 0,
            },
        };
    },
    validations() {
        return {
            form: {
                currency: { required },
                amount: { required, minValue: minValue(1) },
            },
        };
    },
    mounted() {},
    methods: {
        sendRequest() {
            if (this.$api.personal.account.downloadInvoice(this.formObject)) {
                this.onCancel();
            }
        },
        onCancel() {
            this.$router.push({ name: 'CabinetAccountInfo' });
        },
    },
    computed: {
        currencies() {
            return this.$globalDictionaries.currencies.filter((currency) => currency.replenish_available);
        },
        formObject() {
            return {
                currency: this.form.currency,
                amount: this.form.amount,
            };
        },
    },
};
</script>
