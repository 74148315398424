var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "div",
        [
          _c("text-header", { attrs: { "margin-class": "mb-3" } }, [
            _vm._v("Регистрационные данные организации"),
          ]),
          _vm.form.country
            ? _c("text-row", {
                attrs: {
                  compact: true,
                  "label-cols-lg": 6,
                  "label-cols-sm": 6,
                  label: "Страна",
                },
                model: {
                  value: _vm.form.country.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form.country, "title", $$v)
                  },
                  expression: "form.country.title",
                },
              })
            : _vm._e(),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "Полное наименование",
            },
            model: {
              value: _vm.form.name,
              callback: function ($$v) {
                _vm.$set(_vm.form, "name", $$v)
              },
              expression: "form.name",
            },
          }),
          _c("text-row-date", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "Дата государственной регистрации",
            },
            model: {
              value: _vm.form.registration_date,
              callback: function ($$v) {
                _vm.$set(_vm.form, "registration_date", $$v)
              },
              expression: "form.registration_date",
            },
          }),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "Государственный регистрационный номер",
            },
            model: {
              value: _vm.form.registration_number,
              callback: function ($$v) {
                _vm.$set(_vm.form, "registration_number", $$v)
              },
              expression: "form.registration_number",
            },
          }),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "ИНН",
            },
            model: {
              value: _vm.form.inn,
              callback: function ($$v) {
                _vm.$set(_vm.form, "inn", $$v)
              },
              expression: "form.inn",
            },
          }),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "ОКПО",
            },
            model: {
              value: _vm.form.okpo,
              callback: function ($$v) {
                _vm.$set(_vm.form, "okpo", $$v)
              },
              expression: "form.okpo",
            },
          }),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "ФИО руководителя",
            },
            model: {
              value: _vm.form.head,
              callback: function ($$v) {
                _vm.$set(_vm.form, "head", $$v)
              },
              expression: "form.head",
            },
          }),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "Адрес юридический",
            },
            model: {
              value: _vm.form.contacts.legal_address,
              callback: function ($$v) {
                _vm.$set(_vm.form.contacts, "legal_address", $$v)
              },
              expression: "form.contacts.legal_address",
            },
          }),
          _c("text-header", [_vm._v("Контактная информация")]),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "Адрес почтовый",
            },
            model: {
              value: _vm.form.contacts.postal_address,
              callback: function ($$v) {
                _vm.$set(_vm.form.contacts, "postal_address", $$v)
              },
              expression: "form.contacts.postal_address",
            },
          }),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "Адрес электронной почты",
            },
            model: {
              value: _vm.form.contacts.email,
              callback: function ($$v) {
                _vm.$set(_vm.form.contacts, "email", $$v)
              },
              expression: "form.contacts.email",
            },
          }),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "Адрес сайта",
            },
            model: {
              value: _vm.form.contacts.website,
              callback: function ($$v) {
                _vm.$set(_vm.form.contacts, "website", $$v)
              },
              expression: "form.contacts.website",
            },
          }),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "Телефон",
            },
            model: {
              value: _vm.form.contacts.phone,
              callback: function ($$v) {
                _vm.$set(_vm.form.contacts, "phone", $$v)
              },
              expression: "form.contacts.phone",
            },
          }),
          _c("text-row", {
            attrs: {
              compact: true,
              "label-cols-lg": 6,
              "label-cols-sm": 6,
              label: "ФИО контактного лица при осуществлении закупок",
            },
            model: {
              value: _vm.form.contacts.contact_person_name,
              callback: function ($$v) {
                _vm.$set(_vm.form.contacts, "contact_person_name", $$v)
              },
              expression: "form.contacts.contact_person_name",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }