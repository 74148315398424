<template>
    <div>
        <b-btn @click="showModal" class="btn-card-action btn-card-action-light my-1">Копировать</b-btn>
        <b-modal ref="cancel-modal" :title="title" v-model="isModalVisible" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg">
            <b-row>
                <b-col md="12">
                    <p class="fs-16 font-weight-bold grey-color">Будет создана копия объявления о проводимом конкурсе, за исключением данных в поле "Дата и время окончания приема заявок и вскрытия конвертов"</p>
                </b-col>
            </b-row>
            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="cancelModal">Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-danger" class="text-uppercase" @click="copyPurchase">Копировать</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'copy-competitive-purchase-button',
    props: {
        purchase: {
            type: Object,
        },
    },
    data() {
        return {
            isModalVisible: false,
            isDataSending: false,
        };
    },
    methods: {
        showModal() {
            this.isModalVisible = true;
        },
        cancelModal() {
            this.isModalVisible = false;
        },
        copyPurchase() {
            this.isDataSending = true;
            this.$api.purchases.competitive
                .copy(this.purchase.id)
                .then((response) => {
                    this.$router.push({ name: 'CompetitivePurchaseEdit', params: { id: response.id } });
                })
                .finally(() => {
                    this.isDataSending = false;
                });
        },
    },
    computed: {
        title() {
            return `Копировать конкурс № ${this.purchase.registration_number}?`;
        },
    },
};
</script>
