<template>
    <div>
        <notice-simple v-if="purchase.product.id === 'simple'" :purchase="purchase" />
        <notice-competitive v-if="purchase.product.id === 'competitive'" :purchase="purchase" />
        <notice-direct v-if="purchase.product.id === 'direct'" :purchase="purchase" />
    </div>
</template>

<script>
import NoticeSimple from '@/components/notices/notice-simple.vue';
import NoticeCompetitive from '@/components/notices/notice-competitive.vue';
import NoticeDirect from '@/components/notices/notice-direct.vue';

export default {
    name: 'notice',
    components: { NoticeDirect, NoticeCompetitive, NoticeSimple },
    props: ['purchase'],
};
</script>
