var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "fs-14 my-2" },
    [
      _c("protocol-card-title", {
        staticClass: "fs-16",
        attrs: { protocol: _vm.protocol },
      }),
      _vm.protocol.winner_proposal
        ? _c(
            "b-row",
            { staticClass: "my-1" },
            [
              _c("b-col", { attrs: { md: "6" } }, [
                _c("p", [
                  _vm._v(" Время подачи заявки: "),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.getDateTimeFormatSeconds(
                          _vm.protocol.winner_proposal.created_at
                        )
                      )
                    ),
                  ]),
                ]),
                _vm.protocol.winner_proposal.proposal_validity_days
                  ? _c("p", [
                      _vm._v(
                        " Срок действия предложения от даты окончания приема заявок, дней: "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.protocol.winner_proposal.proposal_validity_days
                          )
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "b-col",
                { attrs: { md: "6" } },
                [
                  _vm._v(" Карточка предприятия - "),
                  _vm.protocol.winner_proposal.organization_card &&
                  _vm.protocol.winner_proposal.organization_card.id
                    ? [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.$getAttachmentDownloadLink(
                                _vm.protocol.winner_proposal.organization_card
                                  .id
                              ),
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.protocol.winner_proposal.organization_card
                                  .name
                              )
                            ),
                          ]
                        ),
                      ]
                    : [_vm._v(" Отсутствует ")],
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.protocol.winner_proposal
        ? _c(
            "b-row",
            { staticClass: "my-1" },
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("strong", [_vm._v("Спецификация:")]),
                _c("br"),
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "tcw-50" }, [_vm._v("№")]),
                      _c("th", [_vm._v("Наименование товара (работ, услуг)")]),
                      _c("th", { staticClass: "tcw-100" }, [_vm._v("Ед.изм")]),
                      _c("th", { staticClass: "tcw-100" }, [
                        _vm._v("Количество"),
                      ]),
                      _c("th", { staticClass: "tcw-100" }, [
                        _vm._v("Цена за ед."),
                      ]),
                      _c("th", { staticClass: "tcw-100" }, [_vm._v("Валюта")]),
                      _c("th", { staticClass: "tcw-100" }, [
                        _vm._v("Стоимость"),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.protocol.winner_proposal.deliverables,
                      function (item, index) {
                        return _c(
                          "tr",
                          _vm._b({ key: item.id }, "tr", item, false),
                          [
                            _c("td", [_vm._v(_vm._s(index + 1))]),
                            _c("td", [_vm._v(_vm._s(item.name))]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(item.okei.local_symbol)),
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(item.quantity)),
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                _vm._s(_vm.$formatPrice(item.price_per_unit))
                              ),
                            ]),
                            _c("td", [
                              _vm._v(_vm._s(_vm.purchase.currency_title)),
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatPrice(
                                    item.quantity * item.price_per_unit
                                  )
                                )
                              ),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }