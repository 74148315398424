var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isPurchaseLoading,
              expression: "!isPurchaseLoading",
            },
          ],
        },
        [
          _c(
            "b-container",
            {
              staticClass: "purchase-create-header-block",
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "10" } }, [
                        _c(
                          "div",
                          { staticClass: "fs-40 fw-600 white-color pt-3" },
                          [
                            _vm._v(
                              "Редактирование объявления о прямой закупке № " +
                                _vm._s(_vm.form.registration_number)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.failedPurchase
            ? _c(
                "b-container",
                {
                  staticStyle: { "background-color": "#f5f5f7" },
                  attrs: { fluid: "" },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        {
                          staticClass:
                            "pb-5 position-relative purchase_session",
                        },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "text-header-big",
                                [
                                  _vm._v(
                                    "Информация о несостоявшемся конкурсе № "
                                  ),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "system_link-style2",
                                      attrs: {
                                        to: {
                                          name: "CompetitivePurchaseShow",
                                          params: { id: _vm.failedPurchase.id },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.failedPurchase.registration_number
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("CustomerInfo", {
                                attrs: {
                                  "additional-info":
                                    _vm.form.additional_contact_info,
                                  customer: _vm.customer,
                                  "additional-label":
                                    "Дополнительная контактная информация",
                                },
                              }),
                              _c("text-header", [_vm._v("Условия закупки")]),
                              _vm.failedPurchase.name
                                ? _c("text-row", {
                                    attrs: { label: "Наименование конкурса" },
                                    model: {
                                      value: _vm.failedPurchase.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.failedPurchase,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression: "failedPurchase.name",
                                    },
                                  })
                                : _vm._e(),
                              _c("text-row", {
                                attrs: { label: "Предмет закупки" },
                                model: {
                                  value:
                                    _vm.failedPurchase.deliverable_group_title,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.failedPurchase,
                                      "deliverable_group_title",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "failedPurchase.deliverable_group_title",
                                },
                              }),
                              _c("text-row", {
                                attrs: { label: "Формат заключения контракта" },
                                model: {
                                  value:
                                    _vm.failedPurchase.edms
                                      .contract_format_title,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.failedPurchase.edms,
                                      "contract_format_title",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "failedPurchase.edms.contract_format_title",
                                },
                              }),
                              _vm.failedPurchase.edms.operator_name
                                ? _c("text-row", {
                                    attrs: { label: "Оператор ЭДО" },
                                    model: {
                                      value:
                                        _vm.failedPurchase.edms.operator_name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.failedPurchase.edms,
                                          "operator_name",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "failedPurchase.edms.operator_name",
                                    },
                                  })
                                : _vm._e(),
                              _c("text-row-price", {
                                attrs: { label: "Сумма закупки" },
                                model: {
                                  value: _vm.failedPurchase.starting_price,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.failedPurchase,
                                      "starting_price",
                                      $$v
                                    )
                                  },
                                  expression: "failedPurchase.starting_price",
                                },
                              }),
                              _c("text-row", {
                                attrs: { label: "Валюта" },
                                model: {
                                  value: _vm.failedPurchase.currency_title,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.failedPurchase,
                                      "currency_title",
                                      $$v
                                    )
                                  },
                                  expression: "failedPurchase.currency_title",
                                },
                              }),
                              _vm.failedPurchase.contract_draft_attachment
                                ? _c("text-row-document", {
                                    attrs: { label: "Проект контракта" },
                                    model: {
                                      value:
                                        _vm.failedPurchase
                                          .contract_draft_attachment,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.failedPurchase,
                                          "contract_draft_attachment",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "failedPurchase.contract_draft_attachment",
                                    },
                                  })
                                : _vm._e(),
                              _vm.failedPurchase
                                .notice_supplementary_attachments &&
                              _vm.failedPurchase
                                .notice_supplementary_attachments.length > 0
                                ? _c("text-row-documents", {
                                    attrs: {
                                      label: "Дополнительные документы",
                                    },
                                    model: {
                                      value:
                                        _vm.failedPurchase
                                          .notice_supplementary_attachments,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.failedPurchase,
                                          "notice_supplementary_attachments",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "failedPurchase.notice_supplementary_attachments",
                                    },
                                  })
                                : _vm._e(),
                              _vm.failedPurchase.with_lots
                                ? _c("text-row", {
                                    attrs: {
                                      label: "Конкурс с лотированием",
                                      value: "Да",
                                    },
                                  })
                                : _vm._e(),
                              _c("text-header", [
                                _vm._v("Требования к поставщикам"),
                              ]),
                              _c(
                                "ul",
                                [
                                  _vm.failedPurchase.proposal_validity_days
                                    ? _c(
                                        "li",
                                        {
                                          staticClass:
                                            "fs-14 grey-color my-auto col-form-label font-weight-bold",
                                        },
                                        [
                                          _vm._v(
                                            " Минимальное количество дней действия предложения участника - " +
                                              _vm._s(
                                                _vm.failedPurchase
                                                  .proposal_validity_days
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "fs-14 grey-color my-auto col-form-label font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        " У участника закупочной сессии должны отсутствовать задолженности по налогам и сборам - " +
                                          _vm._s(
                                            _vm.failedPurchase
                                              .supplier_requirements
                                              .has_no_arrears
                                              ? "Да"
                                              : "Нет"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "fs-14 grey-color my-auto col-form-label font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        " Участник закупки не должен присутствовать в реестре недобросовестных поставщиков - " +
                                          _vm._s(
                                            _vm.failedPurchase
                                              .supplier_requirements
                                              .is_reliable_supplier
                                              ? "Да"
                                              : "Нет"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _vm.failedPurchase.supplier_requirements
                                    .has_additional_requirements &&
                                  _vm.failedPurchase.supplier_requirements
                                    .additional_requirements &&
                                  _vm.failedPurchase.supplier_requirements
                                    .additional_requirements.length > 0
                                    ? [
                                        _c(
                                          "li",
                                          {
                                            staticClass:
                                              "fs-14 grey-color my-auto col-form-label",
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                "Участник закупки должен соответствовать следующим требованиям"
                                              ),
                                            ]),
                                            _vm._v(
                                              " - " +
                                                _vm._s(
                                                  _vm.failedPurchase
                                                    .supplier_requirements
                                                    .additional_requirements_description
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticClass:
                                              "fs-14 grey-color my-auto col-form-label font-weight-bold",
                                          },
                                          [
                                            _vm._v(
                                              "Участник закупки должен подтвердить соответствие указанным требованиям, прикрепив следующие документы при подаче заявки:"
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          {
                                            staticStyle: {
                                              "list-style-type": "none",
                                            },
                                          },
                                          [
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.failedPurchase
                                                  .supplier_requirements
                                                  .additional_requirements,
                                                function (requiredDocument) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: requiredDocument.id,
                                                      staticClass:
                                                        "fs-14 grey-color my-auto col-form-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            requiredDocument.title
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        ),
                                      ]
                                    : _vm._e(),
                                  _c(
                                    "li",
                                    {
                                      staticClass:
                                        "fs-14 grey-color my-auto col-form-label font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        " Участник закупки должен обладать опытом аналогичных поставок на сумму не менее - " +
                                          _vm._s(
                                            _vm.failedPurchase
                                              .supplier_requirements
                                              .similar_experience_amounts
                                              ? _vm.$formatPrice(
                                                  _vm.failedPurchase
                                                    .supplier_requirements
                                                    .similar_experience_amounts
                                                )
                                              : "Не требуется"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                2
                              ),
                              _c("text-header", [_vm._v("Спецификация")]),
                              _c(
                                "b-row",
                                {
                                  staticClass: "my-2",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "b-col",
                                    { staticClass: "table-responsive" },
                                    [
                                      _c("table", { staticClass: "table" }, [
                                        _c("thead", [
                                          _c("tr", [
                                            _c(
                                              "th",
                                              { staticClass: "tcw-50" },
                                              [_vm._v("№")]
                                            ),
                                            _c("th", [
                                              _vm._v(
                                                "Наименование товара (работ, услуг)"
                                              ),
                                            ]),
                                            _c("th", [
                                              _vm._v("Характеристики"),
                                            ]),
                                            _c(
                                              "th",
                                              { staticClass: "tcw-100" },
                                              [_vm._v("Ед.изм")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "tcw-100" },
                                              [_vm._v("Количество")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "tcw-100" },
                                              [_vm._v("Цена за ед.")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "tcw-100" },
                                              [_vm._v("Валюта")]
                                            ),
                                            _c(
                                              "th",
                                              { staticClass: "tcw-100" },
                                              [_vm._v("Стоимость")]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "tbody",
                                          _vm._l(
                                            _vm.failedPurchase.deliverables,
                                            function (item, index) {
                                              return _c(
                                                "tr",
                                                _vm._b(
                                                  { key: item.id },
                                                  "tr",
                                                  item,
                                                  false
                                                ),
                                                [
                                                  _c("td", [
                                                    _vm._v(_vm._s(index + 1)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(_vm._s(item.name)),
                                                  ]),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.characteristics
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass: "text-right",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.okei.local_symbol
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-right text-nowrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.quantity)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-right text-nowrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$formatPrice(
                                                            item.price_per_unit
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c("td", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.failedPurchase
                                                          .currency_title
                                                      )
                                                    ),
                                                  ]),
                                                  _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "text-right text-nowrap",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$formatPrice(
                                                            item.quantity *
                                                              item.price_per_unit
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("text-header-big", { staticClass: "mt-5" }, [
                                _vm._v(
                                  "Создание прямой закупки на основании несостоявшегося конкурса"
                                ),
                              ]),
                              _c("form-row-edit-textarea", {
                                attrs: {
                                  rows: 5,
                                  label:
                                    "Примечание к закупке (отображается только в ЛК Заказчика)",
                                },
                                model: {
                                  value: _vm.form.note,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "note", $$v)
                                  },
                                  expression: "form.note",
                                },
                              }),
                              _c("form-row-edit-datetime2", {
                                attrs: {
                                  "max-date":
                                    _vm.validations
                                      .envelopes_opening_max_datetime,
                                  "min-date":
                                    _vm.validations
                                      .envelopes_opening_min_datetime,
                                  v: _vm.$v.form.purchase_end_datetime,
                                  label:
                                    "Дата и время окончания приема заявок и вскрытия конвертов",
                                  "max-error-text":
                                    "Продолжительность закупочной сессии должна составлять максимум 5 полных рабочих дней",
                                  "min-error-text":
                                    "Продолжительность закупочной сессии должна составлять минимум 2 дня (48 часов)",
                                },
                                model: {
                                  value: _vm.form.purchase_end_datetime,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "purchase_end_datetime",
                                      $$v
                                    )
                                  },
                                  expression: "form.purchase_end_datetime",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-row",
                                { staticClass: "my-3 mt-5" },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mx-2 text-uppercase",
                                          attrs: {
                                            variant: "custom-outline-secondary",
                                          },
                                          on: { click: _vm.onCancel },
                                        },
                                        [_vm._v("Отменить")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase mx-2",
                                          attrs: { variant: "custom-danger" },
                                          on: { click: _vm.saveDraft },
                                        },
                                        [_vm._v("Сохранить черновик")]
                                      ),
                                      _vm.userRolesPermitted([
                                        _vm.$userRoles.DIRECTOR,
                                        _vm.$userRoles.ADMINISTRATOR,
                                      ])
                                        ? [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass:
                                                  "text-uppercase mx-2",
                                                attrs: {
                                                  disabled:
                                                    _vm.$v.form.$invalid,
                                                  variant: "custom-blue",
                                                },
                                                on: { click: _vm.rutokenInit },
                                              },
                                              [_vm._v(" Подписать и сохранить")]
                                            ),
                                          ]
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.userRolesPermitted([
            _vm.$userRoles.DIRECTOR,
            _vm.$userRoles.ADMINISTRATOR,
          ])
            ? _c(
                "b-modal",
                {
                  ref: "sign-form-modal",
                  attrs: {
                    centered: "",
                    "hide-header-close": "",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                    scrollable: "",
                    size: "lg",
                    title: "Подписать заявку",
                  },
                  model: {
                    value: _vm.showSignModal,
                    callback: function ($$v) {
                      _vm.showSignModal = $$v
                    },
                    expression: "showSignModal",
                  },
                },
                [
                  _c(
                    "b-tabs",
                    { attrs: { "content-class": "mt-3 px-3 d-block" } },
                    [
                      _c(
                        "b-tab",
                        {
                          attrs: { active: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "system_link-style fs-16",
                                      },
                                      [_vm._v("Форма")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2602645129
                          ),
                        },
                        [
                          _c("CustomerInfo", {
                            attrs: { customer: _vm.customer },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "title",
                                fn: function () {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "system_link-style fs-16",
                                      },
                                      [_vm._v("Xml представление")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3252452967
                          ),
                        },
                        [
                          _c("div", { staticClass: "px-3" }, [
                            _c(
                              "pre",
                              {
                                staticClass: "fs-10 grey-color p-3 greyBg m-0",
                              },
                              [_vm._v(_vm._s(_vm.signedFormXml))]
                            ),
                          ]),
                        ]
                      ),
                      _vm.signature
                        ? _c(
                            "b-tab",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "title",
                                    fn: function () {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "system_link-style fs-16",
                                          },
                                          [_vm._v("Подпись")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                2932814592
                              ),
                            },
                            [
                              _c("div", { staticClass: "px-3" }, [
                                _c(
                                  "pre",
                                  {
                                    staticClass:
                                      "fs-10 grey-color p-3 greyBg m-0",
                                  },
                                  [_vm._v(_vm._s(_vm.signature))]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "w-100 px-3",
                      attrs: { slot: "modal-footer" },
                      slot: "modal-footer",
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "my-3" },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-overlay",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: {
                                    show: _vm.isSigningXml || _vm.isDataSending,
                                    opacity: "0.6",
                                    rounded: "",
                                    "spinner-small": "",
                                    "spinner-variant": "primary",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        disabled: _vm.isDataSending,
                                        variant: "custom-outline-secondary",
                                      },
                                      on: { click: _vm.cancelSignModal },
                                    },
                                    [_vm._v("Отмена")]
                                  ),
                                ],
                                1
                              ),
                              !_vm.signature
                                ? _c(
                                    "b-overlay",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        show: _vm.isSigningXml,
                                        opacity: "0.6",
                                        rounded: "",
                                        "spinner-small": "",
                                        "spinner-variant": "primary",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            disabled: !_vm.selectCertificate,
                                            variant: "custom-blue",
                                          },
                                          on: { click: _vm.askForPin },
                                        },
                                        [_vm._v("Подписать")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.signature
                                ? _c(
                                    "b-overlay",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        show: _vm.isDataSending,
                                        opacity: "0.6",
                                        rounded: "",
                                        "spinner-small": "",
                                        "spinner-variant": "primary",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            disabled: !_vm.selectCertificate,
                                            variant: "custom-blue",
                                          },
                                          on: { click: _vm.sendData },
                                        },
                                        [_vm._v("Сохранить")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              attrs: {
                id: "pin-input-modal",
                centered: "",
                "hide-header-close": "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                title: "Введите PIN-код",
              },
              scopedSlots: _vm._u([
                {
                  key: "modal-footer",
                  fn: function () {
                    return [
                      _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isPinChecking,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2 text-uppercase",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.cancelPinModal },
                            },
                            [_vm._v("Отмена")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isPinChecking,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: !_vm.pin,
                                variant: "custom-blue",
                              },
                              on: { click: _vm.okPinModal },
                            },
                            [_vm._v("OK")]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("selected-certificate-information", {
                attrs: { certificate: _vm.selectCertificate },
              }),
              _c("form-row-edit-text", {
                attrs: { v: _vm.$v.pin, label: "PIN", type: "password" },
                model: {
                  value: _vm.pin,
                  callback: function ($$v) {
                    _vm.pin = $$v
                  },
                  expression: "pin",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("RutokenErrorModal", {
        attrs: {
          "rutoken-error": _vm.rutokenError,
          show: _vm.rutokenModalShow,
        },
        on: {
          cancel: function ($event) {
            _vm.rutokenModalShow = false
          },
        },
      }),
      _c("SelectCertificateModal", {
        attrs: {
          rutoken: _vm.rutoken,
          show: _vm.showSelectCertificate,
          v: _vm.$v.selectCertificate,
        },
        on: {
          hideModal: (value) => _vm.changeShowSelectCertificate(value),
          save: (value) => _vm.makeXml(value),
        },
        model: {
          value: _vm.selectCertificate,
          callback: function ($$v) {
            _vm.selectCertificate = $$v
          },
          expression: "selectCertificate",
        },
      }),
      _vm.isPurchaseLoading ? _c("system-preloader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }