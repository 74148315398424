<template>
    <div>
        <b-container fluid class="my-2" v-if="$store.getters.isLoggedIn && purchase.customer.id === $store.getters.getOrganization.id && purchase.private_data && purchase.private_data.proposals && isStatusProtocolFormedOrGreater">
            <b-container v-if="purchase.private_data.proposals && purchase.private_data.proposals.length > 0">
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Конкурсные заявки</h2>
                    </b-col>
                </b-row>
                <private-proposal-card
                    v-for="(proposal, index) in purchase.private_data.proposals"
                    :key="'proposal_' + index"
                    :proposal="proposal"
                    :purchase-id="purchase.id"
                    :supplier-requirements="purchase.supplier_requirements"
                    :purchase="purchase"
                    :index="index"
                    :with-rating="true" />
            </b-container>
            <b-container v-if="purchase.private_data.proposals && purchase.private_data.proposals.length === 0">
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Конкурсные заявки отсутствуют</h2>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>
<script>
import PrivateProposalCard from '@/components/purchases/direct/private-proposal-card.vue';

export default {
    name: 'private-proposals-block',
    components: {
        PrivateProposalCard,
    },
    props: {
        purchase: {},
    },
    computed: {
        isStatusProtocolFormedOrGreater() {
            return !(this.purchase.status.id === 'draft' || this.purchase.status.id === 'signed' || this.purchase.status.id === 'accepting' || this.purchase.status.id === 'summarizing');
        },
    },
};
</script>
