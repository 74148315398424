var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "fs-14 my-2" },
    [
      _c("proposal-card-title", {
        staticClass: "fs-16",
        attrs: { index: _vm.index, proposal: _vm.proposal },
      }),
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "my-1", attrs: { md: "9" } }, [
            _c("p", [
              _vm._v(" Время подачи заявки: "),
              _c("strong", [
                _vm._v(
                  _vm._s(_vm.getDateTimeFormatSeconds(_vm.proposal.created_at))
                ),
              ]),
            ]),
            _vm.proposal.pdf_signed_attachment_id
              ? _c("p", [
                  _vm._v(" Заявка участника - "),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: _vm.$getAttachmentDownloadLink(
                          _vm.proposal.pdf_signed_attachment_id
                        ),
                      },
                    },
                    [_c("strong", [_vm._v("Скачать")])]
                  ),
                ])
              : _vm._e(),
            _vm.proposal.proposal_validity_days
              ? _c("p", [
                  _vm._v(
                    " Срок действия предложения от даты окончания приема заявок, дней: "
                  ),
                  _c("strong", [
                    _vm._v(_vm._s(_vm.proposal.proposal_validity_days)),
                  ]),
                ])
              : _vm._e(),
          ]),
          !_vm.confirmed
            ? _c(
                "b-col",
                { staticClass: "my-1", attrs: { md: "3" } },
                [
                  _vm.withRating &&
                  (_vm.proposal.status.id === "actual" ||
                    _vm.proposal.status.id === "winner")
                    ? _c("form-row-edit-text-compact", {
                        staticClass: "my-1",
                        attrs: { label: "Рейтинг", type: "number" },
                        model: {
                          value: _vm.rating,
                          callback: function ($$v) {
                            _vm.rating = $$v
                          },
                          expression: "rating",
                        },
                      })
                    : _vm._e(),
                  _vm.proposal.status.id === "actual"
                    ? _c(
                        "b-button",
                        {
                          staticClass: "fs-14 mx-2 my-1",
                          attrs: { size: "sm", variant: "custom-green" },
                          on: { click: _vm.confirmWinner },
                        },
                        [_vm._v(" Выбрать победителем")]
                      )
                    : _vm._e(),
                  _c("reject-direct-proposal-button", {
                    attrs: {
                      proposal: _vm.proposal,
                      "purchase-id": _vm.purchaseId,
                    },
                    on: { refresh: _vm.refresh },
                  }),
                ],
                1
              )
            : _c(
                "b-col",
                { staticClass: "my-1 text-right", attrs: { md: "3" } },
                [
                  _c("p", { staticClass: "fs-14" }, [
                    _vm._v(
                      "Рейтинг: " +
                        _vm._s(
                          _vm.proposal.rating
                            ? _vm.proposal.rating
                            : "Не установлен"
                        )
                    ),
                  ]),
                ]
              ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "my-1", attrs: { md: "6" } },
            [
              _c("b", [_vm._v(_vm._s(_vm.proposal.status.title) + ". ")]),
              _vm.proposal.status.id === "rejected"
                ? [
                    _c("b", [_vm._v("Причина отклонения:")]),
                    _vm._v(" " + _vm._s(_vm.proposal.rejection_reason)),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c(
            "b-col",
            { staticClass: "my-1 text-right", attrs: { md: "6" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "fs-14 mx-2",
                  class: _vm.detailsVisible ? null : "collapsed",
                  attrs: {
                    "aria-expanded": _vm.detailsVisible ? "true" : "false",
                    "aria-controls": "collapse-proposal-details",
                    size: "sm",
                  },
                  on: {
                    click: function ($event) {
                      _vm.detailsVisible = !_vm.detailsVisible
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.detailsVisible ? "Скрыть" : "Показать") +
                      " подробности "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-collapse",
        {
          attrs: { id: "collapse-proposal-details" },
          model: {
            value: _vm.detailsVisible,
            callback: function ($$v) {
              _vm.detailsVisible = $$v
            },
            expression: "detailsVisible",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "my-1" },
            [
              _c(
                "b-col",
                { attrs: { md: "12" } },
                [
                  _vm._v(" Карточка предприятия - "),
                  _vm.proposal.organization_card &&
                  _vm.proposal.organization_card.id
                    ? [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.$getAttachmentDownloadLink(
                                _vm.proposal.organization_card.id
                              ),
                            },
                          },
                          [_vm._v(_vm._s(_vm.proposal.organization_card.name))]
                        ),
                      ]
                    : [_vm._v(" Отсутствует")],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-1" },
            [
              _vm.supplierRequirements.has_no_arrears ||
              _vm.supplierRequirements.is_reliable_supplier ||
              _vm.supplierRequirements.has_additional_requirements
                ? _c("b-col", { attrs: { md: "12" } }, [
                    _vm._v(" Требования к поставщикам:"),
                    _c("br"),
                    _c(
                      "ul",
                      [
                        _vm.supplierRequirements.has_no_arrears
                          ? [
                              _c(
                                "li",
                                [
                                  _vm.proposal.supplier_requirements_confirmed
                                    .no_arrears.id
                                    ? [
                                        _c("shield-green"),
                                        _vm._v(
                                          " Поставщик подтвердил отсутствие задолженности по налогам и сборам - "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.$getAttachmentDownloadLink(
                                                _vm.proposal
                                                  .supplier_requirements_confirmed
                                                  .no_arrears.id
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.proposal
                                                  .supplier_requirements_confirmed
                                                  .no_arrears.name
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c("shield-red"),
                                        _vm._v(" Поставщик "),
                                        _c("strong", [_vm._v("НЕ")]),
                                        _vm._v(
                                          " подтвердил отсутствие задолженности по налогам и сборам "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]
                          : _vm._e(),
                        _vm.supplierRequirements.is_reliable_supplier
                          ? [
                              _c(
                                "li",
                                [
                                  _vm.proposal.supplier_requirements_confirmed
                                    .is_reliable_supplier
                                    ? [
                                        _c("shield-green"),
                                        _vm._v(" Поставщик подтвердил, что "),
                                        _c("strong", [_vm._v("НЕ")]),
                                        _vm._v(
                                          " присутствует в реестре недобросовестных поставщиков "
                                        ),
                                      ]
                                    : [
                                        _c("shield-red"),
                                        _vm._v(" Поставщик "),
                                        _c("strong", [_vm._v("НЕ")]),
                                        _vm._v(
                                          " подтвердил, что не присутствует в реестре недобросовестных поставщиков "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]
                          : _vm._e(),
                        _vm.supplierRequirements.has_additional_requirements
                          ? [
                              _c(
                                "li",
                                [
                                  _vm.proposal.supplier_requirements_confirmed
                                    .additional_requirements &&
                                  _vm.proposal.supplier_requirements_confirmed
                                    .additional_requirements.length > 0
                                    ? [
                                        _c("shield-green"),
                                        _vm._v(
                                          " Поставщик подтвердил что соответствует дополнительным требованиям - " +
                                            _vm._s(
                                              _vm.supplierRequirements
                                                .additional_requirements_description
                                            ) +
                                            " "
                                        ),
                                      ]
                                    : [
                                        _c("shield-red"),
                                        _vm._v(" Поставщик "),
                                        _c("strong", [_vm._v("НЕ")]),
                                        _vm._v(
                                          " подтвердил что соответствует дополнительным требованиям - " +
                                            _vm._s(
                                              _vm.supplierRequirements
                                                .additional_requirements_description
                                            ) +
                                            " "
                                        ),
                                      ],
                                ],
                                2
                              ),
                              _c("span", [
                                _vm._v(
                                  "Документы, подтверждающие соответствие требованиям:"
                                ),
                              ]),
                              _c(
                                "ul",
                                _vm._l(
                                  _vm.proposal.supplier_requirements_confirmed
                                    .additional_requirements,
                                  function (additionalRequirement) {
                                    return _c(
                                      "li",
                                      { key: additionalRequirement.id },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              additionalRequirement.title
                                            ) +
                                            " - "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "fs-14",
                                            attrs: {
                                              href: _vm.$getAttachmentDownloadLink(
                                                additionalRequirement
                                                  .confirmation_document.id
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                additionalRequirement
                                                  .confirmation_document.name
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          : _vm._e(),
                        _vm.supplierRequirements.similar_experience_amounts &&
                        _vm.supplierRequirements.similar_experience_amounts > 0
                          ? [
                              _c(
                                "li",
                                [
                                  _vm.proposal.supplier_requirements_confirmed
                                    .similar_experience_amounts.id
                                    ? [
                                        _c("shield-green"),
                                        _vm._v(
                                          " Поставщик подтвердил опыт аналогичных поставок на сумму не менее " +
                                            _vm._s(
                                              _vm.$formatPrice(
                                                _vm.supplierRequirements
                                                  .similar_experience_amounts
                                              )
                                            ) +
                                            " сом - "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: _vm.$getAttachmentDownloadLink(
                                                _vm.proposal
                                                  .supplier_requirements_confirmed
                                                  .similar_experience_amounts.id
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.proposal
                                                  .supplier_requirements_confirmed
                                                  .similar_experience_amounts
                                                  .name
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    : [
                                        _c("shield-red"),
                                        _vm._v(" Поставщик "),
                                        _c("strong", [_vm._v("НЕ")]),
                                        _vm._v(
                                          " подтвердил опыт аналогичных поставок на сумму не менее " +
                                            _vm._s(
                                              _vm.$formatPrice(
                                                _vm.supplierRequirements
                                                  .similar_experience_amounts
                                              )
                                            ) +
                                            " сом "
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-1" },
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("strong", [
                  _vm._v(
                    "Дополнительные документы, приложенные поставщиком к заявке:"
                  ),
                ]),
                _c("br"),
                _vm.proposal.attachments.length > 0
                  ? _c(
                      "ul",
                      _vm._l(
                        _vm.proposal.attachments,
                        function (document, docIndex) {
                          return _c(
                            "li",
                            {
                              key:
                                "provider_" +
                                _vm.index +
                                "_document_" +
                                docIndex,
                            },
                            [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.$getAttachmentDownloadLink(
                                      document.id
                                    ),
                                  },
                                },
                                [_vm._v(_vm._s(document.name))]
                              ),
                            ]
                          )
                        }
                      ),
                      0
                    )
                  : _c("span", [_vm._v("Отсутствуют")]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-1" },
            [
              _c("b-col", { attrs: { md: "12" } }, [
                _c("strong", [_vm._v("Спецификация:")]),
                _c("br"),
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { staticClass: "tcw-50" }, [_vm._v("№")]),
                      _c("th", [_vm._v("Наименование товара (работ, услуг)")]),
                      _c("th", [_vm._v("Характеристики")]),
                      _c("th", { staticClass: "tcw-100" }, [
                        _vm._v("Количество"),
                      ]),
                      _c("th", { staticClass: "tcw-100" }, [
                        _vm._v("Цена за ед."),
                      ]),
                      _c("th", { staticClass: "tcw-100" }, [_vm._v("Валюта")]),
                      _c("th", { staticClass: "tcw-100" }, [
                        _vm._v("Стоимость"),
                      ]),
                      _c("th", { staticClass: "tcw-100" }, [_vm._v("Ед.изм")]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(_vm.proposal.deliverables, function (item, index) {
                      return _c(
                        "tr",
                        _vm._b({ key: item.id }, "tr", item, false),
                        [
                          _c("td", [_vm._v(_vm._s(index + 1))]),
                          _c("td", [_vm._v(_vm._s(item.name))]),
                          _c("td", [_vm._v(_vm._s(item.characteristics))]),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(_vm._s(item.quantity)),
                          ]),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(_vm.$formatPrice(item.price_per_unit))
                            ),
                          ]),
                          _c("td", [
                            _vm._v(_vm._s(_vm.purchase.currency_title)),
                          ]),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$formatPrice(
                                  item.quantity * item.price_per_unit
                                )
                              )
                            ),
                          ]),
                          _c("td", { staticClass: "text-right" }, [
                            _vm._v(_vm._s(item.okei.local_symbol)),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }