<template>
    <div>
        <b-container fluid class="main_layer py-2" v-if="!!organization">
            <b-container>
                <b-row class="my-1">
                    <b-col class="d-flex align-items-center justify-content-between column-768">
                        <p class="fs-40 fw-600 white-color main_text">{{ organization.name }}</p>
                    </b-col>
                </b-row>
                <b-row v-if="organization.country_name">
                    <b-col>
                        <div>
                            <p class="fs-20 white-color my-1">
                                {{ organization.country_name }}
                            </p>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container>
            <b-row v-if="!!organization">
                <b-col class="pb-5">
                    <text-header>Реквизиты организации</text-header>
                    <text-row label="ИНН" v-model="organization.inn" />
                    <text-row label="ОКПО" v-model="organization.okpo" />
                    <text-row label="Рег.номер" v-model="organization.registration_number" />
                    <text-row-date label="Дата регистрации" v-model="organization.registration_date" />
                    <text-row label="Руководитель" v-model="organization.head" />
                    <text-row label="Юридический адрес" v-model="organization.legal_address" />

                    <text-header>Контактная информация</text-header>
                    <text-row label="Почтовый адрес" v-model="organization.postal_address" />
                    <text-row label="Email" v-model="organization.email" />
                    <text-row label="Сайт" v-model="organization.website" />
                    <text-row label="Телефон" v-model="organization.phone" />
                    <text-row label="ФИО контактного лица" v-model="organization.contact_person_name" />
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'organization-show',
    data() {
        return {
            organization: null,
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        async getData() {
            this.organization = await this.$api.organizations.get(this.$route.params.id);
        },
    },
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 80px;
}
@media screen and (max-width: 992px) {
    .main_text {
        font-size: 40px !important;
    }
}
@media screen and (max-width: 768px) {
    .column-768 {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start !important;
        -webkit-align-items: flex-start !important;
        -moz-box-align: start !important;
        -ms-flex-align: start !important;
        align-items: flex-start !important;
    }
    .info_block {
        margin-bottom: 30px;
    }
}
@media screen and (max-width: 470px) {
    .main_text {
        font-size: 32px !important;
    }
    .purchase_part-header_label {
        white-space: nowrap;
    }
}
@media screen and (max-width: 470px) {
    .main_text {
        line-height: 1;
    }
}
</style>
