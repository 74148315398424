<template>
    <b-card class="fs-14 my-2">
        <protocol-card-title :protocol="protocol" class="fs-16" />
        <b-row v-if="protocol.winner_proposal" class="my-1">
            <b-col md="6">
                <p>
                    Время подачи заявки: <strong>{{ getDateTimeFormatSeconds(protocol.winner_proposal.created_at) }}</strong>
                </p>
                <p v-if="protocol.winner_proposal.proposal_validity_days">
                    Срок действия предложения от даты окончания приема заявок, дней: <strong>{{ protocol.winner_proposal.proposal_validity_days }}</strong>
                </p>
            </b-col>
            <b-col md="6">
                Карточка предприятия -
                <template v-if="protocol.winner_proposal.organization_card && protocol.winner_proposal.organization_card.id">
                    <a :href="$getAttachmentDownloadLink(protocol.winner_proposal.organization_card.id)">{{ protocol.winner_proposal.organization_card.name }}</a>
                </template>
                <template v-else> Отсутствует </template>
            </b-col>
        </b-row>
        <b-row v-if="protocol.winner_proposal" class="my-1">
            <b-col md="12">
                <strong>Спецификация:</strong><br />
                <table class="table">
                    <thead>
                        <tr>
                            <th class="tcw-50">№</th>
                            <th>Наименование товара (работ, услуг)</th>
                            <th class="tcw-100">Ед.изм</th>
                            <th class="tcw-100">Количество</th>
                            <th class="tcw-100">Цена за ед.</th>
                            <th class="tcw-100">Валюта</th>
                            <th class="tcw-100">Стоимость</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in protocol.winner_proposal.deliverables" :key="item.id" v-bind="item">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.name }}</td>
                            <td class="text-right">{{ item.okei.local_symbol }}</td>
                            <td class="text-right">{{ item.quantity }}</td>
                            <td class="text-right">{{ $formatPrice(item.price_per_unit) }}</td>
                            <td>{{ purchase.currency_title }}</td>
                            <td class="text-right">{{ $formatPrice(item.quantity * item.price_per_unit) }}</td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import ProtocolCardTitle from '@/components/purchases/simple/protocol-card-title.vue';

export default {
    name: 'protocol-card',
    props: ['protocol', 'purchase'],
    components: { ProtocolCardTitle },
};
</script>

<style scoped>
a {
    color: var(--est-blue-2);
}
.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}
.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
