<template>
    <div>
        <system-preloader v-if="isLoading"></system-preloader>
        <div v-if="!isLoading">
            <text-header margin-class="mb-3">Создание запроса на возврат средств</text-header>
            <form-row-single-select v-model="form.currency" :options="currencies" :v="$v.form.currency" label="Выберите валюту для возврата денежных средств" label-field="title" placeholder="Выберите валюту для возврата денежных средств" />
            <text-row-price v-model="selectedCurrency.available" label="Доступно для возврата" />
            <form-row-edit-price v-model="form.amount" :limit="selectedCurrency.available" :v="$v.form.amount" label="Сумма возврата" />
            <form-row-single-line-document-upload v-model="form.document" :v="$v.form.document" title="Реквизиты для возврата средств" />
            <h5 class="font-weight-bold my-3 text-danger">Предупреждаем Вас о том, что с момента отправки запроса на возврат средств сумма, указанная в нем, перестанет быть доступной на лицевом счете Вашей организации.</h5>
            <div class="text-right">
                <b-button class="text-uppercase mx-2 fs-14" variant="custom-outline-secondary" @click="onCancel">Отмена</b-button>
                <b-button :disabled="$v.form.$invalid" class="text-uppercase mx-2 fs-14" variant="custom-green" @click="sendData">СФОРМИРОВАТЬ И ОТПРАВИТЬ ЗАПРОС</b-button>
            </div>
        </div>
    </div>
</template>

<script>
import TextHeader from '@/components/common/form-rows/text-header.vue';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import TextRowPrice from '@/components/common/form-rows/text-row-price.vue';

export default {
    name: 'cabinet-account-refund-request-create',
    components: { TextRowPrice, TextHeader },
    data() {
        return {
            isLoading: true,
            form: {
                amount: 0,
                currency: 'KGS',
                document: null,
            },
            availableFunds: [],
        };
    },
    validations() {
        return {
            form: {
                currency: { required },
                amount: { required, minValue: minValue(0.01), maxValue: maxValue(this.selectedCurrency.available + 10000000000000) },
                document: { required },
            },
        };
    },
    async mounted() {
        this.availableFunds = await this.$api.personal.account.getAvailableFunds();
        this.isLoading = false;
    },
    methods: {
        async sendData() {
            this.isDataSending = true;
            const response = await this.$api.personal.account.storeRefundRequest(this.form);
            this.isDataSending = false;
            if (response) {
                this.onCancel();
            }
        },
        onCancel() {
            this.$router.push({ name: 'CabinetAccountRefundRequests' });
        },
    },
    computed: {
        currencies() {
            return this.$globalDictionaries.currencies.filter((currency) => currency.refund_available);
        },
        selectedCurrency() {
            return this.availableFunds.find((item) => item.currency === this.form.currency);
        },
    },
};
</script>
