<template>
    <div v-if="purchase">
        <b-container class="main_layer py-4" fluid>
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-40 fw-600 white-color mt-3 mb-3">{{ purchase.product.title }} - № {{ purchase.registration_number }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="white-color fs-14"><b>Статус</b>: {{ purchase.status.title }}</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container v-if="purchase.status.code === 'cancelled' && purchase.cancellation_info" class="my-2" fluid>
            <b-container>
                <text-row v-if="purchase.cancellation_info.reason" v-model="purchase.cancellation_info.reason" label="Причина отмены закупки" />
                <text-row-document v-if="purchase.cancellation_info.attachment" v-model="purchase.cancellation_info.attachment" label="Документ с обоснованием отмены закупки" />
            </b-container>
        </b-container>
        <b-container class="my-2" fluid>
            <b-container>
                <text-header>Информация о заказчике</text-header>
                <text-row-link v-model="purchase.customer.name" :router-link="{ name: 'OrganizationShow', params: { id: purchase.customer.id } }" label="Наименование" />
                <text-row v-model="purchase.customer.inn" label="ИНН" />
                <text-row v-model="purchase.customer.reg_number" label="Государственный регистрационный номер" />
                <text-row v-model="purchase.customer.okpo" label="ОКПО" />
                <text-row v-model="purchase.customer.address" label="Юридический адрес" />
                <text-row v-if="purchase.additional_contact_info" v-model="purchase.additional_contact_info" label="Дополнительная контактная информация" />
            </b-container>
        </b-container>
        <b-container class="my-2" fluid>
            <b-container>
                <text-header>Условия закупки</text-header>
                <text-row v-if="purchase.name" v-model="purchase.name" label="Наименование конкурса" />
                <text-row v-model="purchase.deliverable_group_title" label="Предмет закупки" />
                <text-row-datetime v-model="purchase.purchase_start_datetime" label="Дата и время размещения закупки" />
                <text-row-datetime v-model="purchase.purchase_end_datetime" label="Дата и время окончания приема заявок и вскрытия конвертов" />
                <text-row v-model="purchase.edms.contract_format_title" label="Формат заключения контракта" />
                <text-row v-if="purchase.edms.operator_name" v-model="purchase.edms.operator_name" label="Оператор ЭДО" />
                <text-row-price v-model="purchase.starting_price" label="Сумма закупки" />
                <text-row v-model="purchase.currency_title" label="Валюта" />
                <text-row-document v-if="purchase.contract_draft_attachment" v-model="purchase.contract_draft_attachment" label="Проект контракта" />
                <text-row-documents v-if="purchase.notice_supplementary_attachments && purchase.notice_supplementary_attachments.length > 0" v-model="purchase.notice_supplementary_attachments" label="Дополнительные документы" />
                <text-row v-if="purchase.with_lots" label="Конкурс с лотированием" value="Да" />
            </b-container>
        </b-container>
        <b-container class="my-2" fluid>
            <b-container>
                <text-header>Требования к поставщикам</text-header>
                <ul>
                    <li v-if="purchase.proposal_validity_days" class="fs-14 grey-color my-auto col-form-label font-weight-bold">Минимальное количество дней действия предложения участника - {{ purchase.proposal_validity_days }}</li>
                    <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">
                        У участника закупочной сессии должны отсутствовать задолженности по налогам и сборам -
                        {{ purchase.supplier_requirements.has_no_arrears ? 'Да' : 'Нет' }}
                    </li>
                    <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">
                        Участник закупки не должен присутствовать в реестре недобросовестных поставщиков -
                        {{ purchase.supplier_requirements.is_reliable_supplier ? 'Да' : 'Нет' }}
                    </li>
                    <template v-if="purchase.supplier_requirements.has_additional_requirements && purchase.supplier_requirements.additional_requirements && purchase.supplier_requirements.additional_requirements.length > 0">
                        <li class="fs-14 grey-color my-auto col-form-label">
                            <strong>Участник закупки должен соответствовать следующим требованиям</strong> -
                            {{ purchase.supplier_requirements.additional_requirements_description }}
                        </li>
                        <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">Участник закупки должен подтвердить соответствие указанным требованиям, прикрепив следующие документы при подаче заявки:</li>
                        <li style="list-style-type: none">
                            <ul>
                                <li v-for="requiredDocument in purchase.supplier_requirements.additional_requirements" :key="requiredDocument.id" class="fs-14 grey-color my-auto col-form-label">
                                    {{ requiredDocument.title }}
                                </li>
                            </ul>
                        </li>
                    </template>

                    <li class="fs-14 grey-color my-auto col-form-label font-weight-bold">
                        Участник закупки должен обладать опытом аналогичных поставок на сумму не менее -
                        {{ purchase.supplier_requirements.similar_experience_amounts ? $formatPrice(purchase.supplier_requirements.similar_experience_amounts) : 'Не требуется' }}
                    </li>
                </ul>
            </b-container>
        </b-container>
        <b-container class="my-2" fluid>
            <b-container>
                <text-header>Спецификация</text-header>
                <b-row class="my-2" no-gutters>
                    <b-col class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="tcw-50">№</th>
                                    <th>Наименование товара (работ, услуг)</th>
                                    <th>Характеристики</th>
                                    <th class="tcw-100">Ед.изм</th>
                                    <th class="tcw-100 text-right">Количество</th>
                                    <th class="tcw-100 text-right">Цена за ед.</th>
                                    <th class="tcw-100">Валюта</th>
                                    <th class="tcw-100 text-right">Стоимость</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in purchase.deliverables" :key="item.id" v-bind="item">
                                    <td>{{ index + 1 }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.characteristics }}</td>
                                    <td>{{ item.okei.local_symbol }}</td>
                                    <td class="text-right text-nowrap">{{ item.quantity }}</td>
                                    <td class="text-right text-nowrap">{{ $formatPrice(item.price_per_unit) }}</td>
                                    <td>{{ purchase.currency_title }}</td>
                                    <td class="text-right text-nowrap">{{ $formatPrice(item.quantity * item.price_per_unit) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <public-proposals-block :purchase="purchase" />
        <private-proposals-block :purchase="purchase" />
        <public-envelopes-opening-protocol :protocol="purchase.envelopes_opening_protocol" />
        <public-final-protocol :protocol="purchase.final_protocol" />
        <private-envelopes-opening-block :purchase="purchase" @confirm="refresh" />
        <private-winner-selection-block :purchase="purchase" @confirm="refresh" @refresh="refresh" />
        <b-container id="action_buttons_row" class="my-2" fluid>
            <b-container class="mb-5">
                <b-row>
                    <b-col>
                        <competitive-purchase-open-envelopes-button :purchase="purchase" @store="refresh" />
                        <competitive-purchase-start-winner-selection-button :purchase="purchase" @store="refresh" />
                        <template v-if="$store.getters.isLoggedIn && (purchase.actions.supplier.proposal.create || purchase.actions.supplier.proposal.edit)">
                            <template v-if="purchase.actions.supplier.has_money.status">
                                <b-button :to="{ name: 'CompetitivePurchaseNewProposal', params: { id: purchase.id } }" class="text-uppercase mx-2" target="_blank" variant="custom-blue">
                                    <template v-if="purchase.actions.supplier.proposal.create"> Подать заявку</template>
                                    <template v-if="purchase.actions.supplier.proposal.edit"> Изменить заявку</template>
                                </b-button>
                            </template>
                            <template v-else>
                                <b-button :id="'popover-' + purchase.product.id + '-' + purchase.id" class="text-uppercase mx-2" target="_blank" variant="custom-blue">
                                    <template v-if="purchase.actions.supplier.proposal.create"> Подать заявку</template>
                                    <template v-if="purchase.actions.supplier.proposal.edit"> Изменить заявку</template>
                                </b-button>
                                <b-popover :target="'popover-' + purchase.product.id + '-' + purchase.id" triggers="hover">
                                    На лицевом счете недостаточно средств. Для подачи предложения не хватает <span class="text-danger text-nowrap">{{ $formatPrice(purchase.actions.supplier.has_money.not_enough) }}</span> сом.<br />
                                    <router-link :to="{ name: 'CabinetAccountReplenishment' }">Пополнить лицевой счет</router-link>
                                    .
                                </b-popover>
                            </template>
                        </template>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import TextRowLink from '@/components/common/form-rows/text-row-link';
import TextRowDocument from '@/components/common/form-rows/text-row-document.vue';
import CompetitivePurchaseOpenEnvelopesButton from '@/components/common/competitive-purchase-open-envelopes-button.vue';
// import PublicProposalsBlock from '@/components/purchases/competitive/public-proposals-block.vue';
import PublicEnvelopesOpeningProtocol from '@/components/purchases/competitive/public-envelopes-opening-protocol.vue';
import PrivateEnvelopesOpeningBlock from '@/components/purchases/competitive/private-envelopes-opening-block.vue';
import PrivateWinnerSelectionBlock from '@/components/purchases/competitive/private-winner-selection-block.vue';
import CompetitivePurchaseStartWinnerSelectionButton from '@/components/common/competitive-purchase-start-winner-selection-button.vue';
import PublicFinalProtocol from '@/components/purchases/competitive/public-final-protocol.vue';
import PublicProposalsBlock from '@/components/purchases/competitive/public-proposals-block.vue';
import PrivateProposalsBlock from '@/components/purchases/competitive/private-proposals-block.vue';

export default {
    name: 'competitive-purchase-show',
    components: {
        PrivateProposalsBlock,
        PublicProposalsBlock,
        PublicFinalProtocol,
        CompetitivePurchaseStartWinnerSelectionButton,
        PrivateWinnerSelectionBlock,
        PrivateEnvelopesOpeningBlock,
        PublicEnvelopesOpeningProtocol,
        // PublicProposalsBlock,
        CompetitivePurchaseOpenEnvelopesButton,
        TextRowDocument,
        TextRowLink,
    },
    data() {
        return {
            id: this.$route.params.id,
            purchase: null,
            visible: [],
        };
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        async fillData() {
            this.purchase = await this.$api.purchases.competitive.show(this.id);
            this.$scrollToHash();
        },
        refresh() {
            this.fillData();
        },
    },
    computed: {
        contractDraftAttachment() {
            return [this.purchase.contract_draft_attachment];
        },
    },
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100px;
}

.link {
    color: var(--est-blue-2) !important;
}

.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}
</style>
