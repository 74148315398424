var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { "background-color": "var(--greyBg-color)" } },
    [
      _vm.$store.state.user && _vm.$store.state.organization
        ? _c(
            "b-container",
            { staticClass: "main_layer py-1", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { staticClass: "my-1" },
                    [
                      _c("b-col", [
                        _c("h2", { staticClass: "fw-600 white-color" }, [
                          _vm._v(_vm._s(_vm.$store.state.organization.name)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "my-1" },
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "fs-14 greyBg-color" }, [
                          _vm._v(" Пользователь: "),
                          _c("strong", [
                            _vm._v(_vm._s(_vm.$store.state.user.name)),
                          ]),
                        ]),
                        _vm.$store.state.user.role &&
                        _vm.$store.state.user.role.title
                          ? _c("p", { staticClass: "fs-14 greyBg-color" }, [
                              _vm._v(" Роль: "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$store.state.user.role.title)
                                ),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "my-1" },
                    [
                      _c(
                        "b-col",
                        [
                          _vm.orgRoleIsPermitted(
                            _vm.$orgRoles.CUSTOMER_COMPETITIVE
                          ) &&
                          _vm.userRolesPermitted([
                            _vm.$userRoles.DIRECTOR,
                            _vm.$userRoles.ADMINISTRATOR,
                            _vm.$userRoles.BUYER,
                          ])
                            ? _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-custom-white mx-1 my-1 text-uppercase",
                                  attrs: {
                                    to: { name: "CompetitivePurchaseCreate" },
                                    tag: "button",
                                  },
                                },
                                [_vm._v(" Создать конкурс ")]
                              )
                            : _vm._e(),
                          _vm.orgRoleIsPermitted(
                            _vm.$orgRoles.CUSTOMER_SIMPLE
                          ) &&
                          _vm.userRolesPermitted([
                            _vm.$userRoles.DIRECTOR,
                            _vm.$userRoles.ADMINISTRATOR,
                            _vm.$userRoles.BUYER,
                          ])
                            ? _c(
                                "router-link",
                                {
                                  staticClass:
                                    "btn btn-custom-white mx-1 my-1 text-uppercase",
                                  attrs: {
                                    to: { name: "SimplePurchaseCreate" },
                                    tag: "button",
                                  },
                                },
                                [_vm._v(" Создать простую закупку ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "py-3" },
                [
                  _c(
                    "b-col",
                    { attrs: { md: "3", sm: "12" } },
                    [
                      _c(
                        "b-nav",
                        {
                          staticClass: "w-100 provider_menu cabinet_menu mb-3",
                          attrs: { vertical: "" },
                        },
                        [
                          _vm.orgRoleIsAnyCustomer
                            ? _c(
                                "b-nav-item",
                                {
                                  attrs: {
                                    active: _vm.isActive("PersonalPurchases"),
                                    to: { name: "PersonalPurchases" },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Закупки")]
                              )
                            : _vm._e(),
                          _c(
                            "b-nav-item",
                            {
                              attrs: {
                                active: _vm.isActive("PersonalProposals"),
                                to: { name: "PersonalProposals" },
                                exact: "",
                              },
                            },
                            [_vm._v("Заявки")]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "nav-item",
                              on: {
                                click: function ($event) {
                                  _vm.organizationSubmenu = true
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  class: {
                                    "router-link-active":
                                      _vm.checkOrganisationLink,
                                  },
                                  attrs: {
                                    href: "javascript:void(0)",
                                    target: "_self",
                                  },
                                },
                                [
                                  _vm._v(" Данные организации "),
                                  _c(
                                    "b-collapse",
                                    {
                                      staticClass: "mt-2 cabinet_submenu",
                                      attrs: { id: "account" },
                                      model: {
                                        value: _vm.organizationSubmenu,
                                        callback: function ($$v) {
                                          _vm.organizationSubmenu = $$v
                                        },
                                        expression: "organizationSubmenu",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            active: _vm.isActive(
                                              "OrganizationInformation"
                                            ),
                                            to: {
                                              name: "OrganizationInformation",
                                            },
                                          },
                                        },
                                        [_vm._v("Информация об организации")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            active: _vm.isActive(
                                              "OrganizationDocuments"
                                            ),
                                            to: {
                                              name: "OrganizationDocuments",
                                            },
                                          },
                                        },
                                        [_vm._v("Документы")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            active: _vm.isActive("UsersList"),
                                            to: { name: "UsersList" },
                                          },
                                        },
                                        [_vm._v("Пользователи организации")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            {
                              staticClass: "nav-item",
                              on: {
                                click: function ($event) {
                                  _vm.accountSubmenu = true
                                },
                              },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "nav-link",
                                  class: {
                                    "router-link-active": _vm.checkAccountLink,
                                  },
                                  attrs: {
                                    href: "javascript:void(0)",
                                    target: "_self",
                                  },
                                },
                                [
                                  _vm._v(" Лицевой счет "),
                                  _c(
                                    "b-collapse",
                                    {
                                      staticClass: "mt-2 cabinet_submenu",
                                      attrs: { id: "account" },
                                      model: {
                                        value: _vm.accountSubmenu,
                                        callback: function ($$v) {
                                          _vm.accountSubmenu = $$v
                                        },
                                        expression: "accountSubmenu",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            active:
                                              _vm.isActive(
                                                "CabinetAccountInfo"
                                              ),
                                            to: { name: "CabinetAccountInfo" },
                                          },
                                        },
                                        [_vm._v("Состояние ЛС")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          attrs: {
                                            active: _vm.isActive(
                                              "CabinetAccountRefundRequests"
                                            ),
                                            to: {
                                              name: "CabinetAccountRefundRequests",
                                            },
                                          },
                                        },
                                        [_vm._v("Возврат средств")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "b-nav-item",
                            {
                              attrs: {
                                active: _vm.isActive("UserProfile"),
                                to: { name: "UserProfile" },
                              },
                            },
                            [_vm._v("Данные учетной записи")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "9", sm: "12" } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("verification-email-modal", {
        attrs: {
          email: _vm.userEmail,
          visible: _vm.visibleVerificationEmailModal,
        },
        on: { hidden: _vm.hiddenVerificationModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }