<template>
    <b-container v-if="protocol" class="my-2" fluid>
        <b-container>
            <text-header>Протокол вскрытия конвертов</text-header>
            <text-row label="Регистрационный номер" :value="protocol.reg_number" />
            <text-row label="Состав комиссии" value=" " />
            <b-row class="my-2" no-gutters>
                <b-col class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>ФИО</th>
                                <th>Должность</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in protocol.commission" :key="index" v-bind="item">
                                <td>{{ item.name }}</td>
                                <td>{{ item.position }}</td>
                            </tr>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
            <text-row-document label="Подписанный членами комиссии протокол вскрытия конвертов" v-model="protocol.attachment" />
        </b-container>
    </b-container>
</template>

<script>
import TextRowDocument from '@/components/common/form-rows/text-row-document.vue';

export default {
    name: 'public-envelopes-opening-protocol',
    components: { TextRowDocument },
    props: {
        protocol: {},
    },
};
</script>
