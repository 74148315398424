<template>
    <div>
        <b-row>
            <b-col class="float-right">
                <b-button class="ml-auto" style="color: var(--est-blue-2) !important" variant="custom-unstyle-grey" @click="addEmptyRow"> Добавить сотрудника </b-button>
            </b-col>
        </b-row>
        <b-row class="py-3" no-gutters>
            <b-col class="fs-14 grey-color">
                <div class="table-responsive" :class="v.$invalid ? 'is-invalid' : ''">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>ФИО</th>
                                <th>Должность</th>
                                <th width="1"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in commission" :key="index">
                                <td class="align-top">
                                    <b-form-input v-model="commission[index].name" class="fs-14 my-auto" />
                                </td>
                                <td class="align-top">
                                    <b-form-input v-model="commission[index].position" class="fs-14 my-auto" />
                                </td>
                                <td nowrap class="align-top">
                                    <b-button size="sm" style="background: transparent" variant="light" @click="removeSpec(index)">
                                        <img alt="trash_icon" src="/images/trash_icon.svg" />
                                    </b-button>
                                </td>
                            </tr>
                            <tr v-if="commission.length === 0">
                                <td align="center" colspan="3">Состав комиссии не заполнен</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <p v-if="errors" class="text-danger">{{ errorMessage }}</p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'envelopes-opening-commission-table',
    props: {
        value: {
            type: Array,
            required: true,
        },
        grey: {
            type: Boolean,
            default: false,
        },
        v: {
            type: Object,
            required: true,
        },
        errors: {
            type: Boolean,
        },
        errorMessage: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            spec: {
                name: '',
                position: '',
            },
            tmpSpecs: [],
        };
    },
    methods: {
        async addEmptyRow() {
            const spec = {
                name: '',
                position: '',
            };
            this.addSpec(spec);
        },
        addSpec(spec) {
            this.tmpSpecs = this.commission;
            let newSpec = JSON.parse(JSON.stringify(spec)); // clone
            this.tmpSpecs.push(newSpec);
            this.commission = this.tmpSpecs;
        },
        removeSpec(index) {
            this.tmpSpecs = this.commission;
            this.tmpSpecs.splice(index, 1);
            this.commission = this.tmpSpecs;
        },
    },
    computed: {
        commission: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style scoped>
.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}
</style>
