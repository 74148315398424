var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "float-right" },
            [
              _c(
                "b-button",
                {
                  staticClass: "ml-auto",
                  staticStyle: { color: "var(--est-blue-2) !important" },
                  attrs: { variant: "custom-unstyle-grey" },
                  on: { click: _vm.addEmptyRow },
                },
                [_vm._v(" Добавить сотрудника ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "py-3", attrs: { "no-gutters": "" } },
        [
          _c("b-col", { staticClass: "fs-14 grey-color" }, [
            _c(
              "div",
              {
                staticClass: "table-responsive",
                class: _vm.v.$invalid ? "is-invalid" : "",
              },
              [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("ФИО")]),
                      _c("th", [_vm._v("Должность")]),
                      _c("th", { attrs: { width: "1" } }),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    [
                      _vm._l(_vm.commission, function (item, index) {
                        return _c("tr", { key: index }, [
                          _c(
                            "td",
                            { staticClass: "align-top" },
                            [
                              _c("b-form-input", {
                                staticClass: "fs-14 my-auto",
                                model: {
                                  value: _vm.commission[index].name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.commission[index], "name", $$v)
                                  },
                                  expression: "commission[index].name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "align-top" },
                            [
                              _c("b-form-input", {
                                staticClass: "fs-14 my-auto",
                                model: {
                                  value: _vm.commission[index].position,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.commission[index],
                                      "position",
                                      $$v
                                    )
                                  },
                                  expression: "commission[index].position",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "td",
                            { staticClass: "align-top", attrs: { nowrap: "" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticStyle: { background: "transparent" },
                                  attrs: { size: "sm", variant: "light" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeSpec(index)
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      alt: "trash_icon",
                                      src: "/images/trash_icon.svg",
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ])
                      }),
                      _vm.commission.length === 0
                        ? _c("tr", [
                            _c(
                              "td",
                              { attrs: { align: "center", colspan: "3" } },
                              [_vm._v("Состав комиссии не заполнен")]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            ),
            _vm.errors
              ? _c("p", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errorMessage)),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }