var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-4", attrs: { fluid: "" } },
        [
          _c(
            "b-form",
            {
              ref: "filter",
              staticClass: "w-100",
              attrs: { autocomplete: "off" },
              on: {
                focusin: function ($event) {
                  _vm.alert = false
                },
              },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "fs-52 fw-600 white-color mt-2 mb-4 less_font-size_mobile",
                                  },
                                  [_vm._v("Реестр объявлений о закупке")]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "filter" },
                            [
                              _c("filter-multiple-select", {
                                attrs: {
                                  label: "Предмет закупки",
                                  options:
                                    _vm.$globalDictionaries.deliverableGroups,
                                  placeholder: "Выберите предметы закупки",
                                  md: "6",
                                  lg: "6",
                                },
                                model: {
                                  value: _vm.filter.deliverableGroup,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.filter,
                                      "deliverableGroup",
                                      $$v
                                    )
                                  },
                                  expression: "filter.deliverableGroup",
                                },
                              }),
                              _c("filter-input-text", {
                                attrs: {
                                  label: "Заказчик",
                                  placeholder:
                                    "Укажите наименование/ИНН заказчика",
                                },
                                model: {
                                  value: _vm.filter.customer,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "customer", $$v)
                                  },
                                  expression: "filter.customer",
                                },
                              }),
                              _c("filter-input-text", {
                                attrs: {
                                  label: "Рег.номер закупки",
                                  placeholder: "Укажите номер закупки",
                                },
                                model: {
                                  value: _vm.filter.regNumber,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filter, "regNumber", $$v)
                                  },
                                  expression: "filter.regNumber",
                                },
                              }),
                              _vm.showFilters
                                ? [
                                    _c("filter-input-currency", {
                                      attrs: {
                                        label: "Стартовая цена от...",
                                        placeholder: "Стартовая цена от...",
                                      },
                                      model: {
                                        value: _vm.filter.priceStartFrom,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "priceStartFrom",
                                            $$v
                                          )
                                        },
                                        expression: "filter.priceStartFrom",
                                      },
                                    }),
                                    _c("filter-input-currency", {
                                      attrs: {
                                        label: "Стартовая цена до...",
                                        placeholder: "Стартовая цена до...",
                                      },
                                      model: {
                                        value: _vm.filter.priceStartTo,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filter,
                                            "priceStartTo",
                                            $$v
                                          )
                                        },
                                        expression: "filter.priceStartTo",
                                      },
                                    }),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: { cols: "12", md: "6", lg: "3" },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("v-date-picker", {
                                              attrs: {
                                                mode: "date",
                                                timezone: "Asia/Dhaka",
                                                "max-date":
                                                  _vm.filter.orderDateFinish,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      inputValue,
                                                      togglePopover,
                                                    }) {
                                                      return [
                                                        _c("b-form-input", {
                                                          staticClass: "fs-14",
                                                          attrs: {
                                                            id: "dtp-order-date-start",
                                                            value: inputValue,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return togglePopover()
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                2330014898
                                              ),
                                              model: {
                                                value:
                                                  _vm.filter.orderDateStart,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filter,
                                                    "orderDateStart",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filter.orderDateStart",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fs-10 halfWhite-color",
                                              },
                                              [_vm._v("Дата проведения с...")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        attrs: { cols: "12", md: "6", lg: "3" },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          [
                                            _c("v-date-picker", {
                                              attrs: {
                                                mode: "date",
                                                timezone: "Asia/Dhaka",
                                                "min-date":
                                                  _vm.filter.orderDateStart,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({
                                                      inputValue,
                                                      togglePopover,
                                                    }) {
                                                      return [
                                                        _c("b-form-input", {
                                                          staticClass: "fs-14",
                                                          attrs: {
                                                            id: "dtp-order-date-finish",
                                                            value: inputValue,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return togglePopover()
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                4222078657
                                              ),
                                              model: {
                                                value:
                                                  _vm.filter.orderDateFinish,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.filter,
                                                    "orderDateFinish",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filter.orderDateFinish",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fs-10 halfWhite-color",
                                              },
                                              [_vm._v("Дата проведения по...")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-4" },
                            [
                              _c(
                                "b-col",
                                { staticClass: "flex_col-mobile" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: { variant: "custom-white" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getPurchases(false)
                                        },
                                      },
                                    },
                                    [_vm._v("Найти ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.showOtherFilter },
                                    },
                                    [_vm._v("Все фильтры ")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-light",
                                      },
                                      on: { click: _vm.clearFilter },
                                    },
                                    [_vm._v("Сбросить")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-container",
        { staticClass: "greyBg", attrs: { fluid: "", id: "content-block" } },
        [
          _vm.purchases.length
            ? _c("b-container", [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("b-pagination", {
                      staticStyle: { "padding-bottom": "0" },
                      attrs: {
                        align: "center",
                        "total-rows": _vm.meta.total,
                        "per-page": _vm.meta.per_page,
                      },
                      model: {
                        value: _vm.meta.current_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.meta, "current_page", $$v)
                        },
                        expression: "meta.current_page",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "b-container",
            _vm._l(_vm.purchases, function (purchase) {
              return _c("notice", {
                key: purchase.product.id + "_" + purchase.id,
                attrs: { purchase: purchase },
              })
            }),
            1
          ),
          !_vm.loading && !_vm.purchases.length
            ? _c("b-container", [_c("empty-request")], 1)
            : _vm._e(),
          _vm.purchases.length
            ? _c("b-container", [
                _c(
                  "div",
                  { staticStyle: { width: "100%" } },
                  [
                    _c("b-pagination", {
                      attrs: {
                        align: "center",
                        "total-rows": _vm.meta.total,
                        "per-page": _vm.meta.per_page,
                      },
                      on: {
                        input: function ($event) {
                          return _vm.getPurchases()
                        },
                      },
                      model: {
                        value: _vm.meta.current_page,
                        callback: function ($$v) {
                          _vm.$set(_vm.meta, "current_page", $$v)
                        },
                        expression: "meta.current_page",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm.loading ? _c("system-preloader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }