var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.purchase
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          { staticClass: "fs-40 fw-600 white-color mt-3 mb-3" },
                          [
                            _vm._v(
                              _vm._s(_vm.purchase.product.title) +
                                " - № " +
                                _vm._s(_vm.purchase.registration_number)
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "white-color fs-14" }, [
                          _c("b", [_vm._v("Статус")]),
                          _vm._v(": " + _vm._s(_vm.purchase.status.title)),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.purchase.status.code === "cancelled" &&
          _vm.purchase.cancellation_info
            ? _c(
                "b-container",
                { staticClass: "my-2", attrs: { fluid: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _vm.purchase.cancellation_info.reason
                        ? _c("text-row", {
                            attrs: { label: "Причина отмены закупки" },
                            model: {
                              value: _vm.purchase.cancellation_info.reason,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.cancellation_info,
                                  "reason",
                                  $$v
                                )
                              },
                              expression: "purchase.cancellation_info.reason",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.cancellation_info.attachment
                        ? _c("text-row-document", {
                            attrs: {
                              label: "Документ с обоснованием отмены закупки",
                            },
                            model: {
                              value: _vm.purchase.cancellation_info.attachment,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase.cancellation_info,
                                  "attachment",
                                  $$v
                                )
                              },
                              expression:
                                "purchase.cancellation_info.attachment",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Информация о заказчике")]),
                  _c("text-row-link", {
                    attrs: {
                      "router-link": {
                        name: "OrganizationShow",
                        params: { id: _vm.purchase.customer.id },
                      },
                      label: "Наименование",
                    },
                    model: {
                      value: _vm.purchase.customer.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "name", $$v)
                      },
                      expression: "purchase.customer.name",
                    },
                  }),
                  _c("text-row", {
                    attrs: { label: "ИНН" },
                    model: {
                      value: _vm.purchase.customer.inn,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "inn", $$v)
                      },
                      expression: "purchase.customer.inn",
                    },
                  }),
                  _c("text-row", {
                    attrs: { label: "Государственный регистрационный номер" },
                    model: {
                      value: _vm.purchase.customer.reg_number,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "reg_number", $$v)
                      },
                      expression: "purchase.customer.reg_number",
                    },
                  }),
                  _c("text-row", {
                    attrs: { label: "ОКПО" },
                    model: {
                      value: _vm.purchase.customer.okpo,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "okpo", $$v)
                      },
                      expression: "purchase.customer.okpo",
                    },
                  }),
                  _c("text-row", {
                    attrs: { label: "Юридический адрес" },
                    model: {
                      value: _vm.purchase.customer.address,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase.customer, "address", $$v)
                      },
                      expression: "purchase.customer.address",
                    },
                  }),
                  _vm.purchase.additional_contact_info
                    ? _c("text-row", {
                        attrs: {
                          label: "Дополнительная контактная информация",
                        },
                        model: {
                          value: _vm.purchase.additional_contact_info,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "additional_contact_info",
                              $$v
                            )
                          },
                          expression: "purchase.additional_contact_info",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Условия закупки")]),
                  _vm.purchase.name
                    ? _c("text-row", {
                        attrs: { label: "Наименование конкурса" },
                        model: {
                          value: _vm.purchase.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "name", $$v)
                          },
                          expression: "purchase.name",
                        },
                      })
                    : _vm._e(),
                  _c("text-row", {
                    attrs: { label: "Предмет закупки" },
                    model: {
                      value: _vm.purchase.deliverable_group_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "deliverable_group_title", $$v)
                      },
                      expression: "purchase.deliverable_group_title",
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: { label: "Дата и время размещения закупки" },
                    model: {
                      value: _vm.purchase.purchase_start_datetime,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "purchase_start_datetime", $$v)
                      },
                      expression: "purchase.purchase_start_datetime",
                    },
                  }),
                  _c("text-row-datetime", {
                    attrs: {
                      label:
                        "Дата и время окончания приема заявок и вскрытия конвертов",
                    },
                    model: {
                      value: _vm.purchase.purchase_end_datetime,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "purchase_end_datetime", $$v)
                      },
                      expression: "purchase.purchase_end_datetime",
                    },
                  }),
                  _c("text-row", {
                    attrs: { label: "Формат заключения контракта" },
                    model: {
                      value: _vm.purchase.edms.contract_format_title,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.purchase.edms,
                          "contract_format_title",
                          $$v
                        )
                      },
                      expression: "purchase.edms.contract_format_title",
                    },
                  }),
                  _vm.purchase.edms.operator_name
                    ? _c("text-row", {
                        attrs: { label: "Оператор ЭДО" },
                        model: {
                          value: _vm.purchase.edms.operator_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase.edms, "operator_name", $$v)
                          },
                          expression: "purchase.edms.operator_name",
                        },
                      })
                    : _vm._e(),
                  _c("text-row-price", {
                    attrs: { label: "Сумма закупки" },
                    model: {
                      value: _vm.purchase.starting_price,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "starting_price", $$v)
                      },
                      expression: "purchase.starting_price",
                    },
                  }),
                  _c("text-row", {
                    attrs: { label: "Валюта" },
                    model: {
                      value: _vm.purchase.currency_title,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "currency_title", $$v)
                      },
                      expression: "purchase.currency_title",
                    },
                  }),
                  _vm.purchase.contract_draft_attachment
                    ? _c("text-row-document", {
                        attrs: { label: "Проект контракта" },
                        model: {
                          value: _vm.purchase.contract_draft_attachment,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "contract_draft_attachment",
                              $$v
                            )
                          },
                          expression: "purchase.contract_draft_attachment",
                        },
                      })
                    : _vm._e(),
                  _vm.purchase.notice_supplementary_attachments &&
                  _vm.purchase.notice_supplementary_attachments.length > 0
                    ? _c("text-row-documents", {
                        attrs: { label: "Дополнительные документы" },
                        model: {
                          value: _vm.purchase.notice_supplementary_attachments,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "notice_supplementary_attachments",
                              $$v
                            )
                          },
                          expression:
                            "purchase.notice_supplementary_attachments",
                        },
                      })
                    : _vm._e(),
                  _vm.purchase.with_lots
                    ? _c("text-row", {
                        attrs: { label: "Конкурс с лотированием", value: "Да" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Требования к поставщикам")]),
                  _c(
                    "ul",
                    [
                      _vm.purchase.proposal_validity_days
                        ? _c(
                            "li",
                            {
                              staticClass:
                                "fs-14 grey-color my-auto col-form-label font-weight-bold",
                            },
                            [
                              _vm._v(
                                "Минимальное количество дней действия предложения участника - " +
                                  _vm._s(_vm.purchase.proposal_validity_days)
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "li",
                        {
                          staticClass:
                            "fs-14 grey-color my-auto col-form-label font-weight-bold",
                        },
                        [
                          _vm._v(
                            " У участника закупочной сессии должны отсутствовать задолженности по налогам и сборам - " +
                              _vm._s(
                                _vm.purchase.supplier_requirements
                                  .has_no_arrears
                                  ? "Да"
                                  : "Нет"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass:
                            "fs-14 grey-color my-auto col-form-label font-weight-bold",
                        },
                        [
                          _vm._v(
                            " Участник закупки не должен присутствовать в реестре недобросовестных поставщиков - " +
                              _vm._s(
                                _vm.purchase.supplier_requirements
                                  .is_reliable_supplier
                                  ? "Да"
                                  : "Нет"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm.purchase.supplier_requirements
                        .has_additional_requirements &&
                      _vm.purchase.supplier_requirements
                        .additional_requirements &&
                      _vm.purchase.supplier_requirements.additional_requirements
                        .length > 0
                        ? [
                            _c(
                              "li",
                              {
                                staticClass:
                                  "fs-14 grey-color my-auto col-form-label",
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    "Участник закупки должен соответствовать следующим требованиям"
                                  ),
                                ]),
                                _vm._v(
                                  " - " +
                                    _vm._s(
                                      _vm.purchase.supplier_requirements
                                        .additional_requirements_description
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              {
                                staticClass:
                                  "fs-14 grey-color my-auto col-form-label font-weight-bold",
                              },
                              [
                                _vm._v(
                                  "Участник закупки должен подтвердить соответствие указанным требованиям, прикрепив следующие документы при подаче заявки:"
                                ),
                              ]
                            ),
                            _c(
                              "li",
                              { staticStyle: { "list-style-type": "none" } },
                              [
                                _c(
                                  "ul",
                                  _vm._l(
                                    _vm.purchase.supplier_requirements
                                      .additional_requirements,
                                    function (requiredDocument) {
                                      return _c(
                                        "li",
                                        {
                                          key: requiredDocument.id,
                                          staticClass:
                                            "fs-14 grey-color my-auto col-form-label",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(requiredDocument.title) +
                                              " "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]
                        : _vm._e(),
                      _c(
                        "li",
                        {
                          staticClass:
                            "fs-14 grey-color my-auto col-form-label font-weight-bold",
                        },
                        [
                          _vm._v(
                            " Участник закупки должен обладать опытом аналогичных поставок на сумму не менее - " +
                              _vm._s(
                                _vm.purchase.supplier_requirements
                                  .similar_experience_amounts
                                  ? _vm.$formatPrice(
                                      _vm.purchase.supplier_requirements
                                        .similar_experience_amounts
                                    )
                                  : "Не требуется"
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Спецификация")]),
                  _c(
                    "b-row",
                    { staticClass: "my-2", attrs: { "no-gutters": "" } },
                    [
                      _c("b-col", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { staticClass: "tcw-50" }, [
                                _vm._v("№"),
                              ]),
                              _c("th", [
                                _vm._v("Наименование товара (работ, услуг)"),
                              ]),
                              _c("th", [_vm._v("Характеристики")]),
                              _c("th", { staticClass: "tcw-100" }, [
                                _vm._v("Ед.изм"),
                              ]),
                              _c("th", { staticClass: "tcw-100 text-right" }, [
                                _vm._v("Количество"),
                              ]),
                              _c("th", { staticClass: "tcw-100 text-right" }, [
                                _vm._v("Цена за ед."),
                              ]),
                              _c("th", { staticClass: "tcw-100" }, [
                                _vm._v("Валюта"),
                              ]),
                              _c("th", { staticClass: "tcw-100 text-right" }, [
                                _vm._v("Стоимость"),
                              ]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.purchase.deliverables,
                              function (item, index) {
                                return _c(
                                  "tr",
                                  _vm._b({ key: item.id }, "tr", item, false),
                                  [
                                    _c("td", [_vm._v(_vm._s(index + 1))]),
                                    _c("td", [_vm._v(_vm._s(item.name))]),
                                    _c("td", [
                                      _vm._v(_vm._s(item.characteristics)),
                                    ]),
                                    _c("td", [
                                      _vm._v(_vm._s(item.okei.local_symbol)),
                                    ]),
                                    _c(
                                      "td",
                                      { staticClass: "text-right text-nowrap" },
                                      [_vm._v(_vm._s(item.quantity))]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "text-right text-nowrap" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$formatPrice(
                                              item.price_per_unit
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                    _c("td", [
                                      _vm._v(
                                        _vm._s(_vm.purchase.currency_title)
                                      ),
                                    ]),
                                    _c(
                                      "td",
                                      { staticClass: "text-right text-nowrap" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$formatPrice(
                                              item.quantity *
                                                item.price_per_unit
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("public-proposals-block", { attrs: { purchase: _vm.purchase } }),
          _c("private-proposals-block", { attrs: { purchase: _vm.purchase } }),
          _c("public-envelopes-opening-protocol", {
            attrs: { protocol: _vm.purchase.envelopes_opening_protocol },
          }),
          _c("public-final-protocol", {
            attrs: { protocol: _vm.purchase.final_protocol },
          }),
          _c("private-envelopes-opening-block", {
            attrs: { purchase: _vm.purchase },
            on: { confirm: _vm.refresh },
          }),
          _c("private-winner-selection-block", {
            attrs: { purchase: _vm.purchase },
            on: { confirm: _vm.refresh, refresh: _vm.refresh },
          }),
          _c(
            "b-container",
            {
              staticClass: "my-2",
              attrs: { id: "action_buttons_row", fluid: "" },
            },
            [
              _c(
                "b-container",
                { staticClass: "mb-5" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c("competitive-purchase-open-envelopes-button", {
                            attrs: { purchase: _vm.purchase },
                            on: { store: _vm.refresh },
                          }),
                          _c(
                            "competitive-purchase-start-winner-selection-button",
                            {
                              attrs: { purchase: _vm.purchase },
                              on: { store: _vm.refresh },
                            }
                          ),
                          _vm.$store.getters.isLoggedIn &&
                          (_vm.purchase.actions.supplier.proposal.create ||
                            _vm.purchase.actions.supplier.proposal.edit)
                            ? [
                                _vm.purchase.actions.supplier.has_money.status
                                  ? [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase mx-2",
                                          attrs: {
                                            to: {
                                              name: "CompetitivePurchaseNewProposal",
                                              params: { id: _vm.purchase.id },
                                            },
                                            target: "_blank",
                                            variant: "custom-blue",
                                          },
                                        },
                                        [
                                          _vm.purchase.actions.supplier.proposal
                                            .create
                                            ? [_vm._v(" Подать заявку")]
                                            : _vm._e(),
                                          _vm.purchase.actions.supplier.proposal
                                            .edit
                                            ? [_vm._v(" Изменить заявку")]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  : [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase mx-2",
                                          attrs: {
                                            id:
                                              "popover-" +
                                              _vm.purchase.product.id +
                                              "-" +
                                              _vm.purchase.id,
                                            target: "_blank",
                                            variant: "custom-blue",
                                          },
                                        },
                                        [
                                          _vm.purchase.actions.supplier.proposal
                                            .create
                                            ? [_vm._v(" Подать заявку")]
                                            : _vm._e(),
                                          _vm.purchase.actions.supplier.proposal
                                            .edit
                                            ? [_vm._v(" Изменить заявку")]
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "b-popover",
                                        {
                                          attrs: {
                                            target:
                                              "popover-" +
                                              _vm.purchase.product.id +
                                              "-" +
                                              _vm.purchase.id,
                                            triggers: "hover",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " На лицевом счете недостаточно средств. Для подачи предложения не хватает "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-danger text-nowrap",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$formatPrice(
                                                    _vm.purchase.actions
                                                      .supplier.has_money
                                                      .not_enough
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(" сом."),
                                          _c("br"),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "CabinetAccountReplenishment",
                                                },
                                              },
                                            },
                                            [_vm._v("Пополнить лицевой счет")]
                                          ),
                                          _vm._v(" . "),
                                        ],
                                        1
                                      ),
                                    ],
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }