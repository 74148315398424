<template>
    <div>
        <b-container>
            <b-row class="mt-0">
                <b-col>
                    <h2 class="grey-color font-weight-bold mb-0">Документы организации</h2>
                    <p class="mt-1 grey-color">Загрузите не более {{ MAX_FILES_TO_UPLOAD }} часто используемых при подаче заявок документов</p>
                </b-col>
                <b-col class="text-right" lg="4">
                    <b-overlay :show="isLoading" opacity="0.6" rounded spinner-variant="primary">
                        <b-button :disabled="countLeft === 0" class="text-uppercase" variant="custom-blue" @click="loadDoc">Загрузить документ</b-button>
                        <p class="mt-2 grey-color">{{ docsLeft }}</p>
                    </b-overlay>
                </b-col>
            </b-row>
        </b-container>
        <b-overlay :show="isLoading" opacity="0.6" rounded spinner-variant="primary">
            <b-container>
                <empty-request v-if="!documents.length" :hidden="isLoading"></empty-request>
                <b-table v-else :fields="tableFields" :items="documents">
                    <template #cell(name)="data">
                        {{ data.value }}
                    </template>
                    <template #cell(attachment.name)="data">
                        <a :href="$getAttachmentDownloadLink(data.item.attachment.id)">
                            {{ data.value }}
                        </a>
                    </template>
                    <template #cell(actions)="data">
                        <b-btn :disabled="isDeleting" size="sm" title="Удалить" variant="light" @click="confirmDelete(data.item.id, data.item.name)">
                            <img alt="trash_icon" src="/images/trash_icon.svg" />
                        </b-btn>
                    </template>
                </b-table>
            </b-container>
        </b-overlay>
        <b-modal ref="load-doc-modal" v-model="showModal" hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Загрузка документа" @show="loadDocShow">
            <b-container>
                <b-overlay :show="isSaving" opacity="0.6" rounded spinner-variant="primary">
                    <form-row-edit-text v-model="form.name" :v="$v.form.name" label="Название документа" />
                    <form-row-single-line-document-upload v-model="form.attachment" :label-cols-lg="3" :label-cols-sm="3" :title="'Загрузить документ'" :v="$v.form.attachment" />
                </b-overlay>
            </b-container>
            <div slot="modal-footer" class="w-100">
                <b-overlay :show="isSaving" opacity="0.6" rounded spinner-variant="primary">
                    <div class="my-3">
                        <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelLoadDoc">Отмена</b-button>
                        <b-button :disabled="$v.form.$invalid" class="mx-2 text-uppercase" variant="custom-blue" @click="saveData">Сохранить</b-button>
                    </div>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import FormRowSingleLineDocumentUpload from '@/components/common/form-rows/form-row-single-line-document-upload';

const MAX_FILES_TO_UPLOAD = 10;

export default {
    name: 'organization-documents',
    components: {
        FormRowSingleLineDocumentUpload,
    },
    data() {
        return {
            MAX_FILES_TO_UPLOAD,
            isLoading: true,
            isSaving: false,
            isDeleting: false,
            showModal: false,
            form: {
                name: '',
                attachment: null,
            },
            tableFields: [
                { key: 'name', label: 'Название документа' },
                { key: 'attachment.name', label: 'Наименование' },
                { key: 'actions', label: '', tdClass: 'text-right' },
            ],
            documents: [],
        };
    },
    validations: {
        form: {
            name: { required },
            attachment: { required },
        },
    },
    mounted() {
        this.fillData();
        this.$scrollToTop();
    },
    methods: {
        async fillData() {
            this.isLoading = true;
            const response = await this.$api.personal.documents.getList();
            this.documents = response;
            this.isLoading = false;
        },
        async saveData() {
            this.isSaving = true;
            await this.$api.personal.documents.store(this.form);
            this.$refs['load-doc-modal'].hide();
            this.isSaving = false;
            await this.fillData();
        },
        confirmDelete(id, name) {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите удалить документ "' + name + '"?', {
                    title: 'Подтвердите действие',
                    okVariant: 'danger',
                    okTitle: 'Удалить',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(async (value) => {
                    if (value) {
                        this.isDeleting = true;
                        await this.$api.personal.documents.delete(id);
                        this.isDeleting = false;
                        await this.fillData();
                    }
                })
                .catch((err) => {
                    // An error occurred
                    console.error(err);
                });
        },
        loadDoc() {
            this.showModal = true;
        },
        cancelLoadDoc() {
            this.$refs['load-doc-modal'].hide();
            this.showModal = false;
        },
        loadDocShow() {
            this.form.name = '';
            this.form.attachment = null;
        },
    },
    computed: {
        countLeft() {
            return MAX_FILES_TO_UPLOAD - this.documents.length;
        },
        docsLeft() {
            return 'Доступно для загрузки документов: ' + this.countLeft;
        },
    },
};
</script>
