var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$store.getters.isLoggedIn &&
      _vm.purchase.customer.id === _vm.$store.getters.getOrganization.id &&
      _vm.purchase.private_data &&
      _vm.purchase.private_data.proposals &&
      _vm.isStatusProtocolFormedOrGreater
        ? _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _vm.purchase.private_data.proposals &&
              _vm.purchase.private_data.proposals.length > 0
                ? _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "10" } }, [
                            _c(
                              "h2",
                              {
                                staticClass: "grey-color font-weight-bold my-3",
                              },
                              [_vm._v("Конкурсные заявки")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.purchase.private_data.proposals,
                        function (proposal, index) {
                          return _c("private-proposal-card", {
                            key: "proposal_" + index,
                            attrs: {
                              proposal: proposal,
                              "purchase-id": _vm.purchase.id,
                              "supplier-requirements":
                                _vm.purchase.supplier_requirements,
                              purchase: _vm.purchase,
                              index: index,
                              "with-rating": true,
                            },
                          })
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.purchase.private_data.proposals &&
              _vm.purchase.private_data.proposals.length === 0
                ? _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "10" } }, [
                            _c(
                              "h2",
                              {
                                staticClass: "grey-color font-weight-bold my-3",
                              },
                              [_vm._v("Конкурсные заявки отсутствуют")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }