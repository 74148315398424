<template>
    <div style="background-color: var(--greyBg-color)">
        <b-container v-if="$store.state.user && $store.state.organization" class="main_layer py-1" fluid>
            <b-container>
                <b-row class="my-1">
                    <b-col>
                        <h2 class="fw-600 white-color">{{ $store.state.organization.name }}</h2>
                    </b-col>
                </b-row>
                <b-row class="my-1">
                    <b-col>
                        <p class="fs-14 greyBg-color">
                            Пользователь: <strong>{{ $store.state.user.name }}</strong>
                        </p>
                        <p v-if="$store.state.user.role && $store.state.user.role.title" class="fs-14 greyBg-color">
                            Роль: <strong>{{ $store.state.user.role.title }}</strong>
                        </p>
                    </b-col>
                </b-row>
                <b-row class="my-1">
                    <b-col>
                        <router-link
                            v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_COMPETITIVE) && userRolesPermitted([$userRoles.DIRECTOR, $userRoles.ADMINISTRATOR, $userRoles.BUYER])"
                            :to="{ name: 'CompetitivePurchaseCreate' }"
                            class="btn btn-custom-white mx-1 my-1 text-uppercase"
                            tag="button">
                            Создать конкурс
                        </router-link>
                        <router-link
                            v-if="orgRoleIsPermitted($orgRoles.CUSTOMER_SIMPLE) && userRolesPermitted([$userRoles.DIRECTOR, $userRoles.ADMINISTRATOR, $userRoles.BUYER])"
                            :to="{ name: 'SimplePurchaseCreate' }"
                            class="btn btn-custom-white mx-1 my-1 text-uppercase"
                            tag="button">
                            Создать простую закупку
                        </router-link>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid>
            <b-container>
                <b-row class="py-3">
                    <b-col md="3" sm="12">
                        <b-nav class="w-100 provider_menu cabinet_menu mb-3" vertical>
                            <b-nav-item v-if="orgRoleIsAnyCustomer" :active="isActive('PersonalPurchases')" :to="{ name: 'PersonalPurchases' }" exact>Закупки</b-nav-item>
                            <b-nav-item :active="isActive('PersonalProposals')" :to="{ name: 'PersonalProposals' }" exact>Заявки</b-nav-item>
                            <li class="nav-item" @click="organizationSubmenu = true">
                                <a :class="{ 'router-link-active': checkOrganisationLink }" class="nav-link" href="javascript:void(0)" target="_self">
                                    Данные организации
                                    <b-collapse id="account" v-model="organizationSubmenu" class="mt-2 cabinet_submenu">
                                        <b-dropdown-item :active="isActive('OrganizationInformation')" :to="{ name: 'OrganizationInformation' }">Информация об организации</b-dropdown-item>
                                        <b-dropdown-item :active="isActive('OrganizationDocuments')" :to="{ name: 'OrganizationDocuments' }">Документы</b-dropdown-item>
                                        <b-dropdown-item :active="isActive('UsersList')" :to="{ name: 'UsersList' }">Пользователи организации</b-dropdown-item>
                                    </b-collapse>
                                </a>
                            </li>
                            <li class="nav-item" @click="accountSubmenu = true">
                                <a :class="{ 'router-link-active': checkAccountLink }" class="nav-link" href="javascript:void(0)" target="_self">
                                    Лицевой счет
                                    <b-collapse id="account" v-model="accountSubmenu" class="mt-2 cabinet_submenu">
                                        <b-dropdown-item :active="isActive('CabinetAccountInfo')" :to="{ name: 'CabinetAccountInfo' }">Состояние ЛС</b-dropdown-item>
                                        <b-dropdown-item :active="isActive('CabinetAccountRefundRequests')" :to="{ name: 'CabinetAccountRefundRequests' }">Возврат средств</b-dropdown-item>
                                    </b-collapse>
                                </a>
                            </li>
                            <b-nav-item :active="isActive('UserProfile')" :to="{ name: 'UserProfile' }">Данные учетной записи</b-nav-item>
                        </b-nav>
                    </b-col>
                    <b-col md="9" sm="12">
                        <router-view></router-view>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <verification-email-modal :email="userEmail" :visible="visibleVerificationEmailModal" @hidden="hiddenVerificationModal"></verification-email-modal>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Cabinet',
    data() {
        return {
            mobileMenu_show: false,
            mobileMenu: false,
            current_route: null,
            accountSubmenu: false,
            organizationSubmenu: false,
        };
    },
    computed: {
        ...mapState({ showVerificationModal: 'showVerificationModal', userEmail: (state) => state.user.email }),
        visibleVerificationEmailModal: {
            get() {
                return typeof this.showVerificationModal === 'boolean' && this.showVerificationModal;
            },
            set(val) {
                this.$store.commit('set_show_verification_modal', val);
            },
        },
        checkAccountLink() {
            return Boolean(this.$route.path.match('/cabinet/account'));
        },
        orgRoleIsAnyCustomer() {
            const customerRoles = [this.$orgRoles.CUSTOMER_COMPETITIVE, this.$orgRoles.CUSTOMER_SIMPLE, this.$orgRoles.CUSTOMER_DIRECT];

            return customerRoles.findIndex((r) => this.orgRoleIsPermitted(r)) > -1;
        },
        checkOrganisationLink() {
            return Boolean(this.$route.path.match('/cabinet/organization'));
        },
    },
    methods: {
        isActive(path) {
            return this.$route.name === path;
        },
        hiddenVerificationModal() {
            this.visibleVerificationEmailModal = false;
        },
    },
    mounted() {
        if (!this.$store.state.organization) {
            this.$router.push('/').catch(() => {});
        }
        if (this.$route.path === '/cabinet') {
            this.$router.push({ name: 'OrganizationInformation' }).catch(() => {});
        }
        if (this.checkAccountLink) {
            this.accountSubmenu = true;
        }
        if (this.checkOrganisationLink) {
            this.organizationSubmenu = true;
        }
    },
    watch: {
        $route() {
            this.accountSubmenu = this.checkAccountLink;
            this.organizationSubmenu = this.checkOrganisationLink;
        },
    },
};
</script>

<style scoped>
.cabinet_menu {
    position: -webkit-sticky;
    position: sticky;
    top: 160px;
    -webkit-box-shadow: 0 0 10px -5px #aaa;
    -moz-box-shadow: 0 0 10px -5px #aaa;
    box-shadow: 0 0 10px -5px #aaa;
}

.cabinet_menu li {
    background: #fff;
    /*border-radius: 4px;*/
}

.cabinet_menu li a {
    border-left: 8px solid transparent;
    font-size: 14px;
    padding: 16px;
    color: var(--grey-color);
}

.cabinet_menu li .router-link-active,
.cabinet_menu li .active,
.cabinet_menu li.notify_active {
    border-left: 8px solid var(--est-blue-2);
    /*border-radius: 4px 0 0 4px;*/
    color: var(--est-blue-2);
    font-weight: 600;
}

.cabinet_menu li + li {
    border-top: 1px solid rgba(149, 155, 164, 0.25);
}

.main_layer {
    background-color: var(--est-blue-2);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 120px;
}

@media screen and (max-width: 769px) {
    .if-tablet {
        font-size: 40px !important;
    }
}
</style>

<style>
#privider_info .dropdown-item {
    white-space: unset !important;
}

.provider_menu .dropdown-item.active {
    background: transparent;
    color: var(--est-blue-2);
}

.provider_menu .cabinet_submenu li {
    border-top: none !important;
}

.provider_menu .cabinet_submenu li a {
    font-size: 14px;
    color: var(--grey-color);
}

.provider_menu .cabinet_submenu li a:active {
    background: transparent;
}

.provider_menu .cabinet_submenu li a:hover {
    background: transparent;
}
</style>
