var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isPurchaseLoading,
              expression: "!isPurchaseLoading",
            },
          ],
        },
        [
          _c(
            "b-container",
            {
              staticClass: "purchase-create-header-block",
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "10" } }, [
                        _vm.isModeEdit
                          ? _c(
                              "div",
                              { staticClass: "fs-42 fw-600 white-color py-3" },
                              [
                                _vm._v(
                                  "Редактирование объявления о закупке № " +
                                    _vm._s(_vm.form.registration_number)
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "fs-42 fw-600 white-color py-3" },
                              [_vm._v("Объявление о закупке")]
                            ),
                      ]),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right my-auto",
                          attrs: { md: "2" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-custom-danger",
                              attrs: {
                                href: _vm.$links.manual
                                  .competitive_purchase_create,
                                target: "_blank",
                              },
                            },
                            [_vm._v("Инструкция")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            {
              staticStyle: { "background-color": "#f5f5f7" },
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    { staticClass: "pb-5 position-relative purchase_session" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("CustomerInfo", {
                            attrs: { customer: _vm.customer },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              label: "Дополнительная контактная информация",
                            },
                            model: {
                              value: _vm.form.additional_contact_info,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "additional_contact_info",
                                  $$v
                                )
                              },
                              expression: "form.additional_contact_info",
                            },
                          }),
                          _c("text-header", [_vm._v("Условия закупки")]),
                          _c("form-row-edit-textarea", {
                            attrs: {
                              rows: 5,
                              label:
                                "Примечание к закупке (отображается только в ЛК Заказчика)",
                            },
                            model: {
                              value: _vm.form.note,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "note", $$v)
                              },
                              expression: "form.note",
                            },
                          }),
                          _c("form-row-edit-checkbox", {
                            attrs: {
                              "label-cols-lg": 3,
                              "label-cols-sm": 4,
                              align: "text-left",
                              label: "Конкурс с лотированием",
                            },
                            model: {
                              value: _vm.form.with_lots,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "with_lots", $$v)
                              },
                              expression: "form.with_lots",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.name,
                              label: "Наименование конкурса",
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                          _c("form-row-single-select", {
                            attrs: {
                              options: _vm.dictionaries.currencies,
                              v: _vm.$v.form.currency,
                              label: "Валюта закупки",
                              "label-field": "title",
                              placeholder: "Выберите валюту закупки",
                            },
                            model: {
                              value: _vm.form.currency,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "currency", $$v)
                              },
                              expression: "form.currency",
                            },
                          }),
                          _c("form-row-single-select", {
                            attrs: {
                              options: _vm.dictionaries.deliverable_groups,
                              v: _vm.$v.form.deliverable_group_id,
                              label: "Предмет закупки",
                              "label-field": "title",
                              placeholder: "Выберите предмет закупки",
                            },
                            model: {
                              value: _vm.form.deliverable_group_id,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deliverable_group_id", $$v)
                              },
                              expression: "form.deliverable_group_id",
                            },
                          }),
                          _c("form-row-edit-datetime2", {
                            attrs: {
                              "min-date":
                                _vm.validations.envelopes_opening_min_datetime,
                              v: _vm.$v.form.purchase_end_datetime,
                              label:
                                "Дата и время окончания приема заявок и вскрытия конвертов",
                              "min-error-text":
                                "Продолжительность закупочной сессии должна составлять минимум 5 полных рабочих дней",
                            },
                            model: {
                              value: _vm.form.purchase_end_datetime,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "purchase_end_datetime", $$v)
                              },
                              expression: "form.purchase_end_datetime",
                            },
                          }),
                          _c("form-row-single-select", {
                            attrs: {
                              options: _vm.dictionaries.edms,
                              v: _vm.$v.form.edms.contract_format_id,
                              label: "Формат заключения контракта",
                              "label-field": "title",
                              placeholder:
                                "Выберите формат заключения контракта",
                            },
                            model: {
                              value: _vm.form.edms.contract_format_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.edms,
                                  "contract_format_id",
                                  $$v
                                )
                              },
                              expression: "form.edms.contract_format_id",
                            },
                          }),
                          _vm.form.edms.contract_format_id === 1
                            ? _c("form-row-edit-text", {
                                attrs: {
                                  v: _vm.$v.form.edms.operator_name,
                                  label: "Оператор ЭДО",
                                },
                                model: {
                                  value: _vm.form.edms.operator_name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.edms,
                                      "operator_name",
                                      $$v
                                    )
                                  },
                                  expression: "form.edms.operator_name",
                                },
                              })
                            : _vm._e(),
                          _c("SupplierRequirements", {
                            attrs: { v: _vm.$v, "is-edit": "" },
                            model: {
                              value: _vm.$data,
                              callback: function ($$v) {
                                _vm.$data = $$v
                              },
                              expression: "$data",
                            },
                          }),
                          _c("form-row-specification-table", {
                            attrs: {
                              okeis: _vm.dictionaries.okei,
                              "selected-currency-id": _vm.form.currency,
                              "spec-error-message": _vm.specErrorMessage,
                              "spec-errors": _vm.specErrors,
                              "spec-sum": _vm.roundedSpecSum,
                              v: _vm.$v.form.deliverables,
                              "with-count-edit": "",
                            },
                            model: {
                              value: _vm.form.deliverables,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "deliverables", $$v)
                              },
                              expression: "form.deliverables",
                            },
                          }),
                          _c("form-row-upload-single-document", {
                            attrs: {
                              v: _vm.$v.form.contract_draft_attachment,
                              title: "Проект контракта",
                            },
                            model: {
                              value: _vm.form.contract_draft_attachment,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "contract_draft_attachment",
                                  $$v
                                )
                              },
                              expression: "form.contract_draft_attachment",
                            },
                          }),
                          _c("form-row-upload-multiple-documents", {
                            attrs: {
                              v: _vm.$v.form.notice_supplementary_attachments,
                              title: "Дополнительные документы",
                            },
                            model: {
                              value: _vm.form.notice_supplementary_attachments,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "notice_supplementary_attachments",
                                  $$v
                                )
                              },
                              expression:
                                "form.notice_supplementary_attachments",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-row",
                            { staticClass: "my-3 mt-5" },
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mx-2 text-uppercase",
                                      attrs: {
                                        variant: "custom-outline-secondary",
                                      },
                                      on: { click: _vm.onCancel },
                                    },
                                    [_vm._v("Отменить")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "text-uppercase mx-2",
                                      attrs: { variant: "custom-danger" },
                                      on: { click: _vm.saveDraft },
                                    },
                                    [_vm._v("Сохранить черновик")]
                                  ),
                                  _vm.userRolesPermitted([
                                    _vm.$userRoles.DIRECTOR,
                                    _vm.$userRoles.ADMINISTRATOR,
                                  ])
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase mx-2",
                                          attrs: {
                                            disabled: _vm.$v.form.$invalid,
                                            variant: "custom-blue",
                                          },
                                          on: { click: _vm.rutokenInit },
                                        },
                                        [_vm._v(" Подписать и сохранить ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.userRolesPermitted([
            _vm.$userRoles.DIRECTOR,
            _vm.$userRoles.ADMINISTRATOR,
          ])
            ? _c(
                "b-modal",
                {
                  ref: "sign-form-modal",
                  attrs: {
                    centered: "",
                    "hide-header-close": "",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                    scrollable: "",
                    size: "lg",
                    title: "Подписать заявку",
                  },
                  model: {
                    value: _vm.showSignModal,
                    callback: function ($$v) {
                      _vm.showSignModal = $$v
                    },
                    expression: "showSignModal",
                  },
                },
                [
                  _c(
                    "b-tabs",
                    { attrs: { "content-class": "mt-3 px-3 d-block" } },
                    [
                      _c(
                        "b-tab",
                        { attrs: { active: "" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Форма")]
                            ),
                          ]),
                          _c("CustomerInfo", {
                            attrs: { customer: _vm.customer },
                          }),
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Xml представление")]
                            ),
                          ]),
                          _c("div", { staticClass: "px-3" }, [
                            _c(
                              "pre",
                              {
                                staticClass: "fs-10 grey-color p-3 greyBg m-0",
                              },
                              [_vm._v(_vm._s(_vm.signedFormXml))]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm.signature
                        ? _c(
                            "b-tab",
                            [
                              _c("template", { slot: "title" }, [
                                _c(
                                  "span",
                                  { staticClass: "system_link-style fs-16" },
                                  [_vm._v("Подпись")]
                                ),
                              ]),
                              _c("div", { staticClass: "px-3" }, [
                                _c(
                                  "pre",
                                  {
                                    staticClass:
                                      "fs-10 grey-color p-3 greyBg m-0",
                                  },
                                  [_vm._v(_vm._s(_vm.signature))]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "w-100 px-3",
                      attrs: { slot: "modal-footer" },
                      slot: "modal-footer",
                    },
                    [
                      _c(
                        "b-row",
                        { staticClass: "my-3" },
                        [
                          _c(
                            "b-col",
                            { attrs: { md: "12" } },
                            [
                              _c(
                                "b-overlay",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: {
                                    show: _vm.isSigningXml || _vm.isDataSending,
                                    opacity: "0.6",
                                    rounded: "",
                                    "spinner-small": "",
                                    "spinner-variant": "primary",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mr-2 text-uppercase",
                                      attrs: {
                                        disabled: _vm.isDataSending,
                                        variant: "custom-outline-secondary",
                                      },
                                      on: { click: _vm.cancelSignModal },
                                    },
                                    [_vm._v("Отмена")]
                                  ),
                                ],
                                1
                              ),
                              !_vm.signature
                                ? _c(
                                    "b-overlay",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        show: _vm.isSigningXml,
                                        opacity: "0.6",
                                        rounded: "",
                                        "spinner-small": "",
                                        "spinner-variant": "primary",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            disabled: !_vm.selectCertificate,
                                            variant: "custom-blue",
                                          },
                                          on: { click: _vm.askForPin },
                                        },
                                        [_vm._v("Подписать")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.signature
                                ? _c(
                                    "b-overlay",
                                    {
                                      staticClass: "d-inline-block",
                                      attrs: {
                                        show: _vm.isDataSending,
                                        opacity: "0.6",
                                        rounded: "",
                                        "spinner-small": "",
                                        "spinner-variant": "primary",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "text-uppercase",
                                          attrs: {
                                            disabled: !_vm.selectCertificate,
                                            variant: "custom-blue",
                                          },
                                          on: { click: _vm.sendData },
                                        },
                                        [_vm._v("Сохранить")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-modal",
            {
              attrs: {
                id: "pin-input-modal",
                centered: "",
                "hide-header-close": "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                title: "Введите PIN-код",
              },
            },
            [
              _c("selected-certificate-information", {
                attrs: { certificate: _vm.selectCertificate },
              }),
              _c("form-row-edit-text", {
                attrs: { v: _vm.$v.pin, label: "PIN", type: "password" },
                model: {
                  value: _vm.pin,
                  callback: function ($$v) {
                    _vm.pin = $$v
                  },
                  expression: "pin",
                },
              }),
              _c(
                "template",
                { slot: "modal-footer" },
                [
                  _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isPinChecking,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2 text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.cancelPinModal },
                        },
                        [_vm._v("Отмена")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-overlay",
                    {
                      staticClass: "d-inline-block",
                      attrs: {
                        show: _vm.isPinChecking,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: { disabled: !_vm.pin, variant: "custom-blue" },
                          on: { click: _vm.okPinModal },
                        },
                        [_vm._v("OK")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("RutokenErrorModal", {
        attrs: {
          "rutoken-error": _vm.rutokenError,
          show: _vm.rutokenModalShow,
        },
        on: {
          cancel: function ($event) {
            _vm.rutokenModalShow = false
          },
        },
      }),
      _c("SelectCertificateModal", {
        attrs: {
          rutoken: _vm.rutoken,
          show: _vm.showSelectCertificate,
          v: _vm.$v.selectCertificate,
        },
        on: {
          hideModal: (value) => _vm.changeShowSelectCertificate(value),
          save: (value) => _vm.makeXml(value),
        },
        model: {
          value: _vm.selectCertificate,
          callback: function ($$v) {
            _vm.selectCertificate = $$v
          },
          expression: "selectCertificate",
        },
      }),
      _vm.isPurchaseLoading ? _c("system-preloader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }