'use strict';

export default class RutokenCertificateVO {
    #parsedCertificate;

    constructor(selfPOJO) {
        this.device_id = selfPOJO.device_id;
        this.id = selfPOJO.id;
        this.prepared_thumbprint = selfPOJO.prepared_thumbprint;
        this.serial_number = selfPOJO.serial_number;
        this.prepared_serial = selfPOJO.prepared_serial;
        this.valid = {
            from: selfPOJO.valid.from,
            to: selfPOJO.valid.to,
        };
        this.issuer_full = selfPOJO.issuer_full;
        this.subject = {
            inn: selfPOJO.subject.inn,
            organization_name: selfPOJO.subject.organization_name,
            full_name: selfPOJO.subject.full_name,
            position: selfPOJO.subject.position,
            email: selfPOJO.subject.email,
            country_code: selfPOJO.subject.country_code,
        };
        this.subject_full = selfPOJO.subject_full;

        Object.freeze(this);
    }

    static fromParsedCertificate(deviceID, certificateID, parseCertificate) {
        const selfPOJO = {
            device_id: deviceID,
            id: certificateID,
            prepared_thumbprint: RutokenCertificateVO.prepareThumbprint(certificateID),
            prepared_serial: RutokenCertificateVO.prepareThumbprint(parseCertificate.serialNumber),
            serial_number: parseCertificate.serialNumber,
            valid: {
                from: parseCertificate.validNotBefore,
                to: parseCertificate.validNotAfter,
            },
            issuer_full: this.getIssuer(parseCertificate),
            subject_full: this.getSubject(parseCertificate),
            subject: {
                inn: RutokenCertificateVO.#getSubjectProperty('INN', parseCertificate) ?? RutokenCertificateVO.#getSubjectProperty('serialNumber', parseCertificate),
                organization_name: RutokenCertificateVO.#getSubjectProperty('organizationName', parseCertificate),
                full_name: RutokenCertificateVO.#getSubjectProperty('commonName', parseCertificate),
                position: RutokenCertificateVO.#getSubjectProperty('title', parseCertificate),
                email: RutokenCertificateVO.#getSubjectProperty('emailAddress', parseCertificate),
                country_code: RutokenCertificateVO.#getSubjectProperty('countryName', parseCertificate),
            },
        };

        return new RutokenCertificateVO(selfPOJO);
    }

    static #getSubjectProperty(propertyName, parseCertificate) {
        const found = parseCertificate.subject.filter((propertyDescriptor) => {
            return propertyDescriptor.rdn === propertyName;
        });

        return found.length > 0 ? found[0].value : null;
    }

    static getIssuer(parseCertificate) {
        let obj = {};

        for (const parseCertificateKey of parseCertificate.issuer) {
            obj[parseCertificateKey.rdn] = parseCertificateKey.value;
        }
        Object.freeze(obj);

        return obj;
    }
    static getSubject(parseCertificate) {
        let obj = {};

        for (const parseCertificateKey of parseCertificate.subject) {
            obj[parseCertificateKey.rdn] = parseCertificateKey.value;
        }
        Object.freeze(obj);

        return obj;
    }

    static prepareThumbprint(thumbprint) {
        return thumbprint.replace(/[^A-Fa-f0-9]/gi, '').toUpperCase();
    }
}
