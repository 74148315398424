var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading && _vm.profile
    ? _c(
        "div",
        [
          _c("text-header", { attrs: { "margin-class": "mb-3" } }, [
            _vm._v("Идентификационные данные пользователя"),
          ]),
          _c("text-row", {
            attrs: { compact: true, label: "ФИО" },
            model: {
              value: _vm.profile.user.name,
              callback: function ($$v) {
                _vm.$set(_vm.profile.user, "name", $$v)
              },
              expression: "profile.user.name",
            },
          }),
          _c("text-row", {
            attrs: { compact: true, label: "Email" },
            model: {
              value: _vm.profile.user.email,
              callback: function ($$v) {
                _vm.$set(_vm.profile.user, "email", $$v)
              },
              expression: "profile.user.email",
            },
          }),
          _c("text-row", {
            attrs: { compact: true, label: "Телефон" },
            model: {
              value: _vm.profile.user.phone,
              callback: function ($$v) {
                _vm.$set(_vm.profile.user, "phone", $$v)
              },
              expression: "profile.user.phone",
            },
          }),
          _c("text-row", {
            attrs: { compact: true, label: "Роль" },
            model: {
              value: _vm.profile.user.role,
              callback: function ($$v) {
                _vm.$set(_vm.profile.user, "role", $$v)
              },
              expression: "profile.user.role",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }