<template>
    <div v-if="purchase">
        <p class="fs-12 m-0 grey-color">
            <span class="mr-2" @click="showModal" v-if="purchase.actions.customer.edit_note">
                <img src="/images/edit_icon.svg" alt="check_label" style="width: 16px; cursor: pointer" />
            </span>

            <b>Примечание: </b>
            <template v-if="purchase.note">
                {{ purchase.note }}
            </template>
            <template v-else> &mdash; </template>
        </p>
        <b-modal
            v-model="show"
            :title="'Редактирование примечания к закупке ' + purchase.registration_number"
            no-close-on-backdrop
            no-close-on-esc
            no-close-on-ok
            hide-footer
            @cancel="handleModalCancel"
            @close="handleModalCancel"
            @ok="handleModalAccept">
            <b-row class="mb-4">
                <b-col>
                    <form-row-edit-textarea label="Примечание к закупке" v-model="note" :rows="8" />
                </b-col>
            </b-row>
            <b-row>
                <b-col class="text-right">
                    <b-btn class="mr-2" @click="handleModalCancel">Отмена</b-btn>
                    <b-overlay :show="isDataUpdating" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                        <b-btn variant="primary" @click="handleModalAccept">Сохранить</b-btn>
                    </b-overlay>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'simple-purchase-note-edit-block',
    props: {
        purchase: {
            type: Object,
        },
    },
    data() {
        return {
            show: false,
            isDataUpdating: false,
            note: '',
        };
    },
    methods: {
        showModal() {
            this.show = true;
            this.isDataUpdating = false;
            this.note = this.purchase.note;
        },
        handleModalCancel() {
            this.note = null;
            this.show = false;
            this.isDataUpdating = false;
        },
        async handleModalAccept() {
            this.isDataUpdating = true;
            await this.$api.purchases.simple.updateNote(this.purchase.id, this.note);
            this.show = false;
            this.isDataUpdating = false;
            this.$emit('refresh');
        },
    },
};
</script>
