var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("text-header", [_vm._v("Требования к поставщикам")]),
      _vm.isEdit
        ? _c(
            "div",
            [
              _c("form-row-edit-number", {
                attrs: {
                  v: _vm.v.form.proposal_validity_days,
                  label:
                    "Минимальное количество дней действия предложения участника",
                },
                model: {
                  value: _vm.form.proposal_validity_days,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "proposal_validity_days", $$v)
                  },
                  expression: "form.proposal_validity_days",
                },
              }),
              _c("form-row-edit-checkbox", {
                attrs: {
                  label:
                    "У участника закупочной сессии должны отсутствовать задолженности по налогам и сборам",
                },
                model: {
                  value: _vm.form.supplier_requirements.has_no_arrears,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.supplier_requirements,
                      "has_no_arrears",
                      $$v
                    )
                  },
                  expression: "form.supplier_requirements.has_no_arrears",
                },
              }),
              _c("form-row-edit-checkbox", {
                attrs: {
                  label:
                    "Участник закупки не должен присутствовать в реестре недобросовестных поставщиков",
                },
                model: {
                  value: _vm.form.supplier_requirements.is_reliable_supplier,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.supplier_requirements,
                      "is_reliable_supplier",
                      $$v
                    )
                  },
                  expression: "form.supplier_requirements.is_reliable_supplier",
                },
              }),
              _c("form-row-edit-checkbox", {
                attrs: {
                  label: "Дополнительные требования к участникам закупки",
                },
                on: { input: _vm.hasAdditionalRequirementsCheckboxEvent },
                model: {
                  value:
                    _vm.form.supplier_requirements.has_additional_requirements,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.form.supplier_requirements,
                      "has_additional_requirements",
                      $$v
                    )
                  },
                  expression:
                    "form.supplier_requirements.has_additional_requirements",
                },
              }),
              _vm.form.supplier_requirements.has_additional_requirements
                ? _c(
                    "div",
                    [
                      _c("form-row-edit-textarea", {
                        attrs: {
                          v: _vm.v.form.supplier_requirements
                            .additional_requirements_description,
                          label:
                            "Укажите, каким требованиям должен соответствовать участник закупки",
                        },
                        model: {
                          value:
                            _vm.form.supplier_requirements
                              .additional_requirements_description,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.form.supplier_requirements,
                              "additional_requirements_description",
                              $$v
                            )
                          },
                          expression:
                            "form.supplier_requirements.additional_requirements_description",
                        },
                      }),
                      _c(
                        "b-row",
                        { staticClass: "form-row mb-3" },
                        [
                          _c("b-col", { attrs: { sm: "9" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "fs-14 grey-color font-weight-bold",
                              },
                              [
                                _vm._v(
                                  "Укажите, какими документами участник закупки должен подтвердить соответствие дополнительным требованиям:"
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "b-col",
                            { staticClass: "text-right", attrs: { sm: "3" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  staticClass: "fs-14 text-uppercase",
                                  attrs: { variant: "new-green" },
                                  on: { click: _vm.addAdditionalRequirement },
                                },
                                [
                                  _c("img", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      alt: "plus",
                                      src: "/images/plus-white.svg",
                                    },
                                  }),
                                  _vm._v(" Добавить документ "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.form.supplier_requirements.additional_requirements,
                        function (document, index) {
                          return _c(
                            "b-form-group",
                            {
                              key: index,
                              attrs: {
                                label: "Документ " + (index + 1) + ":",
                                state:
                                  !_vm.v.form.supplier_requirements
                                    .additional_requirements.$each.$iter[index]
                                    .$invalid,
                                "invalid-feedback":
                                  "Поле обязательно для заполнения",
                                "label-class":
                                  "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                "label-cols-lg": "3",
                                "label-cols-sm": "4",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    staticClass: "fs-14",
                                    attrs: {
                                      placeholder:
                                        "Введите название документа, которым участник закупки должен подтвердить соответствие указанным требованиям",
                                    },
                                    model: {
                                      value:
                                        _vm.form.supplier_requirements
                                          .additional_requirements[index],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.supplier_requirements
                                            .additional_requirements,
                                          index,
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.supplier_requirements.additional_requirements[index]",
                                    },
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-btn",
                                        {
                                          staticClass: "fs-14",
                                          attrs: {
                                            disabled:
                                              _vm.form.supplier_requirements
                                                .additional_requirements
                                                .length === 1,
                                            variant: "danger",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeAdditionalRequirement(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              alt: "plus",
                                              src: "/images/trash-white.svg",
                                            },
                                          }),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label:
                              "Участник закупки должен обладать опытом аналогичных поставок на сумму не менее (Не требуется если пусто или 0)",
                            "label-class":
                              "fs-14 grey-color fw-700 d-flex align-items-baseline",
                            "label-cols-lg": "8",
                            "label-cols-sm": "8",
                          },
                        },
                        [
                          _c("currency-input", {
                            directives: [
                              {
                                name: "stop-number-mousewheel",
                                rawName: "v-stop-number-mousewheel",
                              },
                            ],
                            staticClass: "form-control fs-14",
                            class: _vm.v.form.supplier_requirements
                              .similar_experience_amounts
                              ? "is-valid"
                              : "is-invalid",
                            attrs: {
                              currency: null,
                              locale: "ru-KG",
                              "value-as-integer": "",
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements
                                  .similar_experience_amounts,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements,
                                  "similar_experience_amounts",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements.similar_experience_amounts",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _c("div", [
            _c(
              "ul",
              [
                _vm.purchase.proposal_validity_days
                  ? _c(
                      "li",
                      {
                        staticClass:
                          "fs-14 grey-color my-auto col-form-label font-weight-bold",
                      },
                      [
                        _vm._v(
                          "Минимальное количество дней действия предложения участника - " +
                            _vm._s(_vm.purchase.proposal_validity_days)
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "li",
                  {
                    staticClass:
                      "fs-14 grey-color my-auto col-form-label font-weight-bold",
                  },
                  [
                    _vm._v(
                      " У участника закупочной сессии должны отсутствовать задолженности по налогам и сборам - " +
                        _vm._s(_vm.supplierRequirements.has_no_arrears) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "li",
                  {
                    staticClass:
                      "fs-14 grey-color my-auto col-form-label font-weight-bold",
                  },
                  [
                    _vm._v(
                      " Участник закупки не должен присутствовать в реестре недобросовестных поставщиков - " +
                        _vm._s(_vm.supplierRequirements.is_reliable_supplier) +
                        " "
                    ),
                  ]
                ),
                _vm.purchase.supplier_requirements
                  .has_additional_requirements &&
                _vm.purchase.supplier_requirements.additional_requirements &&
                _vm.purchase.supplier_requirements.additional_requirements
                  .length > 0
                  ? [
                      _c(
                        "li",
                        {
                          staticClass:
                            "fs-14 grey-color my-auto col-form-label",
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              "Участник закупки должен соответствовать следующим требованиям"
                            ),
                          ]),
                          _vm._v(
                            " - " +
                              _vm._s(
                                _vm.purchase.supplier_requirements
                                  .additional_requirements_description
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "li",
                        {
                          staticClass:
                            "fs-14 grey-color my-auto col-form-label font-weight-bold",
                        },
                        [
                          _vm._v(
                            "Участник закупки должен подтвердить соответствие указанным требованиям, прикрепив следующие документы при подаче заявки:"
                          ),
                        ]
                      ),
                      _c("li", { staticStyle: { "list-style-type": "none" } }, [
                        _c(
                          "ul",
                          _vm._l(
                            _vm.purchase.supplier_requirements
                              .additional_requirements,
                            function (requiredDocument) {
                              return _c(
                                "li",
                                {
                                  key: requiredDocument.id,
                                  staticClass:
                                    "fs-14 grey-color my-auto col-form-label",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(requiredDocument.title) + " "
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _c(
                  "li",
                  {
                    staticClass:
                      "fs-14 grey-color my-auto col-form-label font-weight-bold",
                  },
                  [
                    _vm._v(
                      " Участник закупки должен обладать опытом аналогичных поставок на сумму не менее - " +
                        _vm._s(
                          _vm.supplierRequirements.similar_experience_amounts
                        ) +
                        " "
                    ),
                  ]
                ),
              ],
              2
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }