<template>
    <div v-if="days + hours + minutes + seconds > 0 || endText">
        <p class="fs-12 m-0 grey-color fw-600 time_to_end" v-if="endText">
            {{ endText }}
        </p>
        <p class="fs-12 m-0 grey-color fw-600 time_to_end" v-else>
            <span class="fw-600" v-if="days > 0">{{ days }} {{ num2str(days, ['день', 'дня', 'дней']) }} </span>{{ hours }}:{{ minutes }}:{{ seconds }}
        </p>
    </div>
</template>

<script>
export default {
    props: ['data'],
    data() {
        return {
            timer: null,
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
            endText: null,
        };
    },
    created() {
        this.countdown();
        this.timer = setInterval(this.countdown, 1000);
    },
    methods: {
        countdown() {
            let today = new Date().getTime();
            let endDate = new Date(this.data).getTime();
            let seconds = endDate - today;
            if (Math.sign(seconds) === -1) {
                this.endText = 'Прием заявок завершен';
                clearInterval(this.timer);
                this.$emit('timeout');
            } else {
                this.days = Math.floor(seconds / (1000 * 60 * 60 * 24));
                this.hours = ('0' + Math.floor((seconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).slice(-2);
                this.minutes = ('0' + Math.floor((seconds % (1000 * 60 * 60)) / (1000 * 60))).slice(-2);
                this.seconds = ('0' + Math.floor((seconds % (1000 * 60)) / 1000)).slice(-2);
            }
        },
    },
};
</script>
