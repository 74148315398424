<template>
    <div v-if="!isLoading">
        <text-header margin-class="mb-3">Регистрационные данные организации</text-header>
        <text-row v-if="form.country" v-model="form.country.title" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Страна" />
        <text-row v-model="form.name" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Полное наименование" />
        <text-row-date v-model="form.registration_date" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Дата государственной регистрации" />
        <text-row v-model="form.registration_number" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Государственный регистрационный номер" />
        <text-row v-model="form.inn" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="ИНН" />
        <text-row v-model="form.okpo" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="ОКПО" />
        <text-row v-model="form.head" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="ФИО руководителя" />
        <text-row v-model="form.contacts.legal_address" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Адрес юридический" />

        <text-header>Контактная информация</text-header>
        <text-row v-model="form.contacts.postal_address" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Адрес почтовый" />
        <text-row v-model="form.contacts.email" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Адрес электронной почты" />
        <text-row v-model="form.contacts.website" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Адрес сайта" />
        <text-row v-model="form.contacts.phone" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="Телефон" />
        <text-row v-model="form.contacts.contact_person_name" :compact="true" :label-cols-lg="6" :label-cols-sm="6" label="ФИО контактного лица при осуществлении закупок" />
    </div>
</template>

<script>
export default {
    name: 'organization-information',
    data() {
        return {
            isLoading: true,
            form: {
                name: '',
                registration_date: null,
                registration_number: '',
                inn: '',
                okpo: '',
                head: '',
                country: null,
                contacts: {
                    legal_address: '',
                    postal_address: '',
                    email: '',
                    website: '',
                    phone: '',
                    fax: '',
                    contact_person_name: '',
                },
            },
        };
    },
    mounted() {
        this.fillData();
    },
    methods: {
        async fillData() {
            this.isLoading = true;
            this.form = await this.$api.personal.organization.getInformation();
            this.isLoading = false;
        },
    },
};
</script>
