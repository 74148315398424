var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex align-items-center justify-content-between",
            },
            [
              _c("h2", { staticClass: "grey-color font-weight-bold mb-3" }, [
                _vm._v("Возврат средств"),
              ]),
              _c(
                "b-btn",
                {
                  staticClass: "fs-14",
                  attrs: {
                    to: { name: "CabinetAccountRefundRequestCreate" },
                    variant: "new-green",
                  },
                },
                [
                  _c("img", {
                    staticClass: "mr-2",
                    attrs: { alt: "plus", src: "/images/plus-white.svg" },
                  }),
                  _vm._v(" Запрос на возврат средств "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "py-3" },
        [
          _c("b-table", {
            attrs: {
              busy: _vm.loading,
              fields: _vm.fields,
              items: _vm.items,
              "per-page": _vm.pagination.per_page,
              "empty-text": "Данные отсутствуют",
              responsive: "",
              "show-empty": "",
            },
            scopedSlots: _vm._u([
              {
                key: "table-busy",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "text-center my-2" },
                      [
                        _c("b-spinner", { staticClass: "align-middle mr-1" }),
                        _c("strong", [_vm._v("Загрузка данных...")]),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(created_at)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.getDateTimeFormat(row.item.created_at)) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(operation)",
                fn: function () {
                  return [
                    _c("div", { staticClass: "text-wrap" }, [
                      _vm._v(
                        "aklsjdhflkajshdflkhasdlkfhklashjdflkajshdlfkhalkjsdhflkashdflkajhsdfljkhasdlkjfhalksdjhflaksdhjf"
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(amount)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " + _vm._s(_vm.$formatPrice(row.item.amount)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.pagination.total
            ? _c("b-pagination", {
                staticClass: "mt-4 mb-0 mx-auto",
                attrs: {
                  "per-page": _vm.pagination.per_page,
                  "total-rows": _vm.pagination.total,
                  align: "center",
                },
                on: { input: _vm.applyFilters },
                model: {
                  value: _vm.pagination.current_page,
                  callback: function ($$v) {
                    _vm.$set(_vm.pagination, "current_page", $$v)
                  },
                  expression: "pagination.current_page",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }