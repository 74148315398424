<template>
    <div v-if="isAvailable">
        <b-btn @click="showModal" variant="danger" size="sm" class="fs-14 mx-2 my-1">Отклонить заявку</b-btn>
        <b-modal ref="cancel-modal" :title="title" v-model="isModalVisible" no-close-on-backdrop no-close-on-esc hide-header-close centered size="lg">
            <b-row>
                <b-col md="12">
                    <p class="fs-16 font-weight-bold grey-color">Укажите пункт конкурсной документации или Положения о закупках, в соответствии с которым отклоняется заявка участника.</p>
                </b-col>
            </b-row>
            <form-row-edit-textarea label="Введите причину отклонения заявки" v-model="form.reason" :v="$v.form.reason" :rows="5" />
            <p class="text-danger fw-600">ВНИМАНИЕ! Данная операция необратима.</p>
            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-outline-secondary" class="text-uppercase mr-2" @click="cancelModal">Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                    <b-button variant="custom-danger" class="text-uppercase" :disabled="$v.$invalid" @click="rejectProposal">Отклонить заявку</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
    name: 'reject-direct-proposal-button',
    props: ['proposal', 'purchaseId'],
    validations: {
        form: {
            reason: { required },
        },
    },
    data() {
        return {
            isModalVisible: false,
            form: {
                reason: '',
            },
            isDataSending: false,
        };
    },
    methods: {
        showModal() {
            this.form.reason = '';
            this.isModalVisible = true;
        },
        cancelModal() {
            this.isModalVisible = false;
            this.form.reason = '';
        },
        async rejectProposal() {
            this.isDataSending = true;
            await this.$api.purchases.direct.rejectProposal(this.purchaseId, this.proposal.id, this.form);
            this.isDataSending = false;
            this.isModalVisible = false;
            this.$emit('refresh');
        },
    },
    computed: {
        title() {
            return 'Вы действительно хотите отклонить заявку ' + this.proposal.supplier.name + ' на сумму ' + this.$formatPrice(this.proposal.price) + ' сом?';
        },
        isAvailable() {
            return this.proposal.status.id === 'actual';
        },
    },
};
</script>
