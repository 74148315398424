var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.$store.getters.isLoggedIn ||
      (_vm.$store.getters.isLoggedIn &&
        _vm.$store.getters.getOrganization &&
        _vm.$store.getters.getOrganization.id !== _vm.purchase.customer.id)
        ? _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _vm.purchase.proposals && _vm.purchase.proposals.length > 0
                ? _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "10" } }, [
                            _c(
                              "h2",
                              {
                                staticClass: "grey-color font-weight-bold my-3",
                              },
                              [_vm._v("Конкурсные заявки")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.purchase.proposals,
                        function (proposal, index) {
                          return _c("public-proposal-card", {
                            key: "proposal_" + index,
                            attrs: { index: index, proposal: proposal },
                          })
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.purchase.proposals && _vm.purchase.proposals.length === 0
                ? _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "10" } }, [
                            _c(
                              "h2",
                              {
                                staticClass: "grey-color font-weight-bold my-3",
                              },
                              [_vm._v("Конкурсные заявки отсутствуют")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }