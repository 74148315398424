var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "mt-0" },
            [
              _c("b-col", [
                _c("h2", { staticClass: "grey-color font-weight-bold mb-0" }, [
                  _vm._v("Документы организации"),
                ]),
                _c("p", { staticClass: "mt-1 grey-color" }, [
                  _vm._v(
                    "Загрузите не более " +
                      _vm._s(_vm.MAX_FILES_TO_UPLOAD) +
                      " часто используемых при подаче заявок документов"
                  ),
                ]),
              ]),
              _c(
                "b-col",
                { staticClass: "text-right", attrs: { lg: "4" } },
                [
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        show: _vm.isLoading,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: {
                            disabled: _vm.countLeft === 0,
                            variant: "custom-blue",
                          },
                          on: { click: _vm.loadDoc },
                        },
                        [_vm._v("Загрузить документ")]
                      ),
                      _c("p", { staticClass: "mt-2 grey-color" }, [
                        _vm._v(_vm._s(_vm.docsLeft)),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isLoading,
            opacity: "0.6",
            rounded: "",
            "spinner-variant": "primary",
          },
        },
        [
          _c(
            "b-container",
            [
              !_vm.documents.length
                ? _c("empty-request", { attrs: { hidden: _vm.isLoading } })
                : _c("b-table", {
                    attrs: { fields: _vm.tableFields, items: _vm.documents },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(name)",
                        fn: function (data) {
                          return [_vm._v(" " + _vm._s(data.value) + " ")]
                        },
                      },
                      {
                        key: "cell(attachment.name)",
                        fn: function (data) {
                          return [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.$getAttachmentDownloadLink(
                                    data.item.attachment.id
                                  ),
                                },
                              },
                              [_vm._v(" " + _vm._s(data.value) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(actions)",
                        fn: function (data) {
                          return [
                            _c(
                              "b-btn",
                              {
                                attrs: {
                                  disabled: _vm.isDeleting,
                                  size: "sm",
                                  title: "Удалить",
                                  variant: "light",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.confirmDelete(
                                      data.item.id,
                                      data.item.name
                                    )
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    alt: "trash_icon",
                                    src: "/images/trash_icon.svg",
                                  },
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "load-doc-modal",
          attrs: {
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
            title: "Загрузка документа",
          },
          on: { show: _vm.loadDocShow },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isSaving,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c("form-row-edit-text", {
                    attrs: { v: _vm.$v.form.name, label: "Название документа" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c("form-row-single-line-document-upload", {
                    attrs: {
                      "label-cols-lg": 3,
                      "label-cols-sm": 3,
                      title: "Загрузить документ",
                      v: _vm.$v.form.attachment,
                    },
                    model: {
                      value: _vm.form.attachment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "attachment", $$v)
                      },
                      expression: "form.attachment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.isSaving,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "my-3" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2 text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.cancelLoadDoc },
                        },
                        [_vm._v("Отмена")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-2 text-uppercase",
                          attrs: {
                            disabled: _vm.$v.form.$invalid,
                            variant: "custom-blue",
                          },
                          on: { click: _vm.saveData },
                        },
                        [_vm._v("Сохранить")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }