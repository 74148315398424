<template>
    <b-col md="6" class="my-3">
        <div class="purchase_part">
            <div class="purchase_part-header">
                <router-link class="purchase_part-header_number fw-600 text-uppercase hover-blue" target="_blank" :to="{ name: 'OrganizationShow', params: { id: organization.id } }">{{ organization.name }}</router-link>
            </div>
            <b-row class="purchase_part-body">
                <!--                <div class="px-3 w-100 mb-4">-->
                <!--                    <p class="m-0 fs-12 grey-color">Юридический адрес:</p>-->
                <!--                    <p class="m-0 fs-12 grey-color fw-600">{{ organization.address }}</p>-->
                <!--                </div>-->
                <b-col class="purchase_part-body_col">
                    <div class="my-2" v-if="organization.inn">
                        <p class="fs-12 fw-400 m-0 text-uppercase grey-color">
                            ИНН: <span class="fw-600 grey-color"> {{ organization.inn }} </span>
                        </p>
                    </div>
                    <div class="my-2" v-if="organization.okpo">
                        <p class="fs-12 fw-400 m-0 text-uppercase grey-color">
                            ОКПО: <span class="fw-600 grey-color"> {{ organization.okpo }} </span>
                        </p>
                    </div>
                    <div class="my-2" v-if="organization.registration_number">
                        <p class="fs-12 fw-400 m-0 text-uppercase grey-color">
                            Рег.номер: <span class="fw-600 grey-color"> {{ organization.registration_number }} </span>
                        </p>
                    </div>
                </b-col>
                <!--                <b-col cols="12" class="my-1">-->
                <!--                    <b-btn :to="{ name: 'OrganizationShow', params: { id: organization.id } }" variant="custom-blue" size="sm" class="text-uppercase custom-blue-btn" target="_blank">Сведения</b-btn>-->
                <!--                </b-col>-->
            </b-row>
        </div>
    </b-col>
</template>

<script>
export default {
    name: 'organization-card',
    props: ['organization'],
};
</script>

<style scoped>
.max-content {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}
.visible-768 {
    display: none;
}
.purchase_part {
    box-shadow: 0px 2px 4px #0000000a;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background: #ffffff;
}
.hover-blue:hover {
    color: var(--est-blue-3) !important;
}
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #005d9b;
    padding: 8px 24px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 40px;
}
.purchase_part-body {
    padding: 12px 24px;
}
.purchase_part-header_number {
    font-size: 12px;
    color: #fff;
    margin: 0;
}
.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}
.purchase_part-body_col + .purchase_part-body_col::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
}
.purchase_part-body span {
    text-transform: none;
}
@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .purchase_part-body_col::before {
        display: none;
    }
}
@media screen and (max-width: 992px) {
    .visible-768 {
        display: block;
    }
}
@media screen and (max-width: 568px) {
    .purchase_part-header {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .purchase_part-header_number {
        margin: 15px 0;
    }
}
</style>
