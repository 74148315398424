var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.proposal.product.id === "competitive"
        ? _c("personal-competitive-proposal", {
            attrs: { proposal: _vm.proposal },
            on: { refresh: _vm.refresh },
          })
        : _vm._e(),
      _vm.proposal.product.id === "direct"
        ? _c("personal-direct-proposal", {
            attrs: { proposal: _vm.proposal },
            on: { refresh: _vm.refresh },
          })
        : _vm._e(),
      _vm.proposal.product.id === "simple"
        ? _c("personal-simple-proposal", {
            attrs: { proposal: _vm.proposal },
            on: { refresh: _vm.refresh },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }