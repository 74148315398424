var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-col", { staticClass: "my-3", attrs: { md: "6" } }, [
    _c(
      "div",
      { staticClass: "purchase_part" },
      [
        _c(
          "div",
          { staticClass: "purchase_part-header" },
          [
            _c(
              "router-link",
              {
                staticClass:
                  "purchase_part-header_number fw-600 text-uppercase hover-blue",
                attrs: {
                  target: "_blank",
                  to: {
                    name: "OrganizationShow",
                    params: { id: _vm.organization.id },
                  },
                },
              },
              [_vm._v(_vm._s(_vm.organization.name))]
            ),
          ],
          1
        ),
        _c(
          "b-row",
          { staticClass: "purchase_part-body" },
          [
            _c("b-col", { staticClass: "purchase_part-body_col" }, [
              _vm.organization.inn
                ? _c("div", { staticClass: "my-2" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-12 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" ИНН: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.organization.inn) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.organization.okpo
                ? _c("div", { staticClass: "my-2" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-12 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" ОКПО: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(" " + _vm._s(_vm.organization.okpo) + " "),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm.organization.registration_number
                ? _c("div", { staticClass: "my-2" }, [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-12 fw-400 m-0 text-uppercase grey-color",
                      },
                      [
                        _vm._v(" Рег.номер: "),
                        _c("span", { staticClass: "fw-600 grey-color" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.organization.registration_number) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }