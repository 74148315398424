var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card-title",
    [
      _c(
        "b-row",
        { staticClass: "my-2" },
        [
          _c("b-col", { staticClass: "my-1", attrs: { md: "6" } }, [
            _vm._v(" Статус: "),
            _c("strong", [_vm._v(_vm._s(_vm.protocol.status.title))]),
          ]),
          _c("b-col", { staticClass: "my-1 text-right", attrs: { md: "6" } }, [
            _vm._v(" Время формирования итогового протокола:"),
            _c("br"),
            _c("strong", [
              _vm._v(_vm._s(_vm.getDateTimeFormat(_vm.protocol.created_at))),
            ]),
          ]),
        ],
        1
      ),
      _vm.protocol.winner_proposal
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "my-1", attrs: { md: "6" } },
                [
                  _vm._v(" Поставщик:"),
                  _c("br"),
                  _c(
                    "router-link",
                    {
                      staticClass: "font-weight-bold",
                      attrs: {
                        to: {
                          name: "OrganizationShow",
                          params: {
                            id: _vm.protocol.winner_proposal.supplier.id,
                          },
                        },
                        target: "_blank",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.protocol.winner_proposal.supplier.name) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "my-1 text-right", attrs: { md: "6" } },
                [
                  _vm._v(" Стоимость заявки:"),
                  _c("br"),
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$formatPrice(_vm.protocol.winner_proposal.price)
                      )
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }