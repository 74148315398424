<template>
    <div>
        <b-row>
            <b-col md="9">
                <h2 class="grey-color font-weight-bold mb-3">Пользователи организации</h2>
            </b-col>
            <b-col class="text-right my-auto" md="3">
                <b-button v-if="userRolesPermitted([$userRoles.DIRECTOR, $userRoles.ADMINISTRATOR])" :to="{ name: 'UserCreate' }" variant="custom-blue">Добавить пользователя</b-button>
            </b-col>
        </b-row>
        <b-table :fields="tableFields" :items="users">
            <template #cell(name)="data">
                <font-awesome-icon v-if="!data.item.is_active" class="text-danger mx-1" icon="lock" size="xs" />
                {{ data.value }}
                <span v-if="data.item.is_current">(Текущий пользователь)</span>
            </template>
            <template #cell(created_at)="data">
                {{ data.value ? getDateTimeFormat(data.value) : '&mdash;' }}
            </template>
            <template #cell(actions)="data" class="text-right">
                <router-link v-if="!data.item.is_current && userRolesPermitted([$userRoles.DIRECTOR, $userRoles.ADMINISTRATOR])" :to="{ name: 'UserEdit', params: { id: data.item.id } }" class="btn btn-custom-outline-secondary-small my-1"
                    >Редактировать
                </router-link>
                <br />
                <b-btn v-if="!data.item.is_current && data.item.is_active && userRolesPermitted([$userRoles.DIRECTOR, $userRoles.ADMINISTRATOR])" class="my-1" variant="custom-danger-small" @click="confirmBlock(data.item.id, data.item.name)"
                    >Блокировать
                </b-btn>
                <b-btn
                    v-if="!data.item.is_current && !data.item.is_active && userRolesPermitted([$userRoles.DIRECTOR, $userRoles.ADMINISTRATOR])"
                    class="my-1"
                    variant="custom-green-small"
                    @click="confirmUnblock(data.item.id, data.item.name)"
                    >Разблокировать
                </b-btn>
            </template>
        </b-table>
        <b-pagination v-if="users" v-model="meta.current_page" :per-page="meta.per_page" :total-rows="meta.total" align="center" class="mb-3" @input="getUsers()"></b-pagination>
    </div>
</template>

<script>
export default {
    name: 'user-profile',
    data() {
        return {
            isLoading: true,
            meta: {
                current_page: 1,
                total: 0,
                per_page: 1,
            },
            tableFields: [
                { key: 'name', label: 'ФИО' },
                { key: 'email', label: 'Email' },
                { key: 'role', label: 'Роль' },
                { key: 'created_at', label: 'Дата создания' },
                { key: 'actions', label: 'Действия', tdClass: 'text-center' },
            ],
            users: [],
        };
    },
    async mounted() {
        await this.getUsers();
    },
    methods: {
        async getUsers(page = true, loading = true) {
            this.isLoading = loading;
            const response = await this.$api.personal.users.getList(this.getFilters(page));
            this.users = response.data;
            this.meta = response.meta;
            this.$scrollToTop();
            this.isLoading = false;
        },
        getFilters(page = true) {
            return {
                filter: { ...this.filter },
                page: page ? this.meta.current_page : 1,
            };
        },

        confirmBlock(id, name) {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите заблокировать пользователя "' + name + '"? Доступ пользователя в систему будет заблокирован.', {
                    title: 'Подтвердите действие',
                    okVariant: 'danger',
                    okTitle: 'Заблокировать',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(async (value) => {
                    if (value) {
                        await this.$api.personal.users.block(id);
                        await this.getUsers(false);
                    }
                })
                .catch((err) => {
                    // An error occurred
                    console.error(err);
                });
        },
        confirmUnblock(id, name) {
            this.$bvModal
                .msgBoxConfirm('Вы действительно хотите разблокировать пользователя "' + name + '"?', {
                    title: 'Подтвердите действие',
                    okVariant: 'danger',
                    okTitle: 'Разблокировать',
                    cancelTitle: 'Отмена',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true,
                })
                .then(async (value) => {
                    if (value) {
                        await this.$api.personal.users.unblock(id);
                        await this.getUsers(false);
                    }
                })
                .catch((err) => {
                    // An error occurred
                    console.error(err);
                });
        },
    },
};
</script>
