<template>
    <div>
        <b-row class="mb-4">
            <b-col class="d-flex align-items-center justify-content-between">
                <h2 class="grey-color font-weight-bold mb-3">Лицевой счет</h2>
                <b-btn :to="{ name: 'CabinetAccountReplenishment' }" class="fs-14" variant="new-green">
                    <img alt="plus" class="mr-2" src="/images/plus-white.svg" />
                    Пополнение счета
                </b-btn>
            </b-col>
        </b-row>

        <b-row class="border-bottom py-3 account_block" no-gutters>
            <b-col class="px-4 py-3" lg="4">
                <div class="d-flex flex-column align-items-center px-3">
                    <div class="cust_label total">
                        {{ statistics.total !== null ? $formatPrice(statistics.total) : '&mdash;' }}
                    </div>
                    <p class="m-0 text-center fs-14 grey-color">Депозит</p>
                </div>
            </b-col>
            <b-col class="px-4 py-3" lg="4">
                <div class="d-flex flex-column align-items-center px-3">
                    <div class="cust_label available">
                        {{ statistics.available !== null ? $formatPrice(statistics.available) : '&mdash;' }}
                    </div>
                    <p class="m-0 text-center fs-14 grey-color">Доступные</p>
                </div>
            </b-col>
            <b-col class="px-4 py-3" lg="4">
                <div class="d-flex flex-column align-items-center px-3">
                    <div class="cust_label blocked">
                        {{ statistics.blocked !== null ? $formatPrice(statistics.blocked) : '&mdash;' }}
                    </div>
                    <p class="m-0 text-center fs-14 grey-color">Заблокированные</p>
                </div>
            </b-col>
        </b-row>

        <div class="py-3">
            <b-table :busy="loading" :fields="fields" :items="items" :per-page="pagination.per_page" empty-text="Данные отсутствуют" responsive show-empty>
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="align-middle mr-1"></b-spinner>
                        <strong>Загрузка данных...</strong>
                    </div>
                </template>
                <template #cell(created_at)="row">
                    {{ getDateTimeFormat(row.item.created_at) }}
                </template>
                <template #cell(operation)="row">
                    <strong>{{ row.item.operation.title }}&nbsp;</strong>
                    <template v-if="row.item.accountable_type === 'competitive_purchase'">
                        (Извещение №
                        <router-link :to="{ name: 'CompetitivePurchaseShow', params: { id: row.item.accountable_id } }" target="_blank">
                            {{ row.item.accountable.reg_number }}
                        </router-link>
                        )
                    </template>
                    <template v-if="row.item.accountable_type === 'simple_purchase'">
                        (Извещение №
                        <router-link :to="{ name: 'SimplePurchaseShow', params: { id: row.item.accountable_id } }" target="_blank">
                            {{ row.item.accountable.reg_number }}
                        </router-link>
                        )
                    </template>
                    <template v-if="row.item.accountable_type === 'direct_purchase'">
                        (Извещение №
                        <router-link :to="{ name: 'DirectPurchaseShow', params: { id: row.item.accountable_id } }" target="_blank">
                            {{ row.item.accountable.reg_number }}
                        </router-link>
                        )
                    </template>
                    <template v-if="row.item.accountable_type === 'refund_request'"> (Запрос на возврат средств № {{ row.item.accountable.reg_number }})</template>
                </template>
                <template #cell(amount)="row">
                    {{ $formatPrice(row.item.amount) }}
                </template>
            </b-table>
            <b-pagination v-if="pagination.total" v-model="pagination.current_page" :per-page="pagination.per_page" :total-rows="pagination.total" align="center" class="mt-4 mb-0 mx-auto" @input="applyFilters" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'CabinetAccountInfo',
    data() {
        return {
            statistics: {
                total: null,
                available: null,
                blocked: null,
            },
            loading: true,
            pagination: {},
            items: [],
            fields: [
                { key: 'created_at', label: 'Дата' },
                { key: 'operation', label: 'Операция' },
                { key: 'amount', label: 'Сумма, сом', class: 'text-right text-nowrap' },
            ],
        };
    },
    created() {
        this.$api.personal.account.getBrief().then((response) => {
            this.statistics = response;
        });
        this.fillData();
    },
    methods: {
        applyFilters() {
            this.fillData();
        },
        async fillData() {
            this.loading = true;
            const response = await this.$api.personal.account.getTransactions({ page: this.pagination.current_page || 1 });
            this.items = response.data;
            this.pagination = response.meta;
            this.loading = false;
            this.$scrollToTop();
        },
    },
};
</script>

<style scoped>
.account_block div + div {
    border-left: 1px solid #959ba450;
}

@media screen and (max-width: 992px) {
    .account_block div + div {
        border-top: 1px solid var(--grey-color);
        border-left: none;
    }
}
</style>

<style>
.cTable {
    border-collapse: collapse;
}

.cTable thead th {
    background: #e4e6e8 !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    padding: 11px 24px !important;
    color: var(--grey-color) !important;
}

.cTable tbody td {
    font-size: 12px !important;
    padding: 11px 24px !important;
    border-bottom: 1px solid #dee2e6 !important;
    color: var(--grey-color) !important;
}

.cTable tbody tr {
    outline: none;
}

.cTable tbody tr:hover {
    cursor: pointer;
    background: rgba(228, 230, 232, 0.5);
}

.cTable tbody .b-table-empty-row:hover {
    cursor: default;
    background: transparent;
}

#attention-modal .modal-body {
    padding: 28px 40px;
}

#filter-modal .modal-body {
    padding: 28px 40px;
}

.available {
    background-color: var(--green-color);
}

.total {
    background-color: #959ba4;
}

.blocked {
    background-color: #f6b33a;
}

.cust_label {
    min-width: 169px;
    margin-bottom: 18px;
    border-radius: 4px;
    text-align: center;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    padding: 7px 34px;
    white-space: nowrap;
}

.account_block {
    background-color: #fff;
    box-shadow: 0px 2px 4px #0000000a;
    border-radius: 4px;
}
</style>
