<template>
    <div>
        <b-container v-if="rutokenError" class="text-danger fs-18 fw-600 text-center my-5">
            <p>{{ rutokenError.message }}</p>
            <template v-if="rutokenError.code === 90097 || rutokenError.code === 90098">
                <p>
                    Инструкция по установке Рутокен Плагин на сайте разработчика -
                    <a href="https://dev.rutoken.ru/pages/viewpage.action?pageId=72451796" target="_blank">ссылка</a>
                </p>
            </template>
        </b-container>
        <b-modal :visible="visible" title="Выберите сертификат для привязки к учетной записи" no-close-on-backdrop hide-header-close no-close-on-esc centered size="lg">
            <div class="text-center">
                <b-button class="mb-3" size="sm" variant="outline-secondary" :disabled="is_certificates_loading" @click="refreshCertificates"> Обновить список сертификатов </b-button>
                <multiselect
                    v-model="selectCertificate"
                    :allow-clear="false"
                    :allow-empty="false"
                    :close-on-select="true"
                    :multiple="false"
                    :options="certificates"
                    :preserve-search="false"
                    :show-labels="false"
                    :disabled="is_certificates_loading"
                    label="text"
                    placeholder="Выберите сертификат"
                    track-by="id">
                    <span slot="noOptions">Нет данных</span>
                    <span slot="noResult">По вашему запросу сертификаты не найдены</span>
                    <template slot="singleLabel" slot-scope="props">
                        <b-row>
                            <b-col v-if="props.option.subject.inn" class="py-1" md="6">
                                <strong>ИНН</strong><br />
                                {{ props.option.subject.inn }}
                            </b-col>
                            <b-col v-if="props.option.subject.organization_name" class="py-1" md="6">
                                <strong>Организация</strong><br />
                                {{ props.option.subject.organization_name }}
                            </b-col>
                            <b-col v-if="props.option.subject.full_name" class="py-1" md="6">
                                <strong>ФИО</strong><br />
                                {{ props.option.subject.full_name }}
                            </b-col>
                            <b-col v-if="props.option.subject.position" class="py-1" md="6">
                                <strong>Должность</strong><br />
                                {{ props.option.subject.position }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="py-1" md="6">
                                <strong>SHA1 отпечаток</strong><br />
                                {{ props.option.prepared_thumbprint }}
                            </b-col>
                            <b-col class="py-1" md="6">
                                <strong>Срок действия</strong><br />
                                С {{ getDateFormat(props.option.valid.from) }} по {{ getDateFormat(props.option.valid.to) }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="py-1">
                                <strong>Серийный номер</strong><br />
                                {{ props.option.prepared_serial }}
                            </b-col>
                        </b-row>
                    </template>
                    <template slot="option" slot-scope="props">
                        <b-row>
                            <b-col v-if="props.option.subject.inn" class="py-1" md="6">
                                <strong>ИНН</strong><br />
                                {{ props.option.subject.inn }}
                            </b-col>
                            <b-col v-if="props.option.subject.organization_name" class="py-1" md="6">
                                <strong>Организация</strong><br />
                                {{ props.option.subject.organization_name }}
                            </b-col>
                            <b-col v-if="props.option.subject.full_name" class="py-1" md="6">
                                <strong>ФИО</strong><br />
                                {{ props.option.subject.full_name }}
                            </b-col>
                            <b-col v-if="props.option.subject.position" class="py-1" md="6">
                                <strong>Должность</strong><br />
                                {{ props.option.subject.position }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="py-1" md="6">
                                <strong>SHA1 отпечаток</strong><br />
                                {{ props.option.prepared_thumbprint }}
                            </b-col>
                            <b-col class="py-1" md="6">
                                <strong>Срок действия</strong><br />
                                С {{ getDateFormat(props.option.valid.from) }} по {{ getDateFormat(props.option.valid.to) }}
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="py-1">
                                <strong>Серийный номер</strong><br />
                                {{ props.option.prepared_serial }}
                            </b-col>
                        </b-row>
                    </template>
                </multiselect>
            </div>
            <template #modal-footer>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="mx-1 text-uppercase" variant="custom-outline-secondary" @click="close">Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="mx-1 text-uppercase" variant="custom-blue" :disabled="!selectCertificate" @click="linkCertificate">Привязать</b-button>
                </b-overlay>
            </template>
        </b-modal>
        <system-preloader v-if="isPageLoading"></system-preloader>
    </div>
</template>

<script>
import RutokenService from '@/services/rutoken/RutokenService';
import axios from 'axios';
import store from '@/store';

export default {
    name: 'user-change-certificate',
    data() {
        return {
            isPageLoading: true,
            certificates: [],
            rutoken: undefined,
            is_certificates_loading: false,
            selectCertificate: null,
            rutokenError: null,
            isDataSending: false,
            visible: false,
        };
    },
    async mounted() {
        try {
            this.rutoken = await new RutokenService().initialize();
            await this.refreshCertificates();
            this.isPageLoading = false;
            this.visible = true;
        } catch (error) {
            this.isPageLoading = false;
            this.rutokenError = error;
        }
    },
    methods: {
        async refreshCertificates() {
            this.is_certificates_loading = true;
            this.certificates = await this.rutoken.getCertificatesList();
            this.selectCertificate = null;
            this.is_certificates_loading = false;
        },
        async getActualCertData() {
            try {
                return await this.rutoken.getCertificateModel(this.selectCertificate);
            } catch (e) {
                this.showRutokenError(e);
            }
        },
        async linkCertificate() {
            this.isDataSending = true;
            const certData = await this.getActualCertData();
            axios
                .post('/personal/profile/change_certificate', { certificate: certData })
                .then(async () => {
                    await store.dispatch('showSuccessToast', 'Сертификат успешно привязан');
                    await this.$store.dispatch('logout');
                })
                .catch((error) => {
                    console.log('ERROR', error);
                    this.isDataSending = false;
                    this.$bvToast.toast(error.response.data.message, {
                        title: 'Ошибка',
                        variant: 'danger',
                    });
                });
        },
        close() {
            this.$router.push({ name: 'UserProfile' });
        },
    },
};
</script>
