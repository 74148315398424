var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      attrs: {
        "label-cols-sm": "4",
        "label-cols-lg": "3",
        label: _vm.label + ":",
        "label-for": _vm.id,
        state: _vm.state,
        "label-class": "fs-14 grey-color fw-700 d-flex align-items-baseline",
      },
    },
    [
      _c("v-date-picker", {
        staticClass: "inline-block h-full",
        attrs: {
          mode: "date",
          timezone: "Asia/Dhaka",
          "min-date": _vm.minDate,
          "max-date": _vm.maxDate,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ inputValue, togglePopover }) {
              return [
                _c("b-form-input", {
                  staticClass: "fs-14",
                  class: !_vm.disabled ? "bg-white" : "",
                  attrs: {
                    id: _vm.id,
                    value: inputValue,
                    state: _vm.state,
                    readonly: "",
                    disabled: _vm.disabled,
                  },
                  on: {
                    click: function ($event) {
                      !_vm.disabled ? togglePopover() : null
                    },
                  },
                }),
                !_vm.state
                  ? [
                      _c("b-form-invalid-feedback", [
                        _vm._v(_vm._s(_vm.errorMessage)),
                      ]),
                    ]
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }