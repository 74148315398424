<template>
    <div v-if="isAvailable">
        <b-btn class="btn-card-action btn-card-action-danger my-1" @click="showModal">Вскрыть конверты</b-btn>
        <b-modal ref="cancel-modal" v-model="isModalVisible" centered hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Укажите состав комиссии по вскрытию конвертов">
            <envelopes-opening-commission-table v-model="form.commission" :error-message="errorMessage" :errors="errors" :v="$v.form.commission" />
            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="cancelModal">Отменить</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="$v.$invalid" class="text-uppercase" variant="custom-danger" @click="store">Подтвердить вскрытие конвертов</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>
<script>
// import FormRowSingleDocumentUpload from '@/components/common/form-rows/form-row-single-document-upload.vue';
import { required } from 'vuelidate/lib/validators';
import EnvelopesOpeningCommissionTable from '@/components/common/envelopes-opening-commission-table.vue';

export default {
    name: 'competitive-purchase-open-envelopes-button',
    components: { EnvelopesOpeningCommissionTable },
    // components: { FormRowSingleDocumentUpload },
    props: {
        purchase: {
            type: Object,
        },
    },
    validations: {
        form: {
            commission: {
                required,
                $each: {
                    required,
                    name: { required },
                    position: { required },
                },
            },
        },
    },
    data() {
        return {
            isModalVisible: false,
            form: {
                commission: '',
            },
            isDataSending: false,
        };
    },
    methods: {
        showModal() {
            this.form.commission = [];
            this.isModalVisible = true;
        },
        cancelModal() {
            this.isModalVisible = false;
            this.form.commission = [];
        },
        async store() {
            this.isDataSending = true;
            if (await this.$api.purchases.competitive.storeEnvelopesOpening(this.purchase.id, this.form)) {
                this.isModalVisible = false;
                this.$emit('store');
            }
            this.isDataSending = false;
        },
    },
    computed: {
        title() {
            return `Отменить конкурс № ${this.purchase.registration_number}?`;
        },
        isAvailable() {
            return this.$store.getters.isLoggedIn && this.purchase.actions.customer.open_envelopes && !this.purchase.private_data?.envelopes_opening;
        },
        errors() {
            return !!this.errorMessage;
        },
        errorMessage() {
            if (this.form.commission.length === 0) return 'Заполните состав комиссии';
            if (!this.form.commission.every((e) => e.name !== '')) return 'У одного или нескольких сотрудников не заполнено поле "ФИО"';
            if (!this.form.commission.every((e) => e.position !== '')) return 'У одного или нескольких сотрудников не заполнено поле "Должность"';
            return '';
        },
    },
};
</script>
