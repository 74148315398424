var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-2" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "purchase_part" }, [
              _c(
                "div",
                { staticClass: "purchase_part-header status_color_2" },
                [
                  _c("b-col", [
                    !(
                      _vm.proposal.purchase.status.id === "draft" ||
                      _vm.proposal.purchase.status.id === "signed"
                    )
                      ? _c(
                          "p",
                          { staticClass: "purchase_part-header_number fw-600" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "white-system_link-style",
                                attrs: {
                                  target: "_blank",
                                  to: {
                                    name: "CompetitiveProposalShow",
                                    params: { id: _vm.proposal.id },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "Заявка на конкурс № " +
                                    _vm._s(
                                      _vm.proposal.purchase.registration_number
                                    ) +
                                    " (" +
                                    _vm._s(_vm.proposal.purchase.status.title) +
                                    ")"
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "p",
                          { staticClass: "purchase_part-header_number fw-600" },
                          [
                            _vm._v(
                              _vm._s(_vm.proposal.product.title) +
                                " - " +
                                _vm._s(_vm.proposal.registration_number)
                            ),
                          ]
                        ),
                  ]),
                  _c("b-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "p",
                      { staticClass: "purchase_part-header_label fw-600" },
                      [_vm._v(" " + _vm._s(_vm.proposal.status.title) + " ")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "purchase_part-body row" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "6" },
                    },
                    [
                      _c("div", [
                        _vm.proposal.purchase.status.id === "accepting"
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("До окончания приема заявок:"),
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "fs-12 m-0 grey-color fw-600 time_to_end",
                                },
                                [
                                  !!_vm.proposal.purchase.purchase_end_datetime
                                    ? _c("timer-new", {
                                        attrs: {
                                          data: _vm.proposal.purchase
                                            .purchase_end_datetime,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        !!_vm.proposal.purchase.purchase_end_datetime
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v(
                                  "Планируемая дата и время вскрытия конвертов:"
                                ),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fw-600 fs-12 m-0 grey-color" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getDateTimeFormat(
                                        _vm.proposal.purchase
                                          .purchase_end_datetime
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "my-2" },
                          [
                            _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                              _vm._v("Заказчик:"),
                            ]),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "system_link-style fw-600 text-uppercase fs-12",
                                attrs: {
                                  to: {
                                    name: "OrganizationShow",
                                    params: { id: _vm.proposal.customer.id },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.proposal.customer.name) + " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm.proposal.purchase.name
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                                _vm._v("Наименование конкурса:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fs-12 green-color fw-600" },
                                [_vm._v(_vm._s(_vm.proposal.purchase.name))]
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                            _vm._v("Предмет закупки:"),
                          ]),
                          _c("p", { staticClass: "fs-12 green-color fw-600" }, [
                            _vm._v(
                              _vm._s(
                                _vm.proposal.purchase.deliverable_group_title
                              )
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 my-2 grey-color" }, [
                            _vm._v(" Сумма закупки: "),
                            _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatPrice(
                                    _vm.proposal.purchase.starting_price
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _c("p", { staticClass: "fs-12 my-2 grey-color" }, [
                            _vm._v(" Валюта: "),
                            _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                              _vm._v(
                                _vm._s(_vm.proposal.purchase.currency_title)
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "6" },
                    },
                    [
                      _c("div", { staticClass: "h-100" }, [
                        _c(
                          "div",
                          [
                            _vm.proposal.status.id === "rejected" &&
                            _vm.proposal.rejection_reason
                              ? _c("div", { staticClass: "my-2" }, [
                                  _c(
                                    "p",
                                    { staticClass: "fs-12 m-0 grey-color" },
                                    [_vm._v("Причина отклонения:")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "fw-600 fs-12 m-0 grey-color",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.proposal.rejection_reason)
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("Дата и время подачи заявки:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fw-600 fs-12 m-0 grey-color" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.getDateTimeFormat(
                                        _vm.proposal.created_at
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]),
                            _vm.proposal.proposal_validity_days
                              ? _c("div", { staticClass: "my-2" }, [
                                  _c(
                                    "p",
                                    { staticClass: "fs-12 m-0 grey-color" },
                                    [
                                      _vm._v(
                                        "Срок действия предложения от даты окончания приема заявок, дней:"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "fw-600 fs-12 m-0 grey-color",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.proposal.proposal_validity_days
                                        )
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("Стоимость заявки:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fw-600 fs-12 m-0 grey-color" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$formatPrice(_vm.proposal.price))
                                  ),
                                ]
                              ),
                            ]),
                            _vm.$store.getters.isLoggedIn &&
                            _vm.proposal.actions.supplier.edit
                              ? [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "btn btn-card-action btn-card-action-green my-1",
                                      attrs: {
                                        to: {
                                          name: "CompetitivePurchaseNewProposal",
                                          params: {
                                            id: _vm.proposal.purchase.id,
                                          },
                                        },
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(" Изменить заявку ")]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }