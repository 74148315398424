var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.purchase.product.id === "simple"
        ? _c("notice-simple", { attrs: { purchase: _vm.purchase } })
        : _vm._e(),
      _vm.purchase.product.id === "competitive"
        ? _c("notice-competitive", { attrs: { purchase: _vm.purchase } })
        : _vm._e(),
      _vm.purchase.product.id === "direct"
        ? _c("notice-direct", { attrs: { purchase: _vm.purchase } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }