<template>
    <div>
        <div v-if="!loading">
            <b-container class="purchase-offer-header-block" fluid>
                <b-container>
                    <b-row>
                        <b-col md="10">
                            <p class="fs-40 fw-600 white-color mt-3 mb-3">Прямая закупка № {{ purchase.registration_number }}</p>
                        </b-col>
                        <b-col class="text-right my-auto" md="2">
                            <a :href="$links.manual.direct_proposal_create" class="btn btn-custom-danger" target="_blank">Инструкция</a>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <p class="white-color fs-14">Форма подачи заявки</p>
                        </b-col>
                    </b-row>
                </b-container>
            </b-container>
            <b-container class="my-2" fluid>
                <b-container>
                    <CustomerInfo :additional-info="purchase.additional_contact_info" :customer="purchase.customer" additional-label="Дополнительная контактная информация" />

                    <text-header>Условия закупки</text-header>
                    <text-row v-if="purchase.name" v-model="purchase.name" label="Наименование конкурса" />
                    <text-row v-model="purchase.deliverable_group_title" label="Предмет закупки" />
                    <text-row-price v-model="purchase.starting_price" label="Сумма закупки" />
                    <text-row v-model="purchase.currency_title" label="Валюта" />
                    <text-row-datetime v-model="purchase.purchase_start_datetime" label="Дата и время размещения закупки" />
                    <text-row-datetime v-model="purchase.purchase_end_datetime" label="Дата и время окончания приема заявок и вскрытия конвертов" />
                    <text-row-documents v-if="purchase.contract_draft_attachment" v-model="purchaseContractDraftAttachment" label="Проект контракта" />
                    <text-row-documents v-if="purchase.notice_supplementary_attachments.length > 0" v-model="purchase.notice_supplementary_attachments" label="Дополнительные документы" />
                    <text-row v-if="purchase.with_lots" label="Прямая закупка с лотированием" value="Да" />

                    <SupplierRequirements v-model="$data" />

                    <text-header>Информация об организации</text-header>
                    <form-row-edit-text v-model="form.contact_name" :v="$v.form.contact_name" label="Контактное лицо поставщика" />
                    <form-row-single-line-document-upload v-model="form.organization_card" :label-cols-lg="3" :label-cols-sm="3" :v="$v.form.organization_card" title="Карточка предприятия" />

                    <text-header>Спецификация</text-header>
                    <p v-if="purchase.fee_amount && !form.existing_proposal" class="font-weight-bold grey-color">
                        При подаче заявки на лицевом счету Вашей организации, в соответствии с регламентом, будет заблокирована сумма {{ $formatPrice(purchase.fee_amount) }} сом.
                    </p>
                    <b-row class="my-2" no-gutters>
                        <b-col class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="fw-600">№</th>
                                        <th class="fw-600">Наименование товара (работ, услуг)</th>
                                        <th class="fw-600">Характеристики</th>
                                        <th class="fw-600">Количество</th>
                                        <th class="fw-600">Единица</th>
                                        <th class="fw-600" style="min-width: 100px">Объявление, Цена за единицу</th>
                                        <th class="fw-600">Объявление, Стоимость</th>
                                        <th class="fw-600" style="min-width: 100px">Заявка, Цена за единицу</th>
                                        <th class="fw-600">Валюта</th>
                                        <th class="fw-600">Заявка, Стоимость</th>
                                        <th v-if="purchase.with_lots" class="fw-600">Не участвую</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in form.deliverables" :key="item.id">
                                        <td class="align-text-top">{{ index + 1 }}</td>
                                        <td class="align-text-top">{{ getPurchaseDeliverable(item.id).name }}</td>
                                        <td class="align-text-top">{{ getPurchaseDeliverable(item.id).characteristics }}</td>
                                        <td class="align-text-top">{{ getPurchaseDeliverable(item.id).quantity }}</td>
                                        <td class="text-lowercase align-text-top">
                                            {{ getPurchaseDeliverable(item.id).okei.local_symbol }}
                                        </td>
                                        <td class="align-text-top">{{ $formatPrice(getPurchaseDeliverable(item.id).price_per_unit) }}</td>
                                        <td class="align-text-top">{{ $formatPrice(getPurchaseDeliverableTotal(item.id)) }}</td>
                                        <td class="align-text-top">
                                            <div class="d-flex align-items-center">
                                                <currency-input
                                                    v-model="item.price_per_unit"
                                                    v-stop-number-mousewheel
                                                    :allow-negative="false"
                                                    :class="(item.price_per_unit > 0 && summLimit) || item.not_participate ? 'is-valid' : 'is-invalid'"
                                                    :currency="null"
                                                    :disabled="item.not_participate"
                                                    class="form-control fs-14"
                                                    locale="ru-KG"
                                                    value-as-integer
                                                    @input="recalculateTotalSum" />
                                            </div>
                                        </td>
                                        <td class="align-text-top">{{ purchase.currency_title }}</td>
                                        <td class="align-text-top">{{ $formatPrice(sumTotalItem(item.id)) }}</td>
                                        <td v-if="purchase.with_lots" class="align-text-top">
                                            <b-form-checkbox v-model="item.not_participate" class="fs-14 grey-color mt-2 text-right" />
                                        </td>
                                    </tr>
                                    <tr v-if="!summLimit">
                                        <td :colspan="purchase.with_lots ? 11 : 10" align="right" class="py-2 border-0">
                                            <span class="text-danger is-invalid">Превышена максимально допустимая стоимость заявки ({{ $formatPrice(purchase.starting_price) }} сом), проверьте заполненные данные</span>
                                        </td>
                                    </tr>
                                    <tr v-if="!specValid">
                                        <td :colspan="purchase.with_lots ? 11 : 10" align="right" class="py-2 border-0">
                                            <span class="text-danger is-invalid">Должна быть минимум одна позиция, в которой вы собираетесь участвовать</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td :colspan="purchase.with_lots ? 11 : 10" align="right" class="py-2 border-0">
                                            <span class="fs-18 fw-600">Стоимость спецификации: {{ $formatPrice(totalSum) }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-col>
                    </b-row>

                    <proposal-documents :documents="documents" :v="$v.form.$model" />

                    <text-header>Подтверждение требований к поставщикам</text-header>
                    <form-row-edit-number v-model="form.proposal_validity_days" :v="$v.form.proposal_validity_days" label="Срок действия предложения от даты окончания приема заявок, дней" />
                    <form-row-single-line-document-upload
                        v-if="purchase.supplier_requirements.has_no_arrears"
                        v-model="form.supplier_requirements_confirmed.no_arrears.confirmation_document"
                        :label-cols-lg="6"
                        :label-cols-sm="6"
                        :v="$v.form.supplier_requirements_confirmed.no_arrears.confirmation_document"
                        title="Документ с подтверждением отсутствия задолженности по налогам и сборам" />
                    <b-form-group
                        v-if="purchase.supplier_requirements.is_reliable_supplier"
                        :state="!$v.form.supplier_requirements_confirmed.is_reliable_supplier.$invalid"
                        label="Подтверждаю, что не присутствую в реестре недобросовестных поставщиков"
                        label-class="fs-14 grey-color fw-700 d-flex align-items-baseline"
                        label-cols-lg="11"
                        label-cols-sm="11">
                        <b-form-checkbox
                            v-model="form.supplier_requirements_confirmed.is_reliable_supplier"
                            :state="!$v.form.supplier_requirements_confirmed.is_reliable_supplier.$invalid"
                            class="fs-14 grey-color mt-2 text-right"></b-form-checkbox>
                    </b-form-group>
                    <div v-if="purchase.supplier_requirements.has_additional_requirements" class="form-row">
                        <div class="col">
                            <p class="fs-14 grey-color mt-2">
                                <strong>Подтверждаю соответствие дополнительным требованиям, приложив следующие документы:</strong>
                            </p>
                        </div>
                    </div>
                    <template v-if="purchase.supplier_requirements.has_additional_requirements">
                        <form-row-single-line-document-upload
                            v-for="(item, index) in $v.form.supplier_requirements_confirmed.additional_requirements.$each.$iter"
                            :key="index"
                            v-model="item.$model.confirmation_document"
                            :title="getAdditionalRequirementTitle(item.$model.additional_requirement_id)"
                            :v="item" />
                    </template>
                    <form-row-single-line-document-upload
                        v-if="purchase.supplier_requirements.similar_experience_amounts > 0"
                        v-model="form.supplier_requirements_confirmed.similar_experience_amounts.confirmation_document"
                        :label-cols-lg="6"
                        :label-cols-sm="6"
                        :title="'Документ с подтверждением опыта аналогичных поставок на сумму не менее ' + $formatPrice(purchase.supplier_requirements.similar_experience_amounts) + ' сом'"
                        :v="$v.form.supplier_requirements_confirmed.similar_experience_amounts.confirmation_document" />

                    <b-row class="my-5">
                        <b-col>
                            <b-button class="mx-2" variant="custom-outline-secondary" @click="cancelOffer">ОТМЕНИТЬ</b-button>
                            <b-button :disabled="$v.form.$invalid || $v.totalSum.$invalid" class="mx-2" variant="custom-blue" @click="checkResources">ПОДАТЬ ЗАЯВКУ</b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </b-container>
            <!--SIGN OFFER MODAL WINDOW -->
            <b-modal v-if="isResidentKGZ" ref="sign-form-modal" v-model="showSignModal" centered hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Подписать заявку">
                <div class="d-block text-left">
                    <b-tabs content-class="mt-3">
                        <b-tab active>
                            <template slot="title">
                                <span class="system_link-style fs-16">Форма</span>
                            </template>
                            <text-row v-model="purchase.reg_number" label="Закупочная сессия" />
                            <text-row-price v-model="totalSum" label="Итоговая стоимость заявки" />
                        </b-tab>
                        <b-tab>
                            <template slot="title">
                                <span class="system_link-style fs-16">Xml представление</span>
                            </template>
                            <div class="px-3">
                                <pre class="fs-10 grey-color p-3 greyBg m-0">{{ formXml }}</pre>
                            </div>
                        </b-tab>
                        <b-tab v-if="signature">
                            <template slot="title">
                                <span class="system_link-style fs-16">Подпись</span>
                            </template>
                            <div class="px-3">
                                <pre class="fs-10 grey-color p-3 greyBg m-0">{{ signature }}</pre>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
                <div slot="modal-footer" class="w-100">
                    <b-overlay :show="isSigningXml || isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="isDataSending" class="text-uppercase mr-2" variant="custom-outline-secondary" @click="cancelSignModal">ОТМЕНИТЬ</b-button>
                    </b-overlay>
                    <b-overlay v-if="!signature" :show="isSigningXml" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="!selectCertificate || !summLimit" class="text-uppercase" variant="custom-blue" @click="askForPin">Подписать</b-button>
                    </b-overlay>
                    <b-overlay v-if="signature" :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="!selectCertificate || !summLimit" class="text-uppercase" variant="custom-blue" @click="sendData">Отправить</b-button>
                    </b-overlay>
                </div>
            </b-modal>
            <b-modal v-if="!isResidentKGZ" ref="sign-form-modal" v-model="showSignModal" centered header-class="pb-0" no-close-on-backdrop no-close-on-esc size="lg" title="Подача заявки" @close="cancelSignModalForPdf">
                <div class="d-block text-left">
                    <b-form-group :label-cols-lg="4" :label-cols-sm="4" label="Загрузите скан подписанной заявки" label-class="fs-14 grey-color fw-700 d-flex align-items-baseline">
                        <b-button :href="$getAttachmentDownloadLink(formPdf?.id)" class="text-uppercase float-right mr-3" size="sm" target="_blank" variant="custom-blue">СКАЧАТЬ ДОКУМЕНТ ДЛЯ ПОДПИСИ</b-button>
                    </b-form-group>
                    <form-row-single-line-document-upload v-model="formPdfSigned" :label-cols-lg="4" :label-cols-sm="4" :v="$v.formPdfSigned" title="Подписанный документ" />
                </div>
                <div slot="modal-footer" class="w-100">
                    <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="isDataSending" class="text-uppercase mr-2" variant="custom-outline-secondary" @click="cancelSignModalForPdf">ОТМЕНИТЬ</b-button>
                    </b-overlay>
                    <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="$v.formPdfSigned.$invalid || !summLimit" class="text-uppercase" variant="custom-blue" @click="sendData">ПОДТВЕРДИТЬ</b-button>
                    </b-overlay>
                </div>
            </b-modal>
            <b-modal id="pin-input-modal" centered hide-header-close no-close-on-backdrop no-close-on-esc title="Введите PIN-код">
                <selected-certificate-information :certificate="selectCertificate" />
                <form-row-edit-text v-model="pin" :v="$v.pin" label="PIN" type="password" />
                <template slot="modal-footer">
                    <b-overlay :show="isPinChecking" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelPinModal">Отмена</b-button>
                    </b-overlay>
                    <b-overlay :show="isPinChecking" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="!pin" class="text-uppercase" variant="custom-blue" @click="okPinModal">OK</b-button>
                    </b-overlay>
                </template>
            </b-modal>
        </div>
        <RutokenErrorModal :rutoken-error="rutokenError" :show="rutokenModalShow" @cancel="rutokenModalShow = false" />
        <SelectCertificateModal v-model="selectCertificate" :rutoken="rutoken" :show="showSelectCertificate" :v="$v.selectCertificate" @hideModal="(value) => changeShowSelectCertificate(value)" @save="(value) => makeXml(value)" />
        <system-preloader v-if="loading"></system-preloader>
    </div>
</template>

<script>
import DownloadFilesApi from '@/services/api/DownloadFiles';
import TextHeader from '@/components/common/form-rows/text-header';
import TextRow from '@/components/common/form-rows/text-row';
import TextRowPrice from '@/components/common/form-rows/text-row-price';
import TextRowDatetime from '@/components/common/form-rows/text-row-datetime';
import TextRowDocuments from '@/components/common/form-rows/text-row-documents';
import { maxValue, minLength, minValue, required, requiredIf, sameAs } from 'vuelidate/lib/validators';
import FormRowSingleLineDocumentUpload from '@/components/common/form-rows/form-row-single-line-document-upload';
import FormRowEditNumber from '@/components/common/form-rows/form-row-edit-number.vue';
import SelectedCertificateInformation from '@/components/auth/selected-certificate-information.vue';
import RutokenService from '@/services/rutoken/RutokenService';
import ProposalDocuments from '@/components/purchases/parts/proposal-documents.vue';
import CustomerInfo from '@/components/purchases/parts/customer-info.vue';
import SupplierRequirements from '@/components/purchases/parts/supplier-requirements.vue';
import SelectCertificateModal from '@/components/purchases/parts/select-certificate-modal.vue';
import RutokenErrorModal from '@/components/purchases/parts/rutoken-error-modal.vue';

export default {
    name: 'direct-purchase-proposal-create',
    components: {
        RutokenErrorModal,
        SelectCertificateModal,
        SupplierRequirements,
        CustomerInfo,
        SelectedCertificateInformation,
        FormRowEditNumber,
        FormRowSingleLineDocumentUpload,
        TextRowDocuments,
        TextRowDatetime,
        TextRowPrice,
        TextRow,
        TextHeader,
        ProposalDocuments,
    },
    data() {
        return {
            showSelectCertificate: false,
            isSigningXml: false,
            isDataSending: false,
            isSigningPdf: false,
            formXml: '',
            formPdf: null,
            formPdfSigned: null,
            isSignedXml: false,
            loading: true,
            showSignModal: false,
            showAttentionModal: false,
            showDepositModal: false,
            havingDeposit: false,
            neededDeposit: 0,
            needDep: 0,
            accounting: null,
            email: this.$store.state.user.email,
            visible: [],
            isProviderChecked: false,

            documents: [],
            purchase: {
                id: null,
                reg_number: '',
                additional_contact_info: '',
                customer: {
                    id: null,
                    name: '',
                    inn: '',
                    kpp: '',
                    ogrn: '',
                },
                terms_of_purchase: {
                    purchase_method: '',
                    purchase_category_name: '',
                    national_project_name: '',
                    order_type_name: '',
                    payment_type_name: '',
                    payment_term_name: '',
                },
                supplier_requirements: {
                    only_msp: false,
                    supplier_reqs_a: false,
                    supplier_reqs_b: false,
                    supplier_reqs_c: false,
                    supplier_required_documents: [],
                },
                terms_of_delivery: {
                    addresses: [],
                    delivery_information: '',
                    max_delivery_date: null,
                },
                deliverables: [],
                contract_project_document: null,
                additional_documents: [],
                max_total_cost: 0,
                fee_amount: null,
            },
            form: {
                existing_proposal: false,
                contact_name: '',
                deliverables: [],
                attachments: [],
                organization_card: null,
                supplier_requirements_confirmed: null,
            },
            proposalTotalCost: 0,
            isPurchaseLoading: true,
            rutoken: undefined,
            pin: null,
            signature: null,
            isPinChecking: false,
            rutokenError: null,
            isGettingXML: false,
            isCertificateChecking: false,
            selectCertificate: null,
            rutokenModalShow: false,
        };
    },
    validations() {
        return {
            totalSum: {
                required,
                minValue: minValue(0.01),
                maxValue: maxValue(this.purchase.starting_price),
            },
            specValid: {
                required,
            },
            form: {
                contact_name: {},
                proposal_validity_days: {
                    required: requiredIf(() => this.purchase.proposal_validity_days),
                    minValue: minValue(this.purchase.proposal_validity_days ?? 1),
                },
                organization_card: { required },
                supplier_requirements_confirmed: {
                    no_arrears: {
                        confirmation_document: {
                            required: requiredIf(() => this.purchase.supplier_requirements.has_no_arrears),
                        },
                    },
                    is_reliable_supplier: {
                        sameAs: sameAs(() => this.purchase.supplier_requirements.is_reliable_supplier),
                    },
                    additional_requirements: {
                        $each: {
                            confirmation_document: {
                                required,
                            },
                        },
                    },
                    similar_experience_amounts: {
                        confirmation_document: {
                            required: requiredIf(() => this.purchase.supplier_requirements.similar_experience_amounts > 0),
                        },
                    },
                },
                deliverables: {
                    required,
                    $each: {
                        required,
                        price_per_unit: {
                            required: requiredIf((item) => item.not_participate === false),
                            minValue: (value, row) => {
                                return row.not_participate ? true : value >= 1;
                            },
                        },
                    },
                },
            },
            formPdfSigned: {
                required,
            },
            pin: {
                required,
                minLength: minLength(1),
            },
        };
    },
    async mounted() {
        await this.fillData();
        this.loading = false;
    },
    methods: {
        async rutokenInit() {
            this.rutokenError = null;
            try {
                this.rutoken = await new RutokenService().initialize();
            } catch (error) {
                console.error(error);
                this.rutokenError = error;
                this.rutokenModalShow = true;
            } finally {
                if (!this.rutokenError && this.rutoken) {
                    this.showSelectCertificate = true;
                }
            }
        },
        changeShowSelectCertificate(value) {
            this.showSelectCertificate = value;
        },
        async getActualCertData() {
            try {
                return await this.rutoken.getCertificateModel(this.selectCertificate);
            } catch (e) {
                this.showRutokenError(e);
            }
        },
        recalculateTotalSum() {
            this.proposalTotalCost = this.totalSum;
        },
        cancelSignModalForPdf() {
            this.showSignModal = false;
            this.formPdfSigned = null;
            this.formPdf = null;
        },
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
            this.signature = null;
            this.pin = null;
        },
        async fillData() {
            const purchaseData = await this.$api.purchases.direct.getOfferCreateData(this.$route.params.id);
            this.form = purchaseData.proposal;
            this.purchase = purchaseData.purchase;
            this.validations = purchaseData.validations;
            const response = await this.$api.personal.documents.getList();
            this.documents = response;
            this.recalculateTotalSum();
        },
        getPurchaseDeliverable(id) {
            return this.purchase.deliverables.find((x) => x.id === id);
        },
        getPurchaseDeliverableTotal(id) {
            const deliverable = this.purchase.deliverables.find((x) => x.id === id);
            if (deliverable) {
                return deliverable.price_per_unit * deliverable.quantity;
            } else {
                return 0;
            }
        },
        getAdditionalRequirementTitle(id) {
            return this.purchase.supplier_requirements.additional_requirements.find((x) => x.id === id)?.title;
        },
        async askForPin() {
            this.pin = null;
            this.$bvModal.show('pin-input-modal');
        },
        cancelPinModal() {
            this.pin = null;
            this.$bvModal.hide('pin-input-modal');
        },
        async okPinModal() {
            const pinOk = await this.checkPin(this.selectCertificate, this.pin);
            if (!pinOk) {
                return;
            }
            this.$bvModal.hide('pin-input-modal');

            await this.signForm(this.pin);
        },
        async checkPin(certificate, pin) {
            try {
                this.isPinChecking = true;
                await this.rutoken.checkPin(certificate, pin);
                return true;
            } catch (err) {
                this.showRutokenError(err);
            } finally {
                this.isPinChecking = false;
            }
        },
        async signForm(pin) {
            this.isSigningXml = true;
            this.selectCertificate = await this.rutoken.refreshSelectedCertificateDeviceID(this.selectCertificate);
            this.signature = await this.rutoken.getDetachedSignature(this.selectCertificate, pin, this.formXml);
            this.isSigningXml = false;
        },
        async makeXml(value) {
            this.selectCertificate = value;
            try {
                this.isGettingXML = true;
                this.formXml = await this.$api.xmlGenerator.directProposal(this.$route.params.id, this.form);
                if (!this.formXml) {
                    return;
                }
                this.showSignModal = true;
            } catch (e) {
                this.showRutokenError(e);
            } finally {
                this.isGettingXML = false;
            }
        },
        async makePdf() {
            try {
                this.formPdf = await this.$api.pdfGenerator.directProposal(this.$route.params.id, this.form);
                this.showSignModal = true;
            } catch (e) {
                this.showRutokenError(e);
            }
        },
        async sendData() {
            if (!this.signature && this.isResidentKGZ) {
                return;
            }
            if (!this.formPdfSigned && !this.isResidentKGZ) {
                return;
            }
            this.isDataSending = true;
            let responseData;

            if (this.isResidentKGZ) {
                const certData = await this.rutoken.getCertificateModel(this.selectCertificate);

                responseData = await this.$api.purchases.direct.storeSignedProposal(this.$route.params.id, this.form, this.formXml, this.signature, certData);
            } else {
                responseData = await this.$api.purchases.direct.storeSignedByPdfProposal(this.$route.params.id, this.form, this.formPdfSigned.id);
            }

            this.isDataSending = false;
            if (responseData) {
                this.onCancel();
            }
        },
        sumTotalItem(id) {
            const purchaseDeliverable = this.purchase.deliverables.find((x) => x.id === id);
            const formDeliverable = this.form.deliverables.find((x) => x.id === id);

            return (purchaseDeliverable?.quantity ?? 0) * (formDeliverable?.price_per_unit ?? 0);
        },
        onCancel() {
            this.$router.push({ name: 'PersonalProposals' }).catch(() => {});
        },
        cancelOffer() {
            this.onCancel();
        },
        // проверка наличия средств на счету + предупреждение
        checkResources() {
            if (this.isResidentKGZ) {
                this.rutokenInit();
            } else {
                this.makePdf();
            }
        },
        downloadInvoice() {
            if (this.isValid) {
                DownloadFilesApi.downloadInvoice('/account/invoice/download', 'Invoice.pdf', { amount: this.neededDeposit });
            } else {
                this.pushToast({
                    text: 'Введите корректные данные',
                    title: 'Ошибка',
                    variant: 'danger',
                });
            }
        },
        sendInvoiceToEmail() {
            if (this.isValid) {
                let url = '/account/mail/sending';
                let data = { email: this.email, amount: this.neededDeposit };
                DownloadFilesApi.sendFileToEmail(url, data).then(() => {
                    this.pushToast({
                        text: 'Счет отправлен на Ваш email',
                        title: 'Успех',
                        variant: 'green',
                    });
                });
            } else {
                this.pushToast({
                    text: 'Введите корректные данные',
                    title: 'Ошибка',
                    variant: 'danger',
                });
            }
        },
    },
    computed: {
        specValid() {
            return !this.purchase.with_lots || this.form.deliverables.some((item) => item.not_participate === false);
        },
        supplierRequirementsConfirmationNeeded() {
            return (
                this.purchase.supplier_requirements.has_no_arrears ||
                this.purchase.supplier_requirements.is_reliable_supplier ||
                this.purchase.supplier_requirements.has_additional_requirements ||
                this.purchase.supplier_requirements.similar_experience_amounts > 0
            );
        },
        purchaseContractDraftAttachment() {
            return this.purchase.contract_draft_attachment ? [this.purchase.contract_draft_attachment] : [];
        },
        isValid() {
            return this.checkDepositValid && this.checkEmailValid;
        },
        checkDepositValid() {
            let re = /^\d{1,16}\.{0,1}\d{0,2}$/;
            return !isNaN(this.neededDeposit) && this.neededDeposit >= this.needDep && re.test(this.neededDeposit);
        },
        checkEmailValid() {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(this.email);
        },
        totalSum() {
            let sum = 0;
            this.purchase.deliverables.forEach(function (item) {
                const deliverable = this?.form?.deliverables.find((x) => x.id === item.id);
                sum += deliverable?.not_participate ? 0 : Math.round(deliverable?.price_per_unit * item.quantity);
            }, this);
            if (sum) {
                return sum;
            }
            return 0;
        },
        summLimit() {
            return this.totalSum <= parseFloat(this.purchase.starting_price);
        },
        notNullSpecsPrice() {
            return this.form.deliverables && this.form.deliverables.length > 0 ? this.form?.deliverables.every((item) => item.price_per_unit > 0) : false;
        },
        isUserCanSign() {
            return this.$store.getters.getCanSign;
        },
        isResidentKGZ() {
            return this.$store.getters.getOrganization.is_resident_kgz;
        },
    },
};
</script>

<style scoped>
.purchase-offer-header-block {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.head_register-list {
    color: var(--halfWhite-color);
}
</style>

<style scoped>
/*a {*/
/*    color: var(--est-blue-2) !important;*/
/*}*/

.main_layer {
    background-image: url('/images/purchases_head.svg');
    min-height: 250px;
}

.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>

<style>
.new_offer .is-valid {
    border-color: rgb(206, 212, 218) !important;
    background: #fff !important;
}
</style>
