var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? _c(
            "div",
            [
              _c(
                "b-container",
                {
                  staticClass: "purchase-offer-header-block",
                  attrs: { fluid: "" },
                },
                [
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "10" } }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "fs-40 fw-600 white-color mt-3 mb-3",
                              },
                              [
                                _vm._v(
                                  "Простая закупка № " +
                                    _vm._s(_vm.purchase.registration_number)
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "b-col",
                            {
                              staticClass: "text-right my-auto",
                              attrs: { md: "2" },
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn-custom-danger",
                                  attrs: {
                                    href: _vm.$links.manual
                                      .simple_proposal_create,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("Инструкция")]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c("p", { staticClass: "white-color fs-14" }, [
                              _vm._v("Форма подачи заявки"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-container",
                { staticClass: "my-2", attrs: { fluid: "" } },
                [
                  _c(
                    "b-container",
                    [
                      _c("CustomerInfo", {
                        attrs: {
                          "additional-info":
                            _vm.purchase.additional_contact_info,
                          customer: _vm.purchase.customer,
                          "additional-label":
                            "Дополнительная контактная информация",
                        },
                      }),
                      _c("text-header", [_vm._v("Условия закупки")]),
                      _vm.purchase.name
                        ? _c("text-row", {
                            attrs: { label: "Наименование закупки" },
                            model: {
                              value: _vm.purchase.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.purchase, "name", $$v)
                              },
                              expression: "purchase.name",
                            },
                          })
                        : _vm._e(),
                      _c("text-row", {
                        attrs: { label: "Предмет закупки" },
                        model: {
                          value: _vm.purchase.deliverable_group_title,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "deliverable_group_title",
                              $$v
                            )
                          },
                          expression: "purchase.deliverable_group_title",
                        },
                      }),
                      _c("text-row-price", {
                        attrs: { label: "Сумма закупки" },
                        model: {
                          value: _vm.purchase.starting_price,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "starting_price", $$v)
                          },
                          expression: "purchase.starting_price",
                        },
                      }),
                      _c("text-row", {
                        attrs: { label: "Валюта" },
                        model: {
                          value: _vm.purchase.currency_title,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "currency_title", $$v)
                          },
                          expression: "purchase.currency_title",
                        },
                      }),
                      _c("text-row-datetime", {
                        attrs: { label: "Дата и время размещения закупки" },
                        model: {
                          value: _vm.purchase.purchase_start_datetime,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.purchase,
                              "purchase_start_datetime",
                              $$v
                            )
                          },
                          expression: "purchase.purchase_start_datetime",
                        },
                      }),
                      _c("text-row-datetime", {
                        attrs: {
                          label: "Дата и время окончания приема предложений",
                        },
                        model: {
                          value: _vm.purchase.purchase_end_datetime,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "purchase_end_datetime", $$v)
                          },
                          expression: "purchase.purchase_end_datetime",
                        },
                      }),
                      _vm.purchase.contract_draft_attachment
                        ? _c("text-row-documents", {
                            attrs: { label: "Проект контракта" },
                            model: {
                              value: _vm.purchaseContractDraftAttachment,
                              callback: function ($$v) {
                                _vm.purchaseContractDraftAttachment = $$v
                              },
                              expression: "purchaseContractDraftAttachment",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.notice_supplementary_attachments.length > 0
                        ? _c("text-row-documents", {
                            attrs: { label: "Дополнительные документы" },
                            model: {
                              value:
                                _vm.purchase.notice_supplementary_attachments,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.purchase,
                                  "notice_supplementary_attachments",
                                  $$v
                                )
                              },
                              expression:
                                "purchase.notice_supplementary_attachments",
                            },
                          })
                        : _vm._e(),
                      _c("SupplierRequirements", {
                        model: {
                          value: _vm.$data,
                          callback: function ($$v) {
                            _vm.$data = $$v
                          },
                          expression: "$data",
                        },
                      }),
                      _c("text-header", [_vm._v("Информация об организации")]),
                      _c("form-row-edit-text", {
                        attrs: {
                          v: _vm.$v.form.contact_name,
                          label: "Контактное лицо поставщика",
                        },
                        model: {
                          value: _vm.form.contact_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "contact_name", $$v)
                          },
                          expression: "form.contact_name",
                        },
                      }),
                      _c("form-row-single-line-document-upload", {
                        attrs: {
                          "label-cols-lg": 6,
                          "label-cols-sm": 6,
                          v: _vm.$v.form.organization_card,
                          title: "Карточка предприятия",
                        },
                        model: {
                          value: _vm.form.organization_card,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "organization_card", $$v)
                          },
                          expression: "form.organization_card",
                        },
                      }),
                      _c("text-header", [_vm._v("Спецификация")]),
                      _vm.purchase.fee_amount && !_vm.form.existing_proposal
                        ? _c(
                            "p",
                            { staticClass: "font-weight-bold grey-color" },
                            [
                              _vm._v(
                                " При подаче заявки на лицевом счету Вашей организации, в соответствии с регламентом, будет заблокирована сумма " +
                                  _vm._s(
                                    _vm.$formatPrice(_vm.purchase.fee_amount)
                                  ) +
                                  " сом. "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-row",
                        { staticClass: "my-2", attrs: { "no-gutters": "" } },
                        [
                          _c("b-col", { staticClass: "table-responsive" }, [
                            _c("table", { staticClass: "table" }, [
                              _c("thead", [
                                _c("tr", [
                                  _c("th", { staticClass: "fw-600" }, [
                                    _vm._v("№"),
                                  ]),
                                  _c("th", { staticClass: "fw-600" }, [
                                    _vm._v(
                                      "Наименование товара (работ, услуг)"
                                    ),
                                  ]),
                                  _c("th", { staticClass: "fw-600" }, [
                                    _vm._v("Характеристики"),
                                  ]),
                                  _c("th", { staticClass: "fw-600" }, [
                                    _vm._v("Количество"),
                                  ]),
                                  _c("th", { staticClass: "fw-600" }, [
                                    _vm._v("Единица"),
                                  ]),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "fw-600",
                                      staticStyle: { "min-width": "100px" },
                                    },
                                    [_vm._v("Объявление, Цена за единицу")]
                                  ),
                                  _c("th", { staticClass: "fw-600" }, [
                                    _vm._v("Объявление, Стоимость"),
                                  ]),
                                  _c(
                                    "th",
                                    {
                                      staticClass: "fw-600",
                                      staticStyle: { "min-width": "100px" },
                                    },
                                    [_vm._v("Заявка, Цена за единицу")]
                                  ),
                                  _c("th", { staticClass: "fw-600" }, [
                                    _vm._v("Валюта"),
                                  ]),
                                  _c("th", { staticClass: "fw-600" }, [
                                    _vm._v("Заявка, Стоимость"),
                                  ]),
                                ]),
                              ]),
                              _c(
                                "tbody",
                                [
                                  _vm._l(
                                    _vm.form.deliverables,
                                    function (item, index) {
                                      return _c("tr", { key: item.id }, [
                                        _c(
                                          "td",
                                          { staticClass: "align-text-top" },
                                          [_vm._v(_vm._s(index + 1))]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-text-top" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getPurchaseDeliverable(
                                                  item.id
                                                ).name
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-text-top" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getPurchaseDeliverable(
                                                  item.id
                                                ).characteristics
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-text-top" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getPurchaseDeliverable(
                                                  item.id
                                                ).quantity
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          {
                                            staticClass:
                                              "text-lowercase align-text-top",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.getPurchaseDeliverable(
                                                    item.id
                                                  ).okei.local_symbol
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-text-top" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$formatPrice(
                                                  _vm.getPurchaseDeliverable(
                                                    item.id
                                                  ).price_per_unit
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-text-top" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$formatPrice(
                                                  _vm.getPurchaseDeliverableTotal(
                                                    item.id
                                                  )
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-text-top" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex align-items-center",
                                              },
                                              [
                                                _c("currency-input", {
                                                  directives: [
                                                    {
                                                      name: "stop-number-mousewheel",
                                                      rawName:
                                                        "v-stop-number-mousewheel",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "form-control fs-14",
                                                  class:
                                                    item.price_per_unit > 0 &&
                                                    _vm.summLimit
                                                      ? "is-valid"
                                                      : "is-invalid",
                                                  attrs: {
                                                    "allow-negative": false,
                                                    currency: null,
                                                    locale: "ru-KG",
                                                    "value-as-integer": "",
                                                  },
                                                  on: {
                                                    input:
                                                      _vm.recalculateTotalSum,
                                                  },
                                                  model: {
                                                    value: item.price_per_unit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "price_per_unit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.price_per_unit",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-text-top" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.purchase.currency_title
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "td",
                                          { staticClass: "align-text-top" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$formatPrice(
                                                  _vm.sumTotalItem(item.id)
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    }
                                  ),
                                  !_vm.summLimit
                                    ? _c("tr", [
                                        _c(
                                          "td",
                                          {
                                            staticClass: "py-2 border-0",
                                            attrs: {
                                              align: "right",
                                              colspan: "10",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-danger is-invalid",
                                              },
                                              [
                                                _vm._v(
                                                  "Превышена максимально допустимая стоимость заявки (" +
                                                    _vm._s(
                                                      _vm.$formatPrice(
                                                        _vm.purchase
                                                          .starting_price
                                                      )
                                                    ) +
                                                    " сом), проверьте заполненные данные"
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("tr", [
                                    _c(
                                      "td",
                                      {
                                        staticClass: "py-2 border-0",
                                        attrs: {
                                          align: "right",
                                          colspan: "10",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "fs-18 fw-600" },
                                          [
                                            _vm._v(
                                              "Стоимость спецификации: " +
                                                _vm._s(
                                                  _vm.$formatPrice(_vm.totalSum)
                                                ) +
                                                " сом"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c("proposal-documents", {
                        attrs: {
                          documents: _vm.documents,
                          v: _vm.$v.form.$model,
                        },
                      }),
                      _c("text-header", [
                        _vm._v("Подтверждение требований к поставщикам"),
                      ]),
                      _c("form-row-edit-number", {
                        attrs: {
                          v: _vm.$v.form.proposal_validity_days,
                          label:
                            "Срок действия предложения от даты окончания приема заявок, дней",
                        },
                        model: {
                          value: _vm.form.proposal_validity_days,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "proposal_validity_days", $$v)
                          },
                          expression: "form.proposal_validity_days",
                        },
                      }),
                      _vm.purchase.supplier_requirements.has_no_arrears
                        ? _c("form-row-single-line-document-upload", {
                            attrs: {
                              "label-cols-lg": 6,
                              "label-cols-sm": 6,
                              v: _vm.$v.form.supplier_requirements_confirmed
                                .no_arrears.confirmation_document,
                              title:
                                "Документ с подтверждением отсутствия задолженности по налогам и сборам",
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements_confirmed
                                  .no_arrears.confirmation_document,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements_confirmed
                                    .no_arrears,
                                  "confirmation_document",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements_confirmed.no_arrears.confirmation_document",
                            },
                          })
                        : _vm._e(),
                      _vm.purchase.supplier_requirements.is_reliable_supplier
                        ? _c(
                            "b-form-group",
                            {
                              attrs: {
                                state:
                                  !_vm.$v.form.supplier_requirements_confirmed
                                    .is_reliable_supplier.$invalid,
                                label:
                                  "Подтверждаю, что не присутствую в реестре недобросовестных поставщиков",
                                "label-class":
                                  "fs-14 grey-color fw-700 d-flex align-items-baseline",
                                "label-cols-lg": "11",
                                "label-cols-sm": "11",
                              },
                            },
                            [
                              _c("b-form-checkbox", {
                                staticClass: "fs-14 grey-color mt-2 text-right",
                                attrs: {
                                  state:
                                    !_vm.$v.form.supplier_requirements_confirmed
                                      .is_reliable_supplier.$invalid,
                                },
                                model: {
                                  value:
                                    _vm.form.supplier_requirements_confirmed
                                      .is_reliable_supplier,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.supplier_requirements_confirmed,
                                      "is_reliable_supplier",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.supplier_requirements_confirmed.is_reliable_supplier",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.purchase.supplier_requirements
                        .has_additional_requirements
                        ? _c("div", { staticClass: "form-row" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "p",
                                { staticClass: "fs-14 grey-color mt-2" },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      "Подтверждаю соответствие дополнительным требованиям, приложив следующие документы:"
                                    ),
                                  ]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.purchase.supplier_requirements
                        .has_additional_requirements
                        ? _vm._l(
                            _vm.$v.form.supplier_requirements_confirmed
                              .additional_requirements.$each.$iter,
                            function (item, index) {
                              return _c(
                                "form-row-single-line-document-upload",
                                {
                                  key: index,
                                  attrs: {
                                    title: _vm.getAdditionalRequirementTitle(
                                      item.$model.additional_requirement_id
                                    ),
                                    v: item,
                                  },
                                  model: {
                                    value: item.$model.confirmation_document,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        item.$model,
                                        "confirmation_document",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "item.$model.confirmation_document",
                                  },
                                }
                              )
                            }
                          )
                        : _vm._e(),
                      _vm.purchase.supplier_requirements
                        .similar_experience_amounts > 0
                        ? _c("form-row-single-line-document-upload", {
                            attrs: {
                              "label-cols-lg": 6,
                              "label-cols-sm": 6,
                              title:
                                "Документ с подтверждением опыта аналогичных поставок на сумму не менее " +
                                _vm.$formatPrice(
                                  _vm.purchase.supplier_requirements
                                    .similar_experience_amounts
                                ) +
                                " сом",
                              v: _vm.$v.form.supplier_requirements_confirmed
                                .similar_experience_amounts
                                .confirmation_document,
                            },
                            model: {
                              value:
                                _vm.form.supplier_requirements_confirmed
                                  .similar_experience_amounts
                                  .confirmation_document,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.supplier_requirements_confirmed
                                    .similar_experience_amounts,
                                  "confirmation_document",
                                  $$v
                                )
                              },
                              expression:
                                "form.supplier_requirements_confirmed.similar_experience_amounts.confirmation_document",
                            },
                          })
                        : _vm._e(),
                      _c(
                        "b-row",
                        { staticClass: "my-5" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "mx-2",
                                  attrs: {
                                    variant: "custom-outline-secondary",
                                  },
                                  on: { click: _vm.cancelOffer },
                                },
                                [_vm._v("ОТМЕНИТЬ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  staticClass: "mx-2",
                                  attrs: {
                                    disabled:
                                      _vm.$v.form.$invalid ||
                                      _vm.$v.proposalTotalCost.$invalid,
                                    variant: "custom-blue",
                                  },
                                  on: { click: _vm.checkResources },
                                },
                                [_vm._v("ПОДАТЬ ЗАЯВКУ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.isResidentKGZ
                ? _c(
                    "b-modal",
                    {
                      ref: "sign-form-modal",
                      attrs: {
                        centered: "",
                        "hide-header-close": "",
                        "no-close-on-backdrop": "",
                        "no-close-on-esc": "",
                        size: "lg",
                        title: "Подписать заявку",
                      },
                      model: {
                        value: _vm.showSignModal,
                        callback: function ($$v) {
                          _vm.showSignModal = $$v
                        },
                        expression: "showSignModal",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-block text-left" },
                        [
                          _c(
                            "b-tabs",
                            { attrs: { "content-class": "mt-3" } },
                            [
                              _c(
                                "b-tab",
                                { attrs: { active: "" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "system_link-style fs-16",
                                      },
                                      [_vm._v("Форма")]
                                    ),
                                  ]),
                                  _c("text-row", {
                                    attrs: { label: "Закупочная сессия" },
                                    model: {
                                      value: _vm.purchase.reg_number,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.purchase,
                                          "reg_number",
                                          $$v
                                        )
                                      },
                                      expression: "purchase.reg_number",
                                    },
                                  }),
                                  _c("text-row-price", {
                                    attrs: {
                                      label: "Итоговая стоимость заявки",
                                    },
                                    model: {
                                      value: _vm.proposalTotalCost,
                                      callback: function ($$v) {
                                        _vm.proposalTotalCost = $$v
                                      },
                                      expression: "proposalTotalCost",
                                    },
                                  }),
                                ],
                                2
                              ),
                              _c(
                                "b-tab",
                                [
                                  _c("template", { slot: "title" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "system_link-style fs-16",
                                      },
                                      [_vm._v("Xml представление")]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "px-3" }, [
                                    _c(
                                      "pre",
                                      {
                                        staticClass:
                                          "fs-10 grey-color p-3 greyBg m-0",
                                      },
                                      [_vm._v(_vm._s(_vm.formXml))]
                                    ),
                                  ]),
                                ],
                                2
                              ),
                              _vm.signature
                                ? _c(
                                    "b-tab",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "system_link-style fs-16",
                                          },
                                          [_vm._v("Подпись")]
                                        ),
                                      ]),
                                      _c("div", { staticClass: "px-3" }, [
                                        _c(
                                          "pre",
                                          {
                                            staticClass:
                                              "fs-10 grey-color p-3 greyBg m-0",
                                          },
                                          [_vm._v(_vm._s(_vm.signature))]
                                        ),
                                      ]),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "w-100",
                          attrs: { slot: "modal-footer" },
                          slot: "modal-footer",
                        },
                        [
                          _c(
                            "b-overlay",
                            {
                              staticClass: "d-inline-block",
                              attrs: {
                                show: _vm.isSigningXml || _vm.isDataSending,
                                opacity: "0.6",
                                rounded: "",
                                "spinner-small": "",
                                "spinner-variant": "primary",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase mr-2",
                                  attrs: {
                                    disabled: _vm.isDataSending,
                                    variant: "custom-outline-secondary",
                                  },
                                  on: { click: _vm.cancelSignModal },
                                },
                                [_vm._v("ОТМЕНИТЬ")]
                              ),
                            ],
                            1
                          ),
                          !_vm.signature
                            ? _c(
                                "b-overlay",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: {
                                    show: _vm.isSigningXml,
                                    opacity: "0.6",
                                    rounded: "",
                                    "spinner-small": "",
                                    "spinner-variant": "primary",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        disabled:
                                          !_vm.selectCertificate ||
                                          !_vm.summLimit,
                                        variant: "custom-blue",
                                      },
                                      on: { click: _vm.askForPin },
                                    },
                                    [_vm._v("Подписать")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.signature
                            ? _c(
                                "b-overlay",
                                {
                                  staticClass: "d-inline-block",
                                  attrs: {
                                    show: _vm.isDataSending,
                                    opacity: "0.6",
                                    rounded: "",
                                    "spinner-small": "",
                                    "spinner-variant": "primary",
                                  },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "text-uppercase",
                                      attrs: {
                                        disabled:
                                          !_vm.selectCertificate ||
                                          !_vm.summLimit,
                                        variant: "custom-blue",
                                      },
                                      on: { click: _vm.sendData },
                                    },
                                    [_vm._v("Отправить")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              !_vm.isResidentKGZ
                ? _c(
                    "b-modal",
                    {
                      ref: "sign-form-modal",
                      attrs: {
                        centered: "",
                        "header-class": "pb-0",
                        "no-close-on-backdrop": "",
                        "no-close-on-esc": "",
                        size: "lg",
                        title: "Подача заявки",
                      },
                      on: { close: _vm.cancelSignModalForPdf },
                      model: {
                        value: _vm.showSignModal,
                        callback: function ($$v) {
                          _vm.showSignModal = $$v
                        },
                        expression: "showSignModal",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-block text-left" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                "label-cols-lg": 4,
                                "label-cols-sm": 4,
                                label: "Загрузите скан подписанной заявки",
                                "label-class":
                                  "fs-14 grey-color fw-700 d-flex align-items-baseline",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "text-uppercase float-right mr-3",
                                  attrs: {
                                    href: _vm.$getAttachmentDownloadLink(
                                      _vm.formPdf?.id
                                    ),
                                    size: "sm",
                                    target: "_blank",
                                    variant: "custom-blue",
                                  },
                                },
                                [_vm._v("СКАЧАТЬ ДОКУМЕНТ ДЛЯ ПОДПИСИ")]
                              ),
                            ],
                            1
                          ),
                          _c("form-row-single-line-document-upload", {
                            attrs: {
                              "label-cols-lg": 4,
                              "label-cols-sm": 4,
                              v: _vm.$v.formPdfSigned,
                              title: "Подписанный документ",
                            },
                            model: {
                              value: _vm.formPdfSigned,
                              callback: function ($$v) {
                                _vm.formPdfSigned = $$v
                              },
                              expression: "formPdfSigned",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "w-100",
                          attrs: { slot: "modal-footer" },
                          slot: "modal-footer",
                        },
                        [
                          _c(
                            "b-overlay",
                            {
                              staticClass: "d-inline-block",
                              attrs: {
                                show: _vm.isDataSending,
                                opacity: "0.6",
                                rounded: "",
                                "spinner-small": "",
                                "spinner-variant": "primary",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase mr-2",
                                  attrs: {
                                    disabled: _vm.isDataSending,
                                    variant: "custom-outline-secondary",
                                  },
                                  on: { click: _vm.cancelSignModalForPdf },
                                },
                                [_vm._v("ОТМЕНИТЬ")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-overlay",
                            {
                              staticClass: "d-inline-block",
                              attrs: {
                                show: _vm.isDataSending,
                                opacity: "0.6",
                                rounded: "",
                                "spinner-small": "",
                                "spinner-variant": "primary",
                              },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-uppercase",
                                  attrs: {
                                    disabled:
                                      _vm.$v.formPdfSigned.$invalid ||
                                      !_vm.summLimit,
                                    variant: "custom-blue",
                                  },
                                  on: { click: _vm.sendData },
                                },
                                [_vm._v("ПОДТВЕРДИТЬ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "pin-input-modal",
                    centered: "",
                    "hide-header-close": "",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                    title: "Введите PIN-код",
                  },
                },
                [
                  _c("selected-certificate-information", {
                    attrs: { certificate: _vm.selectCertificate },
                  }),
                  _c("form-row-edit-text", {
                    attrs: { v: _vm.$v.pin, label: "PIN", type: "password" },
                    model: {
                      value: _vm.pin,
                      callback: function ($$v) {
                        _vm.pin = $$v
                      },
                      expression: "pin",
                    },
                  }),
                  _c(
                    "template",
                    { slot: "modal-footer" },
                    [
                      _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isPinChecking,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2 text-uppercase",
                              attrs: { variant: "custom-outline-secondary" },
                              on: { click: _vm.cancelPinModal },
                            },
                            [_vm._v("Отмена")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isPinChecking,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: !_vm.pin,
                                variant: "custom-blue",
                              },
                              on: { click: _vm.okPinModal },
                            },
                            [_vm._v("OK")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c("RutokenErrorModal", {
        attrs: {
          "rutoken-error": _vm.rutokenError,
          show: _vm.rutokenModalShow,
        },
        on: {
          cancel: function ($event) {
            _vm.rutokenModalShow = false
          },
        },
      }),
      _c("SelectCertificateModal", {
        attrs: {
          rutoken: _vm.rutoken,
          show: _vm.showSelectCertificate,
          v: _vm.$v.selectCertificate,
        },
        on: {
          hideModal: (value) => _vm.changeShowSelectCertificate(value),
          save: (value) => _vm.makeXml(value),
        },
        model: {
          value: _vm.selectCertificate,
          callback: function ($$v) {
            _vm.selectCertificate = $$v
          },
          expression: "selectCertificate",
        },
      }),
      _vm.loading ? _c("system-preloader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }