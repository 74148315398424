var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.protocol
    ? _c(
        "b-container",
        { staticClass: "my-2", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c("text-header", [_vm._v("Протокол процесса закупки")]),
              _c("text-row", {
                attrs: {
                  label: "Регистрационный номер",
                  value: _vm.protocol.reg_number,
                },
              }),
              _c("text-row", {
                attrs: { label: "Состав комиссии", value: " " },
              }),
              _c(
                "b-row",
                { staticClass: "my-2", attrs: { "no-gutters": "" } },
                [
                  _c("b-col", { staticClass: "table-responsive" }, [
                    _c("table", { staticClass: "table" }, [
                      _c("thead", [
                        _c("tr", [
                          _c("th", [_vm._v("ФИО")]),
                          _c("th", [_vm._v("Должность")]),
                        ]),
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.protocol.commission, function (item, index) {
                          return _c(
                            "tr",
                            _vm._b({ key: index }, "tr", item, false),
                            [
                              _c("td", [_vm._v(_vm._s(item.name))]),
                              _c("td", [_vm._v(_vm._s(item.position))]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("text-row-document", {
                attrs: {
                  label:
                    "Подписанный членами комиссии протокол процесса закупки",
                },
                model: {
                  value: _vm.protocol.attachment,
                  callback: function ($$v) {
                    _vm.$set(_vm.protocol, "attachment", $$v)
                  },
                  expression: "protocol.attachment",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }