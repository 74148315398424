var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.$store.getters.isLoggedIn &&
      _vm.purchase.actions.customer.open_envelopes &&
      _vm.purchase.private_data &&
      _vm.purchase.private_data.envelopes_opening &&
      _vm.purchase.status.id === "summarizing"
        ? _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "10" } }, [
                        _c(
                          "h2",
                          { staticClass: "grey-color font-weight-bold my-3" },
                          [_vm._v("Процедура вскрытия конвертов")]
                        ),
                      ]),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right my-auto",
                          attrs: { md: "2" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-custom-danger",
                              attrs: {
                                href: _vm.$links.manual
                                  .competitive_purchase_envelopes_opening,
                                target: "_blank",
                              },
                            },
                            [_vm._v("Инструкция")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("text-row", {
                    attrs: { label: "Состав комиссии", value: " " },
                  }),
                  _c(
                    "b-row",
                    { staticClass: "my-2", attrs: { "no-gutters": "" } },
                    [
                      _c("b-col", { staticClass: "table-responsive" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("thead", [
                            _c("tr", [
                              _c("th", [_vm._v("ФИО")]),
                              _c("th", [_vm._v("Должность")]),
                            ]),
                          ]),
                          _c(
                            "tbody",
                            _vm._l(
                              _vm.purchase.private_data.envelopes_opening
                                .commission,
                              function (item, index) {
                                return _c(
                                  "tr",
                                  _vm._b({ key: index }, "tr", item, false),
                                  [
                                    _c("td", [_vm._v(_vm._s(item.name))]),
                                    _c("td", [_vm._v(_vm._s(item.position))]),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Шаблон протокола вскрытия конвертов:",
                        "label-class":
                          "fs-14 grey-color fw-700 d-flex align-middle",
                        "label-cols-lg": "3",
                        "label-cols-sm": "4",
                      },
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "fs-14 grey-color my-auto col-form-label",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-custom-blue",
                              attrs: {
                                href: _vm.purchase.private_data
                                  .envelopes_opening.protocol_template_link,
                              },
                            },
                            [_vm._v("Скачать")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _c("form-row-upload-single-document", {
                    attrs: {
                      v: _vm.$v.form.attachment,
                      "header-class": "fs-14 fw-700",
                      title:
                        "Подписанный членами комиссии протокол вскрытия конвертов",
                    },
                    model: {
                      value: _vm.form.attachment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "attachment", $$v)
                      },
                      expression: "form.attachment",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "w-100 text-right" },
                    [
                      _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isDataSending,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: _vm.$v.$invalid,
                                variant: "custom-danger",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.showConfirmModal = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "Отправить подписанный членами комиссии протокол вскрытия конвертов "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  ref: "publish-modal",
                  attrs: {
                    centered: "",
                    "hide-header-close": "",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                    size: "lg",
                    title: "Отправка подписанного протокола вскрытия конвертов",
                  },
                  model: {
                    value: _vm.showConfirmModal,
                    callback: function ($$v) {
                      _vm.showConfirmModal = $$v
                    },
                    expression: "showConfirmModal",
                  },
                },
                [
                  _c("div", { staticClass: "d-block text-left" }, [
                    _vm._v(
                      "Вы действительно хотите отправить подписанный членами комиссии протокол вскрытия конвертов?"
                    ),
                  ]),
                  _c("div", { staticClass: "d-block text-left" }, [
                    _vm._v(
                      "Данное действие является необратимым, отменить его будет невозможно!"
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "w-100 text-right",
                      attrs: { slot: "modal-footer" },
                      slot: "modal-footer",
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase mr-2",
                          attrs: { variant: "custom-outline-secondary" },
                          on: {
                            click: function ($event) {
                              _vm.showConfirmModal = false
                            },
                          },
                        },
                        [_vm._v("Отмена")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase",
                          attrs: { variant: "custom-danger" },
                          on: { click: _vm.confirmEnvelopesOpening },
                        },
                        [
                          _vm._v(
                            "Отправить подписанный членами комиссии протокол вскрытия конвертов "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.$store.getters.isLoggedIn &&
      _vm.purchase.private_data &&
      _vm.purchase.private_data.envelopes_opening &&
      !_vm.purchase.private_data.winner_selection &&
      _vm.purchase.private_data.proposals &&
      _vm.purchase.status.id === "summarizing"
        ? _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _vm.purchase.private_data.proposals &&
              _vm.purchase.private_data.proposals.length > 0
                ? _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "10" } }, [
                            _c(
                              "h2",
                              {
                                staticClass: "grey-color font-weight-bold my-3",
                              },
                              [_vm._v("Конкурсные заявки")]
                            ),
                          ]),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.purchase.private_data.proposals,
                        function (proposal, index) {
                          return _c("envelopes-opening-proposal-card", {
                            key: "proposal_" + index,
                            attrs: {
                              index: index,
                              proposal: proposal,
                              purchase: _vm.purchase,
                              "purchase-id": _vm.purchase.id,
                              "supplier-requirements":
                                _vm.purchase.supplier_requirements,
                            },
                          })
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.purchase.private_data.proposals &&
              _vm.purchase.private_data.proposals.length === 0
                ? _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { attrs: { md: "10" } }, [
                            _c(
                              "h2",
                              {
                                staticClass: "grey-color font-weight-bold my-3",
                              },
                              [_vm._v("Конкурсные заявки отсутствуют")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }