var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("text-header", { attrs: { "margin-class": "mb-3" } }, [
        _vm._v("Заявки"),
      ]),
      _vm._l(_vm.proposals, function (proposal) {
        return _c(
          "div",
          { key: proposal.product.id + "_" + proposal.id },
          [
            _c("personal-proposal", {
              attrs: { proposal: proposal },
              on: { refresh: _vm.refresh },
            }),
          ],
          1
        )
      }),
      !_vm.proposals.length && !_vm.loading ? _c("empty-request") : _vm._e(),
      _vm.proposals
        ? _c("b-pagination", {
            staticClass: "mb-3",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            on: {
              input: function ($event) {
                return _vm.getPurchases()
              },
            },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }