var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading ? _c("system-preloader") : _vm._e(),
      !_vm.isLoading
        ? _c(
            "div",
            [
              _c("text-header", { attrs: { "margin-class": "mb-3" } }, [
                _vm._v("Создание запроса на возврат средств"),
              ]),
              _c("form-row-single-select", {
                attrs: {
                  options: _vm.currencies,
                  v: _vm.$v.form.currency,
                  label: "Выберите валюту для возврата денежных средств",
                  "label-field": "title",
                  placeholder: "Выберите валюту для возврата денежных средств",
                },
                model: {
                  value: _vm.form.currency,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "currency", $$v)
                  },
                  expression: "form.currency",
                },
              }),
              _c("text-row-price", {
                attrs: { label: "Доступно для возврата" },
                model: {
                  value: _vm.selectedCurrency.available,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedCurrency, "available", $$v)
                  },
                  expression: "selectedCurrency.available",
                },
              }),
              _c("form-row-edit-price", {
                attrs: {
                  limit: _vm.selectedCurrency.available,
                  v: _vm.$v.form.amount,
                  label: "Сумма возврата",
                },
                model: {
                  value: _vm.form.amount,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "amount", $$v)
                  },
                  expression: "form.amount",
                },
              }),
              _c("form-row-single-line-document-upload", {
                attrs: {
                  v: _vm.$v.form.document,
                  title: "Реквизиты для возврата средств",
                },
                model: {
                  value: _vm.form.document,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "document", $$v)
                  },
                  expression: "form.document",
                },
              }),
              _c("h5", { staticClass: "font-weight-bold my-3 text-danger" }, [
                _vm._v(
                  "Предупреждаем Вас о том, что с момента отправки запроса на возврат средств сумма, указанная в нем, перестанет быть доступной на лицевом счете Вашей организации."
                ),
              ]),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mx-2 fs-14",
                      attrs: { variant: "custom-outline-secondary" },
                      on: { click: _vm.onCancel },
                    },
                    [_vm._v("Отмена")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mx-2 fs-14",
                      attrs: {
                        disabled: _vm.$v.form.$invalid,
                        variant: "custom-green",
                      },
                      on: { click: _vm.sendData },
                    },
                    [_vm._v("СФОРМИРОВАТЬ И ОТПРАВИТЬ ЗАПРОС")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }