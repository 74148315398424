var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          ref: "certificate-modal",
          attrs: {
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
            title: "Выбор сертификата",
          },
          on: { show: _vm.showWindow },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        show: _vm.is_certificates_loading,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-small": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-1 text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v("Отмена")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-1 text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.refreshCertificates },
                        },
                        [_vm._v("Обновить список сертификатов")]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mx-1 text-uppercase",
                          attrs: {
                            disabled: _vm.$v.$invalid,
                            variant: "custom-blue",
                          },
                          on: { click: _vm.save },
                        },
                        [_vm._v("Продолжить")]
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "b-overlay",
            {
              attrs: {
                show: _vm.is_certificates_loading,
                opacity: "0.6",
                rounded: "",
                "spinner-variant": "primary",
              },
            },
            [
              _vm.rutokenError
                ? _c(
                    "b-container",
                    {
                      staticClass: "text-danger fs-18 fw-600 text-center my-5",
                    },
                    [
                      _c("p", [_vm._v(_vm._s(_vm.rutokenError.message))]),
                      _vm.rutokenError.code === 90097 ||
                      _vm.rutokenError.code === 90098
                        ? [
                            _c("p", [
                              _vm._v(
                                " Инструкция по установке Рутокен Плагин на сайте разработчика - "
                              ),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: "https://dev.rutoken.ru/pages/viewpage.action?pageId=72451796",
                                    target: "_blank",
                                  },
                                },
                                [_vm._v("ссылка")]
                              ),
                            ]),
                          ]
                        : _vm._e(),
                    ],
                    2
                  )
                : _c(
                    "b-form-group",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "multiselect",
                        {
                          attrs: {
                            "allow-clear": false,
                            "allow-empty": false,
                            "close-on-select": true,
                            disabled: _vm.is_certificates_loading,
                            multiple: false,
                            options: _vm.certificates,
                            "preserve-search": false,
                            "show-labels": false,
                            label: "text",
                            placeholder: "Выберите сертификат",
                            "track-by": "id",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "singleLabel",
                              fn: function (props) {
                                return [
                                  _c(
                                    "b-row",
                                    [
                                      props.option.subject.inn
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "py-1",
                                              attrs: { md: "6" },
                                            },
                                            [
                                              _c("strong", [_vm._v("ИНН")]),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.option.subject.inn
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      props.option.subject.organization_name
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "py-1",
                                              attrs: { md: "6" },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v("Организация"),
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.option.subject
                                                      .organization_name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      props.option.subject.full_name
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "py-1",
                                              attrs: { md: "6" },
                                            },
                                            [
                                              _c("strong", [_vm._v("ФИО")]),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.option.subject
                                                      .full_name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      props.option.subject.position
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "py-1",
                                              attrs: { md: "6" },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v("Должность"),
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.option.subject
                                                      .position
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "py-1",
                                          attrs: { md: "6" },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("SHA1 отпечаток"),
                                          ]),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.option.prepared_thumbprint
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "py-1",
                                          attrs: { md: "6" },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("Срок действия"),
                                          ]),
                                          _c("br"),
                                          _vm._v(
                                            " С " +
                                              _vm._s(
                                                _vm.getDateFormat(
                                                  props.option.valid.from
                                                )
                                              ) +
                                              " по " +
                                              _vm._s(
                                                _vm.getDateFormat(
                                                  props.option.valid.to
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { staticClass: "py-1" }, [
                                        _c("strong", [
                                          _vm._v("Серийный номер"),
                                        ]),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.option.prepared_serial
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "option",
                              fn: function (props) {
                                return [
                                  _c(
                                    "b-row",
                                    [
                                      props.option.subject.inn
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "py-1",
                                              attrs: { md: "6" },
                                            },
                                            [
                                              _c("strong", [_vm._v("ИНН")]),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.option.subject.inn
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      props.option.subject.organization_name
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "py-1",
                                              attrs: { md: "6" },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v("Организация"),
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.option.subject
                                                      .organization_name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      props.option.subject.full_name
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "py-1",
                                              attrs: { md: "6" },
                                            },
                                            [
                                              _c("strong", [_vm._v("ФИО")]),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.option.subject
                                                      .full_name
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      props.option.subject.position
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass: "py-1",
                                              attrs: { md: "6" },
                                            },
                                            [
                                              _c("strong", [
                                                _vm._v("Должность"),
                                              ]),
                                              _c("br"),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    props.option.subject
                                                      .position
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "py-1",
                                          attrs: { md: "6" },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("SHA1 отпечаток"),
                                          ]),
                                          _c("br"),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                props.option.prepared_thumbprint
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "py-1",
                                          attrs: { md: "6" },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v("Срок действия"),
                                          ]),
                                          _c("br"),
                                          _vm._v(
                                            " С " +
                                              _vm._s(
                                                _vm.getDateFormat(
                                                  props.option.valid.from
                                                )
                                              ) +
                                              " по " +
                                              _vm._s(
                                                _vm.getDateFormat(
                                                  props.option.valid.to
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", { staticClass: "py-1" }, [
                                        _c("strong", [
                                          _vm._v("Серийный номер"),
                                        ]),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              props.option.prepared_serial
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.selectCertificate,
                            callback: function ($$v) {
                              _vm.selectCertificate = $$v
                            },
                            expression: "selectCertificate",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "noOptions" }, slot: "noOptions" },
                            [_vm._v("Нет данных")]
                          ),
                          _c(
                            "span",
                            { attrs: { slot: "noResult" }, slot: "noResult" },
                            [_vm._v("По вашему запросу сертификаты не найдены")]
                          ),
                        ]
                      ),
                      !_vm.state
                        ? [
                            _c(
                              "b-form-invalid-feedback",
                              {
                                staticClass: "text-left",
                                attrs: { "force-show": "" },
                              },
                              [_vm._v(_vm._s(_vm.errorMessage))]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }