<template>
    <b-card-title>
        <b-row class="my-2">
            <b-col md="6" class="my-1">
                Статус: <strong>{{ protocol.status.title }}</strong>
            </b-col>
            <b-col md="6" class="my-1 text-right">
                Время формирования итогового протокола:<br />
                <strong>{{ getDateTimeFormat(protocol.created_at) }}</strong>
            </b-col>
        </b-row>
        <b-row v-if="protocol.winner_proposal">
            <b-col md="6" class="my-1">
                Поставщик:<br />
                <router-link :to="{ name: 'OrganizationShow', params: { id: protocol.winner_proposal.supplier.id } }" target="_blank" class="font-weight-bold">
                    {{ protocol.winner_proposal.supplier.name }}
                </router-link>
            </b-col>
            <b-col md="6" class="my-1 text-right">
                Стоимость заявки:<br />
                <strong>{{ $formatPrice(protocol.winner_proposal.price) }}</strong>
            </b-col>
        </b-row>
    </b-card-title>
</template>

<script>
export default {
    name: 'protocol-card-title',
    props: ['protocol'],
};
</script>

<style scoped>
a {
    color: var(--est-blue-2);
}
</style>
