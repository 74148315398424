var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "9" } }, [
            _c("h2", { staticClass: "grey-color font-weight-bold mb-3" }, [
              _vm._v("Пользователи организации"),
            ]),
          ]),
          _c(
            "b-col",
            { staticClass: "text-right my-auto", attrs: { md: "3" } },
            [
              _vm.userRolesPermitted([
                _vm.$userRoles.DIRECTOR,
                _vm.$userRoles.ADMINISTRATOR,
              ])
                ? _c(
                    "b-button",
                    {
                      attrs: {
                        to: { name: "UserCreate" },
                        variant: "custom-blue",
                      },
                    },
                    [_vm._v("Добавить пользователя")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-table", {
        attrs: { fields: _vm.tableFields, items: _vm.users },
        scopedSlots: _vm._u([
          {
            key: "cell(name)",
            fn: function (data) {
              return [
                !data.item.is_active
                  ? _c("font-awesome-icon", {
                      staticClass: "text-danger mx-1",
                      attrs: { icon: "lock", size: "xs" },
                    })
                  : _vm._e(),
                _vm._v(" " + _vm._s(data.value) + " "),
                data.item.is_current
                  ? _c("span", [_vm._v("(Текущий пользователь)")])
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(created_at)",
            fn: function (data) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      data.value ? _vm.getDateTimeFormat(data.value) : "—"
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "cell(actions)",
            fn: function (data) {
              return [
                !data.item.is_current &&
                _vm.userRolesPermitted([
                  _vm.$userRoles.DIRECTOR,
                  _vm.$userRoles.ADMINISTRATOR,
                ])
                  ? _c(
                      "router-link",
                      {
                        staticClass:
                          "btn btn-custom-outline-secondary-small my-1",
                        attrs: {
                          to: {
                            name: "UserEdit",
                            params: { id: data.item.id },
                          },
                        },
                      },
                      [_vm._v("Редактировать ")]
                    )
                  : _vm._e(),
                _c("br"),
                !data.item.is_current &&
                data.item.is_active &&
                _vm.userRolesPermitted([
                  _vm.$userRoles.DIRECTOR,
                  _vm.$userRoles.ADMINISTRATOR,
                ])
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "my-1",
                        attrs: { variant: "custom-danger-small" },
                        on: {
                          click: function ($event) {
                            return _vm.confirmBlock(
                              data.item.id,
                              data.item.name
                            )
                          },
                        },
                      },
                      [_vm._v("Блокировать ")]
                    )
                  : _vm._e(),
                !data.item.is_current &&
                !data.item.is_active &&
                _vm.userRolesPermitted([
                  _vm.$userRoles.DIRECTOR,
                  _vm.$userRoles.ADMINISTRATOR,
                ])
                  ? _c(
                      "b-btn",
                      {
                        staticClass: "my-1",
                        attrs: { variant: "custom-green-small" },
                        on: {
                          click: function ($event) {
                            return _vm.confirmUnblock(
                              data.item.id,
                              data.item.name
                            )
                          },
                        },
                      },
                      [_vm._v("Разблокировать ")]
                    )
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
      _vm.users
        ? _c("b-pagination", {
            staticClass: "mb-3",
            attrs: {
              "per-page": _vm.meta.per_page,
              "total-rows": _vm.meta.total,
              align: "center",
            },
            on: {
              input: function ($event) {
                return _vm.getUsers()
              },
            },
            model: {
              value: _vm.meta.current_page,
              callback: function ($$v) {
                _vm.$set(_vm.meta, "current_page", $$v)
              },
              expression: "meta.current_page",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }