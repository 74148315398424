<template>
    <div class="my-2">
        <b-row>
            <b-col>
                <div class="purchase_part">
                    <div class="purchase_part-header status_color_2">
                        <b-col>
                            <p v-if="!(purchase.status.id === 'draft' || purchase.status.id === 'signed')" class="purchase_part-header_number fw-600">
                                <router-link :to="{ name: 'DirectPurchaseShow', params: { id: purchase.id } }" class="white-system_link-style" target="_blank">{{ purchase.product.title }} - {{ purchase.registration_number }}</router-link>
                            </p>
                            <p v-else class="purchase_part-header_number fw-600">{{ purchase.product.title }} - {{ purchase.registration_number }}</p>
                        </b-col>
                        <b-col cols="auto">
                            <p class="purchase_part-header_label fw-600">
                                {{ purchase.status.title }}
                            </p>
                        </b-col>
                    </div>

                    <!--                    <div class="purchase_part-subheader">-->
                    <!--                        <b-col xs="12" sm="6" lg="3" class="d-flex align-items-center">-->
                    <!--                            <span class="fs-11 green-color"-->
                    <!--                                >Сумма закупки: <span class="fw-600 ws-nowrap">{{ $formatPrice(purchase.starting_price) }}</span></span-->
                    <!--                            >-->
                    <!--                        </b-col>-->
                    <!--                    </div>-->
                    <div class="purchase_part-body row">
                        <b-col class="purchase_part-body_col" md="4">
                            <div>
                                <div v-if="isPurchaseCreator(purchase)" class="my-2">
                                    <direct-purchase-note-edit-block :purchase="purchase" @refresh="refresh" />
                                </div>
                                <div class="my-2">
                                    <p class="fs-12 m-0 grey-color">На основании несостоявшегося конкурса:</p>
                                    <p class="fs-12 m-0 grey-color fw-600">
                                        <router-link :to="{ name: 'CompetitivePurchaseShow', params: { id: purchase.failed_purchase.id } }" class="system_link-style2" target="_blank"
                                            >{{ purchase.failed_purchase.registration_number }}
                                        </router-link>
                                    </p>
                                </div>
                                <div v-if="purchase.status.id === 'accepting'" class="my-2">
                                    <p class="fs-12 m-0 grey-color">До окончания приема заявок:</p>
                                    <p class="fs-12 m-0 grey-color fw-600 time_to_end">
                                        <timer-new v-if="!!purchase.purchase_end_datetime" :data="purchase.purchase_end_datetime"></timer-new>
                                    </p>
                                </div>
                                <div v-if="!!purchase.purchase_end_datetime" class="my-2">
                                    <p class="fs-12 m-0 grey-color">Планируемая дата и время вскрытия конвертов:</p>
                                    <p class="fw-600 fs-12 m-0 grey-color">{{ getDateTimeFormat(purchase.purchase_end_datetime) }}</p>
                                </div>
                                <div v-if="!(purchase.status.id === 'draft' || purchase.status.id === 'signed')" class="my-2">
                                    <p class="fs-12 m-0 grey-color">Количество поданных заявок:</p>
                                    <p class="fs-12 m-0 grey-color fw-600">{{ purchase.proposals_count }}</p>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="purchase_part-body_col" md="4">
                            <div>
                                <div v-if="purchase.name" class="my-2">
                                    <p class="fs-12 grey-color m-0">Наименование конкурса:</p>
                                    <p class="fs-12 green-color fw-600">{{ purchase.name }}</p>
                                </div>
                                <div class="my-2">
                                    <p class="fs-12 grey-color m-0">Предмет закупки:</p>
                                    <p class="fs-12 green-color fw-600">{{ purchase.deliverable_group_title }}</p>
                                </div>
                                <div class="my-2">
                                    <p class="fs-12 my-2 grey-color">
                                        Сумма закупки:
                                        <span class="fw-600 ws-nowrap">{{ $formatPrice(purchase.starting_price) }}</span>
                                    </p>
                                    <p class="fs-12 my-2 grey-color">
                                        Валюта:
                                        <span class="fw-600 ws-nowrap">{{ purchase.currency_title }}</span>
                                    </p>
                                </div>
                            </div>
                        </b-col>
                        <b-col class="purchase_part-body_col" md="4">
                            <div class="h-100">
                                <div>
                                    <router-link v-if="purchase.actions.customer.edit" :to="{ name: 'DirectPurchaseEdit', params: { id: purchase.id } }" class="btn btn-card-action btn-card-action-green my-1" tag="button">
                                        Продолжить редактирование
                                    </router-link>
                                    <b-btn v-if="purchase.actions.customer.publish" class="btn-card-action btn-card-action-green my-1" @click="showPublishModal = true"> Опубликовать</b-btn>
                                    <b-btn v-if="purchase.actions.customer.delete" class="btn-card-action btn-card-action-light my-1" style="font-size: 12px !important" @click="showDeleteModal = true">Удалить</b-btn>
                                    <router-link
                                        v-if="purchase.actions.customer.open_envelopes"
                                        :to="{ name: 'DirectPurchaseShow', params: { id: purchase.id }, hash: '#action_buttons_row' }"
                                        class="btn btn-card-action btn-card-action-green my-1"
                                        tag="button">
                                        Вскрыть конверты
                                    </router-link>
                                    <router-link
                                        v-if="purchase.actions.customer.select_winner"
                                        :to="{ name: 'DirectPurchaseShow', params: { id: purchase.id }, hash: '#action_buttons_row' }"
                                        class="btn btn-card-action btn-card-action-green my-1"
                                        tag="button">
                                        Подвести итоги
                                    </router-link>

                                    <cancel-direct-purchase-button :purchase="purchase" @cancel="refresh" />
                                </div>
                            </div>
                        </b-col>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-modal ref="delete-modal" v-model="showDeleteModal" centered hide-header-close no-close-on-backdrop no-close-on-esc title="Удалить закупку">
            <div class="d-block text-left">Вы действительно хотите удалить прямую закупку {{ purchase.registration_number }}?</div>
            <div slot="modal-footer" class="w-100 text-right">
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="isDataSending" class="text-uppercase mr-2" variant="custom-outline-secondary" @click="showDeleteModal = false">Отмена</b-button>&nbsp;
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="isDataSending" class="text-uppercase" variant="custom-danger" @click="deletePurchase">Удалить</b-button>
                </b-overlay>
            </div>
        </b-modal>
        <b-modal ref="publish-modal" v-model="showPublishModal" centered hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Опубликовать закупку">
            <div class="d-block text-left">Вы действительно хотите опубликовать прямую закупку {{ purchase.registration_number }}?</div>
            <div slot="modal-footer" class="w-100 text-">
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="isDataSending" class="text-uppercase mr-2" variant="custom-outline-secondary" @click="showPublishModal = false">Отмена</b-button>
                </b-overlay>
                <b-overlay :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                    <b-button :disabled="isDataSending" class="text-uppercase" variant="custom-blue" @click="publishPurchase">Опубликовать</b-button>
                </b-overlay>
            </div>
        </b-modal>
    </div>
</template>

<script>
import TimerNew from '@/components/elements/TimerNew.vue';
import DirectPurchaseNoteEditBlock from '@/components/cabinet/components/direct-purchase-note-edit-block.vue';
import CancelDirectPurchaseButton from '@/components/common/cancel-direct-purchase-button.vue';

export default {
    name: 'personal-direct-purchase',
    components: { CancelDirectPurchaseButton, DirectPurchaseNoteEditBlock, TimerNew },
    props: ['purchase'],
    data() {
        return {
            showDeleteModal: false,
            showPublishModal: false,
            isDataSending: false,
        };
    },
    methods: {
        async deletePurchase() {
            this.isDataSending = true;
            await this.$api.purchases.direct.delete(this.purchase.id);
            this.showDeleteModal = false;
            this.isDataSending = false;
            this.refresh();
        },
        async publishPurchase() {
            this.isDataSending = true;
            await this.$api.purchases.direct.publish(this.purchase.id);
            this.showPublishModal = false;
            this.isDataSending = false;
            this.refresh();
        },
        refresh() {
            this.$emit('refresh');
        },
    },
};
</script>

<style scoped>
.purchase_part {
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    border: 1px solid #d9d9de;
}

.purchase_part-subheader {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #e4e6e8;
    padding: 12px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-body {
    padding: 14px 26px;
}

.purchase_part-body_col + .purchase_part-body_col > div::before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    background-color: #d9d9de;
    left: 0px;
    top: 0px;
}

@media screen and (max-width: 767px) {
    .purchase_part-body {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .purchase_part-body_col > div {
        position: relative;
    }

    .purchase_part-body_col > div::before {
        content: '';
        width: 100% !important;
        height: 1px !important;
        top: -12px;
    }

    .hidden-mobile {
        display: none;
    }

    .time_to_end {
        font-size: 12px !important;
        margin-bottom: 0 !important;
        /*margin-left: 5px !important;*/
    }

    /*.purchase_status {*/
    /*    margin-left: 5px !important;*/
    /*}*/
}

@media screen and (max-width: 768px) {
    .purchase_part-body_col::before {
        content: '';
        display: none;
    }
}

@media screen and (max-width: 420px) {
    .purchase_part-header {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -moz-box-orient: vertical;
        -moz-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -moz-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        min-height: 65px;
    }
}
</style>
<style>
.draft.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: var(--est-blue-2);
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.draft .purchase_part-header_number {
    font-size: 14px;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.draft .purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}
</style>
<style scoped>
.purchase_part-header {
    -webkit-border-radius: 4px 4px 0 0;
    -moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
    background-color: #959ba4;
    padding: 9px 8px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.purchase_part-header_number {
    font-size: 14px !important;
    color: #fff;
    margin: 0;
    font-weight: 600;
}

.purchase_part-header_label {
    text-transform: uppercase;
    color: #fff;
    margin: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
}

.status_color_2,
.status_color_3,
.status_color_4 {
    background-color: var(--est-blue-2);
}

.status_color_7,
.status_color_8,
.status_color_5,
.status_color_6 {
    background-color: #f6b33a;
}
</style>
