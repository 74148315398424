<template>
    <div>
        <b-modal ref="rutoken-error-modal" v-model="showModal" hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Ошибка Рутокен">
            <b-container v-if="rutokenError" class="text-danger fs-18 fw-600 text-center my-5">
                <p>{{ rutokenError.message }}</p>
                <template v-if="rutokenError.code === 90097 || rutokenError.code === 90098">
                    <p>
                        Инструкция по установке Рутокен Плагин на сайте разработчика -
                        <a href="https://dev.rutoken.ru/pages/viewpage.action?pageId=72451796" target="_blank">ссылка</a>
                    </p>
                </template>
            </b-container>
            <template #modal-footer>
                <b-button class="mx-1 text-uppercase" variant="custom-outline-secondary" @click="cancel">Закрыть</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'rutoken-error-modal',
    props: {
        show: {
            type: Boolean,
            default: false,
        },
        rutokenError: {},
    },
    methods: {
        cancel() {
            this.$refs['rutoken-error-modal'].hide();
        },
    },
    computed: {
        showModal: {
            get() {
                return this.show;
            },
            set() {
                this.$emit('cancel');
            },
        },
    },
};
</script>
