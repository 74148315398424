var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-2" },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("div", { staticClass: "purchase_part" }, [
              _c(
                "div",
                { staticClass: "purchase_part-header status_color_2" },
                [
                  _c("b-col", [
                    !(
                      _vm.purchase.status.id === "draft" ||
                      _vm.purchase.status.id === "signed"
                    )
                      ? _c(
                          "p",
                          { staticClass: "purchase_part-header_number fw-600" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "white-system_link-style",
                                attrs: {
                                  to: {
                                    name: "SimplePurchaseShow",
                                    params: { id: _vm.purchase.id },
                                  },
                                  target: "_blank",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.purchase.product.title) +
                                    " - " +
                                    _vm._s(_vm.purchase.registration_number)
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "p",
                          { staticClass: "purchase_part-header_number fw-600" },
                          [
                            _vm._v(
                              _vm._s(_vm.purchase.product.title) +
                                " - " +
                                _vm._s(_vm.purchase.registration_number)
                            ),
                          ]
                        ),
                  ]),
                  _c("b-col", { attrs: { cols: "auto" } }, [
                    _c(
                      "p",
                      { staticClass: "purchase_part-header_label fw-600" },
                      [_vm._v(" " + _vm._s(_vm.purchase.status.title) + " ")]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "purchase_part-body row" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", [
                        _vm.isPurchaseCreator(_vm.purchase)
                          ? _c(
                              "div",
                              { staticClass: "my-2" },
                              [
                                _c("simple-purchase-note-edit-block", {
                                  attrs: { purchase: _vm.purchase },
                                  on: { refresh: _vm.refresh },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.purchase.status.id === "accepting"
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("До окончания приема заявок:"),
                              ]),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "fs-18 m-0 grey-color fw-600 time_to_end",
                                },
                                [
                                  !!_vm.purchase.purchase_end_datetime
                                    ? _c("timer-new", {
                                        attrs: {
                                          data: _vm.purchase
                                            .purchase_end_datetime,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        !(
                          _vm.purchase.status.id === "draft" ||
                          _vm.purchase.status.id === "signed"
                        )
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 m-0 grey-color" }, [
                                _vm._v("Количество поданных заявок:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fs-12 m-0 grey-color fw-600" },
                                [_vm._v(_vm._s(_vm.purchase.proposals_count))]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", [
                        _vm.purchase.name
                          ? _c("div", { staticClass: "my-2" }, [
                              _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                                _vm._v("Наименование конкурса:"),
                              ]),
                              _c(
                                "p",
                                { staticClass: "fs-12 green-color fw-600" },
                                [_vm._v(_vm._s(_vm.purchase.name))]
                              ),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 grey-color m-0" }, [
                            _vm._v("Предмет закупки:"),
                          ]),
                          _c("p", { staticClass: "fs-12 green-color fw-600" }, [
                            _vm._v(
                              _vm._s(_vm.purchase.deliverable_group_title)
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "my-2" }, [
                          _c("p", { staticClass: "fs-12 my-2 grey-color" }, [
                            _vm._v(" Сумма закупки: "),
                            _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatPrice(_vm.purchase.starting_price)
                                ) + " сом"
                              ),
                            ]),
                          ]),
                          _c("p", { staticClass: "fs-12 my-2 grey-color" }, [
                            _vm._v(" Валюта: "),
                            _c("span", { staticClass: "fw-600 ws-nowrap" }, [
                              _vm._v(_vm._s(_vm.purchase.currency_title)),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "purchase_part-body_col",
                      attrs: { md: "4" },
                    },
                    [
                      _c("div", { staticClass: "h-100" }, [
                        _c("div"),
                        _c(
                          "div",
                          [
                            _vm.purchase.actions.customer.edit
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass:
                                      "btn btn-card-action btn-card-action-green my-1",
                                    attrs: {
                                      to: {
                                        name: "SimplePurchaseEdit",
                                        params: { id: _vm.purchase.id },
                                      },
                                      tag: "button",
                                    },
                                  },
                                  [_vm._v(" Продолжить редактирование ")]
                                )
                              : _vm._e(),
                            _vm.purchase.actions.customer.publish
                              ? _c(
                                  "b-btn",
                                  {
                                    staticClass:
                                      "btn-card-action btn-card-action-green my-1",
                                    on: {
                                      click: function ($event) {
                                        _vm.showPublishModal = true
                                      },
                                    },
                                  },
                                  [_vm._v(" Опубликовать")]
                                )
                              : _vm._e(),
                            _vm.purchase.actions.customer.delete
                              ? _c(
                                  "b-btn",
                                  {
                                    staticClass:
                                      "btn-card-action btn-card-action-light my-1",
                                    staticStyle: {
                                      "font-size": "12px !important",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.showDeleteModal = true
                                      },
                                    },
                                  },
                                  [_vm._v("Удалить")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "delete-modal",
          attrs: {
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: "Удалить закупку",
          },
          model: {
            value: _vm.showDeleteModal,
            callback: function ($$v) {
              _vm.showDeleteModal = $$v
            },
            expression: "showDeleteModal",
          },
        },
        [
          _c("div", { staticClass: "d-block text-left" }, [
            _vm._v(
              "Вы действительно хотите удалить простую закупку " +
                _vm._s(_vm.purchase.registration_number) +
                "?"
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100 text-right",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: {
                        disabled: _vm.isDataSending,
                        variant: "custom-outline-secondary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showDeleteModal = false
                        },
                      },
                    },
                    [_vm._v("Отмена")]
                  ),
                  _vm._v("  "),
                ],
                1
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        disabled: _vm.isDataSending,
                        variant: "custom-danger",
                      },
                      on: { click: _vm.deletePurchase },
                    },
                    [_vm._v("Удалить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          ref: "publish-modal",
          attrs: {
            centered: "",
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
            title: "Опубликовать закупку",
          },
          model: {
            value: _vm.showPublishModal,
            callback: function ($$v) {
              _vm.showPublishModal = $$v
            },
            expression: "showPublishModal",
          },
        },
        [
          _c("div", { staticClass: "d-block text-left" }, [
            _vm._v(
              "Вы действительно хотите опубликовать простую закупку " +
                _vm._s(_vm.purchase.registration_number) +
                "?"
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "w-100 text-",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mr-2",
                      attrs: {
                        disabled: _vm.isDataSending,
                        variant: "custom-outline-secondary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.showPublishModal = false
                        },
                      },
                    },
                    [_vm._v("Отмена")]
                  ),
                ],
                1
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.isDataSending,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        disabled: _vm.isDataSending,
                        variant: "custom-blue",
                      },
                      on: { click: _vm.publishPurchase },
                    },
                    [_vm._v("Опубликовать")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }