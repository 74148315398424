var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showForm
        ? _c(
            "b-container",
            { staticClass: "register_page_header_block", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { md: "10" } }, [
                        _c(
                          "div",
                          { staticClass: "fs-52 fw-600 white-color pt-3" },
                          [_vm._v("Регистрация организации")]
                        ),
                      ]),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right my-auto",
                          attrs: { md: "2" },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "btn btn-custom-danger",
                              attrs: {
                                href: _vm.$links.manual.registration,
                                target: "_blank",
                              },
                            },
                            [_vm._v("Инструкция")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tabs",
                    {
                      staticClass: "w-100 register_customer py-3",
                      attrs: { "content-class": "invisible" },
                      model: {
                        value: _vm.tabIndex,
                        callback: function ($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        [
                          _c(
                            "registration-tab-header",
                            {
                              attrs: {
                                slot: "title",
                                "has-errors": _vm.$v.form.organization.$invalid,
                                number: "01",
                              },
                              slot: "title",
                            },
                            [
                              _vm._v(
                                " Регистрационные и контактные данные организации"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c(
                            "registration-tab-header",
                            {
                              attrs: {
                                slot: "title",
                                "has-errors": _vm.$v.form.user.$invalid,
                                number: "02",
                              },
                              slot: "title",
                            },
                            [_vm._v(" Идентификационные данные пользователя")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c(
                            "registration-tab-header",
                            {
                              attrs: {
                                slot: "title",
                                "has-errors": _vm.$v.form.checkRules.$invalid,
                                number: "03",
                              },
                              slot: "title",
                            },
                            [_vm._v(" Завершение создания заявления")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showForm
        ? _c(
            "b-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "w-100",
                      attrs: { "nav-class": "invisible" },
                      model: {
                        value: _vm.tabIndex,
                        callback: function ($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex",
                      },
                    },
                    [
                      _c(
                        "b-tab",
                        [
                          _c("text-header", [_vm._v("Данные организации")]),
                          _c("form-row-single-select", {
                            attrs: {
                              disabled: true,
                              options: _vm.$globalDictionaries.countries,
                              v: _vm.$v.form.organization.country_id,
                              label:
                                "Страна резидентом которой является Ваша компания",
                              "label-field": "title",
                            },
                            model: {
                              value: _vm.form.organization.country_id,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "country_id",
                                  $$v
                                )
                              },
                              expression: "form.organization.country_id",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.name,
                              label: "Наименование",
                            },
                            model: {
                              value: _vm.form.organization.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "name", $$v)
                              },
                              expression: "form.organization.name",
                            },
                          }),
                          _c("form-row-edit-date-2", {
                            attrs: {
                              v: _vm.$v.form.organization.registration_date,
                              label: "Дата государственной регистрации",
                            },
                            model: {
                              value: _vm.form.organization.registration_date,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "registration_date",
                                  $$v
                                )
                              },
                              expression: "form.organization.registration_date",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.registration_number,
                              label: "Государственный регистрационный номер",
                            },
                            model: {
                              value: _vm.form.organization.registration_number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization,
                                  "registration_number",
                                  $$v
                                )
                              },
                              expression:
                                "form.organization.registration_number",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              disabled: true,
                              v: _vm.$v.form.organization.inn,
                              label: "ИНН",
                            },
                            model: {
                              value: _vm.form.organization.inn,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "inn", $$v)
                              },
                              expression: "form.organization.inn",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.okpo,
                              label: "ОКПО",
                            },
                            model: {
                              value: _vm.form.organization.okpo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "okpo", $$v)
                              },
                              expression: "form.organization.okpo",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.head,
                              label: "ФИО руководителя",
                            },
                            model: {
                              value: _vm.form.organization.head,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.organization, "head", $$v)
                              },
                              expression: "form.organization.head",
                            },
                          }),
                          _c("text-header", [_vm._v("Контактная информация")]),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.contacts
                                .legal_address,
                              label: "Адрес юридический",
                            },
                            model: {
                              value:
                                _vm.form.organization.contacts.legal_address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization.contacts,
                                  "legal_address",
                                  $$v
                                )
                              },
                              expression:
                                "form.organization.contacts.legal_address",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.contacts
                                .postal_address,
                              label: "Адрес почтовый",
                            },
                            model: {
                              value:
                                _vm.form.organization.contacts.postal_address,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization.contacts,
                                  "postal_address",
                                  $$v
                                )
                              },
                              expression:
                                "form.organization.contacts.postal_address",
                            },
                          }),
                          _c("form-row-edit-email", {
                            attrs: {
                              "email-confirmed":
                                _vm.form.organization.contacts.email_confirmed,
                              v: _vm.$v.form.organization.contacts.email,
                              value: _vm.form.organization.contacts.email,
                              "with-validation": true,
                              label: "Адрес электронной почты",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.organization.contacts.email = $event
                              },
                              "email-confirmed": function ($event) {
                                _vm.form.organization.contacts.email_confirmed =
                                  $event
                              },
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.contacts.website,
                              label: "Адрес сайта",
                            },
                            model: {
                              value: _vm.form.organization.contacts.website,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization.contacts,
                                  "website",
                                  $$v
                                )
                              },
                              expression: "form.organization.contacts.website",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.contacts.phone,
                              label: "Телефон",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.organization.contacts.phone = $event
                              },
                            },
                            model: {
                              value: _vm.form.organization.contacts.phone,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization.contacts,
                                  "phone",
                                  $$v
                                )
                              },
                              expression: "form.organization.contacts.phone",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.organization.contacts
                                .contact_person_name,
                              label:
                                "ФИО контактного лица при осуществлении закупок",
                            },
                            model: {
                              value:
                                _vm.form.organization.contacts
                                  .contact_person_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.form.organization.contacts,
                                  "contact_person_name",
                                  $$v
                                )
                              },
                              expression:
                                "form.organization.contacts.contact_person_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("text-header", [
                            _vm._v("Идентификационные данные пользователя"),
                          ]),
                          _c("form-row-edit-text", {
                            attrs: { v: _vm.$v.form.user.name, label: "ФИО" },
                            model: {
                              value: _vm.form.user.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "name", $$v)
                              },
                              expression: "form.user.name",
                            },
                          }),
                          _c("form-row-edit-email", {
                            attrs: {
                              disabled: true,
                              "email-confirmed": _vm.form.user.email_confirmed,
                              v: _vm.$v.form.user.email,
                              value: _vm.form.user.email,
                              "with-validation": true,
                              description:
                                "Данный адрес будет использоваться как логин при входе в ИС",
                              label: "Адрес электронной почты",
                            },
                            on: {
                              value: function ($event) {
                                _vm.form.user.email = $event
                              },
                              "email-confirmed": function ($event) {
                                _vm.form.user.email_confirmed = $event
                              },
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.user.password,
                              label: "Пароль",
                              type: "password",
                            },
                            model: {
                              value: _vm.form.user.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "password", $$v)
                              },
                              expression: "form.user.password",
                            },
                          }),
                          _c("form-row-edit-text", {
                            attrs: {
                              v: _vm.$v.form.user.phone,
                              label: "Телефон",
                            },
                            model: {
                              value: _vm.form.user.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.form.user, "phone", $$v)
                              },
                              expression: "form.user.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("text-header", [
                            _vm._v("Завершение создания заявления"),
                          ]),
                          _c("registration-confirmation-text-block"),
                          _c(
                            "b-form-group",
                            { attrs: { state: !_vm.$v.form.checkRules } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: { state: !_vm.$v.form.checkRules },
                                  model: {
                                    value: _vm.form.checkRules,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "checkRules", $$v)
                                    },
                                    expression: "form.checkRules",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "grey-color fw-600" },
                                    [
                                      _vm._v(
                                        "Подтверждаю перечисленные выше условия"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "my-5" },
                    [
                      _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isGettingXML,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _vm.tabIndex > 0
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mx-2 text-uppercase",
                                  attrs: {
                                    variant: "custom-outline-secondary",
                                  },
                                  on: { click: _vm.previousTab },
                                },
                                [_vm._v("Назад")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.tabIndex < 2
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mx-2 text-uppercase",
                              attrs: { variant: "custom-blue" },
                              on: { click: _vm.nextTab },
                            },
                            [_vm._v("Далее")]
                          )
                        : _vm._e(),
                      _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isGettingXML,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _vm.tabIndex === 2
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mx-2 text-uppercase",
                                  attrs: {
                                    disabled: _vm.$v.form.$invalid,
                                    variant: "custom-blue",
                                  },
                                  on: { click: _vm.register },
                                },
                                [_vm._v("Готово")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.tabIndex === 2 && _vm.$v.form.$invalid
                        ? _c("span", { staticClass: "text-danger mx-2" }, [
                            _vm._v(
                              "Заполните все обязательные поля на каждой вкладке."
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          ref: "enter-org-modal",
          attrs: {
            "hide-header-close": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            size: "lg",
            title: "Регистрация",
          },
          on: { show: _vm.getCountryId },
          model: {
            value: _vm.showModal,
            callback: function ($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal",
          },
        },
        [
          _c(
            "div",
            { staticClass: "d-block" },
            [
              _c(
                "b-overlay",
                {
                  attrs: {
                    show: _vm.checkingEnterOrg,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-normal": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-overlay",
                    {
                      attrs: {
                        show: _vm.isGettingCountryId,
                        opacity: "0.6",
                        rounded: "",
                        "spinner-normal": "",
                        "spinner-variant": "primary",
                      },
                    },
                    [
                      _c("form-row-single-select", {
                        attrs: {
                          options: _vm.$globalDictionaries.countries,
                          v: _vm.$v.form.organization.country_id,
                          label:
                            "Выберите страну резидентом которой является Ваша компания",
                          "label-field": "title",
                        },
                        model: {
                          value: _vm.form.organization.country_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form.organization, "country_id", $$v)
                          },
                          expression: "form.organization.country_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("form-row-edit-text", {
                    attrs: {
                      errorText: _vm.innError,
                      v: _vm.$v.form.organization.inn,
                      label: "ИНН",
                    },
                    on: { input: _vm.innChange },
                    model: {
                      value: _vm.form.organization.inn,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.organization, "inn", $$v)
                      },
                      expression: "form.organization.inn",
                    },
                  }),
                  _c("form-row-edit-email", {
                    attrs: {
                      errorText: _vm.emailError,
                      v: _vm.$v.form.user.email,
                      value: _vm.form.user.email,
                      description:
                        "Данный адрес будет использоваться как логин при входе в ИС",
                      label: "Адрес электронной почты пользователя",
                    },
                    on: {
                      change: _vm.emailChange,
                      value: function ($event) {
                        _vm.form.user.email = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "w-100",
              attrs: { slot: "modal-footer" },
              slot: "modal-footer",
            },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2 text-uppercase",
                  attrs: {
                    disabled: _vm.checkingEnterOrg,
                    variant: "custom-outline-secondary",
                  },
                  on: { click: _vm.cancelEnterOrg },
                },
                [_vm._v("Отмена")]
              ),
              _c(
                "b-overlay",
                {
                  staticClass: "d-inline-block",
                  attrs: {
                    show: _vm.checkingEnterOrg,
                    opacity: "0.6",
                    rounded: "",
                    "spinner-small": "",
                    "spinner-variant": "primary",
                  },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: {
                        disabled:
                          _vm.$v.form.organization.inn.$invalid ||
                          _vm.$v.form.user.email.$invalid,
                        variant: "custom-blue",
                      },
                      on: { click: _vm.checkEnterOrg },
                    },
                    [_vm._v(" Продолжить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }