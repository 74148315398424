var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.proposal
    ? _c(
        "div",
        [
          _c(
            "b-container",
            {
              staticClass: "purchase-offer-header-block",
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          { staticClass: "fs-40 fw-600 white-color mt-3 mb-3" },
                          [
                            _vm._v(
                              "Заявка на прямую закупку № " +
                                _vm._s(
                                  _vm.proposal.purchase.registration_number
                                )
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("p", { staticClass: "white-color fs-14" }, [
                          _c("b", [_vm._v("Статус")]),
                          _vm._v(": " + _vm._s(_vm.proposal.status.title)),
                        ]),
                        _vm.proposal.status.id === "rejected" &&
                        _vm.proposal.rejection_reason
                          ? _c("p", { staticClass: "white-color fs-14" }, [
                              _c("b", [_vm._v("Причина отклонения")]),
                              _vm._v(
                                ": " + _vm._s(_vm.proposal.rejection_reason)
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "text-header",
                    [
                      _vm._v(" Прямая закупка № "),
                      _c(
                        "router-link",
                        {
                          staticClass: "system_link-style2",
                          attrs: {
                            target: "_blank",
                            to: {
                              name: "DirectPurchaseShow",
                              params: { id: _vm.proposal.purchase.id },
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.proposal.purchase.registration_number) +
                              " (" +
                              _vm._s(_vm.proposal.purchase.status.title) +
                              ")"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("text-header", [
                    _vm._v("Подтверждение требований к поставщикам"),
                  ]),
                  _c("text-row-document", {
                    attrs: {
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label: "Карточка предприятия",
                    },
                    model: {
                      value: _vm.proposal.organization_card,
                      callback: function ($$v) {
                        _vm.$set(_vm.proposal, "organization_card", $$v)
                      },
                      expression: "proposal.organization_card",
                    },
                  }),
                  _c("text-row", {
                    attrs: {
                      "label-cols-lg": 4,
                      "label-cols-sm": 4,
                      label:
                        "Срок действия предложения от даты окончания приема заявок, дней",
                    },
                    model: {
                      value: _vm.proposal.proposal_validity_days,
                      callback: function ($$v) {
                        _vm.$set(_vm.proposal, "proposal_validity_days", $$v)
                      },
                      expression: "proposal.proposal_validity_days",
                    },
                  }),
                  _vm.proposal.purchase.supplier_requirements.has_no_arrears &&
                  _vm.proposal.supplier_requirements_confirmed.no_arrears.id
                    ? _c("text-row-document", {
                        attrs: {
                          "label-cols-lg": 4,
                          "label-cols-sm": 4,
                          label:
                            "Документ с подтверждением отсутствия задолженности по налогам и сборам",
                        },
                        model: {
                          value:
                            _vm.proposal.supplier_requirements_confirmed
                              .no_arrears,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.proposal.supplier_requirements_confirmed,
                              "no_arrears",
                              $$v
                            )
                          },
                          expression:
                            "proposal.supplier_requirements_confirmed.no_arrears",
                        },
                      })
                    : _vm._e(),
                  _vm.proposal.purchase.supplier_requirements
                    .is_reliable_supplier
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            "label-cols-sm": "4",
                            "label-cols-lg": "4",
                            label:
                              "Подтверждаю, что не присутствую в реестре недобросовестных поставщиков",
                            "label-class":
                              "fs-14 grey-color fw-700 d-flex align-items-baseline",
                          },
                        },
                        [
                          _c("b-form-checkbox", {
                            staticClass: "fs-14 grey-color mt-2",
                            attrs: { disabled: "" },
                            model: {
                              value:
                                _vm.proposal.supplier_requirements_confirmed
                                  .is_reliable_supplier,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.proposal.supplier_requirements_confirmed,
                                  "is_reliable_supplier",
                                  $$v
                                )
                              },
                              expression:
                                "proposal.supplier_requirements_confirmed.is_reliable_supplier",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.proposal.purchase.supplier_requirements
                    .has_additional_requirements
                    ? [
                        _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c("p", { staticClass: "fs-14 grey-color mt-2" }, [
                              _c("strong", [
                                _vm._v(
                                  "Подтверждаю соответствие дополнительным требованиям, приложив следующие документы:"
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                        _vm._l(
                          _vm.proposal.supplier_requirements_confirmed
                            .additional_requirements,
                          function (document) {
                            return _c("text-row-document", {
                              key: document.id,
                              attrs: {
                                "label-cols-lg": 4,
                                "label-cols-sm": 4,
                                label: document.title,
                              },
                              model: {
                                value: document.attachment,
                                callback: function ($$v) {
                                  _vm.$set(document, "attachment", $$v)
                                },
                                expression: "document.attachment",
                              },
                            })
                          }
                        ),
                      ]
                    : _vm._e(),
                  _vm.proposal.purchase.supplier_requirements
                    .similar_experience_amounts > 0 &&
                  _vm.proposal.supplier_requirements_confirmed
                    .similar_experience_amounts.id
                    ? _c("text-row-document", {
                        attrs: {
                          "label-cols-lg": 4,
                          "label-cols-sm": 4,
                          label:
                            "Документ с подтверждением опыта аналогичных поставок на сумму не менее " +
                            _vm.$formatPrice(
                              _vm.proposal.purchase.supplier_requirements
                                .similar_experience_amounts
                            ),
                        },
                        model: {
                          value:
                            _vm.proposal.supplier_requirements_confirmed
                              .similar_experience_amounts,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.proposal.supplier_requirements_confirmed,
                              "similar_experience_amounts",
                              $$v
                            )
                          },
                          expression:
                            "proposal.supplier_requirements_confirmed.similar_experience_amounts",
                        },
                      })
                    : _vm._e(),
                  _vm.proposal.attachments.length > 0
                    ? _c("text-row-documents", {
                        attrs: {
                          "label-cols-lg": 4,
                          "label-cols-sm": 4,
                          label: "Дополнительные документы",
                        },
                        model: {
                          value: _vm.proposal.attachments,
                          callback: function ($$v) {
                            _vm.$set(_vm.proposal, "attachments", $$v)
                          },
                          expression: "proposal.attachments",
                        },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "my-2", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c("text-header", [_vm._v("Спецификация")]),
                  _c("table", { staticClass: "table" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "tcw-50" }, [_vm._v("№")]),
                        _c("th", [
                          _vm._v("Наименование товара (работ, услуг)"),
                        ]),
                        _c("th", [_vm._v("Характеристики")]),
                        _c("th", { staticClass: "tcw-100" }, [
                          _vm._v("Количество"),
                        ]),
                        _c("th", { staticClass: "tcw-100" }, [
                          _vm._v("Цена за ед."),
                        ]),
                        _c("th", { staticClass: "tcw-100" }, [
                          _vm._v("Валюта"),
                        ]),
                        _c("th", { staticClass: "tcw-100" }, [
                          _vm._v("Стоимость"),
                        ]),
                        _c("th", { staticClass: "tcw-100" }, [
                          _vm._v("Ед.изм"),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.proposal.deliverables, function (item, index) {
                        return _c(
                          "tr",
                          _vm._b({ key: item.id }, "tr", item, false),
                          [
                            _c("td", [_vm._v(_vm._s(index + 1))]),
                            _c("td", [_vm._v(_vm._s(item.name))]),
                            _c("td", [_vm._v(_vm._s(item.characteristics))]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(item.quantity)),
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                _vm._s(_vm.$formatPrice(item.price_per_unit))
                              ),
                            ]),
                            _c("td", [
                              _vm._v(
                                _vm._s(_vm.proposal.purchase.currency_title)
                              ),
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$formatPrice(
                                    item.quantity * item.price_per_unit
                                  )
                                )
                              ),
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm._v(_vm._s(item.okei.local_symbol)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c("text-row-price", {
                    attrs: { label: "Стоимость заявки" },
                    model: {
                      value: _vm.proposal.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.proposal, "price", $$v)
                      },
                      expression: "proposal.price",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-container",
            {
              staticClass: "my-2",
              attrs: { fluid: "", id: "action_buttons_row" },
            },
            [
              _c(
                "b-container",
                { staticClass: "mb-5" },
                [
                  _vm.$store.getters.isLoggedIn &&
                  _vm.proposal.actions.supplier.edit
                    ? [
                        _c(
                          "router-link",
                          {
                            staticClass:
                              "text-nowrap btn btn-custom-blue text-uppercase mx-2",
                            attrs: {
                              to: {
                                name: "DirectPurchaseNewProposal",
                                params: { id: _vm.proposal.purchase.id },
                              },
                            },
                          },
                          [_vm._v(" Изменить заявку ")]
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }