<template>
    <div v-if="proposal">
        <b-container fluid class="purchase-offer-header-block">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-40 fw-600 white-color mt-3 mb-3">Заявка на конкурс № {{ proposal.purchase.registration_number }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="white-color fs-14"><b>Статус</b>: {{ proposal.status.title }}</p>
                        <p class="white-color fs-14" v-if="proposal.status.id === 'rejected' && proposal.rejection_reason"><b>Причина отклонения</b>: {{ proposal.rejection_reason }}</p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>
                    Конкурс №
                    <router-link class="system_link-style2" target="_blank" :to="{ name: 'CompetitivePurchaseShow', params: { id: proposal.purchase.id } }"
                        >{{ proposal.purchase.registration_number }} ({{ proposal.purchase.status.title }})</router-link
                    >
                </text-header>
                <text-header>Подтверждение требований к поставщикам</text-header>
                <text-row-document :label-cols-lg="4" :label-cols-sm="4" label="Карточка предприятия" v-model="proposal.organization_card" />
                <text-row :label-cols-lg="4" :label-cols-sm="4" label="Срок действия предложения от даты окончания приема заявок, дней" v-model="proposal.proposal_validity_days" />
                <text-row-document
                    :label-cols-lg="4"
                    :label-cols-sm="4"
                    label="Документ с подтверждением отсутствия задолженности по налогам и сборам"
                    v-model="proposal.supplier_requirements_confirmed.no_arrears"
                    v-if="proposal.purchase.supplier_requirements.has_no_arrears && proposal.supplier_requirements_confirmed.no_arrears.id" />
                <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="4"
                    label="Подтверждаю, что не присутствую в реестре недобросовестных поставщиков"
                    label-class="fs-14 grey-color fw-700 d-flex align-items-baseline"
                    v-if="proposal.purchase.supplier_requirements.is_reliable_supplier">
                    <b-form-checkbox class="fs-14 grey-color mt-2" v-model="proposal.supplier_requirements_confirmed.is_reliable_supplier" disabled />
                </b-form-group>
                <template v-if="proposal.purchase.supplier_requirements.has_additional_requirements">
                    <div class="form-row">
                        <div class="col">
                            <p class="fs-14 grey-color mt-2">
                                <strong>Подтверждаю соответствие дополнительным требованиям, приложив следующие документы:</strong>
                            </p>
                        </div>
                    </div>
                    <text-row-document :label-cols-lg="4" :label-cols-sm="4" v-for="document in proposal.supplier_requirements_confirmed.additional_requirements" :label="document.title" v-model="document.attachment" :key="document.id" />
                </template>
                <text-row-document
                    :label-cols-lg="4"
                    :label-cols-sm="4"
                    :label="'Документ с подтверждением опыта аналогичных поставок на сумму не менее ' + $formatPrice(proposal.purchase.supplier_requirements.similar_experience_amounts)"
                    v-model="proposal.supplier_requirements_confirmed.similar_experience_amounts"
                    v-if="proposal.purchase.supplier_requirements.similar_experience_amounts > 0 && proposal.supplier_requirements_confirmed.similar_experience_amounts.id" />
                <text-row-documents :label-cols-lg="4" :label-cols-sm="4" label="Дополнительные документы" v-model="proposal.attachments" v-if="proposal.attachments.length > 0" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2">
            <b-container>
                <text-header>Спецификация</text-header>
                <table class="table">
                    <thead>
                        <tr>
                            <th class="tcw-50">№</th>
                            <th>Наименование товара (работ, услуг)</th>
                            <th>Характеристики</th>
                            <th class="tcw-100">Количество</th>
                            <th class="tcw-100">Цена за ед.</th>
                            <th class="tcw-100">Валюта</th>
                            <th class="tcw-100">Стоимость</th>
                            <th class="tcw-100">Ед.изм</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in proposal.deliverables" :key="item.id" v-bind="item">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.characteristics }}</td>
                            <td class="text-right">{{ item.quantity }}</td>
                            <td class="text-right">{{ $formatPrice(item.price_per_unit) }}</td>
                            <td>{{ proposal.purchase.currency_title }}</td>
                            <td class="text-right">{{ $formatPrice(item.quantity * item.price_per_unit) }}</td>
                            <td class="text-right">{{ item.okei.local_symbol }}</td>
                        </tr>
                    </tbody>
                </table>
                <text-row-price label="Стоимость заявки" v-model="proposal.price" />
            </b-container>
        </b-container>
        <b-container fluid class="my-2" id="action_buttons_row">
            <b-container class="mb-5">
                <template v-if="$store.getters.isLoggedIn && proposal.actions.supplier.edit">
                    <router-link class="text-nowrap btn btn-custom-blue text-uppercase mx-2" :to="{ name: 'CompetitivePurchaseNewProposal', params: { id: proposal.purchase.id } }"> Изменить заявку </router-link>
                </template>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import TextRowDocument from '@/components/common/form-rows/text-row-document.vue';

export default {
    name: 'competitive-proposal-show',
    components: {
        TextRowDocument,
    },
    data() {
        return {
            id: this.$route.params.id,
            proposal: null,
            visible: [],
        };
    },
    async mounted() {
        await this.fillData();
    },
    methods: {
        async fillData() {
            this.proposal = await this.$api.proposals.competitive.get(this.id);
            this.$scrollToHash();
        },
        refresh() {
            this.fillData();
        },
    },
};
</script>

<style scoped>
.purchase-offer-header-block {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.link {
    color: var(--est-blue-2) !important;
}
.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}
</style>
