<template>
    <div>
        <b-container v-if="$store.getters.isLoggedIn && purchase.actions.customer.select_winner && purchase.private_data && purchase.private_data.winner_selection && purchase.status.id === 'summarizing'" class="my-2" fluid>
            <b-container>
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Процедура процесса закупки</h2>
                    </b-col>
                    <b-col class="text-right my-auto" md="2">
                        <a :href="$links.manual.competitive_purchase_envelopes_opening" target="_blank" class="btn btn-custom-danger">Инструкция</a>
                    </b-col>
                </b-row>
                <text-row label="Состав комиссии" value=" " />
                <b-row class="my-2" no-gutters>
                    <b-col class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>ФИО</th>
                                    <th>Должность</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in purchase.private_data.winner_selection.commission" :key="index" v-bind="item">
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.position }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>

        <b-container
            fluid
            class="my-2"
            v-if="$store.getters.isLoggedIn && purchase.actions.customer.select_winner && purchase.private_data && purchase.private_data.proposals && purchase.private_data.winner_selection && purchase.status.id === 'summarizing'">
            <b-container v-if="purchase.private_data.proposals && purchase.private_data.proposals.length > 0">
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Конкурсные заявки</h2>
                    </b-col>
                </b-row>
                <private-winner-selection-proposal-card
                    v-for="(proposal, index) in purchase.private_data.proposals"
                    :key="'proposal_' + index"
                    :proposal="proposal"
                    :purchase-id="purchase.id"
                    :supplier-requirements="purchase.supplier_requirements"
                    :purchase="purchase"
                    :index="index"
                    :with-rating="true"
                    v-model="proposal.rating"
                    :confirmed="purchase.private_data.winner_selection.confirmed"
                    @refresh="refresh" />
                <competitive-purchase-store-winner-selection-button :purchase="purchase" :ratings="proposalRatings" :proposals="purchase.private_data.proposals" @store="refresh" />
                <p class="text-danger">Процедура выбора победителя не завершена пока не загружен файл итогового протокола с подписями членов комиссии по выбору победителя</p>
            </b-container>
            <b-container v-if="purchase.private_data.proposals && purchase.private_data.proposals.length === 0">
                <b-row>
                    <b-col md="10">
                        <h2 class="grey-color font-weight-bold my-3">Конкурсные заявки отсутствуют</h2>
                    </b-col>
                </b-row>
                <competitive-purchase-store-winner-selection-button :purchase="purchase" :ratings="proposalRatings" :proposals="purchase.private_data.proposals" @store="refresh" />
                <p class="text-danger">Процедура выбора победителя не завершена пока не загружен файл итогового протокола с подписями членов комиссии по выбору победителя</p>
            </b-container>
        </b-container>

        <b-container
            v-if="
                $store.getters.isLoggedIn &&
                purchase.actions.customer.select_winner &&
                purchase.private_data &&
                purchase.private_data.winner_selection &&
                purchase.private_data.winner_selection.confirmed &&
                purchase.status.id === 'summarizing'
            "
            class="my-2"
            fluid>
            <b-container>
                <text-header>Протокол процесса закупки</text-header>
                <a class="btn btn-custom-blue text-uppercase" :href="purchase.private_data.winner_selection.protocol_template_link">Скачать протокол процесса закупки</a>
                <form-row-upload-single-document title="Подписанный членами комиссии протокол процесса закупки" v-model="form.attachment" :v="$v.form.attachment" header-class="fs-14 fw-700" />
                <div class="w-100 text-right">
                    <b-overlay :show="isDataSending" rounded opacity="0.6" spinner-small spinner-variant="primary" class="d-inline-block">
                        <b-button variant="custom-danger" class="text-uppercase" :disabled="$v.$invalid" @click="showConfirmModal = true">Отправить подписанный членами комиссии протокол процесса закупки</b-button>
                    </b-overlay>
                </div>
            </b-container>
            <b-modal ref="confirm-modal" v-model="showConfirmModal" centered hide-header-close no-close-on-backdrop no-close-on-esc size="lg" title="Отправка подписанного протокола процесса закупки">
                <p class="text-left">Вы действительно хотите отправить подписанный членами комиссии протокол процесса закупки?</p>
                <p class="text-left text-danger fw-600">ВНИМАНИЕ! Данное действие является необратимым, отменить его будет невозможно!</p>
                <div slot="modal-footer" class="w-100 text-right">
                    <b-button class="text-uppercase mr-2" variant="custom-outline-secondary" @click="showConfirmModal = false">Отмена</b-button>
                    <b-button class="text-uppercase" variant="custom-danger" @click="confirmWinnerSelection">Отправить подписанный членами комиссии протокол процесса закупки</b-button>
                </div>
            </b-modal>
        </b-container>
    </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import FormRowUploadSingleDocument from '@/components/common/form-rows/form-row-upload-single-document.vue';
import PrivateWinnerSelectionProposalCard from '@/components/purchases/competitive/private-winner-selection-proposal-card.vue';
import CompetitivePurchaseStoreWinnerSelectionButton from '@/components/common/competitive-purchase-store-winner-selection-button.vue';

export default {
    name: 'private-winner-selection-block',
    components: {
        CompetitivePurchaseStoreWinnerSelectionButton,
        PrivateWinnerSelectionProposalCard,
        FormRowUploadSingleDocument,
    },
    props: {
        purchase: {},
    },
    validations: {
        form: {
            attachment: { required },
        },
    },
    data() {
        return {
            showConfirmModal: false,
            showStoreModal: false,
            form: {
                attachment: null,
            },
            ratings: [],
            isDataSending: false,
        };
    },
    methods: {
        async confirmWinnerSelection() {
            this.isDataSending = true;
            if (await this.$api.purchases.competitive.confirmWinnerSelection(this.purchase.id, this.form)) {
                this.showConfirmModal = false;
                this.$emit('confirm');
            }
            this.isDataSending = false;
        },
        refresh() {
            this.$emit('refresh');
        },
    },
    computed: {
        proposalRatings() {
            return this.purchase.private_data && this.purchase.private_data.proposals
                ? this.purchase.private_data.proposals.map(function (proposal) {
                      return {
                          id: proposal.id,
                          rating: parseInt(proposal.rating) ?? null,
                      };
                  })
                : null;
        },
    },
};
</script>
