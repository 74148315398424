var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c("text-header", [_vm._v("Документы")]),
              _c(
                "file-uploader",
                {
                  attrs: {
                    "with-favourite": _vm.documents.length > 0,
                    "files-list": _vm.documents,
                    "modal-title":
                      "Выберите документы для прикрепления к заявке",
                    "btn-text": "Добавить документ",
                  },
                  on: { set_file: _vm.addFile },
                },
                [_vm._v(" Добавить документ ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", { staticStyle: { width: "50px" } }),
                  _c("th", [_vm._v("Наименование")]),
                  _c("th"),
                ]),
              ]),
              _vm.attachments.length
                ? _c(
                    "tbody",
                    _vm._l(_vm.attachments, function (document, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _c("td", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.$getAttachmentDownloadLink(
                                  document.id
                                ),
                              },
                            },
                            [_vm._v(_vm._s(document.name))]
                          ),
                        ]),
                        _c(
                          "td",
                          { attrs: { width: "1" } },
                          [
                            _c(
                              "b-button",
                              {
                                staticStyle: { background: "transparent" },
                                attrs: { variant: "light", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFile(index)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/images/trash_icon.svg",
                                    alt: "trash_icon",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "3", align: "center" } }, [
                        _vm._v("Документы не добавлены."),
                      ]),
                    ]),
                  ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }