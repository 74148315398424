<template>
    <b-card class="fs-14 my-2">
        <proposal-card-title :index="index" :proposal="proposal" class="fs-16" />
        <b-row>
            <b-col md="6" class="my-1">
                <p>
                    Время подачи заявки: <strong>{{ getDateTimeFormatSeconds(proposal.created_at) }}</strong>
                </p>
                <p v-if="proposal.proposal_validity_days">
                    Срок действия предложения от даты окончания приема заявок, дней: <strong>{{ proposal.proposal_validity_days }}</strong>
                </p>
            </b-col>
            <b-col md="6" class="my-1">
                <b>{{ proposal.status.title }}</b
                ><br />
                <template v-if="proposal.status.id === 'rejected'"><b>Причина отклонения:</b> {{ proposal.rejection_reason }} </template>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import ProposalCardTitle from '@/components/purchases/direct/proposal-card-title.vue';

export default {
    name: 'public-proposal-card',
    props: ['proposal', 'index'],
    components: { ProposalCardTitle },
    data() {
        return {
            detailsVisible: false,
        };
    },
};
</script>

<style scoped>
a {
    color: var(--est-blue-2);
}
.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}
.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
